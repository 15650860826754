import styled from 'styled-components';
import { Shadow1, Shadow2, Shadow3 } from '../Shadows';
import { Regular18 } from '../Fonts';
import { PX48 } from '../Px';

export interface LetterBoxProps {
  letter?: string;
  focus?: boolean;
}

export const LetterBox = ({ letter, focus }: LetterBoxProps) => {
  return (
    <LetterBoxDiv focus={focus} letter={letter}>
      {letter}
    </LetterBoxDiv>
  );
};

export const LetterBoxDiv = styled.div<LetterBoxProps>`
  ${Regular18};
  ${(props) =>
    (props.letter && Shadow1) || (props.focus && Shadow3) || Shadow2};
  border-radius: 10px;
  min-width: ${PX48};
  height: ${PX48};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 150ms;
`;
