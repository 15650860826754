import styled from 'styled-components';
import React from 'react';

export interface FlexBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  flexWrap?: 'flex-wrap' | 'nowrap' | 'wrap' | 'wrap-reverse';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'left'
    | 'right'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?:
    | 'stretch'
    | 'flex-start'
    | 'start'
    | 'self-start'
    | 'flex-end'
    | 'end'
    | 'self-end'
    | 'center'
    | 'baseline';
  alignContent?:
    | 'normal'
    | 'flex-start'
    | 'start'
    | 'flex-end'
    | 'end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch';
  gap?: number | string;
  rowGap?: number | string;
  columnGap?: number | string;
  height?: number | string;
  width?: number | string;
  overflow?: 'visible' | 'hidden' | 'scroll' | 'auto';
  overflowX?: 'visible' | 'hidden' | 'scroll' | 'auto';
  overflowY?: 'visible' | 'hidden' | 'scroll' | 'auto';
  margin?: string;
  padding?: string;
}

export const FlexBox = styled.div<FlexBoxProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  flex-wrap: ${(props) => props.flexWrap};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  align-content: ${(props) => props.alignContent};
  row-gap: ${(props) => props.rowGap};
  column-gap: ${(props) => props.columnGap};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  gap: ${(props) => props.gap};
  overflow: ${(props) => props.overflow};
  overflow-x: ${(props) => props.overflowX};
  overflow-y: ${(props) => props.overflowY};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
`;
