import styled, { css } from 'styled-components';
import { HTMLAttributes } from 'react';
import { Bold16 } from '../Fonts';
import {
  Blue0,
  Blue8,
  Green0,
  Green4,
  Neutral3,
  Neutral8,
  Pink0,
  Pink7,
} from '../Colors';
import { Shadow1 } from '../Shadows';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  width?: string | number;
  styleType?: 'primary';
  disabled?: boolean;
  color?: 'blue' | 'pink' | 'green';
}

export const Button = styled.button<ButtonProps>`
  ${Bold16};
  ${Shadow1};
  width: ${(props) => props.width};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  padding: 10px 13px;
  border-radius: 10px;
  box-sizing: border-box;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  border: none;

  ${(props) =>
    (props.disabled &&
      css`
        color: ${Neutral3};
        background: ${Neutral8};
      `) ||
    (props.color === 'pink' &&
      css`
        color: ${Pink0};
        background: ${Pink7};
      `) ||
    (props.color === 'green' &&
      css`
        color: ${Green0};
        background: ${Green4};
      `) ||
    css`
      color: ${Blue0};
      background: ${Blue8};
    `}}
`;
