import { css } from 'styled-components';

export const Shadow1 = css`
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
`;

export const Shadow2 = css`
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.12);
`;

export const Shadow3 = css`
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15), 0px 3px 6px rgba(0, 0, 0, 0.1);
`;

export const Shadow4 = css`
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.15),
    0px 5px 10px rgba(0, 0, 0, 0.05);
`;

export const Shadow5 = css`
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
`;
