import { css } from 'styled-components';

export const RegularFontFamily = css`
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
`;

const BoldFontFamily = css`
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
`;

export const Regular12 = css`
  ${RegularFontFamily};
  font-size: 12px;
  line-height: 14px;
`;

export const Bold12 = css`
  ${BoldFontFamily};
  font-size: 12px;
  line-height: 14px;
`;

export const Regular14 = css`
  ${RegularFontFamily};
  font-size: 14px;
  line-height: 17px;
`;

export const Bold14 = css`
  ${BoldFontFamily};
  font-size: 14px;
  line-height: 17px;
`;

export const Regular16 = css`
  ${RegularFontFamily};
  font-size: 16px;
  line-height: 19px;
`;

export const Bold16 = css`
  ${BoldFontFamily};
  font-size: 16px;
  line-height: 19px;
`;

export const Regular18 = css`
  ${RegularFontFamily};
  font-size: 18px;
  line-height: 21px;
`;

export const Bold18 = css`
  ${BoldFontFamily};
  font-size: 18px;
  line-height: 21px;
`;

export const Regular20 = css`
  ${RegularFontFamily};
  font-size: 20px;
  line-height: 24px;
`;

export const Bold20 = css`
  ${BoldFontFamily};
  font-size: 20px;
  line-height: 24px;
`;

export const Regular24 = css`
  ${RegularFontFamily};
  font-size: 24px;
  line-height: 28px;
`;

export const Bold24 = css`
  ${BoldFontFamily};
  font-size: 24px;
  line-height: 28px;
`;

export const Regular32 = css`
  ${RegularFontFamily};
  font-size: 32px;
  line-height: 28px;
`;

export const Bold32 = css`
  ${BoldFontFamily};
  font-size: 32px;
  line-height: 28px;
`;

export const Regular48 = css`
  ${RegularFontFamily};
  font-size: 48px;
  line-height: 57px;
`;

export const Bold48 = css`
  ${BoldFontFamily};
  font-size: 48px;
  line-height: 57px;
`;
