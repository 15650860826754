import { Box } from '../components/structural/box/Box';
import { PX24, PX256, PX32, PX512, PX8 } from '../components/Px';
import { WordsDictionary } from '../data/Words';
import { useEffect, useState } from 'react';
import { FlexBox } from '../components/structural/flex/FlexBox';
import { VStack } from '../components/structural/stack/VStack';
import { WordBox } from '../components/wordbox/WordBox';
import { LetterBox } from '../components/letterbox/LetterBox';
import { Button } from '../components/button/Button';
import { LetterPicker } from '../components/letterpicker/LetterPicker';
import { SixLetterWords } from '../data/6LetterWords';

export const GamePage = () => {
  const [coreLetterOptions, setCoreLetterOptions] = useState<string[]>([]);
  const [letterOptions, setLetterOptions] = useState<string[]>([]);
  const [words, setWords] = useState<{ [key: number]: string[] }>({});
  const [indexesSelected, setIndexesSelected] = useState<number[]>([]);
  const [guessedWords, setGuessedWords] = useState<string[]>([]);
  const [lastGuessedWord, setLastGuessedWord] = useState('');

  useEffect(() => {
    if (letterOptions.length !== 6) {
      const i = getRandomInt(SixLetterWords.length);
      const randomSixLetterWord = SixLetterWords[i];
      setLetterOptions(shuffleArrayOfLetters(randomSixLetterWord.split('')));
      setCoreLetterOptions(randomSixLetterWord.split(''));
    }
  }, [letterOptions]);

  useEffect(() => {
    if (lastGuessedWord !== '') {
      const d = document.getElementById(lastGuessedWord);
      if (d) {
        d.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [lastGuessedWord]);

  useEffect(() => {
    const newWordsArr: { [key: number]: string[] } = {};
    for (let index = 3; index <= 6; index++) {
      const permutations = createPermutations(index, coreLetterOptions);

      const allPermutationWords = [];
      for (const p of permutations) {
        const sortedPermutation = p
          .split('')
          .sort((a, b) => a.localeCompare(b))
          .join('');

        const permutationWords = WordsDictionary[sortedPermutation];
        if (permutationWords) {
          allPermutationWords.push(...permutationWords);
        }
      }

      newWordsArr[index] = Array.from(new Set(allPermutationWords));
    }

    setWords(newWordsArr);
  }, [coreLetterOptions]);

  const doClear = () => setIndexesSelected([]);

  return (
    <Box
      maxWidth={PX512}
      padding={`${PX24} 0`}
      style={{ boxSizing: 'border-box' }}
      height="100vh"
    >
      <FlexBox flexDirection="column" alignItems="stretch" height="100%">
        <Box overflow="scroll" flexGrow={1}>
          <FlexBox justifyContent="space-evenly" flexDirection="row">
            {[3, 4, 5, 6].map((size) => {
              return (
                <VStack spacing={PX8}>
                  {words[size]?.map((w) => {
                    return (
                      <WordBox
                        id={w}
                        word={
                          guessedWords.find((gw) => w === gw) ? w : undefined
                        }
                        key={w}
                      />
                    );
                  })}
                </VStack>
              );
            })}
          </FlexBox>
        </Box>

        <Box height={PX256}>
          <Box marginTop={PX32}>
            <FlexBox justifyContent="space-evenly">
              {[0, 1, 2, 3, 4, 5].map((i) => {
                const letter =
                  indexesSelected.length > i
                    ? letterOptions[indexesSelected[i]]
                    : undefined;
                const focus = indexesSelected.length === i;

                return <LetterBox letter={letter} focus={focus} />;
              })}
            </FlexBox>
          </Box>

          <Box marginTop={PX32}>
            <FlexBox justifyContent="space-evenly">
              <Button
                disabled={indexesSelected.length > 0}
                onClick={() => {
                  setLetterOptions([...shuffleArrayOfLetters(letterOptions)]);
                }}
              >
                Shuffle
              </Button>
              <Button
                color="green"
                onClick={() => {
                  if (indexesSelected.length >= 3) {
                    const wordsOfSize = words[indexesSelected.length];

                    const lettersSelected = indexesSelected.map((i) => {
                      return letterOptions[i];
                    });

                    const target = wordsOfSize.find(
                      (w) => w === lettersSelected.join('')
                    );

                    if (target) {
                      setGuessedWords([...guessedWords, target]);
                      setLastGuessedWord(target);
                    }

                    doClear();
                  }
                }}
              >
                Submit
              </Button>
              <Button color="pink" onClick={doClear}>
                Clear
              </Button>
            </FlexBox>
          </Box>

          <Box marginTop={PX32}>
            <FlexBox justifyContent="space-evenly">
              {letterOptions.map((anOption, index) => {
                return (
                  <LetterPicker
                    letter={anOption}
                    disabled={
                      indexesSelected.find(
                        (selectedIndex) => index === selectedIndex
                      ) !== undefined
                    }
                    onClick={() => {
                      console.log('click');
                      const newIndexesSelected = [...indexesSelected];
                      newIndexesSelected.push(index);
                      setIndexesSelected(newIndexesSelected);
                    }}
                  />
                );
              })}
            </FlexBox>
          </Box>
        </Box>
      </FlexBox>
    </Box>
  );
};

function createPermutations(
  permutationSize: number,
  arrayOfLetters: string[]
): string[] {
  const f = (
    permutationSize: number,
    startingIndex: number,
    arrayOfLetters: string[]
  ): string[] => {
    const permutations: string[] = [];

    for (
      let currentDepthIndex = startingIndex;
      currentDepthIndex < arrayOfLetters.length - (permutationSize - 1);
      currentDepthIndex++
    ) {
      const letter = arrayOfLetters[currentDepthIndex];

      if (permutationSize == 1) {
        permutations.push(letter);
      } else {
        const laterPermutations = f(
          permutationSize - 1,
          currentDepthIndex + 1,
          arrayOfLetters
        );

        laterPermutations.forEach((p) => {
          permutations.push([letter, p].join(''));
        });
      }
    }

    return permutations;
  };

  return f(permutationSize, 0, arrayOfLetters);
}

function getRandomInt(max: number): number {
  return Math.floor(Math.random() * max);
}

function shuffleArrayOfLetters(array: string[]): string[] {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
