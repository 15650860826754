export const PX4 = '4px';
export const PX8 = '8px';
export const PX12 = '12px';
export const PX16 = '16px';
export const PX24 = '24px';
export const PX32 = '32px';
export const PX48 = '48px';
export const PX64 = '64px';
export const PX96 = '96px';
export const PX128 = '128px';
export const PX192 = '192px';
export const PX256 = '256px';
export const PX384 = '384px';
export const PX512 = '512px';
export const PX640 = '640px';
export const PX768 = '768px';
