import styled, { css } from 'styled-components';
import { PX4, PX64 } from '../Px';
import { Green3, Neutral8, Pink5 } from '../Colors';
import { Regular12 } from '../Fonts';
import { HTMLAttributes } from 'react';

export interface WordBoxProps {
  word?: string;
  notGuessed?: boolean;
  id?: string;
}

export const WordBox = ({ word, notGuessed, id }: WordBoxProps) => {
  return (
    <WordBoxDiv
      border={notGuessed ? 'pink' : word ? 'green' : undefined}
      id={id}
    >
      {word || <>&#x200b;</>}
    </WordBoxDiv>
  );
};

interface WordBoxDiv extends HTMLAttributes<HTMLDivElement> {
  border?: 'green' | 'pink';
}

const WordBoxDiv = styled.div<WordBoxDiv>`
  ${Regular12};
  width: ${PX64};
  ${(props) =>
    (props.border === undefined &&
      css`
        border: 1px solid ${Neutral8};
      `) ||
    (props.border === 'pink' &&
      css`
        border: 1px solid ${Pink5};
      `) ||
    (props.border === 'green' &&
      css`
        border: 1px solid ${Green3};
      `)};
  text-align: center;
  border-radius: 10px;
  padding: ${PX4};
`;
