import React from 'react';
import { FlexBox } from '../flex/FlexBox';

export interface VStackProps {
  children?: React.ReactNode;
  spacing?: string;
  margin?: string;
}

export function VStack(props: VStackProps) {
  const { margin, spacing, children } = props;

  return (
    <FlexBox rowGap={spacing} flexDirection="column" margin={margin}>
      {children}
    </FlexBox>
  );
}
