import styled from 'styled-components';
import React from 'react';
import { FlexChildrenProps } from '../flex/ChildrenProps';

export interface BoxProps
  extends React.HTMLAttributes<HTMLDivElement>,
    FlexChildrenProps {
  width?: number | string;
  maxWidth?: string;
  minWidth?: string;
  height?: string;
  maxHeight?: string;
  minHeight?: string;
  padding?: string;
  margin?: string;
  marginTop?: string;
  overflow?: 'visible' | 'hidden' | 'scroll' | 'auto';
  overflowX?: 'visible' | 'hidden' | 'scroll' | 'auto';
  overflowY?: 'visible' | 'hidden' | 'scroll' | 'auto';
  position?:
    | 'static'
    | 'relative'
    | 'absolute'
    | 'fixed'
    | 'sticky'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset';
  onClick?: () => void;
  textAlign?: 'left' | 'right' | 'center';
  display?: 'none' | 'inline' | 'block' | 'inline-block';
}

export const Box = styled.div<BoxProps>`
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  min-width: ${(props) => props.minWidth};
  height: ${(props) => props.height};
  max-height: ${(props) => props.maxHeight};
  min-height: ${(props) => props.minHeight};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};
  padding: ${(props) => props.padding};
  flex: ${(props) => props.flex};
  flex-grow: ${(props) => props.flexGrow};
  flex-shrink: ${(props) => props.flexShrink};
  overflow: ${(props) => props.overflow};
  overflow-x: ${(props) => props.overflowX};
  overflow-y: ${(props) => props.overflowY};
  position: ${(props) => props.position};
  cursor: ${(props) => (props.onClick ? 'pointer' : undefined)};
  text-align: ${(props) => props.textAlign};
  display: ${(props) => props.display};
`;
