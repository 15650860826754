import styled from 'styled-components';
import { Shadow1, Shadow2 } from '../Shadows';
import { Regular18 } from '../Fonts';
import { PX48 } from '../Px';
import { Neutral7 } from '../Colors';

export interface LetterPickerProps {
  letter?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const LetterPicker = ({
  letter,
  disabled,
  onClick,
}: LetterPickerProps) => {
  return (
    <LetterPickerDiv disabled={disabled} onClick={onClick}>
      {letter}
    </LetterPickerDiv>
  );
};

interface LetterPickerDivProps {
  disabled?: boolean;
}

export const LetterPickerDiv = styled.div<LetterPickerDivProps>`
  ${Regular18};
  ${(props) => (props.disabled && Shadow1) || Shadow2};
  border-radius: 10px;
  min-width: ${PX48};
  height: ${PX48};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.disabled && Neutral7) || 'inherit'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  transition: 150ms;
`;
