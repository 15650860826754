export const WordsDictionary: { [key: string]: string[] } = {
  "aaabnn": [
    "banana"
  ],
  "aaabrz": [
    "bazaar"
  ],
  "aaajmp": [
    "pajama"
  ],
  "aaakls": [
    "alaska"
  ],
  "aaalms": [
    "salaam"
  ],
  "aaamnp": [
    "panama"
  ],
  "aaartv": [
    "avatar"
  ],
  "aab": [
    "baa"
  ],
  "aabb": [
    "abba",
    "baba"
  ],
  "aabblo": [
    "balboa"
  ],
  "aabchs": [
    "casbah"
  ],
  "aabck": [
    "aback"
  ],
  "aabcsu": [
    "abacus"
  ],
  "aabdlm": [
    "lambda"
  ],
  "aabdor": [
    "aboard",
    "abroad"
  ],
  "aabelz": [
    "ablaze"
  ],
  "aabggs": [
    "gasbag"
  ],
  "aabgmn": [
    "bagman"
  ],
  "aabinz": [
    "banzai"
  ],
  "aabln": [
    "banal"
  ],
  "aabmnr": [
    "barman"
  ],
  "aabmnt": [
    "batman"
  ],
  "aabnw": [
    "bwana"
  ],
  "aabrv": [
    "brava"
  ],
  "aacder": [
    "arcade"
  ],
  "aacehp": [
    "apache"
  ],
  "aacelp": [
    "palace"
  ],
  "aacemr": [
    "camera"
  ],
  "aacenp": [
    "canape"
  ],
  "aacers": [
    "caesar"
  ],
  "aacetv": [
    "vacate"
  ],
  "aacfil": [
    "facial"
  ],
  "aachtt": [
    "attach"
  ],
  "aacilr": [
    "racial"
  ],
  "aacimn": [
    "maniac"
  ],
  "aacirv": [
    "caviar"
  ],
  "aacjkl": [
    "jackal"
  ],
  "aacktt": [
    "attack"
  ],
  "aacln": [
    "canal"
  ],
  "aaclns": [
    "canals"
  ],
  "aaclsu": [
    "casual"
  ],
  "aacltu": [
    "actual"
  ],
  "aacmny": [
    "cayman"
  ],
  "aacmrt": [
    "tarmac"
  ],
  "aacmsw": [
    "macaws"
  ],
  "aacnry": [
    "canary"
  ],
  "aacnsv": [
    "canvas"
  ],
  "aacntv": [
    "vacant"
  ],
  "aacrst": [
    "carats"
  ],
  "aacrt": [
    "carat"
  ],
  "aacs": [
    "casa"
  ],
  "aadeg": [
    "adage"
  ],
  "aadegm": [
    "damage"
  ],
  "aadegn": [
    "agenda"
  ],
  "aadeh": [
    "ahead"
  ],
  "aademm": [
    "madame"
  ],
  "aademz": [
    "amazed"
  ],
  "aadepr": [
    "parade"
  ],
  "aadfir": [
    "afraid"
  ],
  "aadhil": [
    "dahlia"
  ],
  "aadhrz": [
    "hazard"
  ],
  "aadilr": [
    "radial"
  ],
  "aadlns": [
    "sandal"
  ],
  "aadls": [
    "salad"
  ],
  "aadlss": [
    "salads"
  ],
  "aadmm": [
    "madam"
  ],
  "aadmmn": [
    "madman"
  ],
  "aadmr": [
    "drama"
  ],
  "aadmrs": [
    "dramas"
  ],
  "aadmyy": [
    "mayday"
  ],
  "aadn": [
    "nada"
  ],
  "aadnp": [
    "panda"
  ],
  "aadpt": [
    "adapt"
  ],
  "aadpyy": [
    "payday"
  ],
  "aadrr": [
    "radar"
  ],
  "aadrsw": [
    "awards"
  ],
  "aadrw": [
    "award"
  ],
  "aadt": [
    "data"
  ],
  "aaeggr": [
    "garage"
  ],
  "aaegl": [
    "algae"
  ],
  "aaegmn": [
    "manage"
  ],
  "aaegrv": [
    "ravage"
  ],
  "aaegsv": [
    "savage"
  ],
  "aaeilr": [
    "aerial"
  ],
  "aaeimn": [
    "anemia"
  ],
  "aaeknw": [
    "awaken"
  ],
  "aaekrt": [
    "karate"
  ],
  "aaeksw": [
    "awakes"
  ],
  "aaekw": [
    "awake"
  ],
  "aaellp": [
    "paella"
  ],
  "aaelpp": [
    "appeal"
  ],
  "aaelrv": [
    "larvae"
  ],
  "aaemns": [
    "seaman"
  ],
  "aaemsz": [
    "amazes"
  ],
  "aaemz": [
    "amaze"
  ],
  "aaenr": [
    "arena"
  ],
  "aaensu": [
    "nausea"
  ],
  "aaeppr": [
    "appear"
  ],
  "aaer": [
    "area"
  ],
  "aaers": [
    "areas"
  ],
  "aaerw": [
    "aware"
  ],
  "aaffir": [
    "affair"
  ],
  "aafijt": [
    "fajita"
  ],
  "aafim": [
    "mafia"
  ],
  "aafinr": [
    "farina"
  ],
  "aafirs": [
    "safari"
  ],
  "aaflot": [
    "afloat"
  ],
  "aaflt": [
    "fatal"
  ],
  "aafnu": [
    "fauna"
  ],
  "aafr": [
    "afar"
  ],
  "aag": [
    "aga"
  ],
  "aagg": [
    "gaga"
  ],
  "aaghmr": [
    "graham"
  ],
  "aaghr": [
    "aargh"
  ],
  "aagin": [
    "again"
  ],
  "aaginn": [
    "angina"
  ],
  "aaginu": [
    "iguana"
  ],
  "aaginv": [
    "vagina"
  ],
  "aagjn": [
    "ganja"
  ],
  "aagjru": [
    "jaguar"
  ],
  "aagl": [
    "gala"
  ],
  "aaglxy": [
    "galaxy"
  ],
  "aagmm": [
    "gamma"
  ],
  "aagnor": [
    "angora"
  ],
  "aagnp": [
    "pagan"
  ],
  "aags": [
    "saga"
  ],
  "aaguv": [
    "guava"
  ],
  "aah": [
    "aha"
  ],
  "aahh": [
    "haha"
  ],
  "aahjr": [
    "rajah"
  ],
  "aahknu": [
    "kahuna"
  ],
  "aahks": [
    "kasha"
  ],
  "aahlo": [
    "aloha"
  ],
  "aahlp": [
    "alpha"
  ],
  "aahm": [
    "amah"
  ],
  "aahmns": [
    "shaman"
  ],
  "aahmrs": [
    "ashram"
  ],
  "aahmst": [
    "asthma"
  ],
  "aahmtz": [
    "matzah"
  ],
  "aahpty": [
    "apathy"
  ],
  "aahrss": [
    "harass"
  ],
  "aailmn": [
    "animal",
    "manila"
  ],
  "aailmp": [
    "impala"
  ],
  "aailms": [
    "salami"
  ],
  "aailns": [
    "salina"
  ],
  "aails": [
    "alias"
  ],
  "aailsv": [
    "saliva"
  ],
  "aaimnr": [
    "airman",
    "marina"
  ],
  "aaimr": [
    "maria"
  ],
  "aainpt": [
    "pinata"
  ],
  "aaintt": [
    "attain"
  ],
  "aaipzz": [
    "piazza"
  ],
  "aair": [
    "aria"
  ],
  "aairt": [
    "tiara"
  ],
  "aairwy": [
    "airway"
  ],
  "aaistw": [
    "awaits"
  ],
  "aaitw": [
    "await"
  ],
  "aajnp": [
    "japan"
  ],
  "aajr": [
    "ajar"
  ],
  "aajv": [
    "java"
  ],
  "aakky": [
    "kayak"
  ],
  "aaklo": [
    "koala"
  ],
  "aakmr": [
    "karma"
  ],
  "aakpp": [
    "kappa"
  ],
  "aakpr": [
    "parka"
  ],
  "aakrt": [
    "karat"
  ],
  "aal": [
    "ala"
  ],
  "aallm": [
    "llama"
  ],
  "aallrv": [
    "larval"
  ],
  "aallw": [
    "walla"
  ],
  "aalm": [
    "alma",
    "lama"
  ],
  "aalmnp": [
    "napalm"
  ],
  "aalmnu": [
    "manual"
  ],
  "aalmnw": [
    "lawman"
  ],
  "aalmny": [
    "layman"
  ],
  "aalmo": [
    "alamo"
  ],
  "aalmor": [
    "amoral"
  ],
  "aalmps": [
    "plasma"
  ],
  "aalmr": [
    "alarm"
  ],
  "aalmrs": [
    "alarms"
  ],
  "aaln": [
    "alan",
    "anal"
  ],
  "aalnns": [
    "annals"
  ],
  "aalnnu": [
    "annual"
  ],
  "aalns": [
    "nasal"
  ],
  "aalnv": [
    "naval"
  ],
  "aalpy": [
    "playa"
  ],
  "aalpz": [
    "plaza"
  ],
  "aalrst": [
    "astral"
  ],
  "aalrsy": [
    "salary"
  ],
  "aalrt": [
    "altar"
  ],
  "aalru": [
    "laura"
  ],
  "aalrz": [
    "lazar"
  ],
  "aals": [
    "alas"
  ],
  "aalss": [
    "salsa"
  ],
  "aalst": [
    "atlas"
  ],
  "aalswy": [
    "always"
  ],
  "aalv": [
    "lava"
  ],
  "aam": [
    "ama"
  ],
  "aamm": [
    "mama"
  ],
  "aammm": [
    "mamma"
  ],
  "aamnn": [
    "manna"
  ],
  "aamnoz": [
    "amazon"
  ],
  "aamnrt": [
    "mantra"
  ],
  "aamny": [
    "mayan"
  ],
  "aamor": [
    "aroma"
  ],
  "aamrtu": [
    "trauma"
  ],
  "aamss": [
    "massa"
  ],
  "aamy": [
    "maya"
  ],
  "aan": [
    "ana"
  ],
  "aann": [
    "anna",
    "nana"
  ],
  "aanost": [
    "sonata"
  ],
  "aanrs": [
    "saran"
  ],
  "aanrtz": [
    "tarzan"
  ],
  "aanstz": [
    "stanza"
  ],
  "aansu": [
    "sauna"
  ],
  "aanwyy": [
    "anyway"
  ],
  "aaorru": [
    "aurora"
  ],
  "aapp": [
    "papa"
  ],
  "aapr": [
    "para"
  ],
  "aaprt": [
    "apart"
  ],
  "aapst": [
    "pasta",
    "tapas"
  ],
  "aaqu": [
    "aqua"
  ],
  "aarrtt": [
    "tartar"
  ],
  "aarry": [
    "array"
  ],
  "aarsty": [
    "astray"
  ],
  "aarsu": [
    "auras"
  ],
  "aarsww": [
    "warsaw"
  ],
  "aaru": [
    "aura"
  ],
  "aav": [
    "ava"
  ],
  "aawy": [
    "away"
  ],
  "abbbel": [
    "babble"
  ],
  "abbcot": [
    "bobcat"
  ],
  "abbcry": [
    "crabby"
  ],
  "abbcsy": [
    "scabby"
  ],
  "abbdel": [
    "dabble"
  ],
  "abbder": [
    "barbed"
  ],
  "abbe": [
    "babe"
  ],
  "abbeis": [
    "babies"
  ],
  "abbejr": [
    "jabber"
  ],
  "abbelr": [
    "rabble"
  ],
  "abbelu": [
    "bauble"
  ],
  "abberr": [
    "barber"
  ],
  "abbes": [
    "babes"
  ],
  "abbey": [
    "abbey"
  ],
  "abbgry": [
    "grabby"
  ],
  "abbgy": [
    "gabby"
  ],
  "abbhsy": [
    "shabby"
  ],
  "abbir": [
    "rabbi"
  ],
  "abbirt": [
    "rabbit"
  ],
  "abbko": [
    "kabob"
  ],
  "abbl": [
    "blab"
  ],
  "abbmoo": [
    "bamboo"
  ],
  "abbnoo": [
    "baboon"
  ],
  "abbors": [
    "absorb"
  ],
  "abbost": [
    "abbots"
  ],
  "abbot": [
    "abbot"
  ],
  "abbr": [
    "barb"
  ],
  "abbrs": [
    "barbs"
  ],
  "abbty": [
    "tabby"
  ],
  "abbu": [
    "babu"
  ],
  "abby": [
    "baby"
  ],
  "abc": [
    "cab"
  ],
  "abcdek": [
    "backed"
  ],
  "abcdtu": [
    "abduct"
  ],
  "abceem": [
    "became"
  ],
  "abceh": [
    "beach"
  ],
  "abcehl": [
    "bleach"
  ],
  "abcehr": [
    "breach"
  ],
  "abcekr": [
    "backer"
  ],
  "abcel": [
    "cable"
  ],
  "abcels": [
    "cables"
  ],
  "abceno": [
    "beacon"
  ],
  "abcer": [
    "brace"
  ],
  "abcers": [
    "braces"
  ],
  "abcfir": [
    "fabric"
  ],
  "abch": [
    "bach"
  ],
  "abchnr": [
    "branch"
  ],
  "abcht": [
    "batch"
  ],
  "abciim": [
    "iambic"
  ],
  "abcin": [
    "cabin"
  ],
  "abcins": [
    "cabins"
  ],
  "abcis": [
    "basic"
  ],
  "abciss": [
    "basics"
  ],
  "abck": [
    "back"
  ],
  "abckl": [
    "black"
  ],
  "abckls": [
    "blacks"
  ],
  "abckpu": [
    "backup"
  ],
  "abcks": [
    "backs"
  ],
  "abclmy": [
    "cymbal"
  ],
  "abcmot": [
    "combat"
  ],
  "abcno": [
    "bacon"
  ],
  "abcnor": [
    "carbon"
  ],
  "abcor": [
    "carbo",
    "cobra"
  ],
  "abcors": [
    "cobras"
  ],
  "abcorx": [
    "boxcar"
  ],
  "abcr": [
    "carb",
    "crab"
  ],
  "abcrs": [
    "carbs",
    "crabs"
  ],
  "abcs": [
    "cabs",
    "scab"
  ],
  "abcss": [
    "scabs"
  ],
  "abcsu": [
    "scuba"
  ],
  "abd": [
    "bad",
    "dab"
  ],
  "abddee": [
    "beaded"
  ],
  "abdder": [
    "badder"
  ],
  "abdeem": [
    "beamed"
  ],
  "abdeet": [
    "debate"
  ],
  "abdefl": [
    "fabled"
  ],
  "abdefr": [
    "barfed"
  ],
  "abdeg": [
    "badge"
  ],
  "abdegg": [
    "bagged"
  ],
  "abdegn": [
    "banged"
  ],
  "abdego": [
    "bodega"
  ],
  "abdegr": [
    "barged"
  ],
  "abdegs": [
    "badges"
  ],
  "abdehs": [
    "bashed"
  ],
  "abdeht": [
    "bathed"
  ],
  "abdei": [
    "abide"
  ],
  "abdeil": [
    "bailed"
  ],
  "abdeis": [
    "biased"
  ],
  "abdeit": [
    "baited"
  ],
  "abdek": [
    "baked"
  ],
  "abdekr": [
    "barked"
  ],
  "abdel": [
    "blade"
  ],
  "abdell": [
    "balled"
  ],
  "abdelm": [
    "bedlam",
    "blamed"
  ],
  "abdels": [
    "blades"
  ],
  "abdenn": [
    "banned"
  ],
  "abdenp": [
    "bedpan"
  ],
  "abdeo": [
    "abode",
    "adobe"
  ],
  "abder": [
    "bared",
    "beard",
    "bread"
  ],
  "abderr": [
    "barred"
  ],
  "abders": [
    "beards"
  ],
  "abderv": [
    "braved"
  ],
  "abdes": [
    "based",
    "beads"
  ],
  "abdesu": [
    "abused"
  ],
  "abdett": [
    "batted"
  ],
  "abdilr": [
    "bridal"
  ],
  "abdint": [
    "bandit"
  ],
  "abdir": [
    "braid",
    "rabid"
  ],
  "abdl": [
    "bald"
  ],
  "abdln": [
    "bland"
  ],
  "abdly": [
    "badly"
  ],
  "abdn": [
    "band"
  ],
  "abdnou": [
    "abound"
  ],
  "abdnr": [
    "brand"
  ],
  "abdnry": [
    "brandy"
  ],
  "abdns": [
    "bands"
  ],
  "abdor": [
    "board",
    "broad"
  ],
  "abdors": [
    "boards",
    "broads"
  ],
  "abdr": [
    "bard",
    "brad",
    "drab"
  ],
  "abdrsu": [
    "absurd"
  ],
  "abdwy": [
    "bawdy"
  ],
  "abeegl": [
    "beagle"
  ],
  "abeehv": [
    "behave"
  ],
  "abeein": [
    "beanie"
  ],
  "abeeln": [
    "enable"
  ],
  "abeent": [
    "beaten"
  ],
  "abeerr": [
    "bearer"
  ],
  "abeert": [
    "beater",
    "berate",
    "rebate"
  ],
  "abeerv": [
    "beaver"
  ],
  "abeerw": [
    "beware"
  ],
  "abefhl": [
    "behalf"
  ],
  "abefl": [
    "fable"
  ],
  "abefll": [
    "befall"
  ],
  "abeggr": [
    "beggar"
  ],
  "abegl": [
    "bagel"
  ],
  "abeglm": [
    "gamble"
  ],
  "abegls": [
    "bagels"
  ],
  "abegn": [
    "began"
  ],
  "abegoz": [
    "gazebo"
  ],
  "abegr": [
    "barge"
  ],
  "abegrs": [
    "barges"
  ],
  "abegt": [
    "begat"
  ],
  "abehlr": [
    "herbal"
  ],
  "abehr": [
    "rehab"
  ],
  "abehrt": [
    "bertha",
    "breath"
  ],
  "abeht": [
    "bathe"
  ],
  "abeill": [
    "liable"
  ],
  "abeilv": [
    "viable"
  ],
  "abeily": [
    "bailey"
  ],
  "abeirs": [
    "rabies"
  ],
  "abek": [
    "bake",
    "beak"
  ],
  "abekl": [
    "bleak"
  ],
  "abekmr": [
    "embark"
  ],
  "abeknr": [
    "banker"
  ],
  "abekr": [
    "baker",
    "brake",
    "break"
  ],
  "abekrr": [
    "barker"
  ],
  "abekrs": [
    "bakers",
    "brakes",
    "breaks"
  ],
  "abekry": [
    "bakery"
  ],
  "abeks": [
    "bakes"
  ],
  "abekst": [
    "basket"
  ],
  "abel": [
    "able",
    "bale"
  ],
  "abell": [
    "label"
  ],
  "abellr": [
    "baller"
  ],
  "abells": [
    "labels"
  ],
  "abellt": [
    "ballet"
  ],
  "abelm": [
    "blame"
  ],
  "abelmr": [
    "marble",
    "ramble"
  ],
  "abelms": [
    "blames"
  ],
  "abelnu": [
    "nebula",
    "unable"
  ],
  "abelrr": [
    "barrel"
  ],
  "abelrv": [
    "verbal"
  ],
  "abelry": [
    "barely",
    "barley"
  ],
  "abelrz": [
    "blazer"
  ],
  "abels": [
    "sable"
  ],
  "abelst": [
    "stable",
    "tables"
  ],
  "abelsu": [
    "usable"
  ],
  "abelsz": [
    "blazes"
  ],
  "abelt": [
    "table"
  ],
  "abeltt": [
    "battle",
    "tablet"
  ],
  "abelz": [
    "blaze"
  ],
  "abem": [
    "beam"
  ],
  "abemr": [
    "amber"
  ],
  "abems": [
    "beams"
  ],
  "abemsy": [
    "maybes"
  ],
  "abemy": [
    "maybe"
  ],
  "aben": [
    "bane",
    "bean"
  ],
  "abennr": [
    "banner"
  ],
  "abenrr": [
    "barren"
  ],
  "abenrt": [
    "banter"
  ],
  "abenry": [
    "nearby"
  ],
  "abenrz": [
    "brazen"
  ],
  "abens": [
    "beans"
  ],
  "abenst": [
    "absent"
  ],
  "abeov": [
    "above"
  ],
  "abeptu": [
    "upbeat"
  ],
  "aber": [
    "bare",
    "bear"
  ],
  "aberr": [
    "barre"
  ],
  "aberrt": [
    "barter"
  ],
  "aberrv": [
    "braver"
  ],
  "abers": [
    "bears",
    "saber"
  ],
  "aberss": [
    "sabers"
  ],
  "aberst": [
    "breast"
  ],
  "abersu": [
    "abuser"
  ],
  "abersz": [
    "zebras"
  ],
  "abertt": [
    "batter"
  ],
  "aberty": [
    "betray"
  ],
  "aberuu": [
    "bureau"
  ],
  "aberv": [
    "brave"
  ],
  "aberz": [
    "zebra"
  ],
  "abes": [
    "base",
    "sabe"
  ],
  "abess": [
    "bases"
  ],
  "abesst": [
    "beasts"
  ],
  "abest": [
    "baste",
    "bates",
    "beast",
    "beats"
  ],
  "abesu": [
    "abuse"
  ],
  "abet": [
    "beat",
    "beta"
  ],
  "abetuy": [
    "beauty"
  ],
  "abeu": [
    "beau"
  ],
  "abfr": [
    "barf"
  ],
  "abg": [
    "bag"
  ],
  "abggy": [
    "baggy"
  ],
  "abgikn": [
    "baking"
  ],
  "abgilm": [
    "gimbal"
  ],
  "abgimt": [
    "gambit"
  ],
  "abgimy": [
    "bigamy"
  ],
  "abginr": [
    "baring"
  ],
  "abgllo": [
    "global"
  ],
  "abgn": [
    "bang"
  ],
  "abgns": [
    "bangs"
  ],
  "abgr": [
    "brag",
    "garb",
    "grab"
  ],
  "abgrs": [
    "brags",
    "grabs"
  ],
  "abgs": [
    "bags"
  ],
  "abgy": [
    "gaby"
  ],
  "abh": [
    "bah"
  ],
  "abhins": [
    "banish"
  ],
  "abhiop": [
    "phobia"
  ],
  "abhis": [
    "sahib"
  ],
  "abhist": [
    "habits"
  ],
  "abhit": [
    "habit"
  ],
  "abhl": [
    "blah"
  ],
  "abhmsu": [
    "ambush"
  ],
  "abhorr": [
    "harbor"
  ],
  "abhrs": [
    "brash"
  ],
  "abhs": [
    "bash"
  ],
  "abhst": [
    "baths"
  ],
  "abht": [
    "bath"
  ],
  "abiil": [
    "alibi"
  ],
  "abiils": [
    "alibis"
  ],
  "abiit": [
    "tibia"
  ],
  "abil": [
    "bail"
  ],
  "abilno": [
    "albino"
  ],
  "abilrt": [
    "tribal"
  ],
  "abilru": [
    "burial"
  ],
  "abilrz": [
    "brazil"
  ],
  "abils": [
    "bails",
    "basil"
  ],
  "abily": [
    "bialy"
  ],
  "abimru": [
    "barium"
  ],
  "abinot": [
    "obtain"
  ],
  "abinr": [
    "brain"
  ],
  "abinrs": [
    "brains"
  ],
  "abinry": [
    "binary"
  ],
  "abins": [
    "basin",
    "sabin"
  ],
  "abiorr": [
    "barrio"
  ],
  "abirr": [
    "briar"
  ],
  "abis": [
    "bias"
  ],
  "abiss": [
    "basis"
  ],
  "abit": [
    "bait"
  ],
  "abj": [
    "jab"
  ],
  "abjno": [
    "banjo"
  ],
  "abjot": [
    "jabot"
  ],
  "abjs": [
    "jabs"
  ],
  "abkl": [
    "balk"
  ],
  "abkln": [
    "blank"
  ],
  "abklns": [
    "blanks"
  ],
  "abkn": [
    "bank"
  ],
  "abkns": [
    "banks"
  ],
  "abkr": [
    "bark"
  ],
  "abkrs": [
    "barks"
  ],
  "abks": [
    "bask"
  ],
  "abl": [
    "lab"
  ],
  "abll": [
    "ball"
  ],
  "abllno": [
    "ballon"
  ],
  "abllot": [
    "ballot"
  ],
  "ablls": [
    "balls"
  ],
  "abllsy": [
    "ballsy"
  ],
  "ablm": [
    "balm",
    "blam",
    "lamb"
  ],
  "ablmru": [
    "lumbar"
  ],
  "ablms": [
    "lambs"
  ],
  "ablmsu": [
    "albums"
  ],
  "ablmu": [
    "album"
  ],
  "ablor": [
    "labor"
  ],
  "abloru": [
    "labour"
  ],
  "ablorw": [
    "barlow"
  ],
  "ablpru": [
    "burlap"
  ],
  "ablrtu": [
    "brutal"
  ],
  "ablrw": [
    "brawl"
  ],
  "abls": [
    "labs"
  ],
  "ablst": [
    "blast"
  ],
  "ablswy": [
    "bylaws"
  ],
  "ablw": [
    "bawl"
  ],
  "abm": [
    "bam"
  ],
  "abmnow": [
    "bowman"
  ],
  "abmru": [
    "rumba"
  ],
  "abn": [
    "ban"
  ],
  "abnor": [
    "baron"
  ],
  "abnot": [
    "baton"
  ],
  "abnoty": [
    "botany"
  ],
  "abnr": [
    "barn",
    "bran"
  ],
  "abnrtu": [
    "turban"
  ],
  "abnru": [
    "urban"
  ],
  "abo": [
    "boa"
  ],
  "abor": [
    "boar",
    "bora"
  ],
  "aborr": [
    "arbor"
  ],
  "aborrw": [
    "barrow"
  ],
  "abort": [
    "abort"
  ],
  "aborv": [
    "bravo"
  ],
  "abost": [
    "boast",
    "boats"
  ],
  "abot": [
    "boat"
  ],
  "abotu": [
    "about"
  ],
  "abouy": [
    "bayou"
  ],
  "abprtu": [
    "abrupt"
  ],
  "abpssy": [
    "bypass"
  ],
  "abr": [
    "bar",
    "bra"
  ],
  "abrs": [
    "bars",
    "bras"
  ],
  "abrss": [
    "brass"
  ],
  "abrst": [
    "brats"
  ],
  "abrsy": [
    "brays"
  ],
  "abrt": [
    "brat"
  ],
  "abru": [
    "bura"
  ],
  "abs": [
    "abs"
  ],
  "abss": [
    "bass"
  ],
  "abssw": [
    "swabs"
  ],
  "abst": [
    "bats",
    "stab",
    "tabs"
  ],
  "absuwy": [
    "subway"
  ],
  "absw": [
    "swab"
  ],
  "absy": [
    "bays"
  ],
  "abt": [
    "bat",
    "tab"
  ],
  "abtty": [
    "batty"
  ],
  "abtu": [
    "abut",
    "tuba"
  ],
  "aby": [
    "bay"
  ],
  "accdor": [
    "accord"
  ],
  "acceh": [
    "cache"
  ],
  "accehn": [
    "chance"
  ],
  "acceht": [
    "cachet"
  ],
  "acceip": [
    "ipecac"
  ],
  "accekl": [
    "cackle"
  ],
  "acceln": [
    "cancel"
  ],
  "accem": [
    "mecca"
  ],
  "accenr": [
    "cancer"
  ],
  "accent": [
    "accent"
  ],
  "accept": [
    "accept"
  ],
  "accers": [
    "scarce"
  ],
  "access": [
    "access"
  ],
  "accesu": [
    "accuse"
  ],
  "accgno": [
    "cognac"
  ],
  "accho": [
    "coach"
  ],
  "accht": [
    "catch"
  ],
  "acchty": [
    "catchy"
  ],
  "accilo": [
    "calico"
  ],
  "accilt": [
    "lactic"
  ],
  "accirt": [
    "arctic"
  ],
  "accitt": [
    "tactic"
  ],
  "acckl": [
    "clack"
  ],
  "acckr": [
    "crack"
  ],
  "acckrs": [
    "cracks"
  ],
  "acco": [
    "coca"
  ],
  "accoo": [
    "cocoa"
  ],
  "accstu": [
    "cactus"
  ],
  "accsuu": [
    "caucus"
  ],
  "acd": [
    "cad"
  ],
  "acddee": [
    "decade"
  ],
  "acddei": [
    "caddie"
  ],
  "acdden": [
    "danced"
  ],
  "acddin": [
    "candid"
  ],
  "acddit": [
    "addict"
  ],
  "acddy": [
    "caddy"
  ],
  "acde": [
    "aced"
  ],
  "acdees": [
    "ceased"
  ],
  "acdef": [
    "decaf",
    "faced"
  ],
  "acdeg": [
    "caged"
  ],
  "acdegr": [
    "graced"
  ],
  "acdeh": [
    "ached"
  ],
  "acdehk": [
    "hacked"
  ],
  "acdehr": [
    "arched"
  ],
  "acdehs": [
    "cashed",
    "chased"
  ],
  "acdeht": [
    "detach"
  ],
  "acdeiv": [
    "advice"
  ],
  "acdejk": [
    "jacked"
  ],
  "acdekl": [
    "lacked"
  ],
  "acdekp": [
    "packed"
  ],
  "acdekr": [
    "racked"
  ],
  "acdekt": [
    "tacked"
  ],
  "acdel": [
    "laced"
  ],
  "acdell": [
    "called"
  ],
  "acdelm": [
    "calmed"
  ],
  "acdeln": [
    "candle"
  ],
  "acdelp": [
    "placed"
  ],
  "acdelr": [
    "cradle"
  ],
  "acdels": [
    "scaled"
  ],
  "acdelw": [
    "clawed"
  ],
  "acdemp": [
    "camped"
  ],
  "acden": [
    "dance"
  ],
  "acdenn": [
    "canned"
  ],
  "acdeno": [
    "deacon"
  ],
  "acdenr": [
    "dancer"
  ],
  "acdens": [
    "dances"
  ],
  "acdep": [
    "paced"
  ],
  "acdeps": [
    "spaced"
  ],
  "acder": [
    "cared",
    "cedar",
    "raced"
  ],
  "acders": [
    "cedars",
    "sacred",
    "scared"
  ],
  "acdert": [
    "carted",
    "crated",
    "traced"
  ],
  "acderv": [
    "carved"
  ],
  "acderz": [
    "crazed"
  ],
  "acdes": [
    "cased"
  ],
  "acdesu": [
    "caused"
  ],
  "acdet": [
    "acted",
    "cadet"
  ],
  "acdev": [
    "caved"
  ],
  "acdey": [
    "decay"
  ],
  "acdh": [
    "chad"
  ],
  "acdi": [
    "acid"
  ],
  "acdilp": [
    "placid"
  ],
  "acdinr": [
    "rancid"
  ],
  "acdioz": [
    "zodiac"
  ],
  "acdis": [
    "acids"
  ],
  "acdl": [
    "clad"
  ],
  "acdls": [
    "scald"
  ],
  "acdnor": [
    "candor"
  ],
  "acdny": [
    "candy"
  ],
  "acdorw": [
    "coward"
  ],
  "acdr": [
    "card"
  ],
  "acdrs": [
    "cards"
  ],
  "ace": [
    "ace"
  ],
  "aceeip": [
    "apiece"
  ],
  "aceelr": [
    "cereal"
  ],
  "aceelv": [
    "cleave"
  ],
  "aceemn": [
    "menace"
  ],
  "aceemz": [
    "eczema"
  ],
  "aceep": [
    "peace"
  ],
  "aceeps": [
    "escape"
  ],
  "aceerr": [
    "career"
  ],
  "aceers": [
    "crease"
  ],
  "aceert": [
    "create"
  ],
  "acees": [
    "cease"
  ],
  "acef": [
    "cafe",
    "face"
  ],
  "acefft": [
    "affect"
  ],
  "acefin": [
    "fiance"
  ],
  "acefr": [
    "farce"
  ],
  "acefs": [
    "faces"
  ],
  "acefst": [
    "facets"
  ],
  "aceft": [
    "facet"
  ],
  "aceftu": [
    "faucet"
  ],
  "aceg": [
    "cage"
  ],
  "aceghn": [
    "change"
  ],
  "aceghr": [
    "charge"
  ],
  "acegln": [
    "glance"
  ],
  "acegly": [
    "legacy"
  ],
  "acegny": [
    "agency"
  ],
  "acegr": [
    "grace"
  ],
  "acegrs": [
    "graces"
  ],
  "acegs": [
    "cages"
  ],
  "acegy": [
    "cagey"
  ],
  "aceh": [
    "ache",
    "each"
  ],
  "acehkr": [
    "hacker"
  ],
  "acehlp": [
    "chapel"
  ],
  "acehlt": [
    "chalet"
  ],
  "acehm": [
    "mache"
  ],
  "acehp": [
    "cheap",
    "peach"
  ],
  "acehpr": [
    "preach"
  ],
  "acehpy": [
    "peachy"
  ],
  "acehr": [
    "reach"
  ],
  "acehrr": [
    "archer"
  ],
  "acehrs": [
    "arches",
    "chaser",
    "search"
  ],
  "acehs": [
    "aches",
    "chase"
  ],
  "acehss": [
    "chases"
  ],
  "acehst": [
    "cheats"
  ],
  "aceht": [
    "cheat",
    "teach"
  ],
  "aceilm": [
    "malice"
  ],
  "aceimn": [
    "anemic",
    "cinema",
    "iceman"
  ],
  "aceinn": [
    "canine"
  ],
  "aceinr": [
    "carnie"
  ],
  "aceins": [
    "incase"
  ],
  "aceir": [
    "erica"
  ],
  "aceitv": [
    "active"
  ],
  "acejkt": [
    "jacket"
  ],
  "acek": [
    "cake"
  ],
  "aceklt": [
    "tackle"
  ],
  "acekpr": [
    "packer"
  ],
  "acekpt": [
    "packet"
  ],
  "acekr": [
    "creak"
  ],
  "acekrt": [
    "racket"
  ],
  "acekry": [
    "creaky"
  ],
  "aceks": [
    "cakes"
  ],
  "acekst": [
    "casket"
  ],
  "acel": [
    "alec",
    "lace"
  ],
  "acello": [
    "locale"
  ],
  "acellr": [
    "caller",
    "cellar",
    "recall"
  ],
  "acelm": [
    "camel"
  ],
  "acelmr": [
    "calmer",
    "marcel"
  ],
  "acelms": [
    "camels"
  ],
  "aceln": [
    "clean",
    "lance"
  ],
  "acelnr": [
    "lancer"
  ],
  "acelns": [
    "cleans"
  ],
  "acelor": [
    "oracle"
  ],
  "acelos": [
    "solace"
  ],
  "acelot": [
    "locate"
  ],
  "acelov": [
    "alcove"
  ],
  "acelp": [
    "place"
  ],
  "acelps": [
    "places"
  ],
  "acelr": [
    "clear"
  ],
  "acelrs": [
    "clears"
  ],
  "acelrt": [
    "cartel",
    "rectal"
  ],
  "acelrv": [
    "carvel"
  ],
  "acels": [
    "laces",
    "scale"
  ],
  "acelss": [
    "scales"
  ],
  "acelst": [
    "castle",
    "cleats"
  ],
  "acelsu": [
    "clause"
  ],
  "acelsv": [
    "calves"
  ],
  "aceltt": [
    "cattle"
  ],
  "acely": [
    "lacey"
  ],
  "acem": [
    "acme",
    "came",
    "mace"
  ],
  "acemnr": [
    "carmen"
  ],
  "acempr": [
    "camper"
  ],
  "acemr": [
    "cream"
  ],
  "acemrs": [
    "scream"
  ],
  "acemry": [
    "creamy"
  ],
  "acen": [
    "acne",
    "cane"
  ],
  "acenn": [
    "nance"
  ],
  "acennu": [
    "nuance"
  ],
  "aceno": [
    "canoe",
    "ocean"
  ],
  "acenos": [
    "canoes",
    "oceans"
  ],
  "acenot": [
    "octane"
  ],
  "acenp": [
    "pecan"
  ],
  "acenpr": [
    "prance"
  ],
  "acenps": [
    "pecans"
  ],
  "acenr": [
    "crane"
  ],
  "acenrs": [
    "cranes"
  ],
  "acenrt": [
    "canter",
    "nectar",
    "recant",
    "trance"
  ],
  "acenrv": [
    "cavern"
  ],
  "acenry": [
    "carney"
  ],
  "acenst": [
    "stance"
  ],
  "acent": [
    "enact"
  ],
  "aceopt": [
    "capote"
  ],
  "aceors": [
    "coarse"
  ],
  "acep": [
    "cape",
    "pace"
  ],
  "aceppr": [
    "capper"
  ],
  "acepr": [
    "caper",
    "pacer",
    "recap"
  ],
  "aceprs": [
    "scrape"
  ],
  "aceprt": [
    "carpet"
  ],
  "aceps": [
    "paces",
    "space"
  ],
  "acepss": [
    "spaces"
  ],
  "acepst": [
    "aspect"
  ],
  "acepsy": [
    "spacey"
  ],
  "aceptu": [
    "teacup"
  ],
  "acer": [
    "acre",
    "care",
    "race"
  ],
  "acerr": [
    "racer"
  ],
  "acerrt": [
    "carter",
    "crater",
    "tracer"
  ],
  "acerrv": [
    "carver"
  ],
  "acers": [
    "acres",
    "cares",
    "races",
    "scare"
  ],
  "acerss": [
    "caress",
    "scares"
  ],
  "acerst": [
    "caters",
    "crates",
    "reacts",
    "traces"
  ],
  "acersu": [
    "saucer"
  ],
  "acersv": [
    "craves"
  ],
  "acert": [
    "cater",
    "crate",
    "react",
    "trace"
  ],
  "acertu": [
    "curate"
  ],
  "acerv": [
    "carve",
    "crave"
  ],
  "acerz": [
    "craze"
  ],
  "aces": [
    "aces",
    "case"
  ],
  "acess": [
    "cases"
  ],
  "acessu": [
    "causes"
  ],
  "acest": [
    "caste",
    "cates"
  ],
  "acesu": [
    "cause",
    "sauce"
  ],
  "acesv": [
    "caves"
  ],
  "acetu": [
    "acute"
  ],
  "acetx": [
    "exact"
  ],
  "acev": [
    "cave"
  ],
  "acff": [
    "caff"
  ],
  "acffh": [
    "chaff"
  ],
  "acfgin": [
    "facing"
  ],
  "acfios": [
    "fiasco"
  ],
  "acfkl": [
    "flack"
  ],
  "acfl": [
    "calf"
  ],
  "acflno": [
    "falcon"
  ],
  "acflo": [
    "focal"
  ],
  "acfnr": [
    "franc"
  ],
  "acfnrs": [
    "francs"
  ],
  "acfny": [
    "fancy"
  ],
  "acfort": [
    "factor"
  ],
  "acfrs": [
    "scarf"
  ],
  "acfrst": [
    "crafts"
  ],
  "acfrt": [
    "craft"
  ],
  "acfrty": [
    "crafty"
  ],
  "acfst": [
    "facts"
  ],
  "acft": [
    "fact"
  ],
  "acghin": [
    "aching"
  ],
  "acghn": [
    "chang"
  ],
  "acghtu": [
    "caught"
  ],
  "acgilr": [
    "garlic"
  ],
  "acgim": [
    "magic"
  ],
  "acgin": [
    "acing"
  ],
  "acginp": [
    "pacing"
  ],
  "acginr": [
    "caring",
    "racing"
  ],
  "acgins": [
    "casing"
  ],
  "acgint": [
    "acting"
  ],
  "acgir": [
    "cigar"
  ],
  "acgirs": [
    "cigars"
  ],
  "acgirt": [
    "tragic"
  ],
  "acgln": [
    "clang"
  ],
  "acgnor": [
    "garcon"
  ],
  "acgor": [
    "cargo"
  ],
  "acgoru": [
    "cougar"
  ],
  "acgs": [
    "scag"
  ],
  "achht": [
    "hatch"
  ],
  "achin": [
    "chain",
    "china"
  ],
  "achins": [
    "chains"
  ],
  "achir": [
    "chair"
  ],
  "achirs": [
    "chairs"
  ],
  "achk": [
    "hack"
  ],
  "achkl": [
    "chalk"
  ],
  "achkow": [
    "whacko"
  ],
  "achks": [
    "hacks",
    "shack"
  ],
  "achkw": [
    "whack"
  ],
  "achlnu": [
    "launch"
  ],
  "achlr": [
    "larch"
  ],
  "achls": [
    "clash"
  ],
  "achlt": [
    "latch"
  ],
  "achm": [
    "mach"
  ],
  "achmo": [
    "macho",
    "mocha"
  ],
  "achmp": [
    "champ"
  ],
  "achmps": [
    "champs"
  ],
  "achmr": [
    "charm",
    "march"
  ],
  "achmrs": [
    "charms"
  ],
  "achms": [
    "chasm"
  ],
  "achmt": [
    "match"
  ],
  "achno": [
    "nacho"
  ],
  "achnor": [
    "anchor",
    "rancho"
  ],
  "achnos": [
    "nachos"
  ],
  "achnr": [
    "ranch"
  ],
  "achnst": [
    "chants",
    "snatch"
  ],
  "achnt": [
    "chant"
  ],
  "achop": [
    "poach"
  ],
  "achor": [
    "roach"
  ],
  "achos": [
    "chaos"
  ],
  "achov": [
    "havoc"
  ],
  "achp": [
    "chap"
  ],
  "achps": [
    "chaps"
  ],
  "achpt": [
    "patch"
  ],
  "achr": [
    "arch"
  ],
  "achrs": [
    "crash"
  ],
  "achrst": [
    "charts"
  ],
  "achrt": [
    "chart"
  ],
  "achs": [
    "cash"
  ],
  "achstw": [
    "swatch"
  ],
  "achsty": [
    "yachts"
  ],
  "acht": [
    "chat",
    "tach"
  ],
  "achtty": [
    "chatty"
  ],
  "achtw": [
    "watch"
  ],
  "achty": [
    "yacht"
  ],
  "acill": [
    "lilac"
  ],
  "acills": [
    "lilacs"
  ],
  "acilm": [
    "claim"
  ],
  "acilms": [
    "claims"
  ],
  "acilmx": [
    "climax"
  ],
  "acilnr": [
    "carlin"
  ],
  "acilos": [
    "social"
  ],
  "acimn": [
    "manic"
  ],
  "acimot": [
    "atomic"
  ],
  "acimps": [
    "scampi"
  ],
  "acimpt": [
    "impact"
  ],
  "acimrs": [
    "racism"
  ],
  "acin": [
    "cain"
  ],
  "acinos": [
    "casino"
  ],
  "acinot": [
    "action"
  ],
  "acinp": [
    "panic"
  ],
  "acinps": [
    "panics"
  ],
  "acinst": [
    "antics"
  ],
  "acint": [
    "actin"
  ],
  "acintt": [
    "intact"
  ],
  "acio": [
    "ciao"
  ],
  "aciost": [
    "scotia"
  ],
  "acirst": [
    "racist"
  ],
  "acirv": [
    "vicar"
  ],
  "acistt": [
    "static"
  ],
  "acitt": [
    "attic",
    "tacit"
  ],
  "acitvy": [
    "cavity"
  ],
  "acjk": [
    "jack"
  ],
  "acjks": [
    "jacks"
  ],
  "ackkn": [
    "knack"
  ],
  "ackl": [
    "lack"
  ],
  "acklo": [
    "cloak"
  ],
  "ackls": [
    "lacks",
    "slack"
  ],
  "acklss": [
    "slacks"
  ],
  "ackm": [
    "mack"
  ],
  "ackms": [
    "smack"
  ],
  "ackmss": [
    "smacks"
  ],
  "acknpu": [
    "unpack"
  ],
  "acknr": [
    "crank"
  ],
  "acknrs": [
    "cranks"
  ],
  "acknry": [
    "cranky"
  ],
  "ackns": [
    "snack"
  ],
  "acknss": [
    "snacks"
  ],
  "ackor": [
    "croak"
  ],
  "ackosw": [
    "wackos"
  ],
  "ackow": [
    "wacko"
  ],
  "ackp": [
    "pack"
  ],
  "ackps": [
    "packs"
  ],
  "ackqsu": [
    "quacks"
  ],
  "ackqu": [
    "quack"
  ],
  "ackr": [
    "rack"
  ],
  "ackrs": [
    "racks"
  ],
  "ackrst": [
    "tracks"
  ],
  "ackrt": [
    "track"
  ],
  "acks": [
    "sack"
  ],
  "ackss": [
    "sacks"
  ],
  "acksst": [
    "stacks"
  ],
  "ackst": [
    "stack",
    "tacks"
  ],
  "ackt": [
    "tack"
  ],
  "ackty": [
    "tacky"
  ],
  "ackw": [
    "wack"
  ],
  "ackwy": [
    "wacky"
  ],
  "acl": [
    "lac"
  ],
  "acll": [
    "call"
  ],
  "acllmy": [
    "calmly"
  ],
  "acllo": [
    "local"
  ],
  "acllor": [
    "collar"
  ],
  "acllos": [
    "locals"
  ],
  "aclls": [
    "calls"
  ],
  "aclm": [
    "calm",
    "clam"
  ],
  "aclmmy": [
    "clammy"
  ],
  "aclmp": [
    "clamp"
  ],
  "aclms": [
    "calms",
    "clams"
  ],
  "aclmtu": [
    "talcum"
  ],
  "acln": [
    "clan"
  ],
  "aclnuy": [
    "lunacy"
  ],
  "aclo": [
    "coal",
    "cola",
    "loca"
  ],
  "aclor": [
    "carol",
    "coral"
  ],
  "aclorr": [
    "corral"
  ],
  "aclos": [
    "coals"
  ],
  "aclosv": [
    "vocals"
  ],
  "aclov": [
    "vocal"
  ],
  "aclp": [
    "clap"
  ],
  "aclps": [
    "clasp",
    "scalp"
  ],
  "aclpu": [
    "culpa"
  ],
  "aclr": [
    "carl"
  ],
  "aclrsw": [
    "crawls"
  ],
  "aclrw": [
    "crawl"
  ],
  "aclrwy": [
    "crawly"
  ],
  "aclss": [
    "class"
  ],
  "aclssy": [
    "classy"
  ],
  "aclsw": [
    "claws"
  ],
  "aclw": [
    "claw"
  ],
  "acly": [
    "clay",
    "lacy"
  ],
  "acm": [
    "cam",
    "mac"
  ],
  "acmmo": [
    "comma"
  ],
  "acmo": [
    "coma"
  ],
  "acmops": [
    "campos"
  ],
  "acmos": [
    "comas"
  ],
  "acmost": [
    "mascot"
  ],
  "acmott": [
    "tomcat"
  ],
  "acmp": [
    "camp"
  ],
  "acmpr": [
    "cramp"
  ],
  "acmprs": [
    "cramps"
  ],
  "acmps": [
    "camps",
    "scamp"
  ],
  "acmpsu": [
    "campus"
  ],
  "acmr": [
    "cram",
    "marc"
  ],
  "acmrs": [
    "scram"
  ],
  "acms": [
    "cams",
    "scam"
  ],
  "acmss": [
    "scams"
  ],
  "acmuuv": [
    "vacuum"
  ],
  "acn": [
    "can"
  ],
  "acnnno": [
    "cannon"
  ],
  "acnnot": [
    "cannot",
    "canton"
  ],
  "acnnoy": [
    "canyon"
  ],
  "acnnry": [
    "cranny"
  ],
  "acnny": [
    "nancy"
  ],
  "acnoor": [
    "corona"
  ],
  "acnopy": [
    "canopy"
  ],
  "acnor": [
    "acorn"
  ],
  "acnort": [
    "carton",
    "contra"
  ],
  "acnr": [
    "narc"
  ],
  "acnrru": [
    "curran"
  ],
  "acns": [
    "cans",
    "scan"
  ],
  "acnss": [
    "scans"
  ],
  "acnst": [
    "scant"
  ],
  "acnt": [
    "cant"
  ],
  "acop": [
    "capo"
  ],
  "acorrt": [
    "carrot"
  ],
  "acorss": [
    "across"
  ],
  "acorst": [
    "actors",
    "castor"
  ],
  "acort": [
    "actor"
  ],
  "acost": [
    "ascot",
    "coast",
    "coats",
    "costa",
    "tacos"
  ],
  "acot": [
    "coat",
    "taco"
  ],
  "acox": [
    "coax"
  ],
  "acp": [
    "cap",
    "pac"
  ],
  "acppry": [
    "crappy"
  ],
  "acppu": [
    "cuppa"
  ],
  "acpr": [
    "carp",
    "crap"
  ],
  "acprs": [
    "craps",
    "scrap"
  ],
  "acprss": [
    "scraps"
  ],
  "acps": [
    "caps"
  ],
  "acpstu": [
    "catsup"
  ],
  "acpt": [
    "pact"
  ],
  "acr": [
    "arc",
    "car"
  ],
  "acrr": [
    "carr"
  ],
  "acrry": [
    "carry"
  ],
  "acrs": [
    "cars",
    "scar"
  ],
  "acrss": [
    "crass",
    "scars"
  ],
  "acrst": [
    "carts"
  ],
  "acrsy": [
    "scary"
  ],
  "acrt": [
    "cart"
  ],
  "acry": [
    "racy"
  ],
  "acryz": [
    "crazy"
  ],
  "acs": [
    "sac"
  ],
  "acsst": [
    "casts"
  ],
  "acst": [
    "acts",
    "cast",
    "cats",
    "scat"
  ],
  "act": [
    "act",
    "cat"
  ],
  "actt": [
    "tact"
  ],
  "actty": [
    "catty"
  ],
  "add": [
    "add",
    "dad"
  ],
  "addde": [
    "added"
  ],
  "adddel": [
    "addled"
  ],
  "adddep": [
    "padded"
  ],
  "adddy": [
    "daddy"
  ],
  "adde": [
    "dead"
  ],
  "addeeh": [
    "headed"
  ],
  "addeer": [
    "deader"
  ],
  "addef": [
    "faded"
  ],
  "addegr": [
    "graded"
  ],
  "addehn": [
    "handed"
  ],
  "addehs": [
    "dashed"
  ],
  "addei": [
    "aided"
  ],
  "addeil": [
    "dialed"
  ],
  "addeir": [
    "raided"
  ],
  "addej": [
    "jaded"
  ],
  "addeln": [
    "landed"
  ],
  "addelo": [
    "loaded"
  ],
  "addelp": [
    "paddle"
  ],
  "addelr": [
    "ladder"
  ],
  "addels": [
    "saddle"
  ],
  "addelw": [
    "waddle"
  ],
  "addely": [
    "deadly"
  ],
  "addemn": [
    "damned",
    "demand",
    "madden"
  ],
  "addemr": [
    "madder"
  ],
  "addenr": [
    "darned"
  ],
  "addens": [
    "sanded"
  ],
  "addenu": [
    "undead"
  ],
  "addenw": [
    "dawned"
  ],
  "addeor": [
    "adored"
  ],
  "addepr": [
    "draped"
  ],
  "adder": [
    "dared",
    "dread"
  ],
  "adders": [
    "sadder"
  ],
  "addert": [
    "traded"
  ],
  "addet": [
    "dated"
  ],
  "addgin": [
    "adding"
  ],
  "addgmo": [
    "goddam"
  ],
  "addny": [
    "dandy"
  ],
  "addoor": [
    "dorado"
  ],
  "addpy": [
    "paddy"
  ],
  "adds": [
    "adds",
    "dads"
  ],
  "adeefr": [
    "feared"
  ],
  "adeeft": [
    "defeat"
  ],
  "adeegr": [
    "agreed",
    "geared"
  ],
  "adeehl": [
    "healed"
  ],
  "adeehp": [
    "heaped"
  ],
  "adeehr": [
    "adhere",
    "header"
  ],
  "adeeht": [
    "heated"
  ],
  "adeehv": [
    "heaved"
  ],
  "adeeir": [
    "dearie"
  ],
  "adeejy": [
    "deejay"
  ],
  "adeekl": [
    "leaked"
  ],
  "adeekp": [
    "peaked"
  ],
  "adeeln": [
    "leaned"
  ],
  "adeelr": [
    "dealer",
    "leader"
  ],
  "adeels": [
    "leased",
    "sealed"
  ],
  "adeelt": [
    "elated"
  ],
  "adeem": [
    "edema"
  ],
  "adeemn": [
    "demean"
  ],
  "adeemr": [
    "reamed"
  ],
  "adeemt": [
    "teamed"
  ],
  "adeenr": [
    "earned",
    "endear"
  ],
  "adeerr": [
    "reader",
    "reared",
    "reread"
  ],
  "adeers": [
    "erased",
    "reseda",
    "seared"
  ],
  "adeest": [
    "seated",
    "sedate",
    "teased"
  ],
  "adeev": [
    "evade"
  ],
  "adef": [
    "deaf",
    "fade"
  ],
  "adefil": [
    "failed"
  ],
  "adefk": [
    "faked"
  ],
  "adefkl": [
    "flaked"
  ],
  "adeflr": [
    "flared"
  ],
  "adeflu": [
    "feudal"
  ],
  "adeflw": [
    "flawed"
  ],
  "adefly": [
    "flayed"
  ],
  "adefm": [
    "famed"
  ],
  "adefmr": [
    "framed"
  ],
  "adefor": [
    "fedora"
  ],
  "adefr": [
    "fared"
  ],
  "adefrt": [
    "farted"
  ],
  "adefry": [
    "frayed"
  ],
  "adefs": [
    "fades"
  ],
  "adefx": [
    "faxed"
  ],
  "adeg": [
    "aged"
  ],
  "adeggg": [
    "gagged"
  ],
  "adeggn": [
    "ganged",
    "nagged"
  ],
  "adeggr": [
    "dagger",
    "ragged"
  ],
  "adeggt": [
    "gadget",
    "tagged"
  ],
  "adeghn": [
    "hanged"
  ],
  "adegin": [
    "gained"
  ],
  "adegl": [
    "glade"
  ],
  "adegln": [
    "dangle"
  ],
  "adegls": [
    "glades"
  ],
  "adeglz": [
    "glazed"
  ],
  "adegnr": [
    "danger",
    "gander",
    "garden"
  ],
  "adegos": [
    "dosage"
  ],
  "adegp": [
    "paged"
  ],
  "adegps": [
    "gasped"
  ],
  "adegr": [
    "grade"
  ],
  "adegrr": [
    "grader",
    "regard"
  ],
  "adegrs": [
    "grades"
  ],
  "adegrt": [
    "grated"
  ],
  "adegru": [
    "argued"
  ],
  "adegrz": [
    "grazed"
  ],
  "adegss": [
    "gassed"
  ],
  "adegst": [
    "staged"
  ],
  "adegt": [
    "gated"
  ],
  "adegz": [
    "gazed"
  ],
  "adeh": [
    "head"
  ],
  "adehil": [
    "hailed"
  ],
  "adehir": [
    "haired"
  ],
  "adehln": [
    "handle"
  ],
  "adehlr": [
    "herald"
  ],
  "adehls": [
    "lashed"
  ],
  "adehlu": [
    "hauled"
  ],
  "adehmr": [
    "harmed"
  ],
  "adehms": [
    "mashed",
    "shamed"
  ],
  "adehnp": [
    "daphne"
  ],
  "adehnr": [
    "harden"
  ],
  "adehps": [
    "phased",
    "shaped"
  ],
  "adehr": [
    "heard"
  ],
  "adehrr": [
    "harder"
  ],
  "adehrs": [
    "dasher",
    "shared"
  ],
  "adehrt": [
    "hatred",
    "thread"
  ],
  "adehs": [
    "hades",
    "heads",
    "shade"
  ],
  "adehss": [
    "shades"
  ],
  "adehst": [
    "deaths"
  ],
  "adehsv": [
    "shaved"
  ],
  "adehsw": [
    "washed"
  ],
  "adeht": [
    "death",
    "hated"
  ],
  "adehtw": [
    "thawed"
  ],
  "adehy": [
    "heady"
  ],
  "adehyy": [
    "heyday"
  ],
  "adei": [
    "aide",
    "idea"
  ],
  "adeijl": [
    "jailed"
  ],
  "adeil": [
    "ideal"
  ],
  "adeilm": [
    "mailed"
  ],
  "adeiln": [
    "denial",
    "nailed"
  ],
  "adeilr": [
    "derail",
    "redial"
  ],
  "adeils": [
    "ideals",
    "ladies",
    "sailed"
  ],
  "adeilt": [
    "detail",
    "tailed"
  ],
  "adeim": [
    "aimed",
    "media"
  ],
  "adeimm": [
    "maimed"
  ],
  "adeimn": [
    "maiden",
    "medina"
  ],
  "adeimr": [
    "admire"
  ],
  "adeinp": [
    "pained"
  ],
  "adeinr": [
    "rained"
  ],
  "adeint": [
    "detain"
  ],
  "adeinv": [
    "invade"
  ],
  "adeior": [
    "roadie"
  ],
  "adeipr": [
    "diaper",
    "paired",
    "repaid"
  ],
  "adeirr": [
    "raider"
  ],
  "adeirs": [
    "raised"
  ],
  "adeirv": [
    "varied"
  ],
  "adeis": [
    "aides",
    "aside",
    "ideas"
  ],
  "adeisv": [
    "advise"
  ],
  "adeitw": [
    "waited"
  ],
  "adeivw": [
    "waived"
  ],
  "adej": [
    "jade"
  ],
  "adejmm": [
    "jammed"
  ],
  "adejw": [
    "jawed"
  ],
  "adejzz": [
    "jazzed"
  ],
  "adeklt": [
    "talked"
  ],
  "adeklw": [
    "walked"
  ],
  "adekmr": [
    "marked"
  ],
  "adekms": [
    "masked"
  ],
  "adekn": [
    "naked"
  ],
  "adeknr": [
    "darken"
  ],
  "adeknt": [
    "tanked"
  ],
  "adekny": [
    "yanked"
  ],
  "adekos": [
    "soaked"
  ],
  "adekoy": [
    "okayed"
  ],
  "adekpr": [
    "parked"
  ],
  "adekr": [
    "drake",
    "raked"
  ],
  "adekrr": [
    "darker"
  ],
  "adeks": [
    "asked"
  ],
  "adekst": [
    "skated",
    "staked"
  ],
  "adel": [
    "dale",
    "deal",
    "lead"
  ],
  "adell": [
    "ladle"
  ],
  "adellw": [
    "walled"
  ],
  "adelm": [
    "medal"
  ],
  "adelms": [
    "damsel",
    "medals"
  ],
  "adelmu": [
    "mauled"
  ],
  "adeln": [
    "laden"
  ],
  "adelno": [
    "loaned"
  ],
  "adelnr": [
    "lander"
  ],
  "adelnt": [
    "dental"
  ],
  "adelor": [
    "ordeal",
    "reload"
  ],
  "adelp": [
    "pedal",
    "plead"
  ],
  "adelps": [
    "lapsed",
    "pedals",
    "pleads"
  ],
  "adelpt": [
    "plated"
  ],
  "adelpy": [
    "played"
  ],
  "adelry": [
    "dearly"
  ],
  "adels": [
    "deals",
    "leads"
  ],
  "adelst": [
    "deltas",
    "lasted",
    "salted",
    "slated"
  ],
  "adelsv": [
    "slaved"
  ],
  "adelsy": [
    "delays",
    "slayed"
  ],
  "adelt": [
    "dealt",
    "delta"
  ],
  "adeluv": [
    "valued"
  ],
  "adely": [
    "delay"
  ],
  "adelzz": [
    "dazzle"
  ],
  "adem": [
    "dame",
    "made",
    "mead"
  ],
  "ademmn": [
    "madmen"
  ],
  "ademmr": [
    "rammed"
  ],
  "ademn": [
    "amend",
    "named"
  ],
  "ademns": [
    "amends"
  ],
  "ademnt": [
    "tandem"
  ],
  "ademow": [
    "meadow"
  ],
  "adempp": [
    "mapped"
  ],
  "adempr": [
    "damper"
  ],
  "ademr": [
    "armed",
    "dream",
    "madre"
  ],
  "ademrs": [
    "dreams"
  ],
  "ademrt": [
    "dreamt"
  ],
  "ademrw": [
    "warmed"
  ],
  "ademry": [
    "dreamy"
  ],
  "ademsu": [
    "amused",
    "medusa"
  ],
  "ademt": [
    "mated"
  ],
  "ademtt": [
    "matted"
  ],
  "aden": [
    "dean"
  ],
  "adennp": [
    "panned"
  ],
  "adennt": [
    "tanned"
  ],
  "adenot": [
    "donate"
  ],
  "adenpx": [
    "expand"
  ],
  "adenrr": [
    "errand"
  ],
  "adenrt": [
    "ardent"
  ],
  "adenrw": [
    "wander",
    "warden",
    "warned"
  ],
  "adenrz": [
    "zander"
  ],
  "adens": [
    "sedan"
  ],
  "adensu": [
    "sundae"
  ],
  "adentt": [
    "attend"
  ],
  "adentv": [
    "advent"
  ],
  "adentw": [
    "wanted"
  ],
  "adeor": [
    "adore"
  ],
  "adeors": [
    "adores",
    "soared"
  ],
  "adeppr": [
    "dapper"
  ],
  "adeppt": [
    "tapped"
  ],
  "adeppz": [
    "zapped"
  ],
  "adepr": [
    "drape",
    "padre",
  ],
  "adeprs": [
    "drapes",
    "spared",
    "spread"
  ],
  "adeprt": [
    "depart",
    "parted"
  ],
  "adeprw": [
    "warped"
  ],
  "adepry": [
    "prayed"
  ],
  "adeps": [
    "spade"
  ],
  "adepss": [
    "passed",
    "spades"
  ],
  "adepst": [
    "pasted"
  ],
  "adept": [
    "adept",
    "taped"
  ],
  "adeptu": [
    "update"
  ],
  "adepv": [
    "paved"
  ],
  "ader": [
    "dare",
    "dear",
    "read"
  ],
  "aderrt": [
    "retard",
    "tarred",
    "trader"
  ],
  "aderrw": [
    "drawer",
    "reward"
  ],
  "aderry": [
    "dreary"
  ],
  "aders": [
    "dares",
    "dears",
    "reads"
  ],
  "aderst": [
    "stared",
    "trades",
    "treads"
  ],
  "adersw": [
    "dewars"
  ],
  "adert": [
    "dater",
    "rated",
    "trade",
    "tread"
  ],
  "adertt": [
    "ratted"
  ],
  "adertv": [
    "advert"
  ],
  "aderv": [
    "raved"
  ],
  "adery": [
    "rayed",
    "ready"
  ],
  "ades": [
    "sade"
  ],
  "adest": [
    "dates",
    "stead"
  ],
  "adestt": [
    "stated",
    "tasted"
  ],
  "adestw": [
    "wasted"
  ],
  "adesty": [
    "stayed",
    "steady"
  ],
  "adesv": [
    "saved"
  ],
  "adesw": [
    "sawed"
  ],
  "adeswy": [
    "swayed"
  ],
  "adet": [
    "date"
  ],
  "adetx": [
    "taxed"
  ],
  "adevw": [
    "waved"
  ],
  "adew": [
    "awed",
    "wade"
  ],
  "adewx": [
    "waxed"
  ],
  "adez": [
    "daze"
  ],
  "adf": [
    "fad"
  ],
  "adffor": [
    "afford"
  ],
  "adffy": [
    "daffy"
  ],
  "adfgin": [
    "fading"
  ],
  "adfrst": [
    "drafts"
  ],
  "adfrsu": [
    "frauds"
  ],
  "adfrsw": [
    "dwarfs"
  ],
  "adfrt": [
    "draft"
  ],
  "adfrty": [
    "drafty"
  ],
  "adfru": [
    "fraud"
  ],
  "adfrw": [
    "dwarf"
  ],
  "adfs": [
    "fads"
  ],
  "adft": [
    "daft"
  ],
  "adg": [
    "dag"
  ],
  "adgiin": [
    "aiding"
  ],
  "adginr": [
    "daring"
  ],
  "adgint": [
    "dating"
  ],
  "adginw": [
    "wading"
  ],
  "adgl": [
    "glad"
  ],
  "adglly": [
    "gladly"
  ],
  "adgln": [
    "gland"
  ],
  "adglns": [
    "glands"
  ],
  "adglop": [
    "lapdog"
  ],
  "adgn": [
    "dang"
  ],
  "adgnor": [
    "dragon"
  ],
  "adgnr": [
    "grand"
  ],
  "adgo": [
    "dago",
    "goad"
  ],
  "adgr": [
    "drag",
    "grad"
  ],
  "adgrs": [
    "drags"
  ],
  "adgrsu": [
    "guards"
  ],
  "adgru": [
    "guard"
  ],
  "adguy": [
    "gaudy"
  ],
  "adh": [
    "dah",
    "had"
  ],
  "adhij": [
    "jihad"
  ],
  "adhins": [
    "danish"
  ],
  "adhior": [
    "hairdo"
  ],
  "adhirs": [
    "radish"
  ],
  "adhj": [
    "hadj"
  ],
  "adhl": [
    "dahl"
  ],
  "adhlo": [
    "ahold"
  ],
  "adhlry": [
    "hardly"
  ],
  "adhn": [
    "hand"
  ],
  "adhnnu": [
    "unhand"
  ],
  "adhno": [
    "honda"
  ],
  "adhns": [
    "hands"
  ],
  "adhny": [
    "handy"
  ],
  "adhosw": [
    "shadow"
  ],
  "adhr": [
    "hard"
  ],
  "adhrss": [
    "shards"
  ],
  "adhry": [
    "hardy",
    "hydra"
  ],
  "adhs": [
    "dash",
    "shad"
  ],
  "adhsy": [
    "shady"
  ],
  "adi": [
    "aid"
  ],
  "adiil": [
    "iliad"
  ],
  "adiiln": [
    "inlaid"
  ],
  "adiknp": [
    "kidnap"
  ],
  "adil": [
    "dial",
    "laid"
  ],
  "adilms": [
    "dismal"
  ],
  "adilnn": [
    "inland"
  ],
  "adilns": [
    "island"
  ],
  "adilp": [
    "plaid"
  ],
  "adilr": [
    "laird"
  ],
  "adilrz": [
    "lizard"
  ],
  "adils": [
    "dials"
  ],
  "adilt": [
    "tidal"
  ],
  "adilv": [
    "valid"
  ],
  "adily": [
    "daily"
  ],
  "adim": [
    "maid"
  ],
  "adimno": [
    "domain"
  ],
  "adimrs": [
    "disarm"
  ],
  "adimry": [
    "myriad"
  ],
  "adims": [
    "maids"
  ],
  "adimst": [
    "admits"
  ],
  "adimsy": [
    "dismay"
  ],
  "adimt": [
    "admit"
  ],
  "adimwy": [
    "midway"
  ],
  "adinpu": [
    "unpaid"
  ],
  "adinr": [
    "drain"
  ],
  "adinsu": [
    "unsaid"
  ],
  "adinty": [
    "dainty"
  ],
  "adior": [
    "radio"
  ],
  "adiors": [
    "radios"
  ],
  "adiosv": [
    "avoids"
  ],
  "adiou": [
    "audio"
  ],
  "adiov": [
    "avoid"
  ],
  "adip": [
    "paid"
  ],
  "adipr": [
    "rapid"
  ],
  "adiprs": [
    "rapids"
  ],
  "adipv": [
    "vapid"
  ],
  "adir": [
    "arid",
    "raid"
  ],
  "adirs": [
    "raids"
  ],
  "adirsu": [
    "radius"
  ],
  "adirt": [
    "triad"
  ],
  "adirwz": [
    "wizard"
  ],
  "adiry": [
    "dairy",
    "diary"
  ],
  "adis": [
    "aids",
    "dais",
    "said"
  ],
  "adisst": [
    "sadist"
  ],
  "adisv": [
    "divas"
  ],
  "adisy": [
    "daisy"
  ],
  "aditu": [
    "audit"
  ],
  "adiv": [
    "avid",
    "diva"
  ],
  "adjnor": [
    "jordan"
  ],
  "adjstu": [
    "adjust"
  ],
  "adjsu": [
    "judas"
  ],
  "adknr": [
    "drank"
  ],
  "adkov": [
    "vodka"
  ],
  "adkr": [
    "dark"
  ],
  "adkrs": [
    "darks"
  ],
  "adl": [
    "lad"
  ],
  "adllor": [
    "dollar"
  ],
  "adlly": [
    "dally"
  ],
  "adlmno": [
    "almond"
  ],
  "adlmy": [
    "madly"
  ],
  "adln": [
    "land"
  ],
  "adlnot": [
    "dalton"
  ],
  "adlnou": [
    "unload"
  ],
  "adlns": [
    "lands"
  ],
  "adlo": [
    "load"
  ],
  "adlopu": [
    "upload"
  ],
  "adlors": [
    "dorsal"
  ],
  "adlos": [
    "loads"
  ],
  "adlou": [
    "aloud"
  ],
  "adlr": [
    "lard"
  ],
  "adls": [
    "lads"
  ],
  "adlstu": [
    "adults"
  ],
  "adlsy": [
    "sadly"
  ],
  "adltu": [
    "adult"
  ],
  "adlu": [
    "auld",
    "dual"
  ],
  "adly": [
    "lady"
  ],
  "adm": [
    "dam",
    "mad"
  ],
  "admn": [
    "damn"
  ],
  "admno": [
    "nomad"
  ],
  "admnor": [
    "random",
    "rodman"
  ],
  "admnos": [
    "nomads"
  ],
  "admnoy": [
    "dynamo"
  ],
  "admorr": [
    "ramrod"
  ],
  "admp": [
    "damp"
  ],
  "adn": [
    "and"
  ],
  "adnno": [
    "donna"
  ],
  "adnopr": [
    "pardon"
  ],
  "adnoru": [
    "around"
  ],
  "adnorw": [
    "onward"
  ],
  "adnr": [
    "darn",
    "rand"
  ],
  "adnrst": [
    "strand"
  ],
  "adnrtu": [
    "tundra"
  ],
  "adnrw": [
    "drawn"
  ],
  "adnry": [
    "randy"
  ],
  "adns": [
    "sand"
  ],
  "adnss": [
    "sands"
  ],
  "adnsst": [
    "stands"
  ],
  "adnst": [
    "stand"
  ],
  "adnsy": [
    "sandy"
  ],
  "adnw": [
    "dawn",
    "wand"
  ],
  "ado": [
    "ado"
  ],
  "adopry": [
    "parody"
  ],
  "adopt": [
    "adopt"
  ],
  "ador": [
    "road"
  ],
  "adors": [
    "roads"
  ],
  "adortw": [
    "toward"
  ],
  "ados": [
    "soda"
  ],
  "adoss": [
    "sodas"
  ],
  "adot": [
    "dato",
    "toad"
  ],
  "adoty": [
    "today"
  ],
  "adp": [
    "pad"
  ],
  "adpruw": [
    "upward"
  ],
  "adps": [
    "pads"
  ],
  "adqssu": [
    "squads"
  ],
  "adqsu": [
    "squad"
  ],
  "adqu": [
    "quad"
  ],
  "adrst": [
    "darts"
  ],
  "adrsw": [
    "draws"
  ],
  "adrsy": [
    "yards"
  ],
  "adrt": [
    "dart"
  ],
  "adrtwy": [
    "tawdry"
  ],
  "adrty": [
    "tardy"
  ],
  "adrw": [
    "draw",
    "ward"
  ],
  "adry": [
    "yard"
  ],
  "ads": [
    "ads",
    "sad"
  ],
  "adsy": [
    "days"
  ],
  "adt": [
    "tad"
  ],
  "advy": [
    "davy"
  ],
  "adw": [
    "wad"
  ],
  "ady": [
    "day"
  ],
  "aeeflm": [
    "female"
  ],
  "aeeggn": [
    "engage"
  ],
  "aeeggr": [
    "reggae"
  ],
  "aeegl": [
    "eagle"
  ],
  "aeegls": [
    "eagles"
  ],
  "aeeglu": [
    "league"
  ],
  "aeegmn": [
    "menage"
  ],
  "aeegmr": [
    "meager"
  ],
  "aeegnr": [
    "enrage"
  ],
  "aeegnt": [
    "negate"
  ],
  "aeegnv": [
    "avenge",
    "geneva"
  ],
  "aeegot": [
    "goatee"
  ],
  "aeegr": [
    "agree",
    "eager"
  ],
  "aeegrs": [
    "agrees",
    "grease"
  ],
  "aeegsw": [
    "sewage"
  ],
  "aeehlr": [
    "healer"
  ],
  "aeehlx": [
    "exhale"
  ],
  "aeehnv": [
    "heaven"
  ],
  "aeehrs": [
    "hearse"
  ],
  "aeehrt": [
    "heater",
    "reheat"
  ],
  "aeehv": [
    "heave"
  ],
  "aeeimn": [
    "meanie"
  ],
  "aeeirs": [
    "easier"
  ],
  "aeekmr": [
    "remake"
  ],
  "aeeknw": [
    "weaken"
  ],
  "aeekrt": [
    "retake"
  ],
  "aeekrw": [
    "weaker"
  ],
  "aeell": [
    "allee"
  ],
  "aeelmn": [
    "enamel"
  ],
  "aeelpr": [
    "repeal"
  ],
  "aeelps": [
    "asleep",
    "please"
  ],
  "aeelrt": [
    "relate"
  ],
  "aeelrv": [
    "leaver",
    "reveal"
  ],
  "aeels": [
    "easel",
    "lease"
  ],
  "aeelsv": [
    "leaves"
  ],
  "aeelsw": [
    "weasel"
  ],
  "aeelsz": [
    "sleaze"
  ],
  "aeelv": [
    "leave"
  ],
  "aeelwy": [
    "leeway"
  ],
  "aeemn": [
    "enema"
  ],
  "aeemnr": [
    "meaner",
    "rename"
  ],
  "aeemns": [
    "seamen"
  ],
  "aeemss": [
    "sesame"
  ],
  "aeenst": [
    "senate"
  ],
  "aeent": [
    "eaten"
  ],
  "aeenuv": [
    "avenue"
  ],
  "aeeprr": [
    "reaper"
  ],
  "aeeprt": [
    "repeat"
  ],
  "aeerrs": [
    "eraser"
  ],
  "aeerrw": [
    "wearer"
  ],
  "aeers": [
    "erase"
  ],
  "aeerss": [
    "erases"
  ],
  "aeerst": [
    "easter",
    "eaters"
  ],
  "aeersv": [
    "averse"
  ],
  "aeert": [
    "eater"
  ],
  "aeervw": [
    "weaver"
  ],
  "aees": [
    "ease"
  ],
  "aeess": [
    "eases"
  ],
  "aeessw": [
    "seesaw"
  ],
  "aeest": [
    "tease"
  ],
  "aeestt": [
    "estate"
  ],
  "aeesv": [
    "eaves"
  ],
  "aeevw": [
    "weave"
  ],
  "aefflr": [
    "raffle"
  ],
  "aefflw": [
    "waffle"
  ],
  "aefgor": [
    "forage"
  ],
  "aefhll": [
    "fellah"
  ],
  "aefhrt": [
    "father"
  ],
  "aefiln": [
    "finale"
  ],
  "aefimn": [
    "famine"
  ],
  "aefirr": [
    "fairer"
  ],
  "aefist": [
    "fiesta"
  ],
  "aefk": [
    "fake"
  ],
  "aefkl": [
    "flake"
  ],
  "aefkls": [
    "flakes"
  ],
  "aefkr": [
    "freak"
  ],
  "aefkrs": [
    "freaks"
  ],
  "aefkry": [
    "freaky"
  ],
  "aefl": [
    "flea",
    "leaf"
  ],
  "aefll": [
    "fella"
  ],
  "aeflln": [
    "fallen"
  ],
  "aeflls": [
    "fellas"
  ],
  "aeflm": [
    "flame"
  ],
  "aeflmr": [
    "flamer"
  ],
  "aeflms": [
    "flames"
  ],
  "aeflr": [
    "flare"
  ],
  "aeflrs": [
    "flares"
  ],
  "aeflru": [
    "earful"
  ],
  "aefls": [
    "false",
    "fleas",
    "leafs"
  ],
  "aeflsy": [
    "safely"
  ],
  "aeflt": [
    "fetal"
  ],
  "aefly": [
    "leafy"
  ],
  "aefm": [
    "fame"
  ],
  "aefmr": [
    "frame"
  ],
  "aefmrr": [
    "farmer"
  ],
  "aefmrs": [
    "frames"
  ],
  "aefnst": [
    "fasten"
  ],
  "aefnsu": [
    "unsafe"
  ],
  "aefntt": [
    "fatten"
  ],
  "aefr": [
    "fare",
    "fear"
  ],
  "aefrs": [
    "fears",
    "safer"
  ],
  "aefrst": [
    "faster"
  ],
  "aefrt": [
    "after"
  ],
  "aefs": [
    "safe"
  ],
  "aefss": [
    "safes"
  ],
  "aefsst": [
    "safest"
  ],
  "aefst": [
    "fates",
    "feast",
    "feats"
  ],
  "aefsty": [
    "safety"
  ],
  "aefsx": [
    "faxes"
  ],
  "aeft": [
    "fate",
    "feat",
    "feta"
  ],
  "aefv": [
    "fave"
  ],
  "aeg": [
    "age"
  ],
  "aegg": [
    "gage"
  ],
  "aegggl": [
    "gaggle"
  ],
  "aeggin": [
    "ageing"
  ],
  "aeggjr": [
    "jagger"
  ],
  "aeggnr": [
    "grange"
  ],
  "aeggu": [
    "gauge"
  ],
  "aeghio": [
    "hoagie"
  ],
  "aeghmo": [
    "homage"
  ],
  "aeghnr": [
    "hanger"
  ],
  "aeghrt": [
    "gather"
  ],
  "aegil": [
    "agile"
  ],
  "aegilo": [
    "goalie"
  ],
  "aegim": [
    "image"
  ],
  "aegimn": [
    "enigma"
  ],
  "aegimr": [
    "mirage"
  ],
  "aegims": [
    "images"
  ],
  "aeginr": [
    "regain",
    "regina"
  ],
  "aegins": [
    "easing"
  ],
  "aegint": [
    "eating"
  ],
  "aeginu": [
    "guinea"
  ],
  "aegirw": [
    "earwig"
  ],
  "aegisv": [
    "visage"
  ],
  "aegity": [
    "gaiety"
  ],
  "aegkst": [
    "gasket"
  ],
  "aegl": [
    "gale"
  ],
  "aegll": [
    "legal"
  ],
  "aeglm": [
    "gleam"
  ],
  "aegln": [
    "angel",
    "angle"
  ],
  "aeglns": [
    "angels",
    "angles"
  ],
  "aeglnt": [
    "tangle"
  ],
  "aeglor": [
    "galore"
  ],
  "aeglot": [
    "gelato"
  ],
  "aeglpu": [
    "plague"
  ],
  "aeglr": [
    "glare",
    "lager",
    "large",
    "regal"
  ],
  "aeglrr": [
    "larger"
  ],
  "aeglrs": [
    "glares"
  ],
  "aeglrv": [
    "gravel"
  ],
  "aeglry": [
    "argyle"
  ],
  "aeglrz": [
    "glazer"
  ],
  "aeglv": [
    "gavel"
  ],
  "aeglz": [
    "glaze"
  ],
  "aegm": [
    "game"
  ],
  "aegmm": [
    "gemma"
  ],
  "aegmnr": [
    "german",
    "manger"
  ],
  "aegmnt": [
    "magnet"
  ],
  "aegmo": [
    "omega"
  ],
  "aegmr": [
    "gamer",
    "marge"
  ],
  "aegms": [
    "games"
  ],
  "aegno": [
    "genoa"
  ],
  "aegnor": [
    "orange"
  ],
  "aegnr": [
    "anger",
    "range"
  ],
  "aegnrr": [
    "garner",
    "ranger"
  ],
  "aegnrs": [
    "ranges"
  ],
  "aegnrt": [
    "garnet"
  ],
  "aegnst": [
    "agents"
  ],
  "aegnt": [
    "agent"
  ],
  "aegnv": [
    "vegan"
  ],
  "aegotu": [
    "outage"
  ],
  "aegovy": [
    "voyage"
  ],
  "aegp": [
    "page"
  ],
  "aegpr": [
    "grape",
    "pager"
  ],
  "aegprs": [
    "grapes",
    "pagers"
  ],
  "aegps": [
    "pages"
  ],
  "aegr": [
    "gear",
    "rage"
  ],
  "aegrrt": [
    "garter"
  ],
  "aegrs": [
    "gears",
    "rages",
    "sarge"
  ],
  "aegrsu": [
    "argues"
  ],
  "aegrsv": [
    "graves"
  ],
  "aegrsy": [
    "greasy"
  ],
  "aegrt": [
    "grate",
    "great"
  ],
  "aegrtt": [
    "target"
  ],
  "aegru": [
    "argue",
    "auger"
  ],
  "aegrv": [
    "grave"
  ],
  "aegrw": [
    "wager"
  ],
  "aegs": [
    "ages",
    "sage"
  ],
  "aegss": [
    "gases"
  ],
  "aegsss": [
    "gasses"
  ],
  "aegsst": [
    "stages"
  ],
  "aegst": [
    "gates",
    "stage"
  ],
  "aegsty": [
    "gayest"
  ],
  "aegsw": [
    "wages"
  ],
  "aegt": [
    "gate"
  ],
  "aeguv": [
    "vague"
  ],
  "aeguz": [
    "gauze"
  ],
  "aegv": [
    "gave"
  ],
  "aegw": [
    "wage"
  ],
  "aegz": [
    "gaze"
  ],
  "aehhlt": [
    "health"
  ],
  "aehhrs": [
    "rehash"
  ],
  "aehht": [
    "heath"
  ],
  "aehikn": [
    "hankie"
  ],
  "aehiln": [
    "inhale"
  ],
  "aehils": [
    "sheila"
  ],
  "aehilw": [
    "awhile"
  ],
  "aehinr": [
    "hernia"
  ],
  "aehkns": [
    "shaken"
  ],
  "aehkrs": [
    "shaker"
  ],
  "aehkrw": [
    "hawker"
  ],
  "aehks": [
    "shake"
  ],
  "aehkss": [
    "shakes"
  ],
  "aehl": [
    "hale",
    "heal"
  ],
  "aehllt": [
    "lethal"
  ],
  "aehlmt": [
    "hamlet"
  ],
  "aehlot": [
    "loathe"
  ],
  "aehlrt": [
    "halter",
    "lather"
  ],
  "aehls": [
    "heals",
    "leash",
    "shale"
  ],
  "aehlss": [
    "hassle",
    "lashes"
  ],
  "aehlsv": [
    "halves"
  ],
  "aehlsw": [
    "whales"
  ],
  "aehlt": [
    "lathe"
  ],
  "aehltw": [
    "wealth"
  ],
  "aehlw": [
    "whale"
  ],
  "aehlz": [
    "hazel"
  ],
  "aehm": [
    "ahem"
  ],
  "aehmmr": [
    "hammer"
  ],
  "aehmmy": [
    "mayhem"
  ],
  "aehmnt": [
    "anthem"
  ],
  "aehmnu": [
    "humane"
  ],
  "aehmpr": [
    "hamper"
  ],
  "aehmr": [
    "harem"
  ],
  "aehms": [
    "shame"
  ],
  "aehnpp": [
    "happen"
  ],
  "aehnst": [
    "hasten"
  ],
  "aehnsv": [
    "shaven"
  ],
  "aehnsy": [
    "hyenas"
  ],
  "aehnv": [
    "haven"
  ],
  "aehors": [
    "ashore",
    "hoarse"
  ],
  "aehosx": [
    "hoaxes"
  ],
  "aehp": [
    "heap"
  ],
  "aehprr": [
    "harper"
  ],
  "aehprs": [
    "phrase"
  ],
  "aehps": [
    "heaps",
    "phase",
    "shape"
  ],
  "aehpss": [
    "phases",
    "shapes"
  ],
  "aehr": [
    "hare",
    "hear"
  ],
  "aehrrt": [
    "rather"
  ],
  "aehrs": [
    "hears",
    "share",
    "shear"
  ],
  "aehrss": [
    "rashes",
    "shares"
  ],
  "aehrst": [
    "hearts"
  ],
  "aehrsv": [
    "shaver"
  ],
  "aehrsw": [
    "washer"
  ],
  "aehrt": [
    "earth",
    "hater",
    "heart"
  ],
  "aehrtt": [
    "hatter",
    "threat"
  ],
  "aehrty": [
    "earthy",
    "hearty"
  ],
  "aehs": [
    "shea"
  ],
  "aehss": [
    "ashes"
  ],
  "aehssv": [
    "shaves"
  ],
  "aehssw": [
    "washes"
  ],
  "aehst": [
    "haste",
    "hates",
    "heats"
  ],
  "aehsv": [
    "shave"
  ],
  "aeht": [
    "hate",
    "heat"
  ],
  "aehtt": [
    "theta"
  ],
  "aehtu": [
    "haute"
  ],
  "aehtw": [
    "wheat"
  ],
  "aehv": [
    "have"
  ],
  "aehvy": [
    "heavy"
  ],
  "aehy": [
    "yeah"
  ],
  "aehz": [
    "haze"
  ],
  "aeikl": [
    "alike"
  ],
  "aeiklt": [
    "talkie"
  ],
  "aeiknt": [
    "intake"
  ],
  "aeikrs": [
    "kaiser"
  ],
  "aeills": [
    "allies"
  ],
  "aeilmn": [
    "menial"
  ],
  "aeilmp": [
    "impale"
  ],
  "aeilmr": [
    "mailer"
  ],
  "aeiln": [
    "alien",
    "liane"
  ],
  "aeilnp": [
    "alpine"
  ],
  "aeilnr": [
    "linear"
  ],
  "aeilns": [
    "aliens",
    "saline"
  ],
  "aeilnt": [
    "entail"
  ],
  "aeilr": [
    "ariel"
  ],
  "aeilrs": [
    "serial"
  ],
  "aeilrt": [
    "retail"
  ],
  "aeils": [
    "aisle"
  ],
  "aeilss": [
    "aisles",
    "lassie"
  ],
  "aeilsv": [
    "valise"
  ],
  "aeilsy": [
    "easily"
  ],
  "aeilv": [
    "alive"
  ],
  "aeimm": [
    "mamie"
  ],
  "aeimnr": [
    "airmen",
    "marine",
    "remain"
  ],
  "aeimnt": [
    "inmate"
  ],
  "aeimrs": [
    "armies"
  ],
  "aeinns": [
    "insane"
  ],
  "aeinrt": [
    "retain",
    "retina"
  ],
  "aeinrv": [
    "ravine"
  ],
  "aeins": [
    "anise"
  ],
  "aeintu": [
    "auntie"
  ],
  "aeintv": [
    "native"
  ],
  "aeinv": [
    "naive"
  ],
  "aeiprr": [
    "repair"
  ],
  "aeiprs": [
    "aspire",
    "praise"
  ],
  "aeiprt": [
    "pirate"
  ],
  "aeirrs": [
    "raiser",
    "sierra"
  ],
  "aeirrv": [
    "arrive"
  ],
  "aeirs": [
    "arise",
    "raise"
  ],
  "aeirss": [
    "arises",
    "raises"
  ],
  "aeirst": [
    "satire"
  ],
  "aeirsv": [
    "varies"
  ],
  "aeirtw": [
    "waiter"
  ],
  "aeirvw": [
    "waiver"
  ],
  "aeisst": [
    "siesta"
  ],
  "aeivw": [
    "waive"
  ],
  "aejk": [
    "jake"
  ],
  "aejmmr": [
    "jammer"
  ],
  "aejn": [
    "jane",
    "jean"
  ],
  "aejns": [
    "jeans"
  ],
  "aejprs": [
    "jasper"
  ],
  "aekl": [
    "kale",
    "lake",
    "leak"
  ],
  "aekln": [
    "ankle"
  ],
  "aeklns": [
    "ankles"
  ],
  "aeklr": [
    "laker"
  ],
  "aeklrs": [
    "lakers"
  ],
  "aeklrt": [
    "talker"
  ],
  "aeklrw": [
    "walker"
  ],
  "aekls": [
    "lakes",
    "leaks"
  ],
  "aekly": [
    "leaky"
  ],
  "aekm": [
    "make"
  ],
  "aekmpu": [
    "makeup"
  ],
  "aekmr": [
    "maker"
  ],
  "aekmrr": [
    "marker",
    "remark"
  ],
  "aekmrs": [
    "makers"
  ],
  "aekmrt": [
    "market"
  ],
  "aekms": [
    "makes"
  ],
  "aekn": [
    "kane"
  ],
  "aeknrt": [
    "tanker"
  ],
  "aekns": [
    "snake",
    "sneak"
  ],
  "aeknss": [
    "snakes",
    "sneaks"
  ],
  "aeknsy": [
    "sneaky"
  ],
  "aeknt": [
    "taken"
  ],
  "aeknw": [
    "waken"
  ],
  "aekow": [
    "awoke"
  ],
  "aekp": [
    "peak"
  ],
  "aekprr": [
    "parker"
  ],
  "aekps": [
    "peaks",
    "speak"
  ],
  "aekpss": [
    "speaks"
  ],
  "aekptu": [
    "uptake"
  ],
  "aekqru": [
    "quaker"
  ],
  "aekqsu": [
    "squeak"
  ],
  "aekqu": [
    "quake"
  ],
  "aekr": [
    "rake"
  ],
  "aekrst": [
    "skater",
    "streak",
    "takers"
  ],
  "aekrsw": [
    "wreaks"
  ],
  "aekrt": [
    "taker"
  ],
  "aekrw": [
    "wreak"
  ],
  "aeks": [
    "sake"
  ],
  "aekss": [
    "sakes"
  ],
  "aeksst": [
    "skates",
    "stakes",
    "steaks"
  ],
  "aekst": [
    "skate",
    "stake",
    "steak",
    "takes"
  ],
  "aeksw": [
    "askew",
    "wakes"
  ],
  "aekt": [
    "take"
  ],
  "aektw": [
    "tweak"
  ],
  "aekw": [
    "wake",
    "weak"
  ],
  "ael": [
    "ale"
  ],
  "aellmt": [
    "mallet"
  ],
  "aellp": [
    "lapel"
  ],
  "aellrt": [
    "taller"
  ],
  "aellru": [
    "allure",
    "laurel"
  ],
  "aellry": [
    "really"
  ],
  "aellst": [
    "stella"
  ],
  "aellsy": [
    "alleys"
  ],
  "aelltw": [
    "wallet"
  ],
  "aellty": [
    "lately"
  ],
  "aellvy": [
    "valley"
  ],
  "aelly": [
    "alley"
  ],
  "aelm": [
    "lame",
    "male",
    "meal"
  ],
  "aelmnt": [
    "lament",
    "mantel",
    "mantle",
    "mental"
  ],
  "aelmnw": [
    "lawmen"
  ],
  "aelmny": [
    "namely"
  ],
  "aelmor": [
    "morale"
  ],
  "aelmp": [
    "ample",
    "maple"
  ],
  "aelmpr": [
    "palmer"
  ],
  "aelmps": [
    "sample"
  ],
  "aelmpu": [
    "ampule"
  ],
  "aelmr": [
    "realm"
  ],
  "aelmrs": [
    "realms"
  ],
  "aelmrv": [
    "marvel"
  ],
  "aelms": [
    "males",
    "meals"
  ],
  "aelmst": [
    "metals"
  ],
  "aelmsy": [
    "measly"
  ],
  "aelmt": [
    "metal"
  ],
  "aelmtu": [
    "amulet"
  ],
  "aelmy": [
    "mealy"
  ],
  "aeln": [
    "lane",
    "lean"
  ],
  "aelno": [
    "alone"
  ],
  "aelnp": [
    "panel",
    "penal",
    "plane"
  ],
  "aelnps": [
    "panels",
    "planes"
  ],
  "aelnpt": [
    "planet"
  ],
  "aelnr": [
    "learn",
    "renal"
  ],
  "aelnrs": [
    "learns"
  ],
  "aelnrt": [
    "antler",
    "learnt",
    "rental"
  ],
  "aelnru": [
    "neural",
    "unreal"
  ],
  "aelnry": [
    "nearly"
  ],
  "aelns": [
    "lanes",
    "leans"
  ],
  "aelnsu": [
    "unseal"
  ],
  "aelntt": [
    "latent",
    "talent"
  ],
  "aelnty": [
    "neatly"
  ],
  "aelo": [
    "aloe"
  ],
  "aelopr": [
    "parole"
  ],
  "aelp": [
    "leap",
    "pale",
    "plea"
  ],
  "aelpp": [
    "apple"
  ],
  "aelpps": [
    "apples"
  ],
  "aelpqu": [
    "plaque"
  ],
  "aelpr": [
    "paler",
    "pearl"
  ],
  "aelprs": [
    "pearls"
  ],
  "aelpry": [
    "pearly",
    "player",
    "replay"
  ],
  "aelps": [
    "lapse",
    "leaps",
    "pales",
    "pleas"
  ],
  "aelpss": [
    "lapses"
  ],
  "aelpst": [
    "petals",
    "plates",
    "staple"
  ],
  "aelpt": [
    "leapt",
    "petal",
    "plate"
  ],
  "aelqsu": [
    "equals",
    "squeal"
  ],
  "aelqu": [
    "equal"
  ],
  "aelr": [
    "earl",
    "lear",
    "real"
  ],
  "aelrry": [
    "rarely"
  ],
  "aelrs": [
    "laser"
  ],
  "aelrss": [
    "lasers"
  ],
  "aelrst": [
    "alerts",
    "alters",
    "slater"
  ],
  "aelrsy": [
    "layers",
    "slayer"
  ],
  "aelrt": [
    "alert",
    "alter",
    "later"
  ],
  "aelrtt": [
    "latter",
    "rattle"
  ],
  "aelrtv": [
    "travel"
  ],
  "aelrty": [
    "realty"
  ],
  "aelrwy": [
    "lawyer"
  ],
  "aelrx": [
    "relax"
  ],
  "aelry": [
    "early",
    "layer",
    "leary",
    "relay"
  ],
  "aels": [
    "ales",
    "sale",
    "seal"
  ],
  "aelss": [
    "sales",
    "seals"
  ],
  "aelsst": [
    "steals"
  ],
  "aelssv": [
    "slaves"
  ],
  "aelst": [
    "least",
    "slate",
    "stale",
    "steal",
    "tales"
  ],
  "aelstt": [
    "latest",
    "lattes"
  ],
  "aelstu": [
    "salute"
  ],
  "aelsuv": [
    "values"
  ],
  "aelsux": [
    "sexual"
  ],
  "aelsv": [
    "slave"
  ],
  "aelsvv": [
    "valves"
  ],
  "aelsyz": [
    "sleazy"
  ],
  "aelt": [
    "late",
    "tale",
    "teal"
  ],
  "aeltt": [
    "latte"
  ],
  "aelttt": [
    "tattle"
  ],
  "aeltv": [
    "valet"
  ],
  "aeltx": [
    "latex"
  ],
  "aeluv": [
    "value"
  ],
  "aelv": [
    "veal"
  ],
  "aelvv": [
    "valve"
  ],
  "aelx": [
    "axel",
    "axle"
  ],
  "aelz": [
    "zeal"
  ],
  "aem": [
    "mae"
  ],
  "aemmnr": [
    "merman"
  ],
  "aemmry": [
    "yammer"
  ],
  "aemn": [
    "amen",
    "mean",
    "name"
  ],
  "aemnnr": [
    "manner"
  ],
  "aemnrt": [
    "marten"
  ],
  "aemnru": [
    "manure"
  ],
  "aemns": [
    "means",
    "mensa",
    "names"
  ],
  "aemnt": [
    "meant"
  ],
  "aemppr": [
    "pamper"
  ],
  "aemprt": [
    "tamper"
  ],
  "aemprv": [
    "revamp"
  ],
  "aemr": [
    "mare",
    "ream"
  ],
  "aemrrw": [
    "warmer"
  ],
  "aemrs": [
    "smear"
  ],
  "aemrss": [
    "smears"
  ],
  "aemrst": [
    "master",
    "stream"
  ],
  "aemrtt": [
    "matter"
  ],
  "aemrtu": [
    "mature"
  ],
  "aems": [
    "mesa",
    "same",
    "seam"
  ],
  "aemss": [
    "seams"
  ],
  "aemsss": [
    "masses"
  ],
  "aemssu": [
    "amuses",
    "assume"
  ],
  "aemst": [
    "mates",
    "meats",
    "steam",
    "teams"
  ],
  "aemsty": [
    "steamy"
  ],
  "aemsu": [
    "amuse"
  ],
  "aemsx": [
    "exams"
  ],
  "aemt": [
    "mate",
    "meat",
    "meta",
    "tame",
    "team"
  ],
  "aemty": [
    "matey",
    "meaty"
  ],
  "aemx": [
    "exam"
  ],
  "aemz": [
    "maze"
  ],
  "aen": [
    "ane"
  ],
  "aennoy": [
    "anyone"
  ],
  "aennrt": [
    "tanner"
  ],
  "aenntt": [
    "tenant"
  ],
  "aennx": [
    "annex"
  ],
  "aenopw": [
    "weapon"
  ],
  "aenors": [
    "reason",
    "senora"
  ],
  "aenort": [
    "ornate"
  ],
  "aenoss": [
    "season"
  ],
  "aenp": [
    "pane"
  ],
  "aenprt": [
    "parent"
  ],
  "aenps": [
    "aspen"
  ],
  "aenptt": [
    "patent",
    "patten"
  ],
  "aenptu": [
    "peanut"
  ],
  "aenr": [
    "earn",
    "near"
  ],
  "aenrrt": [
    "errant"
  ],
  "aenrrw": [
    "warner",
    "warren"
  ],
  "aenrs": [
    "earns"
  ],
  "aenrsv": [
    "ravens"
  ],
  "aenrsw": [
    "answer"
  ],
  "aenrsy": [
    "yearns"
  ],
  "aenrtu": [
    "nature"
  ],
  "aenrtv": [
    "tavern"
  ],
  "aenrv": [
    "raven"
  ],
  "aens": [
    "sane"
  ],
  "aensst": [
    "sanest"
  ],
  "aenstu": [
    "unseat"
  ],
  "aensuy": [
    "uneasy"
  ],
  "aent": [
    "ante",
    "neat"
  ],
  "aenw": [
    "anew",
    "wean"
  ],
  "aeopr": [
    "opera"
  ],
  "aeoprs": [
    "operas"
  ],
  "aeoptt": [
    "teapot"
  ],
  "aeors": [
    "arose"
  ],
  "aeorsu": [
    "arouse"
  ],
  "aeortt": [
    "rotate"
  ],
  "aep": [
    "ape",
    "pea"
  ],
  "aeppr": [
    "paper"
  ],
  "aepprs": [
    "papers"
  ],
  "aepr": [
    "pare",
    "pear",
    "reap"
  ],
  "aeprry": [
    "prayer"
  ],
  "aeprs": [
    "spare",
    "spear"
  ],
  "aeprss": [
    "passer",
    "spears"
  ],
  "aeprtt": [
    "patter"
  ],
  "aepry": [
    "repay"
  ],
  "aeps": [
    "apes",
    "peas"
  ],
  "aepsss": [
    "passes"
  ],
  "aepssu": [
    "pauses"
  ],
  "aepst": [
    "paste",
    "spate",
    "tapes"
  ],
  "aepsu": [
    "pause"
  ],
  "aept": [
    "pate",
    "peat",
    "tape"
  ],
  "aepv": [
    "pave"
  ],
  "aepx": [
    "apex"
  ],
  "aeqrsu": [
    "square"
  ],
  "aeqsuy": [
    "queasy"
  ],
  "aer": [
    "are",
    "ear",
    "era"
  ],
  "aerr": [
    "rare",
    "rear"
  ],
  "aerrs": [
    "rears"
  ],
  "aerrst": [
    "arrest",
    "rarest"
  ],
  "aerrt": [
    "terra"
  ],
  "aerrty": [
    "artery"
  ],
  "aers": [
    "arse",
    "ears",
    "sear",
    "sera"
  ],
  "aerss": [
    "sears"
  ],
  "aersst": [
    "stares"
  ],
  "aerssu": [
    "assure"
  ],
  "aerssw": [
    "swears"
  ],
  "aerst": [
    "rates",
    "stare",
    "tears"
  ],
  "aerstt": [
    "treats"
  ],
  "aerstv": [
    "starve"
  ],
  "aerstw": [
    "waters"
  ],
  "aerstx": [
    "extras"
  ],
  "aersv": [
    "saver"
  ],
  "aersw": [
    "swear",
    "wares",
    "wears"
  ],
  "aerswy": [
    "sawyer"
  ],
  "aersy": [
    "sayer",
    "years"
  ],
  "aert": [
    "rate",
    "tear"
  ],
  "aertt": [
    "tater",
    "treat"
  ],
  "aertty": [
    "treaty"
  ],
  "aertw": [
    "water"
  ],
  "aertwy": [
    "watery"
  ],
  "aertx": [
    "extra"
  ],
  "aerty": [
    "teary"
  ],
  "aerv": [
    "rave",
    "vera"
  ],
  "aerw": [
    "ware",
    "wear"
  ],
  "aerwy": [
    "weary"
  ],
  "aery": [
    "year"
  ],
  "aes": [
    "sea"
  ],
  "aess": [
    "seas"
  ],
  "aesss": [
    "asses"
  ],
  "aessss": [
    "assess"
  ],
  "aessst": [
    "assets"
  ],
  "aesssy": [
    "essays"
  ],
  "aesst": [
    "asset",
    "seats"
  ],
  "aesstt": [
    "states",
    "tastes"
  ],
  "aesstw": [
    "sweats",
    "wastes"
  ],
  "aessv": [
    "saves"
  ],
  "aessy": [
    "essay"
  ],
  "aest": [
    "east",
    "eats",
    "seat",
    "teas"
  ],
  "aestt": [
    "state",
    "taste"
  ],
  "aesttt": [
    "attest"
  ],
  "aesttu": [
    "astute",
    "statue"
  ],
  "aestv": [
    "stave",
    "vesta"
  ],
  "aestw": [
    "sweat",
    "waste"
  ],
  "aestwy": [
    "sweaty"
  ],
  "aestx": [
    "taxes",
    "texas"
  ],
  "aesty": [
    "yeast"
  ],
  "aesuv": [
    "suave"
  ],
  "aesv": [
    "save",
    "vase"
  ],
  "aesvw": [
    "waves"
  ],
  "aeswx": [
    "waxes"
  ],
  "aesy": [
    "easy"
  ],
  "aet": [
    "ate",
    "eat",
    "eta",
    "tae",
    "tea"
  ],
  "aett": [
    "tate"
  ],
  "aetz": [
    "zeta"
  ],
  "aev": [
    "ave"
  ],
  "aevw": [
    "wave"
  ],
  "aew": [
    "awe"
  ],
  "aex": [
    "axe"
  ],
  "aey": [
    "aye",
    "yea"
  ],
  "affg": [
    "gaff"
  ],
  "affimr": [
    "affirm"
  ],
  "affopy": [
    "payoff"
  ],
  "affst": [
    "staff"
  ],
  "affty": [
    "taffy"
  ],
  "afg": [
    "fag"
  ],
  "afggot": [
    "faggot"
  ],
  "afggy": [
    "faggy"
  ],
  "afgikn": [
    "faking"
  ],
  "afgl": [
    "flag"
  ],
  "afglnu": [
    "fungal"
  ],
  "afglru": [
    "frugal"
  ],
  "afgls": [
    "flags"
  ],
  "afgn": [
    "fang"
  ],
  "afgns": [
    "fangs"
  ],
  "afgrst": [
    "grafts"
  ],
  "afgs": [
    "fags"
  ],
  "afhit": [
    "faith"
  ],
  "afhl": [
    "half"
  ],
  "afhloo": [
    "loofah"
  ],
  "afhls": [
    "flash"
  ],
  "afhlsy": [
    "flashy"
  ],
  "afhmot": [
    "fathom"
  ],
  "afhrw": [
    "wharf"
  ],
  "afhst": [
    "shaft"
  ],
  "afil": [
    "fail"
  ],
  "afill": [
    "flail"
  ],
  "afilmy": [
    "family"
  ],
  "afiln": [
    "final"
  ],
  "afilns": [
    "finals"
  ],
  "afilr": [
    "flair",
    "frail"
  ],
  "afilry": [
    "fairly"
  ],
  "afils": [
    "fails"
  ],
  "afimny": [
    "infamy"
  ],
  "afinnt": [
    "infant"
  ],
  "afinr": [
    "infra"
  ],
  "afinru": [
    "unfair"
  ],
  "afint": [
    "faint"
  ],
  "afir": [
    "fair"
  ],
  "afiry": [
    "fairy"
  ],
  "afiw": [
    "waif"
  ],
  "afkl": [
    "flak"
  ],
  "afkln": [
    "flank"
  ],
  "afklns": [
    "flanks"
  ],
  "afkls": [
    "flask"
  ],
  "afkly": [
    "flaky"
  ],
  "afknr": [
    "frank"
  ],
  "afknrs": [
    "franks"
  ],
  "afkrt": [
    "kraft"
  ],
  "afll": [
    "fall"
  ],
  "afllor": [
    "floral"
  ],
  "afllow": [
    "fallow"
  ],
  "aflls": [
    "falls"
  ],
  "aflluw": [
    "lawful"
  ],
  "aflmor": [
    "formal"
  ],
  "afln": [
    "flan"
  ],
  "aflntu": [
    "flaunt"
  ],
  "aflo": [
    "foal",
    "loaf"
  ],
  "aflor": [
    "flora"
  ],
  "aflorv": [
    "flavor"
  ],
  "aflost": [
    "floats"
  ],
  "aflot": [
    "aloft",
    "float"
  ],
  "aflp": [
    "flap"
  ],
  "aflps": [
    "flaps"
  ],
  "aflrtu": [
    "artful"
  ],
  "aflst": [
    "flats"
  ],
  "aflstu": [
    "faults"
  ],
  "aflsw": [
    "flaws"
  ],
  "aflt": [
    "flat"
  ],
  "afltu": [
    "fault"
  ],
  "afltuy": [
    "faulty"
  ],
  "afluw": [
    "awful"
  ],
  "aflw": [
    "flaw"
  ],
  "afly": [
    "flay"
  ],
  "afmo": [
    "foam"
  ],
  "afmort": [
    "format"
  ],
  "afmosu": [
    "famous"
  ],
  "afmoy": [
    "foamy"
  ],
  "afmr": [
    "farm"
  ],
  "afmrs": [
    "farms"
  ],
  "afn": [
    "fan"
  ],
  "afnny": [
    "fanny"
  ],
  "afns": [
    "fans"
  ],
  "afnu": [
    "faun"
  ],
  "afo": [
    "oaf"
  ],
  "afoot": [
    "afoot"
  ],
  "aforrw": [
    "farrow"
  ],
  "aforsv": [
    "favors"
  ],
  "aforuv": [
    "favour"
  ],
  "aforv": [
    "favor"
  ],
  "afory": [
    "foray"
  ],
  "afos": [
    "sofa"
  ],
  "afoss": [
    "sofas"
  ],
  "afost": [
    "fatso"
  ],
  "afr": [
    "arf",
    "far"
  ],
  "afrst": [
    "farts"
  ],
  "afrt": [
    "fart",
    "frat",
    "raft"
  ],
  "afry": [
    "fray"
  ],
  "afst": [
    "fast"
  ],
  "aft": [
    "aft",
    "fat"
  ],
  "aftty": [
    "fatty"
  ],
  "afux": [
    "faux"
  ],
  "afx": [
    "fax"
  ],
  "afy": [
    "fay"
  ],
  "agg": [
    "gag"
  ],
  "agghis": [
    "haggis"
  ],
  "agghsy": [
    "shaggy"
  ],
  "aggin": [
    "aging"
  ],
  "agginp": [
    "paging"
  ],
  "agginr": [
    "raging"
  ],
  "agginw": [
    "waging"
  ],
  "agglny": [
    "gangly"
  ],
  "agglu": [
    "gulag"
  ],
  "aggmot": [
    "maggot"
  ],
  "aggn": [
    "gang"
  ],
  "aggns": [
    "gangs"
  ],
  "aggs": [
    "gags"
  ],
  "agh": [
    "hag"
  ],
  "aghilt": [
    "alight"
  ],
  "aghint": [
    "hating"
  ],
  "aghinv": [
    "having"
  ],
  "aghinz": [
    "hazing"
  ],
  "aghirs": [
    "garish"
  ],
  "aghirt": [
    "aright"
  ],
  "aghlsu": [
    "laughs"
  ],
  "aghlu": [
    "laugh"
  ],
  "aghn": [
    "hang"
  ],
  "aghno": [
    "hogan"
  ],
  "aghns": [
    "hangs"
  ],
  "aghntu": [
    "naught"
  ],
  "aghoqu": [
    "quahog"
  ],
  "aghprs": [
    "graphs"
  ],
  "aghrt": [
    "garth"
  ],
  "aghs": [
    "hags",
    "shag"
  ],
  "aghttu": [
    "taught"
  ],
  "aghtu": [
    "aught"
  ],
  "agiiln": [
    "ailing"
  ],
  "agiimn": [
    "aiming"
  ],
  "agiinr": [
    "airing"
  ],
  "agijsw": [
    "jigsaw"
  ],
  "agikmn": [
    "making"
  ],
  "agikns": [
    "asking"
  ],
  "agiknt": [
    "taking"
  ],
  "agiknw": [
    "waking"
  ],
  "agilmn": [
    "malign"
  ],
  "agilns": [
    "signal"
  ],
  "agilny": [
    "laying"
  ],
  "agilor": [
    "gloria"
  ],
  "agilr": [
    "grail"
  ],
  "agimnn": [
    "naming"
  ],
  "agimnr": [
    "arming",
    "margin"
  ],
  "agimnt": [
    "mating"
  ],
  "agimo": [
    "amigo"
  ],
  "agimos": [
    "amigos"
  ],
  "agims": [
    "sigma"
  ],
  "agimst": [
    "stigma"
  ],
  "agin": [
    "agin",
    "gain"
  ],
  "aginnw": [
    "waning"
  ],
  "aginpr": [
    "raping"
  ],
  "aginpt": [
    "taping"
  ],
  "aginpv": [
    "paving"
  ],
  "aginpw": [
    "pawing"
  ],
  "aginpy": [
    "paying"
  ],
  "aginr": [
    "grain"
  ],
  "aginrr": [
    "raring"
  ],
  "aginrt": [
    "rating"
  ],
  "aginrv": [
    "raving"
  ],
  "aginry": [
    "grainy"
  ],
  "agins": [
    "gains"
  ],
  "aginss": [
    "assign"
  ],
  "aginst": [
    "giants"
  ],
  "aginsv": [
    "saving"
  ],
  "aginsw": [
    "sawing"
  ],
  "aginsy": [
    "saying"
  ],
  "agint": [
    "giant"
  ],
  "agintx": [
    "taxing"
  ],
  "aginvw": [
    "waving"
  ],
  "aginwx": [
    "waxing"
  ],
  "agirtu": [
    "guitar"
  ],
  "agj": [
    "jag"
  ],
  "agjnor": [
    "jargon"
  ],
  "agjs": [
    "jags"
  ],
  "agks": [
    "skag"
  ],
  "agkw": [
    "gawk"
  ],
  "agl": [
    "gal",
    "lag"
  ],
  "agll": [
    "gall"
  ],
  "agllno": [
    "gallon"
  ],
  "aglmor": [
    "glamor"
  ],
  "agln": [
    "lang"
  ],
  "aglno": [
    "along",
    "logan"
  ],
  "aglnoo": [
    "lagoon"
  ],
  "aglnos": [
    "slogan"
  ],
  "aglns": [
    "slang"
  ],
  "aglo": [
    "goal"
  ],
  "aglos": [
    "goals"
  ],
  "aglot": [
    "gloat"
  ],
  "aglruv": [
    "vulgar"
  ],
  "agls": [
    "gals"
  ],
  "aglss": [
    "glass"
  ],
  "aglssy": [
    "glassy"
  ],
  "agltu": [
    "gault"
  ],
  "agm": [
    "mag"
  ],
  "agmmnu": [
    "magnum"
  ],
  "agmmy": [
    "gammy"
  ],
  "agmnnu": [
    "gunman"
  ],
  "agmno": [
    "among"
  ],
  "agmnor": [
    "morgan"
  ],
  "agmnos": [
    "mangos"
  ],
  "agmny": [
    "mangy"
  ],
  "agmors": [
    "orgasm"
  ],
  "agmprs": [
    "gramps"
  ],
  "agmr": [
    "gram"
  ],
  "agmrs": [
    "grams"
  ],
  "agms": [
    "gams",
    "mags"
  ],
  "agmtu": [
    "gamut"
  ],
  "agn": [
    "nag"
  ],
  "agnnry": [
    "granny"
  ],
  "agnor": [
    "argon",
    "organ"
  ],
  "agnors": [
    "organs"
  ],
  "agnosw": [
    "wagons"
  ],
  "agnot": [
    "tango"
  ],
  "agnotu": [
    "nougat"
  ],
  "agnow": [
    "wagon"
  ],
  "agnoy": [
    "agony"
  ],
  "agnprs": [
    "sprang"
  ],
  "agnr": [
    "gran",
    "rang"
  ],
  "agnrst": [
    "grants"
  ],
  "agnrt": [
    "grant"
  ],
  "agnry": [
    "angry"
  ],
  "agns": [
    "sang",
    "snag"
  ],
  "agnss": [
    "snags"
  ],
  "agnst": [
    "angst",
    "gnats",
    "stang"
  ],
  "agnt": [
    "gnat"
  ],
  "agnw": [
    "gnaw"
  ],
  "agny": [
    "yang"
  ],
  "ago": [
    "ago",
    "goa"
  ],
  "agort": [
    "gator"
  ],
  "agost": [
    "goats"
  ],
  "agot": [
    "goat",
    "toga"
  ],
  "agoy": [
    "yoga"
  ],
  "agp": [
    "gap"
  ],
  "agprs": [
    "grasp"
  ],
  "agps": [
    "gaps",
    "gasp"
  ],
  "agr": [
    "gar",
    "rag"
  ],
  "agrs": [
    "rags"
  ],
  "agrss": [
    "grass"
  ],
  "agrssu": [
    "sugars"
  ],
  "agrssy": [
    "grassy"
  ],
  "agrsu": [
    "sugar"
  ],
  "agrsuy": [
    "sugary"
  ],
  "agrvy": [
    "gravy"
  ],
  "agry": [
    "gray"
  ],
  "ags": [
    "gas"
  ],
  "agssy": [
    "gassy"
  ],
  "agst": [
    "stag",
    "tags"
  ],
  "agstuu": [
    "august"
  ],
  "agsy": [
    "gays"
  ],
  "agt": [
    "gat",
    "tag"
  ],
  "agw": [
    "wag"
  ],
  "agy": [
    "gay"
  ],
  "agz": [
    "zag"
  ],
  "ahh": [
    "hah"
  ],
  "ahhrru": [
    "hurrah"
  ],
  "ahhrs": [
    "harsh"
  ],
  "ahhrst": [
    "thrash"
  ],
  "ahhs": [
    "hash",
    "shah"
  ],
  "ahht": [
    "hath"
  ],
  "ahhuzz": [
    "huzzah"
  ],
  "ahikk": [
    "khaki"
  ],
  "ahikks": [
    "khakis"
  ],
  "ahil": [
    "hail"
  ],
  "ahils": [
    "hails"
  ],
  "ahilsv": [
    "lavish"
  ],
  "ahimor": [
    "mohair"
  ],
  "ahimps": [
    "mishap"
  ],
  "ahinsv": [
    "vanish"
  ],
  "ahiprs": [
    "parish"
  ],
  "ahir": [
    "hair"
  ],
  "ahirs": [
    "hairs"
  ],
  "ahirsv": [
    "ravish"
  ],
  "ahirtw": [
    "wraith"
  ],
  "ahiry": [
    "hairy"
  ],
  "ahist": [
    "saith"
  ],
  "ahistu": [
    "hiatus"
  ],
  "ahisv": [
    "shiva"
  ],
  "ahkn": [
    "hank",
    "khan"
  ],
  "ahkns": [
    "hanks",
    "shank"
  ],
  "ahknss": [
    "shanks"
  ],
  "ahknst": [
    "thanks"
  ],
  "ahknt": [
    "thank"
  ],
  "ahkny": [
    "hanky"
  ],
  "ahkrs": [
    "shark"
  ],
  "ahkrss": [
    "sharks"
  ],
  "ahksw": [
    "hawks"
  ],
  "ahksy": [
    "shaky"
  ],
  "ahkw": [
    "hawk"
  ],
  "ahll": [
    "hall"
  ],
  "ahllo": [
    "hallo"
  ],
  "ahllrt": [
    "thrall"
  ],
  "ahlls": [
    "halls",
    "shall"
  ],
  "ahlmos": [
    "shalom"
  ],
  "ahlo": [
    "halo"
  ],
  "ahloop": [
    "hoopla"
  ],
  "ahlort": [
    "harlot"
  ],
  "ahlos": [
    "shoal"
  ],
  "ahlot": [
    "loath"
  ],
  "ahlpr": [
    "ralph"
  ],
  "ahlpss": [
    "splash"
  ],
  "ahlrsy": [
    "rashly"
  ],
  "ahls": [
    "lash"
  ],
  "ahlss": [
    "slash"
  ],
  "ahlst": [
    "shalt"
  ],
  "ahlsu": [
    "hauls"
  ],
  "ahlsw": [
    "shawl"
  ],
  "ahlt": [
    "halt"
  ],
  "ahlu": [
    "haul",
    "hula"
  ],
  "ahm": [
    "ham"
  ],
  "ahmmow": [
    "whammo"
  ],
  "ahmmwy": [
    "whammy"
  ],
  "ahmnos": [
    "hansom"
  ],
  "ahmnsu": [
    "humans"
  ],
  "ahmnu": [
    "human"
  ],
  "ahmotz": [
    "matzoh"
  ],
  "ahmr": [
    "harm"
  ],
  "ahmrs": [
    "harms",
    "marsh"
  ],
  "ahmrtw": [
    "warmth"
  ],
  "ahms": [
    "hams",
    "mash",
    "sham"
  ],
  "ahmss": [
    "smash"
  ],
  "ahmt": [
    "math"
  ],
  "ahmw": [
    "wham"
  ],
  "ahn": [
    "nah"
  ],
  "ahnopr": [
    "orphan"
  ],
  "ahnowy": [
    "anyhow"
  ],
  "ahnstu": [
    "haunts"
  ],
  "ahnsw": [
    "shawn"
  ],
  "ahnt": [
    "than"
  ],
  "ahntu": [
    "haunt"
  ],
  "ahoory": [
    "hooray"
  ],
  "ahoow": [
    "wahoo"
  ],
  "ahooy": [
    "yahoo"
  ],
  "ahopst": [
    "pathos"
  ],
  "ahor": [
    "hora"
  ],
  "ahort": [
    "torah"
  ],
  "ahortt": [
    "throat"
  ],
  "ahortu": [
    "author"
  ],
  "ahost": [
    "oaths"
  ],
  "ahot": [
    "oath"
  ],
  "ahow": [
    "whoa"
  ],
  "ahox": [
    "hoax"
  ],
  "ahoy": [
    "ahoy"
  ],
  "ahp": [
    "hap",
    "pah"
  ],
  "ahppy": [
    "happy"
  ],
  "ahpr": [
    "harp"
  ],
  "ahprs": [
    "harps",
    "sharp"
  ],
  "ahpry": [
    "harpy"
  ],
  "ahps": [
    "haps"
  ],
  "ahpst": [
    "paths"
  ],
  "ahpt": [
    "path"
  ],
  "ahqssu": [
    "squash"
  ],
  "ahr": [
    "rah"
  ],
  "ahrruy": [
    "hurray"
  ],
  "ahrry": [
    "harry"
  ],
  "ahrs": [
    "rash"
  ],
  "ahrst": [
    "harts",
    "trash"
  ],
  "ahrsty": [
    "trashy"
  ],
  "ahrt": [
    "hart",
    "rath"
  ],
  "ahrtw": [
    "wrath"
  ],
  "ahs": [
    "ash",
    "has",
    "sha"
  ],
  "ahsst": [
    "stash"
  ],
  "ahst": [
    "hast",
    "hats",
    "shat"
  ],
  "ahstw": [
    "whats"
  ],
  "ahsty": [
    "hasty"
  ],
  "ahsw": [
    "shaw",
    "wash"
  ],
  "ahswy": [
    "washy"
  ],
  "ahsy": [
    "shay"
  ],
  "aht": [
    "hat"
  ],
  "ahtt": [
    "that"
  ],
  "ahtw": [
    "thaw",
    "what"
  ],
  "ahw": [
    "haw",
    "wha"
  ],
  "ahy": [
    "hay",
    "yah"
  ],
  "ahyz": [
    "hazy"
  ],
  "aiinrs": [
    "raisin"
  ],
  "aijl": [
    "jail"
  ],
  "aijnn": [
    "ninja"
  ],
  "aijor": [
    "rioja"
  ],
  "aiknnp": [
    "napkin"
  ],
  "aiknt": [
    "takin"
  ],
  "aiks": [
    "saki"
  ],
  "ail": [
    "ail"
  ],
  "aillpr": [
    "pillar"
  ],
  "aillsv": [
    "villas"
  ],
  "aillv": [
    "villa"
  ],
  "ailm": [
    "lima",
    "mail"
  ],
  "ailmnr": [
    "marlin"
  ],
  "ailmnu": [
    "alumni"
  ],
  "ailmny": [
    "mainly"
  ],
  "ailmpr": [
    "primal"
  ],
  "ailms": [
    "mails"
  ],
  "ailn": [
    "nail"
  ],
  "ailnot": [
    "latino"
  ],
  "ailnp": [
    "plain"
  ],
  "ailnps": [
    "plains",
    "spinal"
  ],
  "ailnru": [
    "urinal"
  ],
  "ailns": [
    "nails"
  ],
  "ailnss": [
    "snails"
  ],
  "ailnty": [
    "litany"
  ],
  "ailnv": [
    "anvil"
  ],
  "ailors": [
    "sailor"
  ],
  "ailort": [
    "rialto",
    "tailor"
  ],
  "ailov": [
    "viola",
    "voila"
  ],
  "ailp": [
    "pail"
  ],
  "ailpr": [
    "pilar"
  ],
  "ailprs": [
    "spiral"
  ],
  "ailpt": [
    "plait"
  ],
  "ailr": [
    "lair",
    "liar",
    "lira",
    "rail"
  ],
  "ailrs": [
    "liars",
    "rails"
  ],
  "ailrst": [
    "trails",
    "trials"
  ],
  "ailrsv": [
    "rivals"
  ],
  "ailrt": [
    "trail",
    "trial"
  ],
  "ailrtu": [
    "ritual"
  ],
  "ailrv": [
    "rival",
    "viral"
  ],
  "ails": [
    "ails",
    "sail"
  ],
  "ailss": [
    "sails"
  ],
  "ailst": [
    "tails"
  ],
  "ailstv": [
    "vitals"
  ],
  "ailsuv": [
    "visual"
  ],
  "ailsv": [
    "silva",
    "vials"
  ],
  "ailt": [
    "tail"
  ],
  "ailtv": [
    "vital"
  ],
  "ailv": [
    "vail",
    "vial"
  ],
  "ailw": [
    "wail"
  ],
  "aim": [
    "aim",
    "ami"
  ],
  "aimm": [
    "imam",
    "maim"
  ],
  "aimmx": [
    "maxim"
  ],
  "aimn": [
    "amin",
    "main",
    "mina"
  ],
  "aimno": [
    "amino"
  ],
  "aimnrt": [
    "martin"
  ],
  "aimnst": [
    "mantis"
  ],
  "aimpr": [
    "prima"
  ],
  "aimprt": [
    "impart"
  ],
  "aimrtu": [
    "atrium"
  ],
  "aimrtx": [
    "matrix"
  ],
  "aims": [
    "aims"
  ],
  "aimss": [
    "amiss"
  ],
  "aimstu": [
    "autism"
  ],
  "aimsv": [
    "mavis"
  ],
  "aimsw": [
    "swami"
  ],
  "aimx": [
    "maxi"
  ],
  "ain": [
    "ain",
    "ani"
  ],
  "ainnot": [
    "anoint",
    "nation"
  ],
  "ainop": [
    "piano"
  ],
  "ainops": [
    "pianos"
  ],
  "ainort": [
    "ration"
  ],
  "ainp": [
    "pain",
    "pina"
  ],
  "ainprs": [
    "sprain"
  ],
  "ainps": [
    "pains"
  ],
  "ainpst": [
    "paints"
  ],
  "ainpt": [
    "paint"
  ],
  "ainqtu": [
    "quaint"
  ],
  "ainr": [
    "rain"
  ],
  "ainrs": [
    "rains"
  ],
  "ainrst": [
    "strain",
    "trains"
  ],
  "ainrt": [
    "train"
  ],
  "ainry": [
    "rainy"
  ],
  "ainsst": [
    "saints",
    "stains"
  ],
  "ainst": [
    "saint",
    "satin",
    "stain"
  ],
  "ainstt": [
    "titans"
  ],
  "ainsty": [
    "sanity"
  ],
  "ainsv": [
    "savin"
  ],
  "ainsz": [
    "nazis"
  ],
  "aint": [
    "anti"
  ],
  "aintt": [
    "taint",
    "titan"
  ],
  "aintvy": [
    "vanity"
  ],
  "aintw": [
    "twain"
  ],
  "ainv": [
    "vain"
  ],
  "ainz": [
    "nazi"
  ],
  "aiopst": [
    "patois"
  ],
  "aiopt": [
    "patio"
  ],
  "aioptu": [
    "utopia"
  ],
  "aiorsv": [
    "savior"
  ],
  "aiort": [
    "ratio"
  ],
  "aioss": [
    "oasis"
  ],
  "aipr": [
    "pair"
  ],
  "aiprs": [
    "pairs",
    "paris"
  ],
  "aiprst": [
    "rapist"
  ],
  "aipszz": [
    "pizzas"
  ],
  "aipzz": [
    "pizza"
  ],
  "air": [
    "air"
  ],
  "airrty": [
    "rarity"
  ],
  "airs": [
    "airs"
  ],
  "airsst": [
    "stairs"
  ],
  "airst": [
    "stair"
  ],
  "airstt": [
    "artist",
    "strait",
    "traits"
  ],
  "airtt": [
    "trait"
  ],
  "aissst": [
    "assist",
    "stasis"
  ],
  "aissv": [
    "visas"
  ],
  "aistv": [
    "vista"
  ],
  "aistw": [
    "waist",
    "waits"
  ],
  "aistx": [
    "taxis"
  ],
  "aisv": [
    "visa"
  ],
  "aisx": [
    "axis"
  ],
  "aitw": [
    "wait"
  ],
  "aitx": [
    "taxi"
  ],
  "aiv": [
    "via"
  ],
  "aivv": [
    "viva"
  ],
  "ajlopy": [
    "jalopy"
  ],
  "ajm": [
    "jam"
  ],
  "ajmor": [
    "major"
  ],
  "ajmors": [
    "majors"
  ],
  "ajms": [
    "jams"
  ],
  "ajntu": [
    "jaunt"
  ],
  "ajr": [
    "jar"
  ],
  "ajrs": [
    "jars"
  ],
  "ajsw": [
    "jaws"
  ],
  "ajt": [
    "taj"
  ],
  "ajw": [
    "jaw"
  ],
  "ajy": [
    "jay"
  ],
  "ajzz": [
    "jazz"
  ],
  "aklnp": [
    "plank"
  ],
  "aklny": [
    "lanky"
  ],
  "aklop": [
    "polka"
  ],
  "aklr": [
    "lark"
  ],
  "aklsst": [
    "stalks"
  ],
  "aklst": [
    "stalk",
    "talks"
  ],
  "aklsw": [
    "walks"
  ],
  "aklt": [
    "talk"
  ],
  "aklty": [
    "talky"
  ],
  "aklw": [
    "walk"
  ],
  "akmr": [
    "mark"
  ],
  "akmrs": [
    "marks"
  ],
  "akms": [
    "mask"
  ],
  "akmss": [
    "masks"
  ],
  "akno": [
    "kaon"
  ],
  "aknpr": [
    "prank"
  ],
  "aknprs": [
    "pranks"
  ],
  "aknps": [
    "spank"
  ],
  "aknr": [
    "rank"
  ],
  "aknrs": [
    "ranks"
  ],
  "aknrsy": [
    "snarky"
  ],
  "akns": [
    "sank"
  ],
  "aknst": [
    "tanks"
  ],
  "aknsw": [
    "swank"
  ],
  "aknswy": [
    "swanky"
  ],
  "aknsy": [
    "yanks"
  ],
  "aknt": [
    "tank"
  ],
  "akny": [
    "yank"
  ],
  "ako": [
    "oak"
  ],
  "akor": [
    "okra"
  ],
  "akos": [
    "oaks",
    "soak"
  ],
  "akoy": [
    "okay"
  ],
  "akpr": [
    "park"
  ],
  "akprs": [
    "parks",
    "spark"
  ],
  "akprss": [
    "sparks"
  ],
  "akprsy": [
    "sparky"
  ],
  "akptu": [
    "kaput"
  ],
  "akqru": [
    "quark"
  ],
  "akqsuw": [
    "squawk"
  ],
  "akr": [
    "ark"
  ],
  "akrs": [
    "sark"
  ],
  "akrst": [
    "stark"
  ],
  "aks": [
    "ask"
  ],
  "akss": [
    "asks"
  ],
  "aksst": [
    "tasks"
  ],
  "akst": [
    "task"
  ],
  "akt": [
    "kat"
  ],
  "aku": [
    "auk"
  ],
  "aky": [
    "kay",
    "yak"
  ],
  "all": [
    "all"
  ],
  "allm": [
    "mall"
  ],
  "allms": [
    "malls",
    "small"
  ],
  "alloop": [
    "apollo"
  ],
  "allopw": [
    "wallop"
  ],
  "allory": [
    "orally"
  ],
  "allosw": [
    "allows"
  ],
  "allow": [
    "allow"
  ],
  "alloww": [
    "wallow"
  ],
  "alloy": [
    "loyal"
  ],
  "allpru": [
    "plural"
  ],
  "allqsu": [
    "squall"
  ],
  "allry": [
    "rally"
  ],
  "allst": [
    "stall"
  ],
  "allsw": [
    "walls"
  ],
  "allsy": [
    "sally"
  ],
  "allt": [
    "tall"
  ],
  "allty": [
    "tally"
  ],
  "allw": [
    "wall"
  ],
  "allwy": [
    "wally"
  ],
  "ally": [
    "ally"
  ],
  "alm": [
    "lam"
  ],
  "almnor": [
    "normal"
  ],
  "almnos": [
    "salmon"
  ],
  "almny": [
    "manly"
  ],
  "almoo": [
    "moola"
  ],
  "almor": [
    "moral"
  ],
  "almors": [
    "molars",
    "morals"
  ],
  "almort": [
    "mortal"
  ],
  "almost": [
    "almost"
  ],
  "almp": [
    "lamp",
    "palm"
  ],
  "almps": [
    "lamps",
    "palms",
    "psalm"
  ],
  "almpy": [
    "amply"
  ],
  "almqsu": [
    "qualms"
  ],
  "almrsu": [
    "murals"
  ],
  "almru": [
    "mural"
  ],
  "alms": [
    "slam"
  ],
  "almss": [
    "slams"
  ],
  "almsuy": [
    "asylum"
  ],
  "almt": [
    "malt"
  ],
  "almtuu": [
    "mutual"
  ],
  "almu": [
    "maul"
  ],
  "alnnu": [
    "annul"
  ],
  "alno": [
    "loan"
  ],
  "alnoos": [
    "saloon"
  ],
  "alnor": [
    "loran"
  ],
  "alnos": [
    "loans",
    "salon"
  ],
  "alnost": [
    "talons"
  ],
  "alnotv": [
    "volant"
  ],
  "alnp": [
    "plan"
  ],
  "alnps": [
    "plans"
  ],
  "alnpst": [
    "plants"
  ],
  "alnpt": [
    "plant"
  ],
  "alnrs": [
    "snarl"
  ],
  "alnru": [
    "lunar"
  ],
  "alnst": [
    "slant"
  ],
  "alnstu": [
    "sultan"
  ],
  "alnsw": [
    "lawns"
  ],
  "alntuw": [
    "walnut"
  ],
  "alnu": [
    "luna"
  ],
  "alnw": [
    "lawn"
  ],
  "alop": [
    "opal"
  ],
  "aloppt": [
    "laptop"
  ],
  "alopr": [
    "polar"
  ],
  "aloprr": [
    "parlor"
  ],
  "aloprt": [
    "patrol",
    "portal"
  ],
  "alopst": [
    "postal"
  ],
  "alor": [
    "oral"
  ],
  "alors": [
    "solar"
  ],
  "alorsy": [
    "royals"
  ],
  "alorv": [
    "valor"
  ],
  "alory": [
    "royal"
  ],
  "alos": [
    "also"
  ],
  "aloss": [
    "lasso"
  ],
  "alostt": [
    "totals"
  ],
  "alot": [
    "alto"
  ],
  "alott": [
    "total"
  ],
  "alotuw": [
    "outlaw"
  ],
  "alotuy": [
    "layout"
  ],
  "alov": [
    "oval"
  ],
  "alow": [
    "awol"
  ],
  "alp": [
    "lap",
    "pal"
  ],
  "alpp": [
    "palp"
  ],
  "alppy": [
    "apply"
  ],
  "alprty": [
    "paltry",
    "partly"
  ],
  "alps": [
    "laps",
    "pals",
    "slap"
  ],
  "alpss": [
    "slaps"
  ],
  "alpst": [
    "splat"
  ],
  "alpsy": [
    "palsy",
    "plays"
  ],
  "alpty": [
    "aptly"
  ],
  "alpy": [
    "play"
  ],
  "alr": [
    "lar"
  ],
  "alrru": [
    "rural"
  ],
  "alrs": [
    "lars"
  ],
  "alrtu": [
    "ultra"
  ],
  "als": [
    "als",
    "las",
    "sal"
  ],
  "alss": [
    "lass"
  ],
  "alsst": [
    "lasts"
  ],
  "alst": [
    "last",
    "salt"
  ],
  "alstvy": [
    "vastly"
  ],
  "alsty": [
    "salty"
  ],
  "alsu": [
    "saul"
  ],
  "alsuu": [
    "usual"
  ],
  "alsw": [
    "laws",
    "slaw"
  ],
  "alsy": [
    "lays",
    "slay"
  ],
  "alt": [
    "alt"
  ],
  "altuv": [
    "vault"
  ],
  "altwz": [
    "waltz"
  ],
  "aluu": [
    "luau"
  ],
  "alw": [
    "law"
  ],
  "alx": [
    "lax"
  ],
  "aly": [
    "lay"
  ],
  "alyz": [
    "lazy"
  ],
  "ammmo": [
    "momma"
  ],
  "ammo": [
    "ammo"
  ],
  "ammpuw": [
    "wampum"
  ],
  "ammty": [
    "tammy"
  ],
  "amn": [
    "man",
    "nam"
  ],
  "amno": [
    "mano",
    "moan"
  ],
  "amnoor": [
    "maroon"
  ],
  "amnopt": [
    "tampon"
  ],
  "amnor": [
    "manor",
    "roman"
  ],
  "amnors": [
    "ransom"
  ],
  "amnort": [
    "matron"
  ],
  "amnos": [
    "mason",
    "moans"
  ],
  "amnoss": [
    "masons"
  ],
  "amnotu": [
    "amount"
  ],
  "amnow": [
    "woman"
  ],
  "amnttu": [
    "mutant"
  ],
  "amntuu": [
    "autumn"
  ],
  "amny": [
    "many"
  ],
  "amoott": [
    "tomato"
  ],
  "amor": [
    "roam"
  ],
  "amorr": [
    "armor"
  ],
  "amorrt": [
    "mortar"
  ],
  "amorru": [
    "armour"
  ],
  "amorrw": [
    "marrow"
  ],
  "amorry": [
    "armory"
  ],
  "amorsy": [
    "mayors"
  ],
  "amory": [
    "mayor"
  ],
  "amot": [
    "atom",
    "moat"
  ],
  "amoy": [
    "mayo"
  ],
  "amp": [
    "amp",
    "map",
    "pam"
  ],
  "ampr": [
    "pram",
    "ramp"
  ],
  "amprst": [
    "tramps"
  ],
  "amprt": [
    "tramp"
  ],
  "amps": [
    "maps"
  ],
  "ampss": [
    "spasm"
  ],
  "ampsss": [
    "spasms"
  ],
  "ampsst": [
    "stamps"
  ],
  "ampssw": [
    "swamps"
  ],
  "ampst": [
    "stamp"
  ],
  "ampsv": [
    "vamps"
  ],
  "ampsw": [
    "swamp"
  ],
  "ampv": [
    "vamp"
  ],
  "amr": [
    "arm",
    "mar",
    "ram"
  ],
  "amrrty": [
    "martyr"
  ],
  "amrry": [
    "marry"
  ],
  "amrs": [
    "arms",
    "mars"
  ],
  "amrsst": [
    "smarts"
  ],
  "amrst": [
    "smart"
  ],
  "amrsty": [
    "smarty"
  ],
  "amrsu": [
    "ramus"
  ],
  "amrsw": [
    "swarm",
    "warms"
  ],
  "amrt": [
    "mart",
    "tram"
  ],
  "amrw": [
    "warm"
  ],
  "amry": [
    "army"
  ],
  "ams": [
    "mas"
  ],
  "amss": [
    "mass"
  ],
  "amst": [
    "mats"
  ],
  "amsw": [
    "swam"
  ],
  "amsy": [
    "yams"
  ],
  "amt": [
    "mat"
  ],
  "amtt": [
    "matt"
  ],
  "amw": [
    "maw"
  ],
  "amx": [
    "max"
  ],
  "amy": [
    "may",
    "yam"
  ],
  "ann": [
    "nan"
  ],
  "annny": [
    "nanny"
  ],
  "anno": [
    "anon"
  ],
  "annosy": [
    "annoys"
  ],
  "annotw": [
    "wanton"
  ],
  "annoy": [
    "annoy"
  ],
  "annstu": [
    "suntan"
  ],
  "anopr": [
    "apron"
  ],
  "anoprs": [
    "aprons"
  ],
  "anoprt": [
    "parton",
    "patron"
  ],
  "anorrw": [
    "narrow"
  ],
  "anors": [
    "arson",
    "sonar"
  ],
  "anorty": [
    "notary"
  ],
  "anorw": [
    "rowan"
  ],
  "anosst": [
    "santos"
  ],
  "anost": [
    "santo"
  ],
  "anov": [
    "nova"
  ],
  "anp": [
    "nap",
    "pan"
  ],
  "anppsy": [
    "snappy"
  ],
  "anppy": [
    "nappy"
  ],
  "anprty": [
    "pantry"
  ],
  "anpruw": [
    "unwrap"
  ],
  "anps": [
    "naps",
    "pans",
    "snap",
    "span"
  ],
  "anpss": [
    "snaps",
    "spans"
  ],
  "anpst": [
    "pants"
  ],
  "anpsw": [
    "spawn"
  ],
  "anpsy": [
    "pansy"
  ],
  "anpt": [
    "pant"
  ],
  "anpty": [
    "panty"
  ],
  "anpw": [
    "pawn"
  ],
  "anr": [
    "ran"
  ],
  "anrst": [
    "rants",
    "trans"
  ],
  "anrt": [
    "rant"
  ],
  "anrttu": [
    "truant"
  ],
  "anrtty": [
    "tyrant"
  ],
  "anruwy": [
    "runway"
  ],
  "anrw": [
    "warn"
  ],
  "anry": [
    "nary",
    "yarn"
  ],
  "anss": [
    "sans"
  ],
  "anssw": [
    "swans"
  ],
  "anst": [
    "ants"
  ],
  "ansttu": [
    "taunts"
  ],
  "anstu": [
    "aunts"
  ],
  "anstw": [
    "wants"
  ],
  "ansty": [
    "antsy",
    "nasty"
  ],
  "answ": [
    "swan"
  ],
  "ansyzz": [
    "snazzy"
  ],
  "ant": [
    "ant",
    "tan"
  ],
  "anttu": [
    "taunt"
  ],
  "antty": [
    "natty"
  ],
  "antu": [
    "aunt",
    "tuna"
  ],
  "antw": [
    "want"
  ],
  "anuy": [
    "yuan"
  ],
  "anv": [
    "van"
  ],
  "anvy": [
    "navy"
  ],
  "anw": [
    "naw",
    "wan"
  ],
  "anwy": [
    "yawn"
  ],
  "any": [
    "any",
    "nay"
  ],
  "anyz": [
    "zany"
  ],
  "aooptt": [
    "potato"
  ],
  "aoorrt": [
    "orator"
  ],
  "aoorry": [
    "arroyo"
  ],
  "aoottt": [
    "tattoo"
  ],
  "aoppp": [
    "poppa"
  ],
  "aoprrt": [
    "parrot",
    "raptor"
  ],
  "aoprru": [
    "uproar"
  ],
  "aoprst": [
    "pastor"
  ],
  "aops": [
    "soap"
  ],
  "aopss": [
    "soaps"
  ],
  "aopsy": [
    "soapy"
  ],
  "aopt": [
    "atop"
  ],
  "aoptz": [
    "topaz"
  ],
  "aoqtu": [
    "quota"
  ],
  "aor": [
    "oar"
  ],
  "aorr": [
    "roar"
  ],
  "aorrsw": [
    "arrows"
  ],
  "aorrsz": [
    "razors"
  ],
  "aorrty": [
    "rotary"
  ],
  "aorrw": [
    "arrow"
  ],
  "aorrz": [
    "razor"
  ],
  "aors": [
    "oars",
    "soar"
  ],
  "aorss": [
    "soars"
  ],
  "aorsst": [
    "roasts"
  ],
  "aorst": [
    "roast"
  ],
  "aorsuv": [
    "savour"
  ],
  "aorsv": [
    "savor"
  ],
  "aortt": [
    "tarot"
  ],
  "aosstt": [
    "toasts"
  ],
  "aost": [
    "oats"
  ],
  "aostt": [
    "toast"
  ],
  "aostty": [
    "toasty"
  ],
  "aotu": [
    "auto"
  ],
  "app": [
    "pap"
  ],
  "apppy": [
    "pappy"
  ],
  "appsy": [
    "sappy"
  ],
  "apr": [
    "par",
    "rap"
  ],
  "aprs": [
    "spar"
  ],
  "aprsst": [
    "straps"
  ],
  "aprssy": [
    "sprays"
  ],
  "aprst": [
    "parts",
    "strap",
    "traps"
  ],
  "aprsty": [
    "pastry"
  ],
  "aprsw": [
    "wraps"
  ],
  "aprsy": [
    "prays",
    "spray"
  ],
  "aprt": [
    "part",
    "tarp",
    "trap"
  ],
  "aprty": [
    "party"
  ],
  "aprw": [
    "warp",
    "wrap"
  ],
  "apry": [
    "pray"
  ],
  "aps": [
    "pas",
    "sap",
    "spa"
  ],
  "apss": [
    "pass",
    "saps",
    "spas"
  ],
  "apsst": [
    "pasts"
  ],
  "apssw": [
    "wasps"
  ],
  "apst": [
    "past",
    "spat"
  ],
  "apsty": [
    "patsy"
  ],
  "apsw": [
    "paws",
    "swap",
    "wasp"
  ],
  "apsy": [
    "pays"
  ],
  "apsz": [
    "spaz"
  ],
  "apt": [
    "pat",
    "tap"
  ],
  "aptty": [
    "patty"
  ],
  "apw": [
    "paw"
  ],
  "apx": [
    "pax"
  ],
  "apy": [
    "pay",
    "yap"
  ],
  "apz": [
    "zap"
  ],
  "aqrruy": [
    "quarry"
  ],
  "aqrtu": [
    "quart"
  ],
  "aqstu": [
    "squat"
  ],
  "aqsuw": [
    "squaw"
  ],
  "arrsty": [
    "starry"
  ],
  "arsst": [
    "stars"
  ],
  "arsstt": [
    "starts"
  ],
  "arsstw": [
    "straws"
  ],
  "arst": [
    "arts",
    "rats",
    "star"
  ],
  "arstt": [
    "start",
    "tarts"
  ],
  "arstu": [
    "sutra"
  ],
  "arstw": [
    "straw",
    "warts"
  ],
  "arsty": [
    "artsy",
    "satyr",
    "stray",
    "trays"
  ],
  "arsw": [
    "wars"
  ],
  "arsy": [
    "rays"
  ],
  "art": [
    "art",
    "rat",
    "tar"
  ],
  "artt": [
    "tart"
  ],
  "artty": [
    "ratty"
  ],
  "artw": [
    "wart"
  ],
  "arty": [
    "arty",
    "tray"
  ],
  "arvy": [
    "vary"
  ],
  "arw": [
    "raw",
    "war"
  ],
  "arwy": [
    "awry",
    "wary"
  ],
  "ary": [
    "ray",
    "rya"
  ],
  "asssy": [
    "sassy"
  ],
  "asstt": [
    "stats"
  ],
  "assttu": [
    "status"
  ],
  "assty": [
    "stays"
  ],
  "assw": [
    "saws"
  ],
  "assy": [
    "says"
  ],
  "ast": [
    "sat",
    "tas"
  ],
  "astt": [
    "stat"
  ],
  "asttw": [
    "watts"
  ],
  "astty": [
    "tasty"
  ],
  "astv": [
    "vast"
  ],
  "astw": [
    "swat",
    "twas"
  ],
  "asty": [
    "stay"
  ],
  "asvvy": [
    "savvy"
  ],
  "asw": [
    "saw",
    "was"
  ],
  "aswy": [
    "sway",
    "ways"
  ],
  "asx": [
    "sax"
  ],
  "asy": [
    "say"
  ],
  "att": [
    "att",
    "tat"
  ],
  "attu": [
    "taut"
  ],
  "attw": [
    "watt"
  ],
  "atu": [
    "tau"
  ],
  "atv": [
    "vat"
  ],
  "atx": [
    "tax"
  ],
  "avwy": [
    "wavy"
  ],
  "awx": [
    "wax"
  ],
  "awxy": [
    "waxy"
  ],
  "awy": [
    "way",
    "yaw"
  ],
  "ayy": [
    "yay"
  ],
  "bbbelu": [
    "bubble"
  ],
  "bbbhuu": [
    "hubbub"
  ],
  "bbbino": [
    "bobbin"
  ],
  "bbbluy": [
    "bubbly"
  ],
  "bbboy": [
    "bobby"
  ],
  "bbceow": [
    "cobweb"
  ],
  "bbchuy": [
    "chubby"
  ],
  "bbco": [
    "cobb"
  ],
  "bbcuy": [
    "cubby"
  ],
  "bbddeu": [
    "dubbed"
  ],
  "bbdegu": [
    "bedbug"
  ],
  "bbdeir": [
    "bribed",
    "ribbed"
  ],
  "bbdemo": [
    "bombed"
  ],
  "bbdeor": [
    "robbed"
  ],
  "bbderu": [
    "rubbed"
  ],
  "bbdino": [
    "dobbin"
  ],
  "bbe": [
    "ebb"
  ],
  "bbeelp": [
    "pebble"
  ],
  "bbefir": [
    "fibber"
  ],
  "bbeglo": [
    "gobble"
  ],
  "bbehlo": [
    "hobble"
  ],
  "bbeikl": [
    "kibble"
  ],
  "bbeil": [
    "bible"
  ],
  "bbeiln": [
    "nibble"
  ],
  "bbeils": [
    "bibles"
  ],
  "bbeir": [
    "bribe"
  ],
  "bbeirs": [
    "bribes"
  ],
  "bbelmu": [
    "bumble"
  ],
  "bbelow": [
    "wobble"
  ],
  "bbelru": [
    "rubble"
  ],
  "bbemor": [
    "bomber"
  ],
  "bbeorr": [
    "robber"
  ],
  "bberru": [
    "rubber"
  ],
  "bbgruy": [
    "grubby"
  ],
  "bbhiot": [
    "hobbit"
  ],
  "bbhoy": [
    "hobby"
  ],
  "bbhuy": [
    "hubby"
  ],
  "bbi": [
    "bib"
  ],
  "bbimo": [
    "bimbo"
  ],
  "bbimos": [
    "bimbos"
  ],
  "bbinor": [
    "ribbon",
    "robbin"
  ],
  "bbknoy": [
    "knobby"
  ],
  "bblo": [
    "blob"
  ],
  "bbloy": [
    "lobby"
  ],
  "bblru": [
    "blurb"
  ],
  "bblsu": [
    "bulbs"
  ],
  "bblu": [
    "bulb"
  ],
  "bbmo": [
    "bomb"
  ],
  "bbmos": [
    "bombs"
  ],
  "bbmruy": [
    "brumby"
  ],
  "bbnnoo": [
    "bonbon"
  ],
  "bbnosy": [
    "snobby"
  ],
  "bbo": [
    "bob"
  ],
  "bboo": [
    "boob"
  ],
  "bboos": [
    "boobs"
  ],
  "bbooy": [
    "booby"
  ],
  "bbosuy": [
    "busboy"
  ],
  "bbrsu": [
    "burbs"
  ],
  "bbrsuu": [
    "suburb"
  ],
  "bbtuy": [
    "tubby"
  ],
  "bcciu": [
    "cubic"
  ],
  "bcdeu": [
    "cubed"
  ],
  "bceeh": [
    "beech"
  ],
  "bceehr": [
    "breech"
  ],
  "bceemo": [
    "become"
  ],
  "bcehn": [
    "bench"
  ],
  "bcehru": [
    "cherub"
  ],
  "bceikr": [
    "bicker"
  ],
  "bceiox": [
    "icebox"
  ],
  "bcejot": [
    "object"
  ],
  "bcek": [
    "beck"
  ],
  "bceklu": [
    "buckle"
  ],
  "bceks": [
    "becks"
  ],
  "bcektu": [
    "bucket"
  ],
  "bcenou": [
    "bounce"
  ],
  "bcesu": [
    "cubes"
  ],
  "bceu": [
    "cube"
  ],
  "bcgory": [
    "cyborg"
  ],
  "bchiop": [
    "phobic"
  ],
  "bchir": [
    "birch"
  ],
  "bchnru": [
    "brunch"
  ],
  "bchnu": [
    "bunch"
  ],
  "bchoor": [
    "brooch"
  ],
  "bchtu": [
    "butch"
  ],
  "bciino": [
    "bionic"
  ],
  "bcikr": [
    "brick"
  ],
  "bcikrs": [
    "bricks"
  ],
  "bcilm": [
    "climb"
  ],
  "bcilpu": [
    "public"
  ],
  "bcipu": [
    "pubic"
  ],
  "bcir": [
    "crib"
  ],
  "bcirs": [
    "cribs"
  ],
  "bcklo": [
    "block"
  ],
  "bcklos": [
    "blocks"
  ],
  "bckor": [
    "brock"
  ],
  "bckou": [
    "bucko"
  ],
  "bcksu": [
    "bucks"
  ],
  "bcku": [
    "buck"
  ],
  "bclo": [
    "bloc"
  ],
  "bclsu": [
    "clubs"
  ],
  "bclu": [
    "club"
  ],
  "bcmo": [
    "comb"
  ],
  "bcmoo": [
    "combo"
  ],
  "bcmrsu": [
    "crumbs"
  ],
  "bcmru": [
    "crumb"
  ],
  "bcnoor": [
    "bronco"
  ],
  "bcnouy": [
    "bouncy"
  ],
  "bco": [
    "cob"
  ],
  "bcoowy": [
    "cowboy"
  ],
  "bcrssu": [
    "scrubs"
  ],
  "bcrsu": [
    "scrub"
  ],
  "bcru": [
    "curb"
  ],
  "bcsu": [
    "cubs"
  ],
  "bcu": [
    "cub"
  ],
  "bddeen": [
    "bended"
  ],
  "bddeir": [
    "bidder"
  ],
  "bddeno": [
    "bonded"
  ],
  "bdduy": [
    "buddy"
  ],
  "bde": [
    "bed",
    "deb"
  ],
  "bdeeef": [
    "beefed"
  ],
  "bdeeep": [
    "beeped"
  ],
  "bdeegg": [
    "begged"
  ],
  "bdeeil": [
    "edible"
  ],
  "bdeeis": [
    "beside"
  ],
  "bdeel": [
    "bleed"
  ],
  "bdeels": [
    "bleeds"
  ],
  "bdeelt": [
    "belted"
  ],
  "bdeenr": [
    "bender"
  ],
  "bdeeoy": [
    "obeyed"
  ],
  "bdeer": [
    "breed"
  ],
  "bdeers": [
    "breeds"
  ],
  "bdeerw": [
    "brewed"
  ],
  "bdeest": [
    "bested"
  ],
  "bdeew": [
    "dweeb"
  ],
  "bdeggu": [
    "bugged"
  ],
  "bdegir": [
    "bridge"
  ],
  "bdegtu": [
    "budget"
  ],
  "bdegu": [
    "budge"
  ],
  "bdehin": [
    "behind"
  ],
  "bdehlo": [
    "behold"
  ],
  "bdehot": [
    "hotbed"
  ],
  "bdei": [
    "bide"
  ],
  "bdeiir": [
    "birdie"
  ],
  "bdeilo": [
    "boiled"
  ],
  "bdeimu": [
    "imbued"
  ],
  "bdeinr": [
    "binder",
    "inbred"
  ],
  "bdeios": [
    "bodies"
  ],
  "bdeir": [
    "bride"
  ],
  "bdeirs": [
    "brides",
    "debris"
  ],
  "bdeiru": [
    "buried"
  ],
  "bdeit": [
    "debit"
  ],
  "bdekoo": [
    "booked"
  ],
  "bdel": [
    "bled"
  ],
  "bdeln": [
    "blend"
  ],
  "bdelno": [
    "blonde"
  ],
  "bdelns": [
    "blends"
  ],
  "bdelnu": [
    "bundle"
  ],
  "bdelor": [
    "bolder"
  ],
  "bdelot": [
    "bolted"
  ],
  "bdelou": [
    "double"
  ],
  "bdelow": [
    "blowed",
    "bowled"
  ],
  "bdemmu": [
    "bummed"
  ],
  "bdemoy": [
    "embody"
  ],
  "bdempu": [
    "bumped"
  ],
  "bdemru": [
    "dumber"
  ],
  "bden": [
    "bend"
  ],
  "bdeno": [
    "boned"
  ],
  "bdenoy": [
    "beyond"
  ],
  "bdenru": [
    "burden",
    "burned"
  ],
  "bdens": [
    "bends"
  ],
  "bdeny": [
    "bendy"
  ],
  "bdeoot": [
    "booted"
  ],
  "bdeor": [
    "bored",
    "orbed"
  ],
  "bdeorr": [
    "border"
  ],
  "bdeoss": [
    "bossed"
  ],
  "bdeow": [
    "bowed"
  ],
  "bdeox": [
    "boxed"
  ],
  "bder": [
    "bred"
  ],
  "bdery": [
    "derby"
  ],
  "bdes": [
    "beds"
  ],
  "bdest": [
    "debts"
  ],
  "bdestu": [
    "busted"
  ],
  "bdet": [
    "debt"
  ],
  "bdettu": [
    "butted"
  ],
  "bdetu": [
    "debut"
  ],
  "bdeuzz": [
    "buzzed"
  ],
  "bdfior": [
    "forbid"
  ],
  "bdgiin": [
    "biding"
  ],
  "bdhiry": [
    "hybrid"
  ],
  "bdi": [
    "bid"
  ],
  "bdiilo": [
    "libido"
  ],
  "bdiln": [
    "blind"
  ],
  "bdilns": [
    "blinds"
  ],
  "bdiloy": [
    "bodily"
  ],
  "bdilsu": [
    "builds"
  ],
  "bdilu": [
    "build"
  ],
  "bdimor": [
    "morbid"
  ],
  "bdin": [
    "bind"
  ],
  "bdins": [
    "binds"
  ],
  "bdiotu": [
    "outbid"
  ],
  "bdir": [
    "bird"
  ],
  "bdirs": [
    "birds"
  ],
  "bdis": [
    "bids",
    "dibs"
  ],
  "bdlloy": [
    "boldly"
  ],
  "bdlno": [
    "blond"
  ],
  "bdlnos": [
    "blonds"
  ],
  "bdlo": [
    "bold"
  ],
  "bdloo": [
    "blood"
  ],
  "bdloos": [
    "bloods"
  ],
  "bdlooy": [
    "bloody"
  ],
  "bdlouy": [
    "doubly"
  ],
  "bdmu": [
    "dumb"
  ],
  "bdno": [
    "bond"
  ],
  "bdnoos": [
    "dobson"
  ],
  "bdnooy": [
    "nobody"
  ],
  "bdnos": [
    "bonds"
  ],
  "bdnosu": [
    "bounds"
  ],
  "bdnou": [
    "bound"
  ],
  "bdo": [
    "bod"
  ],
  "bdoor": [
    "brood"
  ],
  "bdoory": [
    "broody"
  ],
  "bdostu": [
    "doubts"
  ],
  "bdotu": [
    "doubt"
  ],
  "bdoy": [
    "body"
  ],
  "bdsu": [
    "buds"
  ],
  "bdu": [
    "bud",
    "dub"
  ],
  "bee": [
    "bee"
  ],
  "beeefl": [
    "feeble"
  ],
  "beeelt": [
    "beetle"
  ],
  "beeepr": [
    "beeper"
  ],
  "beeerz": [
    "breeze"
  ],
  "beef": [
    "beef"
  ],
  "beefil": [
    "belief"
  ],
  "beefor": [
    "before"
  ],
  "beefrt": [
    "bereft"
  ],
  "beefs": [
    "beefs"
  ],
  "beefy": [
    "beefy"
  ],
  "beegi": [
    "beige"
  ],
  "beegnu": [
    "bungee"
  ],
  "beegst": [
    "begets"
  ],
  "beehop": [
    "phoebe"
  ],
  "beehry": [
    "hereby"
  ],
  "beeil": [
    "belie"
  ],
  "beeilv": [
    "belive"
  ],
  "beell": [
    "belle"
  ],
  "beelmm": [
    "emblem"
  ],
  "beelp": [
    "bleep"
  ],
  "beelr": [
    "rebel"
  ],
  "beelrs": [
    "rebels"
  ],
  "beemmr": [
    "member"
  ],
  "beemr": [
    "ember"
  ],
  "been": [
    "been",
    "bene"
  ],
  "beennt": [
    "bennet"
  ],
  "beens": [
    "benes"
  ],
  "beeos": [
    "obese"
  ],
  "beep": [
    "beep"
  ],
  "beeps": [
    "beeps"
  ],
  "beer": [
    "beer",
    "bree"
  ],
  "beerrw": [
    "brewer"
  ],
  "beers": [
    "beers"
  ],
  "beert": [
    "beret"
  ],
  "beertt": [
    "better"
  ],
  "beerw": [
    "weber"
  ],
  "beery": [
    "beery"
  ],
  "beeryz": [
    "breezy"
  ],
  "bees": [
    "bees"
  ],
  "beest": [
    "beets"
  ],
  "beffru": [
    "buffer"
  ],
  "befftu": [
    "buffet"
  ],
  "befir": [
    "brief",
    "fiber",
    "fibre"
  ],
  "befirs": [
    "briefs",
    "fibers"
  ],
  "beflmu": [
    "fumble"
  ],
  "beg": [
    "beg"
  ],
  "beggii": [
    "biggie"
  ],
  "beggir": [
    "bigger"
  ],
  "begglo": [
    "boggle"
  ],
  "beggru": [
    "bugger"
  ],
  "begil": [
    "bilge"
  ],
  "begilo": [
    "oblige"
  ],
  "begilr": [
    "gerbil"
  ],
  "begin": [
    "begin",
    "being",
    "binge"
  ],
  "beginn": [
    "benign"
  ],
  "begins": [
    "begins",
    "beings"
  ],
  "begioo": [
    "boogie"
  ],
  "beglno": [
    "belong"
  ],
  "beglo": [
    "globe"
  ],
  "beglos": [
    "globes"
  ],
  "beglot": [
    "goblet"
  ],
  "beglu": [
    "bugle",
    "bulge"
  ],
  "begnu": [
    "begun"
  ],
  "begoor": [
    "booger",
    "goober"
  ],
  "begooy": [
    "boogey"
  ],
  "begr": [
    "berg"
  ],
  "begrru": [
    "burger"
  ],
  "begs": [
    "begs"
  ],
  "behlmu": [
    "humble"
  ],
  "behlsu": [
    "bushel"
  ],
  "behmor": [
    "hombre"
  ],
  "behoos": [
    "hoboes"
  ],
  "behort": [
    "bother"
  ],
  "behr": [
    "herb"
  ],
  "behrs": [
    "herbs"
  ],
  "behssu": [
    "bushes"
  ],
  "beht": [
    "beth"
  ],
  "beiill": [
    "billie"
  ],
  "beik": [
    "bike"
  ],
  "beikoo": [
    "bookie"
  ],
  "beikr": [
    "biker"
  ],
  "beikrs": [
    "bikers"
  ],
  "beiks": [
    "bikes"
  ],
  "beill": [
    "libel"
  ],
  "beilmo": [
    "mobile"
  ],
  "beilmr": [
    "limber"
  ],
  "beilmy": [
    "blimey"
  ],
  "beilnr": [
    "berlin"
  ],
  "beilnu": [
    "nubile"
  ],
  "beilny": [
    "byline"
  ],
  "beilor": [
    "boiler"
  ],
  "beimoz": [
    "zombie"
  ],
  "beimrt": [
    "timber",
    "timbre"
  ],
  "beinno": [
    "bonnie"
  ],
  "beintt": [
    "bitten"
  ],
  "beiqsu": [
    "bisque"
  ],
  "beir": [
    "brie"
  ],
  "beirsu": [
    "bruise",
    "buries",
    "busier",
    "rubies"
  ],
  "beirt": [
    "tribe"
  ],
  "beirtt": [
    "bitter"
  ],
  "beist": [
    "bites"
  ],
  "beisv": [
    "vibes"
  ],
  "beit": [
    "bite"
  ],
  "beiv": [
    "vibe"
  ],
  "bejlmu": [
    "jumble"
  ],
  "beklo": [
    "bloke"
  ],
  "beklos": [
    "blokes"
  ],
  "beknor": [
    "broken"
  ],
  "beknru": [
    "bunker"
  ],
  "bekoor": [
    "booker"
  ],
  "bekor": [
    "broke"
  ],
  "bekorr": [
    "broker"
  ],
  "bekru": [
    "burke"
  ],
  "bel": [
    "bel"
  ],
  "bell": [
    "bell"
  ],
  "bells": [
    "bells"
  ],
  "belltu": [
    "bullet"
  ],
  "belly": [
    "belly"
  ],
  "belmmu": [
    "mumble"
  ],
  "belmru": [
    "lumber",
    "rumble"
  ],
  "belmtu": [
    "tumble"
  ],
  "belmu": [
    "blume"
  ],
  "belno": [
    "noble"
  ],
  "belo": [
    "lobe"
  ],
  "belorw": [
    "blower",
    "bowler"
  ],
  "belos": [
    "lobes"
  ],
  "belosu": [
    "blouse"
  ],
  "belosw": [
    "bowels",
    "elbows"
  ],
  "belott": [
    "bottle"
  ],
  "below": [
    "below",
    "bowel",
    "elbow"
  ],
  "belrtu": [
    "butler"
  ],
  "belru": [
    "bluer"
  ],
  "belruy": [
    "burley"
  ],
  "belss": [
    "bless"
  ],
  "belst": [
    "belts"
  ],
  "belstu": [
    "bluest",
    "bustle",
    "sublet",
    "subtle"
  ],
  "belsu": [
    "blues"
  ],
  "belt": [
    "belt"
  ],
  "belu": [
    "blue",
    "lube"
  ],
  "belw": [
    "blew"
  ],
  "bemmru": [
    "bummer"
  ],
  "bemnru": [
    "number"
  ],
  "bemoor": [
    "boomer"
  ],
  "bemors": [
    "somber"
  ],
  "bemory": [
    "embryo"
  ],
  "bempru": [
    "bumper"
  ],
  "ben": [
    "ben"
  ],
  "bennot": [
    "bonnet"
  ],
  "benny": [
    "benny"
  ],
  "beno": [
    "bone"
  ],
  "benor": [
    "boner"
  ],
  "benorr": [
    "reborn"
  ],
  "benors": [
    "boners"
  ],
  "benoru": [
    "bourne"
  ],
  "benorz": [
    "bronze"
  ],
  "benos": [
    "bones"
  ],
  "benoy": [
    "ebony"
  ],
  "benr": [
    "bren"
  ],
  "benrru": [
    "burner"
  ],
  "benrt": [
    "brent"
  ],
  "bent": [
    "bent"
  ],
  "beoo": [
    "oboe"
  ],
  "beoort": [
    "reboot"
  ],
  "beoorz": [
    "boozer"
  ],
  "beooz": [
    "booze"
  ],
  "beopr": [
    "probe"
  ],
  "beoprs": [
    "probes"
  ],
  "beor": [
    "bore",
    "robe"
  ],
  "beors": [
    "robes",
    "sober"
  ],
  "beorst": [
    "sorbet",
    "strobe"
  ],
  "beorsw": [
    "bowers",
    "browse"
  ],
  "beorsx": [
    "boxers"
  ],
  "beorwy": [
    "bowery"
  ],
  "beorx": [
    "boxer"
  ],
  "beosss": [
    "bosses",
    "obsess"
  ],
  "beostu": [
    "obtuse"
  ],
  "beostw": [
    "bestow"
  ],
  "beosx": [
    "boxes"
  ],
  "beoy": [
    "obey"
  ],
  "beprsu": [
    "superb"
  ],
  "bepsu": [
    "pubes"
  ],
  "berry": [
    "berry"
  ],
  "berstu": [
    "buster",
    "tubers"
  ],
  "bersu": [
    "rubes"
  ],
  "bersuy": [
    "buyers"
  ],
  "bersv": [
    "verbs"
  ],
  "bersw": [
    "brews"
  ],
  "berttu": [
    "butter"
  ],
  "bertu": [
    "brute"
  ],
  "beru": [
    "rube"
  ],
  "beruy": [
    "buyer"
  ],
  "beruzz": [
    "buzzer"
  ],
  "berw": [
    "brew"
  ],
  "bessu": [
    "buses"
  ],
  "best": [
    "best",
    "bets"
  ],
  "bestu": [
    "tubes"
  ],
  "besuzz": [
    "buzzes"
  ],
  "besw": [
    "webs"
  ],
  "besy": [
    "byes"
  ],
  "bet": [
    "bet"
  ],
  "betu": [
    "tube"
  ],
  "bew": [
    "web"
  ],
  "bey": [
    "bey",
    "bye"
  ],
  "bffi": [
    "biff"
  ],
  "bfflsu": [
    "bluffs"
  ],
  "bfflu": [
    "bluff"
  ],
  "bffsu": [
    "buffs"
  ],
  "bffu": [
    "buff"
  ],
  "bffuy": [
    "buffy"
  ],
  "bfi": [
    "fib"
  ],
  "bfloyy": [
    "flyboy"
  ],
  "bfo": [
    "fob"
  ],
  "bgguy": [
    "buggy"
  ],
  "bghilt": [
    "blight"
  ],
  "bghirt": [
    "bright"
  ],
  "bghmuu": [
    "humbug"
  ],
  "bghotu": [
    "bought"
  ],
  "bgi": [
    "big",
    "gib"
  ],
  "bgiikn": [
    "biking"
  ],
  "bgiint": [
    "biting"
  ],
  "bgil": [
    "glib"
  ],
  "bgilno": [
    "goblin"
  ],
  "bginno": [
    "boning"
  ],
  "bgino": [
    "bingo"
  ],
  "bginor": [
    "boring",
    "orbing"
  ],
  "bginos": [
    "gibson"
  ],
  "bginow": [
    "bowing"
  ],
  "bginox": [
    "boxing"
  ],
  "bginr": [
    "bring"
  ],
  "bginrs": [
    "brings"
  ],
  "bgintu": [
    "tubing"
  ],
  "bginuy": [
    "buying"
  ],
  "bgiot": [
    "bigot"
  ],
  "bgir": [
    "brig"
  ],
  "bgno": [
    "bong"
  ],
  "bgnoo": [
    "bongo"
  ],
  "bgnoos": [
    "bongos"
  ],
  "bgo": [
    "gob"
  ],
  "bgos": [
    "bogs",
    "gobs"
  ],
  "bgosu": [
    "bogus"
  ],
  "bgrsu": [
    "grubs"
  ],
  "bgru": [
    "grub"
  ],
  "bgruy": [
    "rugby"
  ],
  "bgsu": [
    "bugs"
  ],
  "bgu": [
    "bug"
  ],
  "bhikos": [
    "kibosh"
  ],
  "bhiops": [
    "bishop"
  ],
  "bhirst": [
    "births"
  ],
  "bhirsu": [
    "hubris"
  ],
  "bhirt": [
    "birth"
  ],
  "bhlmuy": [
    "humbly"
  ],
  "bhlsu": [
    "blush"
  ],
  "bhmstu": [
    "thumbs"
  ],
  "bhmtu": [
    "thumb"
  ],
  "bho": [
    "hob"
  ],
  "bhoo": [
    "hobo"
  ],
  "bhoost": [
    "booths"
  ],
  "bhoot": [
    "booth"
  ],
  "bhort": [
    "broth"
  ],
  "bhot": [
    "both"
  ],
  "bhrssu": [
    "shrubs"
  ],
  "bhrsu": [
    "brush",
    "shrub"
  ],
  "bhsu": [
    "bush"
  ],
  "bhu": [
    "hub"
  ],
  "biiikn": [
    "bikini"
  ],
  "bij": [
    "jib"
  ],
  "bijou": [
    "bijou"
  ],
  "bikln": [
    "blink"
  ],
  "biklns": [
    "blinks"
  ],
  "biknr": [
    "brink"
  ],
  "bill": [
    "bill"
  ],
  "billr": [
    "brill"
  ],
  "bills": [
    "bills"
  ],
  "billy": [
    "billy"
  ],
  "bilm": [
    "limb"
  ],
  "bilmo": [
    "limbo"
  ],
  "bilmp": [
    "blimp"
  ],
  "bilms": [
    "limbs"
  ],
  "bilo": [
    "boil"
  ],
  "bilos": [
    "boils"
  ],
  "bilp": [
    "blip"
  ],
  "bilps": [
    "blips"
  ],
  "bilss": [
    "bliss"
  ],
  "biltu": [
    "built"
  ],
  "biltz": [
    "blitz"
  ],
  "bimr": [
    "brim"
  ],
  "bimstu": [
    "submit"
  ],
  "bin": [
    "bin"
  ],
  "binnou": [
    "bunion"
  ],
  "binor": [
    "robin"
  ],
  "binors": [
    "robins"
  ],
  "binos": [
    "bison"
  ],
  "binr": [
    "brin"
  ],
  "bins": [
    "bins"
  ],
  "bio": [
    "bio",
    "obi"
  ],
  "biopsy": [
    "biopsy"
  ],
  "biorst": [
    "bistro"
  ],
  "biort": [
    "orbit"
  ],
  "bios": [
    "bios"
  ],
  "biost": [
    "obits"
  ],
  "bir": [
    "rib"
  ],
  "birs": [
    "bris",
    "ribs"
  ],
  "birt": [
    "brit"
  ],
  "birtt": [
    "britt"
  ],
  "bist": [
    "bits"
  ],
  "bisty": [
    "bitsy"
  ],
  "bit": [
    "bit"
  ],
  "bitty": [
    "bitty"
  ],
  "biz": [
    "biz"
  ],
  "bjmou": [
    "jumbo"
  ],
  "bjo": [
    "job"
  ],
  "bjos": [
    "jobs"
  ],
  "bklu": [
    "bulk"
  ],
  "bkluy": [
    "bulky"
  ],
  "bkno": [
    "knob"
  ],
  "bknos": [
    "knobs"
  ],
  "bknsu": [
    "bunks"
  ],
  "bknu": [
    "bunk"
  ],
  "bkoo": [
    "book",
    "kobo"
  ],
  "bkoor": [
    "brook"
  ],
  "bkoors": [
    "brooks"
  ],
  "bkoos": [
    "books"
  ],
  "bllsu": [
    "bulls"
  ],
  "bllu": [
    "bull"
  ],
  "blluy": [
    "bully"
  ],
  "blmoo": [
    "bloom"
  ],
  "blmoos": [
    "blooms"
  ],
  "blmosy": [
    "symbol"
  ],
  "blnow": [
    "blown"
  ],
  "blntu": [
    "blunt"
  ],
  "blo": [
    "lob"
  ],
  "bloott": [
    "blotto"
  ],
  "blopuw": [
    "blowup"
  ],
  "blos": [
    "slob"
  ],
  "blost": [
    "bolts"
  ],
  "blosw": [
    "blows",
    "bowls"
  ],
  "blot": [
    "blot",
    "bolt"
  ],
  "blow": [
    "blow",
    "bowl"
  ],
  "blrruy": [
    "blurry"
  ],
  "blrtu": [
    "blurt"
  ],
  "blru": [
    "blur"
  ],
  "blruy": [
    "burly"
  ],
  "blstuy": [
    "subtly"
  ],
  "bmnu": [
    "numb"
  ],
  "bmo": [
    "mob"
  ],
  "bmoo": [
    "boom"
  ],
  "bmoor": [
    "broom"
  ],
  "bmoors": [
    "brooms"
  ],
  "bmoos": [
    "bosom"
  ],
  "bmoosy": [
    "bosomy"
  ],
  "bmoott": [
    "bottom"
  ],
  "bmos": [
    "mobs"
  ],
  "bmost": [
    "tombs"
  ],
  "bmot": [
    "tomb"
  ],
  "bmow": [
    "womb"
  ],
  "bmpsu": [
    "bumps"
  ],
  "bmpu": [
    "bump"
  ],
  "bmpuy": [
    "bumpy"
  ],
  "bmsu": [
    "bums"
  ],
  "bmu": [
    "bum"
  ],
  "bnnoru": [
    "unborn"
  ],
  "bnnuy": [
    "bunny"
  ],
  "bnoo": [
    "boon"
  ],
  "bnoost": [
    "boston"
  ],
  "bnor": [
    "born"
  ],
  "bnorsw": [
    "browns"
  ],
  "bnortu": [
    "burton"
  ],
  "bnorw": [
    "brown"
  ],
  "bnos": [
    "snob"
  ],
  "bnosu": [
    "bonus"
  ],
  "bnottu": [
    "button"
  ],
  "bnotuy": [
    "bounty"
  ],
  "bnoy": [
    "bony"
  ],
  "bnrsu": [
    "burns"
  ],
  "bnrtu": [
    "brunt",
    "burnt"
  ],
  "bnru": [
    "burn"
  ],
  "bnsu": [
    "buns",
    "snub"
  ],
  "bntu": [
    "bunt"
  ],
  "bnu": [
    "bun",
    "nub"
  ],
  "boo": [
    "boo"
  ],
  "boorrw": [
    "borrow"
  ],
  "boorst": [
    "robots"
  ],
  "boort": [
    "robot"
  ],
  "boosst": [
    "boosts"
  ],
  "boost": [
    "boost",
    "boots"
  ],
  "boosz": [
    "bozos"
  ],
  "boot": [
    "boot"
  ],
  "booty": [
    "booty"
  ],
  "booz": [
    "bozo"
  ],
  "bop": [
    "bop"
  ],
  "bor": [
    "bro",
    "orb",
    "rob"
  ],
  "borru": [
    "burro"
  ],
  "bors": [
    "bros",
    "orbs",
    "robs"
  ],
  "borstu": [
    "robust"
  ],
  "bortu": [
    "turbo"
  ],
  "borw": [
    "brow"
  ],
  "bos": [
    "sob"
  ],
  "boss": [
    "boss",
    "sobs"
  ],
  "bossy": [
    "bossy"
  ],
  "bostu": [
    "bouts"
  ],
  "bosw": [
    "bows"
  ],
  "bosy": [
    "boys"
  ],
  "bot": [
    "bot"
  ],
  "botu": [
    "bout"
  ],
  "botuuy": [
    "buyout"
  ],
  "boty": [
    "toby"
  ],
  "bow": [
    "bow"
  ],
  "box": [
    "box"
  ],
  "boy": [
    "boy"
  ],
  "bpru": [
    "burp"
  ],
  "bpu": [
    "pub"
  ],
  "brr": [
    "brr"
  ],
  "brstu": [
    "burst"
  ],
  "brsu": [
    "rubs"
  ],
  "bru": [
    "rub"
  ],
  "bruy": [
    "bury",
    "ruby"
  ],
  "bsstu": [
    "busts",
    "stubs"
  ],
  "bsttu": [
    "butts"
  ],
  "bstu": [
    "bust",
    "buts",
    "stub",
    "tubs"
  ],
  "bstuy": [
    "busty"
  ],
  "bsu": [
    "bus",
    "sub"
  ],
  "bsuy": [
    "busy",
    "buys"
  ],
  "bttu": [
    "butt"
  ],
  "btu": [
    "but",
    "tub"
  ],
  "buy": [
    "buy"
  ],
  "buzz": [
    "buzz"
  ],
  "ccdeis": [
    "sicced"
  ],
  "ccdeko": [
    "cocked"
  ],
  "cceeor": [
    "coerce"
  ],
  "ccehil": [
    "cliche"
  ],
  "ccehio": [
    "choice"
  ],
  "ccehit": [
    "hectic"
  ],
  "ccehk": [
    "check"
  ],
  "ccehks": [
    "checks"
  ],
  "ccehln": [
    "clench"
  ],
  "cceiil": [
    "icicle"
  ],
  "cceilr": [
    "circle"
  ],
  "cceily": [
    "cicely"
  ],
  "cceins": [
    "scenic"
  ],
  "ccelsy": [
    "cycles"
  ],
  "ccely": [
    "cycle"
  ],
  "cceors": [
    "soccer"
  ],
  "cchhru": [
    "church"
  ],
  "cchi": [
    "chic"
  ],
  "cchik": [
    "chick"
  ],
  "cchiks": [
    "chicks"
  ],
  "cchio": [
    "chico"
  ],
  "cchipu": [
    "hiccup"
  ],
  "cchksu": [
    "chucks"
  ],
  "cchku": [
    "chuck"
  ],
  "cchltu": [
    "clutch"
  ],
  "cchnru": [
    "crunch"
  ],
  "cchors": [
    "scorch"
  ],
  "cchort": [
    "crotch"
  ],
  "cchoru": [
    "crouch"
  ],
  "cchost": [
    "scotch"
  ],
  "cchou": [
    "couch"
  ],
  "cchrtu": [
    "crutch"
  ],
  "cciiln": [
    "clinic"
  ],
  "cciinp": [
    "picnic"
  ],
  "cciirt": [
    "critic"
  ],
  "cciisv": [
    "civics"
  ],
  "cciiv": [
    "civic"
  ],
  "ccikl": [
    "click"
  ],
  "ccikls": [
    "clicks"
  ],
  "ccikr": [
    "crick"
  ],
  "ccilo": [
    "colic"
  ],
  "ccimo": [
    "comic"
  ],
  "ccimos": [
    "comics",
    "cosmic"
  ],
  "ccinsy": [
    "cynics"
  ],
  "cciny": [
    "cynic"
  ],
  "ccirsu": [
    "circus"
  ],
  "ccisty": [
    "cystic"
  ],
  "ccklo": [
    "clock"
  ],
  "ccklos": [
    "clocks"
  ],
  "ccklu": [
    "cluck"
  ],
  "ccko": [
    "cock"
  ],
  "cckoou": [
    "cuckoo"
  ],
  "cckor": [
    "crock"
  ],
  "cckoy": [
    "cocky"
  ],
  "cclotu": [
    "occult"
  ],
  "ccnooo": [
    "cocoon"
  ],
  "ccnoru": [
    "concur"
  ],
  "ccoo": [
    "coco"
  ],
  "ccopuy": [
    "occupy"
  ],
  "ccor": [
    "croc"
  ],
  "ccorsu": [
    "occurs"
  ],
  "ccoru": [
    "occur"
  ],
  "cddeei": [
    "decide"
  ],
  "cddeek": [
    "decked"
  ],
  "cddeeu": [
    "deduce"
  ],
  "cddei": [
    "diced"
  ],
  "cddeko": [
    "docked"
  ],
  "cddeku": [
    "ducked"
  ],
  "cddelo": [
    "coddle"
  ],
  "cddelu": [
    "cuddle"
  ],
  "cddeo": [
    "coded"
  ],
  "cddetu": [
    "deduct"
  ],
  "cddluy": [
    "cuddly"
  ],
  "cddruy": [
    "cruddy"
  ],
  "cdduy": [
    "cuddy"
  ],
  "cdeeer": [
    "decree"
  ],
  "cdeeex": [
    "exceed"
  ],
  "cdeeft": [
    "defect"
  ],
  "cdeeht": [
    "etched"
  ],
  "cdeehw": [
    "chewed"
  ],
  "cdeeip": [
    "pieced"
  ],
  "cdeeit": [
    "deceit"
  ],
  "cdeeiv": [
    "device"
  ],
  "cdeekp": [
    "pecked"
  ],
  "cdeekr": [
    "decker"
  ],
  "cdeell": [
    "celled"
  ],
  "cdeent": [
    "decent"
  ],
  "cdeer": [
    "creed"
  ],
  "cdeers": [
    "creeds"
  ],
  "cdeeru": [
    "reduce"
  ],
  "cdeesu": [
    "deuces",
    "seduce"
  ],
  "cdeett": [
    "detect"
  ],
  "cdeeu": [
    "deuce"
  ],
  "cdeffu": [
    "cuffed"
  ],
  "cdefor": [
    "forced"
  ],
  "cdehko": [
    "choked"
  ],
  "cdehou": [
    "douche"
  ],
  "cdei": [
    "dice",
    "iced"
  ],
  "cdeiik": [
    "dickie"
  ],
  "cdeiju": [
    "juiced"
  ],
  "cdeikk": [
    "kicked"
  ],
  "cdeikl": [
    "licked"
  ],
  "cdeikn": [
    "nicked"
  ],
  "cdeikp": [
    "picked"
  ],
  "cdeikt": [
    "ticked"
  ],
  "cdeikw": [
    "wicked"
  ],
  "cdeilo": [
    "coiled"
  ],
  "cdeils": [
    "sliced"
  ],
  "cdeim": [
    "medic"
  ],
  "cdeims": [
    "medics"
  ],
  "cdeinr": [
    "cinder"
  ],
  "cdeinu": [
    "induce"
  ],
  "cdeiop": [
    "copied"
  ],
  "cdeiov": [
    "voiced"
  ],
  "cdeipr": [
    "priced"
  ],
  "cdeipt": [
    "depict"
  ],
  "cdeir": [
    "cider",
    "cried"
  ],
  "cdeirt": [
    "credit",
    "direct"
  ],
  "cdeit": [
    "cited",
    "edict"
  ],
  "cdeiy": [
    "dicey"
  ],
  "cdek": [
    "deck"
  ],
  "cdeklo": [
    "locked"
  ],
  "cdeklu": [
    "lucked"
  ],
  "cdekmo": [
    "mocked"
  ],
  "cdekno": [
    "conked"
  ],
  "cdekoo": [
    "cooked"
  ],
  "cdekor": [
    "rocked"
  ],
  "cdekos": [
    "socked"
  ],
  "cdekr": [
    "dreck"
  ],
  "cdeks": [
    "decks"
  ],
  "cdeksu": [
    "sucked"
  ],
  "cdektu": [
    "tucked"
  ],
  "cdelno": [
    "cloned"
  ],
  "cdelo": [
    "dolce"
  ],
  "cdeloo": [
    "cooled"
  ],
  "cdelor": [
    "colder"
  ],
  "cdelos": [
    "closed"
  ],
  "cdelru": [
    "curdle",
    "curled"
  ],
  "cdeltu": [
    "dulcet"
  ],
  "cdemoy": [
    "comedy"
  ],
  "cdenno": [
    "conned"
  ],
  "cdenor": [
    "corned"
  ],
  "cdenos": [
    "second"
  ],
  "cdeo": [
    "code",
    "coed",
    "deco"
  ],
  "cdeoop": [
    "cooped"
  ],
  "cdeor": [
    "credo",
    "decor"
  ],
  "cdeorr": [
    "record"
  ],
  "cdeors": [
    "scored"
  ],
  "cdeorw": [
    "crowed"
  ],
  "cdeos": [
    "codes",
    "coeds"
  ],
  "cdeosy": [
    "decoys"
  ],
  "cdeow": [
    "cowed"
  ],
  "cdeoy": [
    "decoy"
  ],
  "cdersu": [
    "cursed"
  ],
  "cderu": [
    "crude",
    "cured"
  ],
  "cdeu": [
    "cued",
    "duce"
  ],
  "cdgino": [
    "coding"
  ],
  "cdhil": [
    "child"
  ],
  "cdhit": [
    "ditch"
  ],
  "cdhor": [
    "chord"
  ],
  "cdhors": [
    "chords"
  ],
  "cdhtu": [
    "dutch"
  ],
  "cdiint": [
    "indict"
  ],
  "cdiioy": [
    "idiocy"
  ],
  "cdik": [
    "dick"
  ],
  "cdiks": [
    "dicks"
  ],
  "cdiky": [
    "dicky"
  ],
  "cdilu": [
    "lucid"
  ],
  "cdinor": [
    "nordic"
  ],
  "cdios": [
    "disco"
  ],
  "cdipu": [
    "cupid"
  ],
  "cdis": [
    "disc"
  ],
  "cdiss": [
    "discs"
  ],
  "cdissu": [
    "discus"
  ],
  "cdko": [
    "dock"
  ],
  "cdkos": [
    "docks"
  ],
  "cdksu": [
    "ducks"
  ],
  "cdku": [
    "duck"
  ],
  "cdkuy": [
    "ducky"
  ],
  "cdlloy": [
    "coldly"
  ],
  "cdlo": [
    "clod",
    "cold"
  ],
  "cdlos": [
    "clods",
    "colds"
  ],
  "cdlosu": [
    "clouds"
  ],
  "cdlou": [
    "cloud",
    "could"
  ],
  "cdlouy": [
    "cloudy"
  ],
  "cdmnoo": [
    "condom"
  ],
  "cdnoo": [
    "condo"
  ],
  "cdnoor": [
    "condor",
    "cordon"
  ],
  "cdnoos": [
    "condos"
  ],
  "cdo": [
    "cod",
    "doc"
  ],
  "cdoort": [
    "doctor"
  ],
  "cdor": [
    "cord"
  ],
  "cdors": [
    "cords"
  ],
  "cdorsw": [
    "crowds"
  ],
  "cdorw": [
    "crowd"
  ],
  "cdos": [
    "docs"
  ],
  "cdru": [
    "crud",
    "curd"
  ],
  "cdstu": [
    "ducts"
  ],
  "cdsu": [
    "scud"
  ],
  "cdtu": [
    "duct"
  ],
  "cdu": [
    "cud"
  ],
  "cee": [
    "cee"
  ],
  "ceeefl": [
    "fleece"
  ],
  "ceeehs": [
    "cheese"
  ],
  "ceeffo": [
    "coffee"
  ],
  "ceefft": [
    "effect"
  ],
  "ceefir": [
    "fierce"
  ],
  "ceefn": [
    "fence"
  ],
  "ceefns": [
    "fences"
  ],
  "ceefs": [
    "feces"
  ],
  "ceehk": [
    "cheek"
  ],
  "ceehkl": [
    "heckle"
  ],
  "ceehks": [
    "cheeks"
  ],
  "ceehl": [
    "leech"
  ],
  "ceehms": [
    "scheme"
  ],
  "ceehn": [
    "hence"
  ],
  "ceehnw": [
    "whence"
  ],
  "ceehos": [
    "echoes"
  ],
  "ceehp": [
    "cheep"
  ],
  "ceehps": [
    "speech"
  ],
  "ceehqu": [
    "cheque"
  ],
  "ceehr": [
    "cheer"
  ],
  "ceehrs": [
    "cheers"
  ],
  "ceehry": [
    "cheery"
  ],
  "ceehsy": [
    "cheesy"
  ],
  "ceein": [
    "niece"
  ],
  "ceeins": [
    "nieces"
  ],
  "ceeint": [
    "entice"
  ],
  "ceeip": [
    "piece"
  ],
  "ceeipr": [
    "pierce",
    "recipe"
  ],
  "ceeips": [
    "pieces"
  ],
  "ceeirt": [
    "recite"
  ],
  "ceeitx": [
    "excite"
  ],
  "ceejrt": [
    "reject"
  ],
  "ceejt": [
    "eject"
  ],
  "ceekpr": [
    "pecker"
  ],
  "ceekr": [
    "creek"
  ],
  "ceelrv": [
    "clever"
  ],
  "ceelry": [
    "celery"
  ],
  "ceelst": [
    "select"
  ],
  "ceelsx": [
    "excels"
  ],
  "ceelt": [
    "elect"
  ],
  "ceelx": [
    "excel"
  ],
  "ceemnt": [
    "cement"
  ],
  "ceemr": [
    "creme"
  ],
  "ceemrr": [
    "mercer"
  ],
  "ceenor": [
    "encore"
  ],
  "ceenp": [
    "pence"
  ],
  "ceenps": [
    "spence"
  ],
  "ceenrs": [
    "screen"
  ],
  "ceenrt": [
    "center",
    "centre",
    "recent"
  ],
  "ceens": [
    "scene"
  ],
  "ceenss": [
    "scenes"
  ],
  "ceepr": [
    "creep",
    "crepe"
  ],
  "ceeprs": [
    "creeps",
    "crepes"
  ],
  "ceepry": [
    "creepy"
  ],
  "ceeptx": [
    "except",
    "expect"
  ],
  "ceerss": [
    "recess"
  ],
  "ceerst": [
    "secret"
  ],
  "ceersu": [
    "recuse",
    "rescue",
    "secure"
  ],
  "ceert": [
    "erect"
  ],
  "ceessx": [
    "excess"
  ],
  "ceesux": [
    "excuse"
  ],
  "ceesx": [
    "execs"
  ],
  "ceffio": [
    "office"
  ],
  "cefh": [
    "chef"
  ],
  "cefhi": [
    "chief"
  ],
  "cefhis": [
    "chiefs"
  ],
  "cefhnr": [
    "french"
  ],
  "cefhs": [
    "chefs"
  ],
  "cefht": [
    "fetch"
  ],
  "cefikl": [
    "fickle"
  ],
  "cefint": [
    "infect"
  ],
  "cefkls": [
    "flecks"
  ],
  "cefl": [
    "clef"
  ],
  "cefnor": [
    "confer"
  ],
  "cefor": [
    "force"
  ],
  "cefors": [
    "forces"
  ],
  "cefruw": [
    "curfew"
  ],
  "ceginr": [
    "cringe"
  ],
  "cegko": [
    "gecko"
  ],
  "ceglry": [
    "clergy"
  ],
  "cegorr": [
    "grocer"
  ],
  "cehiky": [
    "hickey"
  ],
  "cehil": [
    "chile"
  ],
  "cehiln": [
    "lichen"
  ],
  "cehils": [
    "chiles",
    "chisel"
  ],
  "cehim": [
    "chime"
  ],
  "cehin": [
    "niche"
  ],
  "cehinr": [
    "enrich"
  ],
  "cehins": [
    "inches"
  ],
  "cehint": [
    "ethnic"
  ],
  "cehior": [
    "heroic"
  ],
  "cehipr": [
    "cipher"
  ],
  "cehiqu": [
    "quiche"
  ],
  "cehirr": [
    "richer"
  ],
  "cehirs": [
    "riches"
  ],
  "cehirt": [
    "thrice"
  ],
  "cehist": [
    "ethics",
    "itches"
  ],
  "cehit": [
    "ethic"
  ],
  "cehk": [
    "heck"
  ],
  "cehko": [
    "choke"
  ],
  "cehkor": [
    "choker"
  ],
  "cehkos": [
    "chokes"
  ],
  "cehkoy": [
    "hockey"
  ],
  "cehkst": [
    "sketch"
  ],
  "cehkt": [
    "ketch"
  ],
  "cehl": [
    "lech"
  ],
  "cehlot": [
    "clothe"
  ],
  "cehlps": [
    "schlep"
  ],
  "cehlw": [
    "welch"
  ],
  "cehmo": [
    "chemo"
  ],
  "cehmor": [
    "chrome"
  ],
  "cehnos": [
    "chosen"
  ],
  "cehnrt": [
    "trench"
  ],
  "cehnrw": [
    "wrench"
  ],
  "cehnst": [
    "stench"
  ],
  "cehnuu": [
    "eunuch"
  ],
  "cehnw": [
    "wench"
  ],
  "ceho": [
    "echo"
  ],
  "cehoos": [
    "choose"
  ],
  "cehor": [
    "chore"
  ],
  "cehors": [
    "chores"
  ],
  "cehort": [
    "hector"
  ],
  "cehos": [
    "chose"
  ],
  "cehotu": [
    "touche"
  ],
  "cehpr": [
    "perch"
  ],
  "cehpry": [
    "cypher"
  ],
  "cehpsy": [
    "psyche"
  ],
  "cehrry": [
    "cherry"
  ],
  "cehrtw": [
    "wretch"
  ],
  "cehss": [
    "chess"
  ],
  "cehsst": [
    "chests"
  ],
  "cehst": [
    "chest"
  ],
  "cehstu": [
    "chutes"
  ],
  "cehsty": [
    "chesty"
  ],
  "cehtu": [
    "chute"
  ],
  "cehvy": [
    "chevy"
  ],
  "cehw": [
    "chew"
  ],
  "cehwy": [
    "chewy"
  ],
  "cehy": [
    "yech"
  ],
  "cehz": [
    "chez"
  ],
  "cei": [
    "ice"
  ],
  "ceiilt": [
    "elicit"
  ],
  "ceiint": [
    "incite"
  ],
  "ceiist": [
    "cities"
  ],
  "ceijnt": [
    "inject"
  ],
  "ceijsu": [
    "juices"
  ],
  "ceiju": [
    "juice"
  ],
  "ceikkr": [
    "kicker"
  ],
  "ceikln": [
    "nickel"
  ],
  "ceiklp": [
    "pickle"
  ],
  "ceiklr": [
    "licker"
  ],
  "ceiklt": [
    "tickle"
  ],
  "ceikmy": [
    "mickey"
  ],
  "ceikns": [
    "sicken"
  ],
  "ceikoo": [
    "cookie"
  ],
  "ceikpr": [
    "picker"
  ],
  "ceikpt": [
    "picket"
  ],
  "ceikrs": [
    "sicker"
  ],
  "ceikrt": [
    "ticker"
  ],
  "ceikry": [
    "crikey",
    "rickey"
  ],
  "ceiktt": [
    "ticket"
  ],
  "ceil": [
    "lice"
  ],
  "ceillo": [
    "collie"
  ],
  "ceilnp": [
    "pencil"
  ],
  "ceilnt": [
    "client"
  ],
  "ceilny": [
    "nicely"
  ],
  "ceilop": [
    "police"
  ],
  "ceilpv": [
    "pelvic"
  ],
  "ceilqu": [
    "clique"
  ],
  "ceilr": [
    "relic"
  ],
  "ceilrs": [
    "relics",
    "slicer"
  ],
  "ceils": [
    "slice"
  ],
  "ceilss": [
    "slices"
  ],
  "ceim": [
    "mice"
  ],
  "ceimmo": [
    "commie"
  ],
  "ceimn": [
    "mince"
  ],
  "ceimno": [
    "income"
  ],
  "ceimr": [
    "crime"
  ],
  "ceimrs": [
    "crimes"
  ],
  "ceimrt": [
    "metric"
  ],
  "cein": [
    "nice"
  ],
  "ceinot": [
    "notice"
  ],
  "ceinpr": [
    "prince"
  ],
  "ceinqu": [
    "quince"
  ],
  "ceinr": [
    "nicer"
  ],
  "ceins": [
    "since"
  ],
  "ceinst": [
    "incest",
    "insect",
    "nicest"
  ],
  "ceiopr": [
    "copier"
  ],
  "ceiops": [
    "copies"
  ],
  "ceiopt": [
    "poetic"
  ],
  "ceiort": [
    "erotic"
  ],
  "ceiorz": [
    "cozier"
  ],
  "ceiosv": [
    "voices"
  ],
  "ceiotx": [
    "exotic"
  ],
  "ceiov": [
    "voice"
  ],
  "ceip": [
    "epic"
  ],
  "ceipr": [
    "price"
  ],
  "ceiprs": [
    "cripes",
    "prices"
  ],
  "ceipry": [
    "pricey"
  ],
  "ceips": [
    "spice"
  ],
  "ceipss": [
    "spices"
  ],
  "ceipst": [
    "septic"
  ],
  "ceir": [
    "rice"
  ],
  "ceirr": [
    "crier"
  ],
  "ceirs": [
    "cries"
  ],
  "ceirss": [
    "crises"
  ],
  "ceirsu": [
    "cruise"
  ],
  "ceiru": [
    "curie"
  ],
  "ceit": [
    "cite"
  ],
  "ceitu": [
    "cutie"
  ],
  "ceitv": [
    "evict"
  ],
  "ceitw": [
    "twice"
  ],
  "ceiv": [
    "vice"
  ],
  "cejkoy": [
    "jockey"
  ],
  "ceklor": [
    "locker"
  ],
  "ceklot": [
    "locket"
  ],
  "ceklr": [
    "clerk"
  ],
  "ceklrs": [
    "clerks"
  ],
  "cekn": [
    "neck"
  ],
  "ceknor": [
    "reckon"
  ],
  "cekns": [
    "necks"
  ],
  "ceko": [
    "coke"
  ],
  "cekoor": [
    "cooker"
  ],
  "cekopt": [
    "pocket"
  ],
  "cekorr": [
    "corker",
    "rocker"
  ],
  "cekort": [
    "rocket"
  ],
  "cekos": [
    "cokes"
  ],
  "cekost": [
    "socket"
  ],
  "cekp": [
    "peck"
  ],
  "cekpru": [
    "pucker"
  ],
  "cekps": [
    "pecks",
    "speck"
  ],
  "cekrsu": [
    "sucker"
  ],
  "cekrsw": [
    "wrecks"
  ],
  "cekrtu": [
    "tucker"
  ],
  "cekrw": [
    "wreck"
  ],
  "cell": [
    "cell"
  ],
  "cello": [
    "cello"
  ],
  "cells": [
    "cells"
  ],
  "celmop": [
    "compel"
  ],
  "celmsu": [
    "muscle"
  ],
  "celno": [
    "clone"
  ],
  "celnos": [
    "clones"
  ],
  "celnov": [
    "cloven"
  ],
  "celnsu": [
    "uncles"
  ],
  "celnu": [
    "uncle"
  ],
  "celo": [
    "cole"
  ],
  "celoor": [
    "cooler"
  ],
  "celopu": [
    "couple"
  ],
  "celors": [
    "closer"
  ],
  "celorv": [
    "clover"
  ],
  "celos": [
    "close",
    "coles"
  ],
  "celoss": [
    "closes"
  ],
  "celost": [
    "closet"
  ],
  "celosv": [
    "cloves"
  ],
  "celrsu": [
    "ulcers"
  ],
  "celrtu": [
    "cutler"
  ],
  "celru": [
    "cruel",
    "ulcer"
  ],
  "celsu": [
    "clues"
  ],
  "celu": [
    "clue",
    "luce"
  ],
  "cemo": [
    "come"
  ],
  "cemor": [
    "comer"
  ],
  "cemors": [
    "comers"
  ],
  "cemos": [
    "comes"
  ],
  "cemot": [
    "comet"
  ],
  "cemrtu": [
    "rectum"
  ],
  "cemry": [
    "mercy"
  ],
  "cennor": [
    "conner"
  ],
  "ceno": [
    "cone",
    "once"
  ],
  "cenopu": [
    "pounce"
  ],
  "cenor": [
    "recon"
  ],
  "cenorr": [
    "corner"
  ],
  "cenors": [
    "censor"
  ],
  "cenos": [
    "cones",
    "scone"
  ],
  "cenoss": [
    "scones"
  ],
  "cenosu": [
    "ounces"
  ],
  "cenou": [
    "ounce"
  ],
  "cenov": [
    "coven"
  ],
  "cenovy": [
    "convey"
  ],
  "cenoy": [
    "coney"
  ],
  "censst": [
    "scents"
  ],
  "censsu": [
    "census"
  ],
  "censt": [
    "cents",
    "scent"
  ],
  "cent": [
    "cent"
  ],
  "ceoopr": [
    "cooper"
  ],
  "ceoors": [
    "roscoe"
  ],
  "ceooty": [
    "coyote"
  ],
  "ceop": [
    "cope"
  ],
  "ceoppr": [
    "copper"
  ],
  "ceoprs": [
    "corpse"
  ],
  "ceoprt": [
    "copter"
  ],
  "ceops": [
    "scope"
  ],
  "ceopss": [
    "scopes"
  ],
  "ceopu": [
    "coupe"
  ],
  "ceor": [
    "core"
  ],
  "ceors": [
    "score"
  ],
  "ceorss": [
    "scores"
  ],
  "ceorst": [
    "corset",
    "escort",
    "sector"
  ],
  "ceorsu": [
    "course",
    "source"
  ],
  "ceorsv": [
    "covers"
  ],
  "ceortv": [
    "covert",
    "vector"
  ],
  "ceortx": [
    "cortex"
  ],
  "ceorv": [
    "cover"
  ],
  "ceorw": [
    "cower"
  ],
  "ceotv": [
    "covet"
  ],
  "ceov": [
    "cove"
  ],
  "ceprsu": [
    "spruce"
  ],
  "ceprt": [
    "crept"
  ],
  "ceps": [
    "pecs"
  ],
  "cepss": [
    "specs"
  ],
  "cepu": [
    "puce"
  ],
  "cer": [
    "rec"
  ],
  "cerssu": [
    "curses"
  ],
  "cerssw": [
    "screws"
  ],
  "cerst": [
    "crest"
  ],
  "cersu": [
    "cures",
    "curse"
  ],
  "cersuv": [
    "curves"
  ],
  "cersw": [
    "crews",
    "screw"
  ],
  "cerswy": [
    "screwy"
  ],
  "certtu": [
    "cutter"
  ],
  "certu": [
    "cuter",
    "truce"
  ],
  "ceru": [
    "cure"
  ],
  "ceruv": [
    "curve"
  ],
  "cerw": [
    "crew"
  ],
  "ces": [
    "sec"
  ],
  "cesttu": [
    "cutest"
  ],
  "cestuy": [
    "cutesy"
  ],
  "cetu": [
    "cute"
  ],
  "ceu": [
    "cue"
  ],
  "cffil": [
    "cliff"
  ],
  "cffils": [
    "cliffs"
  ],
  "cffino": [
    "coffin"
  ],
  "cffnuu": [
    "uncuff"
  ],
  "cffos": [
    "scoff"
  ],
  "cffotu": [
    "cutoff"
  ],
  "cffsu": [
    "cuffs",
    "scuff"
  ],
  "cffu": [
    "cuff"
  ],
  "cfhiln": [
    "flinch"
  ],
  "cfhin": [
    "finch"
  ],
  "cfhit": [
    "fitch"
  ],
  "cfikl": [
    "flick"
  ],
  "cfikls": [
    "flicks"
  ],
  "cfilor": [
    "frolic"
  ],
  "cfinot": [
    "confit"
  ],
  "cfisu": [
    "ficus"
  ],
  "cfklo": [
    "flock"
  ],
  "cfmoy": [
    "comfy"
  ],
  "cfort": [
    "croft"
  ],
  "cfosu": [
    "focus"
  ],
  "cghilt": [
    "glitch"
  ],
  "cghiot": [
    "gothic"
  ],
  "cghlu": [
    "gulch"
  ],
  "cghoru": [
    "grouch"
  ],
  "cghosu": [
    "coughs"
  ],
  "cghou": [
    "cough"
  ],
  "cghu": [
    "chug"
  ],
  "cgiin": [
    "icing"
  ],
  "cgiint": [
    "citing"
  ],
  "cgiln": [
    "cling"
  ],
  "cgilns": [
    "clings"
  ],
  "cgilny": [
    "clingy"
  ],
  "cgilo": [
    "logic"
  ],
  "cgimno": [
    "coming"
  ],
  "cginoo": [
    "cooing"
  ],
  "cginop": [
    "coping"
  ],
  "cginos": [
    "cosign"
  ],
  "cginru": [
    "curing"
  ],
  "cginry": [
    "crying"
  ],
  "cglnu": [
    "clung"
  ],
  "cglos": [
    "clogs"
  ],
  "cgo": [
    "cog"
  ],
  "chhit": [
    "hitch"
  ],
  "chhiw": [
    "which"
  ],
  "chhnu": [
    "hunch"
  ],
  "chhoo": [
    "hooch"
  ],
  "chhtu": [
    "hutch"
  ],
  "chi": [
    "chi",
    "hic",
    "ich"
  ],
  "chiil": [
    "chili"
  ],
  "chik": [
    "hick"
  ],
  "chikn": [
    "chink"
  ],
  "chikns": [
    "chinks"
  ],
  "chiks": [
    "hicks"
  ],
  "chikst": [
    "shtick"
  ],
  "chikt": [
    "thick"
  ],
  "chill": [
    "chill"
  ],
  "chills": [
    "chills"
  ],
  "chilly": [
    "chilly"
  ],
  "chimp": [
    "chimp"
  ],
  "chimps": [
    "chimps"
  ],
  "chimty": [
    "mythic"
  ],
  "chin": [
    "chin",
    "inch"
  ],
  "chino": [
    "chino"
  ],
  "chinp": [
    "pinch"
  ],
  "chinru": [
    "urchin"
  ],
  "chins": [
    "chins"
  ],
  "chinst": [
    "snitch"
  ],
  "chinw": [
    "winch"
  ],
  "chior": [
    "choir"
  ],
  "chiors": [
    "choirs"
  ],
  "chiosz": [
    "schizo"
  ],
  "chip": [
    "chip"
  ],
  "chipr": [
    "chirp"
  ],
  "chipry": [
    "chirpy"
  ],
  "chips": [
    "chips"
  ],
  "chipsy": [
    "physic"
  ],
  "chipt": [
    "pitch"
  ],
  "chir": [
    "rich"
  ],
  "chistt": [
    "stitch"
  ],
  "chistw": [
    "switch"
  ],
  "chit": [
    "chit",
    "itch"
  ],
  "chittw": [
    "twitch"
  ],
  "chitw": [
    "witch"
  ],
  "chitwy": [
    "witchy"
  ],
  "chity": [
    "itchy"
  ],
  "chknsu": [
    "chunks"
  ],
  "chknu": [
    "chunk"
  ],
  "chknuy": [
    "chunky"
  ],
  "chko": [
    "hock"
  ],
  "chkos": [
    "hocks",
    "shock"
  ],
  "chkoss": [
    "shocks"
  ],
  "chkssu": [
    "shucks"
  ],
  "chksu": [
    "shuck"
  ],
  "chku": [
    "huck"
  ],
  "chlmoo": [
    "moloch"
  ],
  "chlmu": [
    "mulch"
  ],
  "chlnu": [
    "lunch"
  ],
  "chlny": [
    "lynch"
  ],
  "chlo": [
    "loch"
  ],
  "chloos": [
    "school"
  ],
  "chlost": [
    "cloths"
  ],
  "chlosu": [
    "slouch"
  ],
  "chlot": [
    "cloth"
  ],
  "chlru": [
    "lurch"
  ],
  "chmmuy": [
    "chummy"
  ],
  "chmnu": [
    "munch"
  ],
  "chmoo": [
    "mooch"
  ],
  "chmoos": [
    "smooch"
  ],
  "chmop": [
    "chomp"
  ],
  "chmos": [
    "schmo"
  ],
  "chmpsu": [
    "chumps"
  ],
  "chmpu": [
    "chump"
  ],
  "chmsu": [
    "chums"
  ],
  "chmu": [
    "chum",
    "much"
  ],
  "chnoop": [
    "poncho"
  ],
  "chnosz": [
    "schnoz"
  ],
  "chnot": [
    "notch"
  ],
  "chnpu": [
    "punch"
  ],
  "chnpuy": [
    "punchy"
  ],
  "chnru": [
    "churn"
  ],
  "chnsy": [
    "synch"
  ],
  "choop": [
    "pooch"
  ],
  "choosy": [
    "choosy"
  ],
  "chop": [
    "chop"
  ],
  "choppy": [
    "choppy"
  ],
  "chopr": [
    "porch"
  ],
  "chops": [
    "chops"
  ],
  "chopsy": [
    "psycho"
  ],
  "chopu": [
    "pouch"
  ],
  "chorsu": [
    "chorus"
  ],
  "chort": [
    "torch"
  ],
  "chosu": [
    "hocus"
  ],
  "chotu": [
    "touch"
  ],
  "chotuy": [
    "touchy"
  ],
  "chou": [
    "ouch"
  ],
  "chouv": [
    "vouch"
  ],
  "chow": [
    "chow"
  ],
  "chpsy": [
    "psych"
  ],
  "chrsu": [
    "crush"
  ],
  "chsu": [
    "such"
  ],
  "chsuy": [
    "cushy"
  ],
  "ciilv": [
    "civil"
  ],
  "ciimm": [
    "mimic"
  ],
  "ciimtv": [
    "victim"
  ],
  "ciinor": [
    "ironic"
  ],
  "ciirss": [
    "crisis"
  ],
  "cijuy": [
    "juicy"
  ],
  "cik": [
    "ick"
  ],
  "cikk": [
    "kick"
  ],
  "cikks": [
    "kicks"
  ],
  "cikky": [
    "kicky"
  ],
  "cikl": [
    "lick"
  ],
  "cikln": [
    "clink"
  ],
  "cikls": [
    "licks",
    "slick"
  ],
  "ciklsy": [
    "sickly"
  ],
  "cikm": [
    "mick"
  ],
  "cikn": [
    "nick"
  ],
  "cikns": [
    "nicks"
  ],
  "cikos": [
    "sicko"
  ],
  "cikoss": [
    "sickos"
  ],
  "cikp": [
    "pick"
  ],
  "cikppu": [
    "pickup"
  ],
  "cikpr": [
    "prick"
  ],
  "cikprs": [
    "pricks"
  ],
  "cikps": [
    "picks"
  ],
  "cikpy": [
    "picky"
  ],
  "cikqu": [
    "quick"
  ],
  "cikr": [
    "rick"
  ],
  "cikrs": [
    "ricks"
  ],
  "cikrst": [
    "tricks"
  ],
  "cikrt": [
    "trick"
  ],
  "cikrty": [
    "tricky"
  ],
  "ciks": [
    "sick"
  ],
  "ciksst": [
    "sticks"
  ],
  "cikst": [
    "stick",
    "ticks"
  ],
  "ciksty": [
    "sticky"
  ],
  "cikt": [
    "tick"
  ],
  "cikw": [
    "wick"
  ],
  "ciky": [
    "icky"
  ],
  "cilno": [
    "colin"
  ],
  "cilo": [
    "coil"
  ],
  "cilopy": [
    "policy"
  ],
  "cilp": [
    "clip"
  ],
  "cilps": [
    "clips"
  ],
  "cilrsy": [
    "lyrics"
  ],
  "cimmot": [
    "commit"
  ],
  "cimnu": [
    "cumin"
  ],
  "cimor": [
    "micro"
  ],
  "cimost": [
    "sitcom"
  ],
  "cimpr": [
    "crimp"
  ],
  "cimsty": [
    "mystic"
  ],
  "cimsu": [
    "music"
  ],
  "cino": [
    "coin",
    "icon"
  ],
  "cinos": [
    "coins",
    "icons"
  ],
  "cinoss": [
    "sonics"
  ],
  "cinost": [
    "tonics"
  ],
  "cinosu": [
    "cousin"
  ],
  "cinot": [
    "tonic"
  ],
  "cinru": [
    "incur"
  ],
  "cintu": [
    "tunic"
  ],
  "cioprt": [
    "tropic"
  ],
  "ciopst": [
    "topics"
  ],
  "ciopt": [
    "optic",
    "topic"
  ],
  "ciortv": [
    "victor"
  ],
  "ciost": [
    "stoic"
  ],
  "ciostu": [
    "coitus"
  ],
  "ciotx": [
    "toxic"
  ],
  "cip": [
    "pic"
  ],
  "ciprs": [
    "crisp"
  ],
  "ciprss": [
    "crisps"
  ],
  "ciprst": [
    "script"
  ],
  "ciprsy": [
    "crispy"
  ],
  "cips": [
    "spic"
  ],
  "cipsy": [
    "spicy"
  ],
  "cirs": [
    "cris"
  ],
  "cirstt": [
    "strict"
  ],
  "cirstu": [
    "citrus"
  ],
  "cis": [
    "sic"
  ],
  "cissy": [
    "cissy"
  ],
  "cit": [
    "tic"
  ],
  "city": [
    "city"
  ],
  "ciy": [
    "icy"
  ],
  "cjko": [
    "jock"
  ],
  "cjkos": [
    "jocks"
  ],
  "ckkno": [
    "knock"
  ],
  "ckknos": [
    "knocks"
  ],
  "cklnou": [
    "unlock"
  ],
  "cklnu": [
    "clunk"
  ],
  "cklo": [
    "lock"
  ],
  "cklopu": [
    "lockup"
  ],
  "cklos": [
    "locks"
  ],
  "cklpu": [
    "pluck"
  ],
  "cklpuy": [
    "plucky"
  ],
  "cklu": [
    "luck"
  ],
  "ckluy": [
    "lucky"
  ],
  "ckmo": [
    "mock"
  ],
  "ckmos": [
    "mocks",
    "smock"
  ],
  "ckmu": [
    "muck"
  ],
  "ckno": [
    "conk"
  ],
  "cknsu": [
    "snuck"
  ],
  "ckoo": [
    "cook"
  ],
  "ckoor": [
    "crook"
  ],
  "ckoors": [
    "crooks"
  ],
  "ckoos": [
    "cooks"
  ],
  "ckor": [
    "cork",
    "rock"
  ],
  "ckors": [
    "corks",
    "rocks"
  ],
  "ckory": [
    "corky",
    "rocky"
  ],
  "ckos": [
    "sock"
  ],
  "ckoss": [
    "socks"
  ],
  "ckosst": [
    "stocks"
  ],
  "ckost": [
    "stock"
  ],
  "ckosty": [
    "stocky"
  ],
  "ckpu": [
    "puck"
  ],
  "ckrstu": [
    "struck",
    "trucks"
  ],
  "ckrsuu": [
    "ruckus"
  ],
  "ckrtu": [
    "truck"
  ],
  "ckssu": [
    "sucks"
  ],
  "ckstu": [
    "stuck"
  ],
  "cksu": [
    "suck"
  ],
  "cktu": [
    "tuck"
  ],
  "ckuy": [
    "yuck"
  ],
  "cllooy": [
    "coolly"
  ],
  "cllors": [
    "scroll"
  ],
  "clmnou": [
    "column"
  ],
  "clmopy": [
    "comply"
  ],
  "clmpsu": [
    "clumps"
  ],
  "clmpu": [
    "clump"
  ],
  "clmsuy": [
    "clumsy"
  ],
  "clnoo": [
    "colon"
  ],
  "clnoou": [
    "uncool"
  ],
  "clnooy": [
    "colony"
  ],
  "clnosu": [
    "consul"
  ],
  "clnosw": [
    "clowns"
  ],
  "clnow": [
    "clown"
  ],
  "clo": [
    "col"
  ],
  "cloo": [
    "cool",
    "loco"
  ],
  "cloor": [
    "color"
  ],
  "cloors": [
    "colors"
  ],
  "clooru": [
    "colour"
  ],
  "cloos": [
    "cools"
  ],
  "clop": [
    "clop"
  ],
  "clost": [
    "clots"
  ],
  "clostu": [
    "locust"
  ],
  "closty": [
    "costly"
  ],
  "clot": [
    "clot"
  ],
  "clotu": [
    "clout"
  ],
  "clru": [
    "curl"
  ],
  "clruy": [
    "curly"
  ],
  "cltu": [
    "cult"
  ],
  "cmmnoo": [
    "common"
  ],
  "cmmruy": [
    "crummy"
  ],
  "cmmsuy": [
    "scummy"
  ],
  "cmooss": [
    "cosmos"
  ],
  "cmop": [
    "comp"
  ],
  "cmostu": [
    "custom"
  ],
  "cmosuu": [
    "mucous"
  ],
  "cmsu": [
    "scum"
  ],
  "cmsuu": [
    "mucus"
  ],
  "cmu": [
    "cum"
  ],
  "cnno": [
    "conn"
  ],
  "cno": [
    "con"
  ],
  "cnoo": [
    "coon"
  ],
  "cnoopu": [
    "coupon"
  ],
  "cnoor": [
    "croon"
  ],
  "cnoott": [
    "cotton"
  ],
  "cnooty": [
    "tycoon"
  ],
  "cnoovy": [
    "convoy"
  ],
  "cnor": [
    "corn"
  ],
  "cnorsw": [
    "crowns"
  ],
  "cnorw": [
    "crown"
  ],
  "cnory": [
    "corny"
  ],
  "cnos": [
    "cons"
  ],
  "cnostu": [
    "counts"
  ],
  "cnotu": [
    "count"
  ],
  "cnotuy": [
    "county"
  ],
  "cnstu": [
    "cunts"
  ],
  "cnsy": [
    "sync"
  ],
  "cntu": [
    "cunt"
  ],
  "cntuu": [
    "uncut"
  ],
  "coo": [
    "coo"
  ],
  "coop": [
    "coop"
  ],
  "coops": [
    "scoop"
  ],
  "coopss": [
    "scoops"
  ],
  "coost": [
    "scoot"
  ],
  "coot": [
    "coot"
  ],
  "cop": [
    "cop"
  ],
  "copr": [
    "crop"
  ],
  "coprs": [
    "corps",
    "crops"
  ],
  "coprty": [
    "crypto"
  ],
  "cops": [
    "cops"
  ],
  "copu": [
    "coup"
  ],
  "copy": [
    "copy"
  ],
  "cor": [
    "cor"
  ],
  "corss": [
    "cross"
  ],
  "corstu": [
    "courts"
  ],
  "corsw": [
    "crows"
  ],
  "cortu": [
    "court"
  ],
  "corw": [
    "crow"
  ],
  "cory": [
    "cory"
  ],
  "cos": [
    "cos"
  ],
  "cosst": [
    "costs",
    "scots"
  ],
  "cosstu": [
    "scouts"
  ],
  "cost": [
    "cost",
    "cots",
    "scot"
  ],
  "costu": [
    "scout"
  ],
  "cosw": [
    "cows",
    "scow"
  ],
  "cosy": [
    "cosy"
  ],
  "cot": [
    "cot"
  ],
  "cottuu": [
    "cutout"
  ],
  "cow": [
    "cow"
  ],
  "cox": [
    "cox"
  ],
  "coy": [
    "coy"
  ],
  "coyz": [
    "cozy"
  ],
  "coz": [
    "coz"
  ],
  "cprsty": [
    "crypts"
  ],
  "cprsuy": [
    "cyprus"
  ],
  "cprty": [
    "crypt"
  ],
  "cpsu": [
    "cups",
    "cusp"
  ],
  "cpu": [
    "cup"
  ],
  "crrsuy": [
    "scurry"
  ],
  "crruy": [
    "curry"
  ],
  "crsstu": [
    "crusts"
  ],
  "crstu": [
    "crust"
  ],
  "crstuy": [
    "crusty",
    "curtsy"
  ],
  "crsuvy": [
    "scurvy"
  ],
  "crsy": [
    "scry"
  ],
  "crtu": [
    "curt"
  ],
  "cru": [
    "cur"
  ],
  "crux": [
    "crux"
  ],
  "cry": [
    "cry"
  ],
  "cssu": [
    "cuss"
  ],
  "cstu": [
    "cuts"
  ],
  "csty": [
    "cyst"
  ],
  "csuyzz": [
    "scuzzy"
  ],
  "ctu": [
    "cut"
  ],
  "dddeee": [
    "deeded"
  ],
  "dddeew": [
    "wedded"
  ],
  "dddego": [
    "dodged"
  ],
  "dddeno": [
    "nodded"
  ],
  "dddily": [
    "diddly"
  ],
  "ddee": [
    "deed"
  ],
  "ddeeem": [
    "deemed"
  ],
  "ddeeen": [
    "needed"
  ],
  "ddeefi": [
    "defied"
  ],
  "ddeefn": [
    "defend"
  ],
  "ddeeg": [
    "edged"
  ],
  "ddeegr": [
    "dredge"
  ],
  "ddeein": [
    "denied",
    "indeed"
  ],
  "ddeeit": [
    "edited"
  ],
  "ddeelm": [
    "meddle"
  ],
  "ddeelp": [
    "peddle"
  ],
  "ddeelu": [
    "delude",
    "eluded"
  ],
  "ddeelw": [
    "welded"
  ],
  "ddeemn": [
    "mended"
  ],
  "ddeen": [
    "ended"
  ],
  "ddeenp": [
    "depend"
  ],
  "ddeent": [
    "tended"
  ],
  "ddees": [
    "deeds"
  ],
  "ddefgu": [
    "fudged"
  ],
  "ddefil": [
    "fiddle"
  ],
  "ddeflo": [
    "folded"
  ],
  "ddefnu": [
    "funded"
  ],
  "ddefor": [
    "fodder"
  ],
  "ddeggo": [
    "dogged"
  ],
  "ddegil": [
    "gilded"
  ],
  "ddegin": [
    "dinged"
  ],
  "ddegiu": [
    "guided"
  ],
  "ddegju": [
    "judged"
  ],
  "ddeglo": [
    "lodged"
  ],
  "ddego": [
    "dodge"
  ],
  "ddegor": [
    "dodger"
  ],
  "ddegru": [
    "drudge"
  ],
  "ddehin": [
    "hidden"
  ],
  "ddehlu": [
    "huddle"
  ],
  "ddehoo": [
    "hooded"
  ],
  "ddei": [
    "died"
  ],
  "ddeiik": [
    "kiddie"
  ],
  "ddeiiv": [
    "divide"
  ],
  "ddeikr": [
    "kidder"
  ],
  "ddeilm": [
    "middle"
  ],
  "ddeilr": [
    "riddle"
  ],
  "ddeimn": [
    "minded"
  ],
  "ddein": [
    "dined"
  ],
  "ddeinr": [
    "ridden"
  ],
  "ddeinw": [
    "winded"
  ],
  "ddeipp": [
    "dipped"
  ],
  "ddeipr": [
    "prided"
  ],
  "ddeir": [
    "dried",
    "redid"
  ],
  "ddeis": [
    "sided"
  ],
  "ddeiss": [
    "dissed"
  ],
  "ddeknu": [
    "dunked"
  ],
  "ddello": [
    "dolled"
  ],
  "ddellu": [
    "dulled"
  ],
  "ddelmo": [
    "molded"
  ],
  "ddelmu": [
    "muddle"
  ],
  "ddeloo": [
    "doodle"
  ],
  "ddelot": [
    "toddle"
  ],
  "ddelpu": [
    "puddle"
  ],
  "ddemoo": [
    "doomed"
  ],
  "ddempu": [
    "dumped"
  ],
  "ddenow": [
    "downed"
  ],
  "ddenru": [
    "durned"
  ],
  "ddensu": [
    "sudden"
  ],
  "ddeoow": [
    "wooded"
  ],
  "ddeorw": [
    "worded"
  ],
  "ddeos": [
    "dosed"
  ],
  "ddeost": [
    "oddest"
  ],
  "ddeosu": [
    "doused"
  ],
  "ddeott": [
    "dotted"
  ],
  "ddeoz": [
    "dozed"
  ],
  "ddepu": [
    "duped"
  ],
  "dderru": [
    "rudder"
  ],
  "ddestu": [
    "dusted"
  ],
  "ddesu": [
    "dudes"
  ],
  "ddety": [
    "teddy"
  ],
  "ddeu": [
    "dude"
  ],
  "ddey": [
    "dyed",
    "eddy"
  ],
  "ddgiy": [
    "giddy"
  ],
  "ddgoy": [
    "dodgy"
  ],
  "ddhosy": [
    "shoddy"
  ],
  "ddi": [
    "did"
  ],
  "ddiko": [
    "kiddo"
  ],
  "ddilo": [
    "dildo"
  ],
  "ddiors": [
    "sordid"
  ],
  "ddiotu": [
    "outdid"
  ],
  "ddloy": [
    "oddly"
  ],
  "ddmmuu": [
    "dumdum"
  ],
  "ddmuy": [
    "muddy"
  ],
  "ddo": [
    "odd"
  ],
  "ddos": [
    "odds"
  ],
  "ddoty": [
    "toddy"
  ],
  "ddowy": [
    "dowdy"
  ],
  "ddsu": [
    "duds"
  ],
  "ddu": [
    "dud"
  ],
  "dee": [
    "dee"
  ],
  "deeefr": [
    "feeder"
  ],
  "deeegr": [
    "degree"
  ],
  "deeehl": [
    "heeled"
  ],
  "deeekl": [
    "keeled"
  ],
  "deeekp": [
    "peeked"
  ],
  "deeeln": [
    "needle"
  ],
  "deeelp": [
    "peeled"
  ],
  "deeelr": [
    "reeled"
  ],
  "deeelt": [
    "delete"
  ],
  "deeemr": [
    "redeem"
  ],
  "deeems": [
    "seemed"
  ],
  "deeepr": [
    "deeper"
  ],
  "deeepv": [
    "peeved"
  ],
  "deeerv": [
    "veered"
  ],
  "deef": [
    "feed"
  ],
  "deefin": [
    "define"
  ],
  "deefis": [
    "defies"
  ],
  "deeflu": [
    "fueled"
  ],
  "deefnr": [
    "fender"
  ],
  "deefr": [
    "freed"
  ],
  "deefs": [
    "feeds"
  ],
  "deefsu": [
    "defuse"
  ],
  "deeg": [
    "edge"
  ],
  "deeggl": [
    "legged"
  ],
  "deeggp": [
    "pegged"
  ],
  "deegh": [
    "hedge"
  ],
  "deeghs": [
    "hedges"
  ],
  "deegiw": [
    "wedgie"
  ],
  "deegl": [
    "ledge"
  ],
  "deegln": [
    "legend"
  ],
  "deeglp": [
    "pledge"
  ],
  "deeglr": [
    "ledger"
  ],
  "deegls": [
    "sledge"
  ],
  "deeglu": [
    "deluge"
  ],
  "deegnr": [
    "gender"
  ],
  "deegr": [
    "greed"
  ],
  "deegry": [
    "greedy"
  ],
  "deegs": [
    "edges"
  ],
  "deegsw": [
    "wedges"
  ],
  "deegw": [
    "wedge"
  ],
  "deeh": [
    "heed"
  ],
  "deehlp": [
    "helped"
  ],
  "deehmt": [
    "themed"
  ],
  "deeils": [
    "diesel"
  ],
  "deeilv": [
    "veiled"
  ],
  "deeilx": [
    "exiled"
  ],
  "deeims": [
    "demise"
  ],
  "deeins": [
    "denies"
  ],
  "deeinv": [
    "endive",
    "envied"
  ],
  "deeirs": [
    "desire"
  ],
  "deeirt": [
    "dieter"
  ],
  "deeirv": [
    "derive"
  ],
  "deeisv": [
    "devise"
  ],
  "deeisz": [
    "seized"
  ],
  "deeitx": [
    "exited"
  ],
  "deeivw": [
    "viewed"
  ],
  "deejkr": [
    "jerked"
  ],
  "deek": [
    "deke"
  ],
  "deekn": [
    "kneed"
  ],
  "deekov": [
    "evoked"
  ],
  "deeksw": [
    "skewed"
  ],
  "deelly": [
    "yelled"
  ],
  "deelmt": [
    "melted"
  ],
  "deelmy": [
    "medley"
  ],
  "deelop": [
    "eloped"
  ],
  "deelpy": [
    "deeply"
  ],
  "deelr": [
    "elder"
  ],
  "deelrs": [
    "elders"
  ],
  "deelrw": [
    "welder"
  ],
  "deelst": [
    "eldest"
  ],
  "deelsu": [
    "eludes"
  ],
  "deelu": [
    "elude"
  ],
  "deelux": [
    "deluxe"
  ],
  "deelv": [
    "delve"
  ],
  "deem": [
    "deem"
  ],
  "deempr": [
    "premed"
  ],
  "deemru": [
    "demure"
  ],
  "deemry": [
    "remedy"
  ],
  "deemss": [
    "messed"
  ],
  "deen": [
    "need"
  ],
  "deennp": [
    "penned"
  ],
  "deenop": [
    "opened"
  ],
  "deenor": [
    "redone"
  ],
  "deenrr": [
    "render"
  ],
  "deenrs": [
    "sender"
  ],
  "deenrt": [
    "rented",
    "tender"
  ],
  "deenru": [
    "endure"
  ],
  "deens": [
    "dense",
    "needs"
  ],
  "deenss": [
    "sensed"
  ],
  "deensu": [
    "ensued"
  ],
  "deentv": [
    "vented"
  ],
  "deentx": [
    "extend"
  ],
  "deeny": [
    "needy"
  ],
  "deeops": [
    "depose",
    "speedo"
  ],
  "deeor": [
    "erode"
  ],
  "deeorz": [
    "zeroed"
  ],
  "deeotv": [
    "devote",
    "vetoed"
  ],
  "deep": [
    "deep",
    "peed"
  ],
  "deepry": [
    "preyed"
  ],
  "deeps": [
    "speed"
  ],
  "deepss": [
    "speeds"
  ],
  "deepsy": [
    "speedy"
  ],
  "deer": [
    "deer",
    "reed"
  ],
  "deerr": [
    "erred"
  ],
  "deerst": [
    "desert",
    "rested"
  ],
  "deersv": [
    "served",
    "versed"
  ],
  "dees": [
    "seed"
  ],
  "deess": [
    "seeds"
  ],
  "deessw": [
    "swedes"
  ],
  "deest": [
    "deets",
    "steed"
  ],
  "deestt": [
    "detest",
    "tested"
  ],
  "deestv": [
    "vested"
  ],
  "deestw": [
    "stewed"
  ],
  "deesu": [
    "suede"
  ],
  "deesw": [
    "sewed",
    "swede",
    "weeds"
  ],
  "deesy": [
    "seedy"
  ],
  "deet": [
    "teed"
  ],
  "deettv": [
    "vetted"
  ],
  "deetw": [
    "tweed"
  ],
  "deew": [
    "weed"
  ],
  "deey": [
    "eyed"
  ],
  "def": [
    "fed"
  ],
  "deffhu": [
    "huffed"
  ],
  "deffim": [
    "miffed"
  ],
  "deffir": [
    "differ"
  ],
  "defflu": [
    "duffel",
    "duffle"
  ],
  "deffno": [
    "offend"
  ],
  "deffpu": [
    "puffed"
  ],
  "defggo": [
    "fogged"
  ],
  "defgir": [
    "fridge"
  ],
  "defgit": [
    "gifted"
  ],
  "defgor": [
    "forged"
  ],
  "defgu": [
    "fudge"
  ],
  "defhis": [
    "fished"
  ],
  "defikn": [
    "knifed"
  ],
  "defil": [
    "field",
    "filed"
  ],
  "defill": [
    "filled"
  ],
  "defilm": [
    "filmed"
  ],
  "defilo": [
    "foiled"
  ],
  "defils": [
    "fields"
  ],
  "defilt": [
    "lifted"
  ],
  "defin": [
    "fiend",
    "fined"
  ],
  "definr": [
    "finder",
    "friend"
  ],
  "defins": [
    "fiends"
  ],
  "defir": [
    "fired",
    "fried"
  ],
  "defist": [
    "fisted"
  ],
  "defitt": [
    "fitted"
  ],
  "defix": [
    "fixed"
  ],
  "defkor": [
    "forked"
  ],
  "defl": [
    "fled"
  ],
  "deflno": [
    "fondle"
  ],
  "defloo": [
    "fooled"
  ],
  "deflor": [
    "folder"
  ],
  "deflou": [
    "fouled"
  ],
  "deflty": [
    "deftly"
  ],
  "defmor": [
    "formed"
  ],
  "defn": [
    "fend"
  ],
  "defnor": [
    "fonder"
  ],
  "defnou": [
    "fondue"
  ],
  "defnru": [
    "refund"
  ],
  "defoot": [
    "footed"
  ],
  "defrsu": [
    "surfed"
  ],
  "defs": [
    "feds"
  ],
  "defsu": [
    "feuds",
    "fused"
  ],
  "deft": [
    "deft"
  ],
  "defu": [
    "feud"
  ],
  "defy": [
    "defy"
  ],
  "deg": [
    "ged"
  ],
  "degghu": [
    "hugged"
  ],
  "deggin": [
    "edging"
  ],
  "deggio": [
    "doggie"
  ],
  "deggir": [
    "digger",
    "rigged"
  ],
  "deggiw": [
    "wigged"
  ],
  "degglo": [
    "logged"
  ],
  "deggmu": [
    "mugged"
  ],
  "deggou": [
    "gouged"
  ],
  "deggru": [
    "grudge",
    "rugged"
  ],
  "degil": [
    "glide"
  ],
  "degiln": [
    "dingle"
  ],
  "degim": [
    "midge"
  ],
  "degimt": [
    "midget"
  ],
  "deginn": [
    "ending"
  ],
  "degins": [
    "design",
    "signed",
    "singed"
  ],
  "deginw": [
    "winged"
  ],
  "deginy": [
    "dyeing"
  ],
  "degioo": [
    "goodie"
  ],
  "degir": [
    "ridge"
  ],
  "degist": [
    "digest"
  ],
  "degisu": [
    "guides"
  ],
  "degiu": [
    "guide"
  ],
  "degjsu": [
    "judges"
  ],
  "degju": [
    "judge"
  ],
  "deglno": [
    "golden"
  ],
  "deglnu": [
    "lunged"
  ],
  "deglo": [
    "lodge"
  ],
  "deglsu": [
    "sludge"
  ],
  "deglu": [
    "glued"
  ],
  "degmsu": [
    "smudge"
  ],
  "degnnu": [
    "gunned"
  ],
  "degnu": [
    "nudge"
  ],
  "degopr": [
    "groped"
  ],
  "degppy": [
    "gypped"
  ],
  "degpru": [
    "purged"
  ],
  "degrs": [
    "dregs"
  ],
  "degru": [
    "urged"
  ],
  "degy": [
    "edgy"
  ],
  "dehhsu": [
    "hushed"
  ],
  "dehi": [
    "hide"
  ],
  "dehik": [
    "hiked"
  ],
  "dehils": [
    "shield"
  ],
  "dehint": [
    "hinted"
  ],
  "dehir": [
    "hired"
  ],
  "dehis": [
    "hides"
  ],
  "dehiss": [
    "dishes",
    "hissed"
  ],
  "dehisw": [
    "wished"
  ],
  "dehkoo": [
    "hooked"
  ],
  "dehl": [
    "held"
  ],
  "dehlno": [
    "holden"
  ],
  "dehlo": [
    "holed"
  ],
  "dehlor": [
    "holder"
  ],
  "dehlpu": [
    "upheld"
  ],
  "dehmmu": [
    "hummed"
  ],
  "dehmot": [
    "method"
  ],
  "dehmpu": [
    "humped"
  ],
  "dehmry": [
    "rhymed"
  ],
  "dehno": [
    "honed"
  ],
  "dehnop": [
    "phoned"
  ],
  "dehnor": [
    "horned"
  ],
  "dehntu": [
    "hunted"
  ],
  "dehop": [
    "hoped"
  ],
  "dehopp": [
    "hopped"
  ],
  "dehor": [
    "horde"
  ],
  "dehors": [
    "hordes"
  ],
  "dehos": [
    "hosed"
  ],
  "dehost": [
    "hosted"
  ],
  "dehosu": [
    "housed"
  ],
  "dehosv": [
    "shoved"
  ],
  "dehosw": [
    "showed"
  ],
  "dehpst": [
    "depths"
  ],
  "dehpsu": [
    "pushed"
  ],
  "dehpt": [
    "depth"
  ],
  "dehpy": [
    "hyped"
  ],
  "dehr": [
    "herd"
  ],
  "dehrs": [
    "herds",
    "shred"
  ],
  "dehrss": [
    "shreds"
  ],
  "dehrsu": [
    "rushed"
  ],
  "dehrsw": [
    "shrewd"
  ],
  "dehs": [
    "shed"
  ],
  "dehss": [
    "sheds"
  ],
  "dei": [
    "die"
  ],
  "deiin": [
    "indie"
  ],
  "deiins": [
    "indies",
    "inside"
  ],
  "deiinv": [
    "divine"
  ],
  "deiipt": [
    "pitied"
  ],
  "deijlt": [
    "jilted"
  ],
  "deijno": [
    "joined"
  ],
  "deik": [
    "dike"
  ],
  "deikl": [
    "liked"
  ],
  "deikll": [
    "killed"
  ],
  "deiklm": [
    "milked"
  ],
  "deikln": [
    "linked"
  ],
  "deiknr": [
    "kinder"
  ],
  "deikny": [
    "kidney"
  ],
  "deikps": [
    "spiked"
  ],
  "deikrs": [
    "risked"
  ],
  "deiks": [
    "skied"
  ],
  "deikss": [
    "kissed"
  ],
  "deil": [
    "deli",
    "idle",
    "lied"
  ],
  "deillw": [
    "willed"
  ],
  "deilmp": [
    "dimple"
  ],
  "deilms": [
    "misled",
    "smiled"
  ],
  "deilmw": [
    "mildew"
  ],
  "deiln": [
    "lined"
  ],
  "deilo": [
    "oiled",
    "oldie"
  ],
  "deilos": [
    "soiled"
  ],
  "deilp": [
    "piled"
  ],
  "deilpp": [
    "lipped"
  ],
  "deilr": [
    "riled"
  ],
  "deilrs": [
    "slider"
  ],
  "deilrv": [
    "drivel"
  ],
  "deilrw": [
    "wilder"
  ],
  "deils": [
    "sidle",
    "slide"
  ],
  "deilss": [
    "slides"
  ],
  "deilst": [
    "listed"
  ],
  "deilsv": [
    "devils"
  ],
  "deilsy": [
    "yields"
  ],
  "deiltt": [
    "tilted"
  ],
  "deiltu": [
    "dilute"
  ],
  "deilv": [
    "devil",
    "lived"
  ],
  "deilw": [
    "wield"
  ],
  "deily": [
    "yield"
  ],
  "deim": [
    "dime"
  ],
  "deimmr": [
    "dimmer"
  ],
  "deimmu": [
    "medium"
  ],
  "deimn": [
    "mined"
  ],
  "deimnr": [
    "remind"
  ],
  "deimpp": [
    "pimped"
  ],
  "deimpr": [
    "primed"
  ],
  "deims": [
    "dimes"
  ],
  "deimss": [
    "missed"
  ],
  "deimt": [
    "timed"
  ],
  "deimx": [
    "mixed"
  ],
  "dein": [
    "dine"
  ],
  "deinnp": [
    "pinned"
  ],
  "deinnr": [
    "dinner"
  ],
  "deinns": [
    "sinned"
  ],
  "deinnt": [
    "intend"
  ],
  "deinor": [
    "dinero",
    "ironed"
  ],
  "deinpp": [
    "nipped"
  ],
  "deinr": [
    "diner"
  ],
  "deinrs": [
    "diners"
  ],
  "deinru": [
    "ruined"
  ],
  "deinrv": [
    "driven"
  ],
  "deinrw": [
    "rewind"
  ],
  "deins": [
    "snide"
  ],
  "deinsu": [
    "undies"
  ],
  "deintt": [
    "tinted"
  ],
  "deintu": [
    "united",
    "untied"
  ],
  "deinu": [
    "nudie"
  ],
  "deinw": [
    "widen"
  ],
  "deinx": [
    "index",
    "nixed"
  ],
  "deiopr": [
    "period"
  ],
  "deiops": [
    "poised"
  ],
  "deiort": [
    "editor"
  ],
  "deiorw": [
    "weirdo"
  ],
  "deiosv": [
    "videos"
  ],
  "deiov": [
    "video"
  ],
  "deip": [
    "pied"
  ],
  "deippr": [
    "ripped"
  ],
  "deipps": [
    "sipped"
  ],
  "deippt": [
    "tipped"
  ],
  "deippz": [
    "zipped"
  ],
  "deipqu": [
    "piqued"
  ],
  "deipr": [
    "pride"
  ],
  "deiprs": [
    "spider"
  ],
  "deiprz": [
    "prized"
  ],
  "deips": [
    "spied"
  ],
  "deipss": [
    "pissed"
  ],
  "deipsu": [
    "upside"
  ],
  "deipsw": [
    "swiped"
  ],
  "deipt": [
    "tepid"
  ],
  "deiptt": [
    "pitted"
  ],
  "deipw": [
    "wiped"
  ],
  "deir": [
    "dire",
    "ride"
  ],
  "deirr": [
    "drier",
    "rider"
  ],
  "deirrs": [
    "derris",
    "riders"
  ],
  "deirrv": [
    "driver"
  ],
  "deirs": [
    "dries",
    "rides"
  ],
  "deirst": [
    "stride"
  ],
  "deirsv": [
    "divers",
    "drives"
  ],
  "deirt": [
    "tired",
    "tried"
  ],
  "deirtv": [
    "divert"
  ],
  "deirv": [
    "diver",
    "drive"
  ],
  "deirw": [
    "weird",
    "wider",
    "wired"
  ],
  "deis": [
    "dies",
    "side"
  ],
  "deiss": [
    "sides"
  ],
  "deisst": [
    "desist"
  ],
  "deissu": [
    "issued"
  ],
  "deist": [
    "diets",
    "edits",
    "tides"
  ],
  "deistu": [
    "duties",
    "suited"
  ],
  "deistv": [
    "divest"
  ],
  "deisv": [
    "dives"
  ],
  "deisw": [
    "wised"
  ],
  "deisz": [
    "sized"
  ],
  "deit": [
    "diet",
    "edit",
    "tide",
    "tied"
  ],
  "deity": [
    "deity"
  ],
  "deiv": [
    "dive"
  ],
  "deiw": [
    "wide"
  ],
  "dejko": [
    "joked"
  ],
  "dejmpu": [
    "jumped"
  ],
  "dejott": [
    "jotted"
  ],
  "dekloo": [
    "looked"
  ],
  "dekmos": [
    "smoked"
  ],
  "dekno": [
    "kendo"
  ],
  "deknoy": [
    "donkey"
  ],
  "deknoz": [
    "zonked"
  ],
  "deknu": [
    "nuked"
  ],
  "dekop": [
    "poked"
  ],
  "dekorw": [
    "worked"
  ],
  "dekost": [
    "stoked"
  ],
  "deks": [
    "desk"
  ],
  "dekss": [
    "desks"
  ],
  "deksu": [
    "dukes"
  ],
  "deksy": [
    "dykes"
  ],
  "deku": [
    "duke"
  ],
  "deky": [
    "dyke"
  ],
  "del": [
    "del",
    "led"
  ],
  "dell": [
    "dell"
  ],
  "dellmu": [
    "mulled"
  ],
  "dellop": [
    "polled"
  ],
  "dellor": [
    "rolled"
  ],
  "dellpu": [
    "pulled"
  ],
  "dellsw": [
    "dwells"
  ],
  "dellw": [
    "dwell"
  ],
  "delly": [
    "delly"
  ],
  "delmo": [
    "model"
  ],
  "delmos": [
    "models",
    "seldom"
  ],
  "delmou": [
    "module"
  ],
  "delmoy": [
    "melody"
  ],
  "deln": [
    "lend"
  ],
  "delno": [
    "olden"
  ],
  "delnoo": [
    "noodle"
  ],
  "delnou": [
    "louden"
  ],
  "delo": [
    "dole",
    "lode"
  ],
  "deloop": [
    "looped",
    "poodle"
  ],
  "deloos": [
    "oodles"
  ],
  "delopp": [
    "lopped"
  ],
  "delopw": [
    "plowed"
  ],
  "delopy": [
    "deploy"
  ],
  "delor": [
    "older"
  ],
  "deloru": [
    "louder"
  ],
  "delost": [
    "oldest"
  ],
  "delosv": [
    "solved"
  ],
  "delosw": [
    "slowed"
  ],
  "delov": [
    "loved"
  ],
  "deloy": [
    "yodel"
  ],
  "delp": [
    "pled"
  ],
  "delpux": [
    "duplex"
  ],
  "delru": [
    "lured",
    "ruled"
  ],
  "delruy": [
    "rudely"
  ],
  "dels": [
    "sled"
  ],
  "delsty": [
    "styled"
  ],
  "delu": [
    "duel"
  ],
  "delw": [
    "lewd",
    "weld"
  ],
  "dem": [
    "med"
  ],
  "demmo": [
    "modem"
  ],
  "demmsu": [
    "summed"
  ],
  "demn": [
    "mend"
  ],
  "demno": [
    "demon",
    "monde"
  ],
  "demnor": [
    "modern"
  ],
  "demnos": [
    "demons"
  ],
  "demo": [
    "demo",
    "dome",
    "mode"
  ],
  "demop": [
    "moped"
  ],
  "demopp": [
    "mopped"
  ],
  "demorw": [
    "wormed"
  ],
  "demos": [
    "domes"
  ],
  "demost": [
    "modest"
  ],
  "demov": [
    "moved"
  ],
  "demow": [
    "mowed"
  ],
  "demppu": [
    "pumped"
  ],
  "dempru": [
    "dumper"
  ],
  "demrru": [
    "murder"
  ],
  "demru": [
    "demur"
  ],
  "den": [
    "den",
    "end"
  ],
  "dennot": [
    "tendon"
  ],
  "dennou": [
    "undone"
  ],
  "deno": [
    "done",
    "node"
  ],
  "denoow": [
    "wooden"
  ],
  "denopr": [
    "ponder"
  ],
  "denor": [
    "drone"
  ],
  "denors": [
    "drones"
  ],
  "denort": [
    "rodent"
  ],
  "denorv": [
    "vendor"
  ],
  "denorw": [
    "downer",
    "wonder"
  ],
  "denos": [
    "nodes",
    "nosed"
  ],
  "denost": [
    "stoned"
  ],
  "denosw": [
    "snowed"
  ],
  "denosz": [
    "dozens"
  ],
  "denot": [
    "noted",
    "toned"
  ],
  "denov": [
    "devon"
  ],
  "denow": [
    "owned"
  ],
  "denoz": [
    "dozen",
    "zoned"
  ],
  "denps": [
    "spend"
  ],
  "denpss": [
    "spends"
  ],
  "denr": [
    "nerd"
  ],
  "denrs": [
    "nerds"
  ],
  "denrst": [
    "trends"
  ],
  "denrsu": [
    "nursed"
  ],
  "denrt": [
    "trend"
  ],
  "denrtu": [
    "turned"
  ],
  "denrty": [
    "trendy"
  ],
  "denru": [
    "under"
  ],
  "denry": [
    "nerdy"
  ],
  "dens": [
    "ends",
    "send"
  ],
  "denss": [
    "sends"
  ],
  "denst": [
    "dents",
    "tends"
  ],
  "densu": [
    "dunes",
    "nudes"
  ],
  "dent": [
    "dent",
    "tend"
  ],
  "dentu": [
    "tuned"
  ],
  "denu": [
    "nude"
  ],
  "denuu": [
    "undue"
  ],
  "deny": [
    "deny"
  ],
  "deo": [
    "doe",
    "ode"
  ],
  "deoopp": [
    "pooped"
  ],
  "deoor": [
    "rodeo"
  ],
  "deoort": [
    "rooted"
  ],
  "deoorv": [
    "overdo"
  ],
  "deoow": [
    "wooed"
  ],
  "deop": [
    "dope"
  ],
  "deoppp": [
    "popped"
  ],
  "deoppt": [
    "topped"
  ],
  "deopr": [
    "pedro",
    "roped"
  ],
  "deoprt": [
    "deport"
  ],
  "deopru": [
    "poured"
  ],
  "deoprv": [
    "proved"
  ],
  "deoprw": [
    "powder"
  ],
  "deops": [
    "dopes",
    "posed"
  ],
  "deopst": [
    "posted"
  ],
  "deopsu": [
    "pseudo"
  ],
  "deopt": [
    "depot",
    "opted"
  ],
  "deoptt": [
    "potted"
  ],
  "deopy": [
    "dopey"
  ],
  "deoqtu": [
    "quoted"
  ],
  "deor": [
    "doer",
    "redo",
    "rode"
  ],
  "deorr": [
    "order"
  ],
  "deorrs": [
    "orders"
  ],
  "deorst": [
    "sorted",
    "stored"
  ],
  "deorsv": [
    "droves"
  ],
  "deorsw": [
    "dowser"
  ],
  "deortt": [
    "rotted"
  ],
  "deortu": [
    "detour",
    "routed",
    "toured"
  ],
  "deoruv": [
    "devour"
  ],
  "deorv": [
    "drove"
  ],
  "deorz": [
    "dozer"
  ],
  "deos": [
    "does",
    "dose"
  ],
  "deoss": [
    "doses"
  ],
  "deosst": [
    "tossed"
  ],
  "deost": [
    "dotes"
  ],
  "deostw": [
    "stowed"
  ],
  "deosux": [
    "exodus"
  ],
  "deosv": [
    "doves"
  ],
  "deot": [
    "dote",
    "toed"
  ],
  "deotux": [
    "tuxedo"
  ],
  "deotv": [
    "voted"
  ],
  "deotw": [
    "towed"
  ],
  "deoty": [
    "toyed"
  ],
  "deov": [
    "dove"
  ],
  "deovw": [
    "vowed"
  ],
  "deow": [
    "owed"
  ],
  "deoww": [
    "wowed"
  ],
  "deoz": [
    "doze"
  ],
  "deppu": [
    "upped"
  ],
  "deprsu": [
    "prudes"
  ],
  "depru": [
    "prude"
  ],
  "deptuy": [
    "deputy"
  ],
  "depty": [
    "typed"
  ],
  "der": [
    "red"
  ],
  "derrsy": [
    "dryers"
  ],
  "derru": [
    "ruder"
  ],
  "derry": [
    "dryer"
  ],
  "ders": [
    "reds"
  ],
  "derss": [
    "dress"
  ],
  "derssu": [
    "duress"
  ],
  "derssy": [
    "dressy"
  ],
  "derstu": [
    "rusted"
  ],
  "deru": [
    "rude"
  ],
  "derw": [
    "drew"
  ],
  "dery": [
    "dyer"
  ],
  "desu": [
    "dues",
    "sued",
    "used"
  ],
  "desw": [
    "weds"
  ],
  "det": [
    "ted"
  ],
  "detu": [
    "duet"
  ],
  "detuv": [
    "duvet"
  ],
  "deu": [
    "due"
  ],
  "dew": [
    "dew",
    "wed"
  ],
  "dewy": [
    "dewy"
  ],
  "dex": [
    "dex"
  ],
  "dey": [
    "dey",
    "dye"
  ],
  "dez": [
    "zed"
  ],
  "dffu": [
    "duff"
  ],
  "dfgiir": [
    "frigid"
  ],
  "dfilsu": [
    "fluids"
  ],
  "dfilu": [
    "fluid"
  ],
  "dfin": [
    "find"
  ],
  "dfins": [
    "finds"
  ],
  "dfio": [
    "fido"
  ],
  "dfirt": [
    "drift"
  ],
  "dflnou": [
    "unfold"
  ],
  "dflo": [
    "fold"
  ],
  "dfloo": [
    "flood"
  ],
  "dfloos": [
    "floods"
  ],
  "dflos": [
    "folds"
  ],
  "dfno": [
    "fond"
  ],
  "dfnou": [
    "found"
  ],
  "dfnsu": [
    "funds"
  ],
  "dfnu": [
    "fund"
  ],
  "dfoo": [
    "food"
  ],
  "dfoorx": [
    "oxford"
  ],
  "dfoos": [
    "foods"
  ],
  "dfoosu": [
    "doofus"
  ],
  "dfor": [
    "ford"
  ],
  "dggoy": [
    "doggy"
  ],
  "dghiin": [
    "hiding"
  ],
  "dghoot": [
    "hotdog"
  ],
  "dghou": [
    "dough"
  ],
  "dgi": [
    "dig"
  ],
  "dgiiln": [
    "idling"
  ],
  "dgiinn": [
    "dining"
  ],
  "dgiino": [
    "indigo"
  ],
  "dgiinr": [
    "riding"
  ],
  "dgiins": [
    "siding"
  ],
  "dgiinv": [
    "diving"
  ],
  "dgiir": [
    "rigid"
  ],
  "dgiist": [
    "digits"
  ],
  "dgiit": [
    "digit"
  ],
  "dgilno": [
    "doling"
  ],
  "dgilu": [
    "guild"
  ],
  "dgin": [
    "ding"
  ],
  "dgino": [
    "dingo",
    "doing"
  ],
  "dginop": [
    "doping"
  ],
  "dginoz": [
    "dozing"
  ],
  "dginr": [
    "grind"
  ],
  "dginrs": [
    "grinds"
  ],
  "dginru": [
    "during"
  ],
  "dginry": [
    "drying"
  ],
  "dgins": [
    "dings"
  ],
  "dginy": [
    "dingy",
    "dying"
  ],
  "dgir": [
    "grid"
  ],
  "dgis": [
    "digs"
  ],
  "dglo": [
    "gold"
  ],
  "dglooy": [
    "goodly"
  ],
  "dgloy": [
    "godly"
  ],
  "dgno": [
    "dong"
  ],
  "dgnoos": [
    "godson"
  ],
  "dgnoru": [
    "ground"
  ],
  "dgnos": [
    "dongs"
  ],
  "dgnu": [
    "dung"
  ],
  "dgo": [
    "dog",
    "god"
  ],
  "dgoo": [
    "good"
  ],
  "dgoos": [
    "goods"
  ],
  "dgooy": [
    "goody"
  ],
  "dgoru": [
    "gourd"
  ],
  "dgos": [
    "dogs",
    "gods"
  ],
  "dgosty": [
    "stodgy"
  ],
  "dgotuu": [
    "dugout"
  ],
  "dgrsu": [
    "drugs"
  ],
  "dgru": [
    "drug"
  ],
  "dgu": [
    "dug"
  ],
  "dhi": [
    "hid"
  ],
  "dhimu": [
    "humid"
  ],
  "dhin": [
    "hind"
  ],
  "dhiorr": [
    "horrid"
  ],
  "dhirst": [
    "thirds"
  ],
  "dhirt": [
    "third"
  ],
  "dhis": [
    "dish"
  ],
  "dhitw": [
    "width"
  ],
  "dhlo": [
    "hold"
  ],
  "dhlopu": [
    "holdup",
    "uphold"
  ],
  "dhlos": [
    "holds"
  ],
  "dhlosu": [
    "should"
  ],
  "dhnosu": [
    "hounds"
  ],
  "dhnou": [
    "hound"
  ],
  "dhoo": [
    "hood"
  ],
  "dhorsu": [
    "shroud"
  ],
  "dhot": [
    "doth"
  ],
  "dhowy": [
    "howdy"
  ],
  "diilqu": [
    "liquid"
  ],
  "diilv": [
    "livid"
  ],
  "diimo": [
    "idiom"
  ],
  "diimt": [
    "timid"
  ],
  "diimtw": [
    "dimwit"
  ],
  "diiost": [
    "idiots"
  ],
  "diiot": [
    "idiot"
  ],
  "diivv": [
    "vivid"
  ],
  "dik": [
    "kid"
  ],
  "diklny": [
    "kindly"
  ],
  "dikn": [
    "dink",
    "kind"
  ],
  "diknr": [
    "drink"
  ],
  "diknrs": [
    "drinks"
  ],
  "dikns": [
    "dinks",
    "kinds"
  ],
  "dikny": [
    "dinky"
  ],
  "dikr": [
    "dirk"
  ],
  "diks": [
    "disk",
    "kids",
    "skid"
  ],
  "dikss": [
    "disks",
    "skids"
  ],
  "dil": [
    "lid"
  ],
  "dill": [
    "dill"
  ],
  "dillmy": [
    "mildly"
  ],
  "dillr": [
    "drill"
  ],
  "dills": [
    "dills"
  ],
  "dillwy": [
    "wildly"
  ],
  "dilly": [
    "dilly"
  ],
  "dilm": [
    "mild"
  ],
  "dilmy": [
    "dimly"
  ],
  "dilo": [
    "idol",
    "lido"
  ],
  "dilos": [
    "idols",
    "solid"
  ],
  "diloy": [
    "doily"
  ],
  "dils": [
    "lids",
    "slid"
  ],
  "dilw": [
    "wild"
  ],
  "dily": [
    "idly"
  ],
  "dim": [
    "dim",
    "mid"
  ],
  "dimn": [
    "mind"
  ],
  "dimnor": [
    "nimrod"
  ],
  "dimns": [
    "minds"
  ],
  "dimopu": [
    "podium"
  ],
  "dimosu": [
    "sodium"
  ],
  "dimosw": [
    "wisdom"
  ],
  "dimst": [
    "midst"
  ],
  "dinnuw": [
    "unwind"
  ],
  "dinoor": [
    "indoor"
  ],
  "dinosw": [
    "disown"
  ],
  "dinoww": [
    "window"
  ],
  "dinstu": [
    "nudist"
  ],
  "dinsw": [
    "winds"
  ],
  "dintuy": [
    "nudity"
  ],
  "dinw": [
    "wind"
  ],
  "dinwy": [
    "windy"
  ],
  "dioosu": [
    "odious"
  ],
  "diorrt": [
    "torrid"
  ],
  "diostu": [
    "studio"
  ],
  "diosww": [
    "widows"
  ],
  "diott": [
    "ditto"
  ],
  "diov": [
    "void"
  ],
  "dioww": [
    "widow"
  ],
  "dip": [
    "dip"
  ],
  "dippry": [
    "drippy"
  ],
  "dipr": [
    "drip"
  ],
  "diprs": [
    "drips"
  ],
  "diprtu": [
    "putrid"
  ],
  "dips": [
    "dips"
  ],
  "dipstu": [
    "stupid"
  ],
  "diqsu": [
    "squid"
  ],
  "diqu": [
    "quid"
  ],
  "dir": [
    "rid"
  ],
  "dirt": [
    "dirt"
  ],
  "dirty": [
    "dirty"
  ],
  "dis": [
    "dis"
  ],
  "disty": [
    "ditsy"
  ],
  "dit": [
    "dit"
  ],
  "ditty": [
    "ditty"
  ],
  "dity": [
    "tidy"
  ],
  "ditz": [
    "ditz"
  ],
  "diu": [
    "dui"
  ],
  "divvy": [
    "divvy"
  ],
  "diyzz": [
    "dizzy"
  ],
  "djoo": [
    "dojo"
  ],
  "djou": [
    "judo"
  ],
  "dknrsu": [
    "drunks"
  ],
  "dknru": [
    "drunk"
  ],
  "dknsu": [
    "dunks"
  ],
  "dknu": [
    "dunk"
  ],
  "dkor": [
    "dork"
  ],
  "dkory": [
    "dorky"
  ],
  "dkosu": [
    "kudos"
  ],
  "dksu": [
    "dusk"
  ],
  "dksuy": [
    "dusky"
  ],
  "dllo": [
    "doll"
  ],
  "dlloop": [
    "dollop"
  ],
  "dllor": [
    "droll"
  ],
  "dllos": [
    "dolls"
  ],
  "dllouy": [
    "loudly"
  ],
  "dlloy": [
    "dolly"
  ],
  "dllu": [
    "dull"
  ],
  "dlmo": [
    "mold"
  ],
  "dlmos": [
    "molds"
  ],
  "dlmou": [
    "mould"
  ],
  "dlmoy": [
    "moldy"
  ],
  "dlnuuy": [
    "unduly"
  ],
  "dlo": [
    "dol",
    "old"
  ],
  "dloor": [
    "drool"
  ],
  "dloors": [
    "drools"
  ],
  "dlor": [
    "lord"
  ],
  "dlors": [
    "lords"
  ],
  "dlorsw": [
    "worlds"
  ],
  "dlorw": [
    "world"
  ],
  "dlos": [
    "olds",
    "sold"
  ],
  "dlot": [
    "dolt",
    "told"
  ],
  "dlou": [
    "loud"
  ],
  "dlouw": [
    "would"
  ],
  "dlstuy": [
    "studly"
  ],
  "dluy": [
    "duly"
  ],
  "dmmuy": [
    "dummy"
  ],
  "dmnoo": [
    "mondo"
  ],
  "dmnosu": [
    "mounds"
  ],
  "dmnou": [
    "mound"
  ],
  "dmo": [
    "dom",
    "mod"
  ],
  "dmoo": [
    "doom",
    "mood"
  ],
  "dmoos": [
    "moods"
  ],
  "dmoosy": [
    "sodomy"
  ],
  "dmooy": [
    "moody"
  ],
  "dmor": [
    "dorm"
  ],
  "dmors": [
    "dorms"
  ],
  "dmosu": [
    "modus"
  ],
  "dmpsu": [
    "dumps"
  ],
  "dmpu": [
    "dump"
  ],
  "dmrsu": [
    "drums"
  ],
  "dmru": [
    "drum"
  ],
  "dmu": [
    "mud"
  ],
  "dno": [
    "don",
    "nod"
  ],
  "dnoor": [
    "donor"
  ],
  "dnoors": [
    "donors"
  ],
  "dnop": [
    "pond"
  ],
  "dnops": [
    "ponds"
  ],
  "dnopsu": [
    "pounds"
  ],
  "dnopu": [
    "pound"
  ],
  "dnorsu": [
    "rounds"
  ],
  "dnoru": [
    "round"
  ],
  "dnorw": [
    "drown"
  ],
  "dnos": [
    "nods"
  ],
  "dnossu": [
    "sounds"
  ],
  "dnostu": [
    "donuts"
  ],
  "dnosu": [
    "sound"
  ],
  "dnosuw": [
    "wounds"
  ],
  "dnosw": [
    "downs"
  ],
  "dnotu": [
    "donut"
  ],
  "dnou": [
    "undo"
  ],
  "dnouw": [
    "wound"
  ],
  "dnow": [
    "down"
  ],
  "dnowy": [
    "downy"
  ],
  "dnu": [
    "dun"
  ],
  "doooov": [
    "voodoo"
  ],
  "doopr": [
    "droop"
  ],
  "doopry": [
    "droopy"
  ],
  "door": [
    "door",
    "odor"
  ],
  "doors": [
    "doors"
  ],
  "doost": [
    "stood"
  ],
  "doosw": [
    "woods"
  ],
  "dooswy": [
    "woodsy"
  ],
  "dootu": [
    "outdo"
  ],
  "doow": [
    "wood"
  ],
  "doowy": [
    "woody"
  ],
  "dooyz": [
    "doozy"
  ],
  "dop": [
    "pod"
  ],
  "dopr": [
    "drop",
    "prod"
  ],
  "doprs": [
    "drops"
  ],
  "dopru": [
    "proud"
  ],
  "dops": [
    "pods"
  ],
  "dor": [
    "rod"
  ],
  "dors": [
    "rods"
  ],
  "dorssw": [
    "swords"
  ],
  "dorsw": [
    "sword",
    "words"
  ],
  "dorswy": [
    "drowsy"
  ],
  "doru": [
    "dour"
  ],
  "dorw": [
    "word"
  ],
  "dorwy": [
    "rowdy"
  ],
  "dory": [
    "dory"
  ],
  "dos": [
    "dos",
    "sod"
  ],
  "dost": [
    "dost",
    "dots"
  ],
  "dot": [
    "dot",
    "tod"
  ],
  "dotty": [
    "dotty"
  ],
  "dou": [
    "duo"
  ],
  "dpsu": [
    "spud"
  ],
  "drstu": [
    "turds"
  ],
  "drstuy": [
    "sturdy"
  ],
  "drtu": [
    "turd"
  ],
  "dry": [
    "dry"
  ],
  "dsstu": [
    "studs"
  ],
  "dssu": [
    "suds"
  ],
  "dstu": [
    "dust",
    "stud"
  ],
  "dstuy": [
    "dusty",
    "study"
  ],
  "dtuy": [
    "duty"
  ],
  "eeefrr": [
    "reefer"
  ],
  "eeefrz": [
    "freeze"
  ],
  "eeegmr": [
    "emerge"
  ],
  "eeegnr": [
    "renege"
  ],
  "eeegrz": [
    "geezer"
  ],
  "eeegs": [
    "geese"
  ],
  "eeehwz": [
    "wheeze"
  ],
  "eeeinw": [
    "weenie"
  ],
  "eeeir": [
    "eerie"
  ],
  "eeekpr": [
    "keeper"
  ],
  "eeekrs": [
    "seeker"
  ],
  "eeelnv": [
    "eleven"
  ],
  "eeelss": [
    "lessee"
  ],
  "eeelsv": [
    "sleeve"
  ],
  "eeelv": [
    "levee"
  ],
  "eeemst": [
    "esteem"
  ],
  "eeenrs": [
    "serene"
  ],
  "eeenrt": [
    "entree"
  ],
  "eeenrv": [
    "veneer"
  ],
  "eeensz": [
    "sneeze"
  ],
  "eeerrv": [
    "revere"
  ],
  "eeersv": [
    "reeves",
    "severe"
  ],
  "eeerv": [
    "reeve"
  ],
  "eeetwz": [
    "tweeze"
  ],
  "eef": [
    "fee"
  ],
  "eeffot": [
    "toffee"
  ],
  "eefgru": [
    "refuge"
  ],
  "eefhir": [
    "heifer"
  ],
  "eefiln": [
    "feline"
  ],
  "eefilr": [
    "relief"
  ],
  "eefj": [
    "jefe"
  ],
  "eefl": [
    "feel",
    "flee"
  ],
  "eefllr": [
    "feller"
  ],
  "eeflrx": [
    "reflex"
  ],
  "eeflry": [
    "freely"
  ],
  "eefls": [
    "feels"
  ],
  "eeflt": [
    "fleet"
  ],
  "eefluy": [
    "eyeful"
  ],
  "eefmm": [
    "femme"
  ],
  "eefmms": [
    "femmes"
  ],
  "eefprr": [
    "prefer"
  ],
  "eefr": [
    "free",
    "reef"
  ],
  "eefrr": [
    "freer",
    "refer"
  ],
  "eefrrs": [
    "refers"
  ],
  "eefrrt": [
    "ferret"
  ],
  "eefrs": [
    "frees",
    "reefs"
  ],
  "eefrst": [
    "fester"
  ],
  "eefrsu": [
    "refuse"
  ],
  "eefrtu": [
    "refute"
  ],
  "eefrv": [
    "fever"
  ],
  "eefrw": [
    "fewer"
  ],
  "eefs": [
    "fees"
  ],
  "eeft": [
    "feet"
  ],
  "eeg": [
    "gee"
  ],
  "eegil": [
    "liege"
  ],
  "eegimr": [
    "regime"
  ],
  "eegin": [
    "genie"
  ],
  "eeginn": [
    "engine"
  ],
  "eeginp": [
    "peeing"
  ],
  "eegins": [
    "seeing"
  ],
  "eeginy": [
    "eyeing"
  ],
  "eegirv": [
    "grieve"
  ],
  "eegis": [
    "siege"
  ],
  "eegk": [
    "geek"
  ],
  "eegkr": [
    "greek"
  ],
  "eegks": [
    "geeks"
  ],
  "eegky": [
    "geeky"
  ],
  "eegl": [
    "glee"
  ],
  "eeglnt": [
    "gentle"
  ],
  "eegmno": [
    "genome"
  ],
  "eegmrr": [
    "merger"
  ],
  "eegn": [
    "gene"
  ],
  "eegnr": [
    "genre",
    "green"
  ],
  "eegnrs": [
    "greens"
  ],
  "eegnrt": [
    "regent"
  ],
  "eegnry": [
    "energy"
  ],
  "eegns": [
    "genes"
  ],
  "eegrrt": [
    "regret"
  ],
  "eegrrv": [
    "verger"
  ],
  "eegrs": [
    "serge"
  ],
  "eegrst": [
    "greets"
  ],
  "eegrsy": [
    "geyser"
  ],
  "eegrt": [
    "greet"
  ],
  "eegrtt": [
    "getter"
  ],
  "eegrv": [
    "verge"
  ],
  "eegs": [
    "gees"
  ],
  "eegsu": [
    "segue"
  ],
  "eegz": [
    "geez"
  ],
  "eehiin": [
    "heinie"
  ],
  "eehinr": [
    "herein"
  ],
  "eehirt": [
    "either"
  ],
  "eehl": [
    "heel"
  ],
  "eehllr": [
    "heller"
  ],
  "eehlmt": [
    "helmet"
  ],
  "eehlpr": [
    "helper"
  ],
  "eehls": [
    "heels"
  ],
  "eehlsv": [
    "shelve"
  ],
  "eehlsw": [
    "wheels"
  ],
  "eehlw": [
    "wheel"
  ],
  "eehmst": [
    "themes"
  ],
  "eehmt": [
    "theme"
  ],
  "eehmux": [
    "exhume"
  ],
  "eehnpw": [
    "nephew"
  ],
  "eehnrt": [
    "nether"
  ],
  "eehns": [
    "sheen"
  ],
  "eehnsy": [
    "sheeny"
  ],
  "eehors": [
    "heroes"
  ],
  "eehort": [
    "hetero"
  ],
  "eehprs": [
    "herpes",
    "sphere"
  ],
  "eehps": [
    "sheep"
  ],
  "eehr": [
    "here"
  ],
  "eehrs": [
    "sheer"
  ],
  "eehrst": [
    "theres"
  ],
  "eehrsy": [
    "heresy"
  ],
  "eehrt": [
    "ether",
    "there",
    "three"
  ],
  "eehrtt": [
    "tether"
  ],
  "eehrw": [
    "where"
  ],
  "eehsst": [
    "sheets",
    "theses"
  ],
  "eehst": [
    "sheet",
    "these"
  ],
  "eehsx": [
    "hexes"
  ],
  "eeht": [
    "thee"
  ],
  "eehtt": [
    "teeth"
  ],
  "eehw": [
    "whee"
  ],
  "eeilln": [
    "nellie"
  ],
  "eeilns": [
    "senile"
  ],
  "eeilrs": [
    "relies"
  ],
  "eeilrv": [
    "relive"
  ],
  "eeilry": [
    "eerily"
  ],
  "eeilsx": [
    "exiles"
  ],
  "eeilt": [
    "elite"
  ],
  "eeilvw": [
    "weevil"
  ],
  "eeilx": [
    "exile"
  ],
  "eeimpr": [
    "empire"
  ],
  "eeinrt": [
    "entire"
  ],
  "eeinrw": [
    "weiner",
    "wiener"
  ],
  "eeiors": [
    "soiree"
  ],
  "eeippy": [
    "yippee"
  ],
  "eeiptt": [
    "petite"
  ],
  "eeirrs": [
    "sirree"
  ],
  "eeirrt": [
    "retire"
  ],
  "eeirs": [
    "siree"
  ],
  "eeirss": [
    "series"
  ],
  "eeirsv": [
    "revise"
  ],
  "eeirsx": [
    "sexier"
  ],
  "eeirvv": [
    "revive"
  ],
  "eeirvw": [
    "review",
    "viewer"
  ],
  "eeissz": [
    "seizes"
  ],
  "eeisz": [
    "seize"
  ],
  "eejlsw": [
    "jewels"
  ],
  "eejlw": [
    "jewel"
  ],
  "eejp": [
    "jeep"
  ],
  "eejps": [
    "jeeps"
  ],
  "eejrst": [
    "jester"
  ],
  "eejrsy": [
    "jersey"
  ],
  "eejss": [
    "jesse"
  ],
  "eejz": [
    "jeez"
  ],
  "eekl": [
    "keel"
  ],
  "eekln": [
    "kneel"
  ],
  "eekls": [
    "sleek"
  ],
  "eekltt": [
    "kettle"
  ],
  "eeklwy": [
    "weekly"
  ],
  "eekn": [
    "keen",
    "knee"
  ],
  "eekns": [
    "knees"
  ],
  "eekorv": [
    "revoke"
  ],
  "eekp": [
    "keep",
    "peek"
  ],
  "eekppu": [
    "upkeep"
  ],
  "eekps": [
    "keeps",
    "peeks"
  ],
  "eekr": [
    "reek"
  ],
  "eekrs": [
    "reeks"
  ],
  "eekrsw": [
    "skewer"
  ],
  "eeks": [
    "seek"
  ],
  "eekss": [
    "seeks"
  ],
  "eeksw": [
    "weeks"
  ],
  "eekw": [
    "week"
  ],
  "eel": [
    "eel",
    "lee"
  ],
  "eellpt": [
    "pellet"
  ],
  "eellrs": [
    "seller"
  ],
  "eellrt": [
    "teller"
  ],
  "eellry": [
    "yeller"
  ],
  "eellsv": [
    "levels"
  ],
  "eellv": [
    "level"
  ],
  "eelmot": [
    "omelet"
  ],
  "eelmps": [
    "semple"
  ],
  "eelmpt": [
    "temple"
  ],
  "eelmr": [
    "merle"
  ],
  "eelmry": [
    "merely"
  ],
  "eelmtt": [
    "mettle"
  ],
  "eelnps": [
    "spleen"
  ],
  "eelnss": [
    "lenses",
    "lessen"
  ],
  "eelnvy": [
    "evenly"
  ],
  "eelop": [
    "elope"
  ],
  "eelopp": [
    "people"
  ],
  "eelovv": [
    "evolve"
  ],
  "eelp": [
    "peel",
    "pele"
  ],
  "eelpr": [
    "leper"
  ],
  "eelprs": [
    "lepers"
  ],
  "eelps": [
    "peels",
    "sleep"
  ],
  "eelpss": [
    "sleeps"
  ],
  "eelpsy": [
    "sleepy"
  ],
  "eelpx": [
    "expel"
  ],
  "eelqsu": [
    "sequel"
  ],
  "eelr": [
    "reel"
  ],
  "eelrs": [
    "reels"
  ],
  "eelrss": [
    "lesser"
  ],
  "eelrsv": [
    "revels"
  ],
  "eelrtt": [
    "letter"
  ],
  "eelrv": [
    "lever"
  ],
  "eelry": [
    "leery"
  ],
  "eels": [
    "eels",
    "else"
  ],
  "eelssv": [
    "selves",
    "vessel"
  ],
  "eelst": [
    "sleet",
    "steel"
  ],
  "eelstt": [
    "settle"
  ],
  "eelsty": [
    "steely"
  ],
  "eelsv": [
    "elves"
  ],
  "eeltvv": [
    "velvet"
  ],
  "eeltvw": [
    "twelve"
  ],
  "eeltx": [
    "telex"
  ],
  "eemns": [
    "semen"
  ],
  "eemny": [
    "enemy"
  ],
  "eemnyz": [
    "enzyme"
  ],
  "eemort": [
    "meteor",
    "remote"
  ],
  "eemorv": [
    "remove"
  ],
  "eemprt": [
    "temper"
  ],
  "eemptx": [
    "exempt"
  ],
  "eemr": [
    "mere"
  ],
  "eemrst": [
    "meters"
  ],
  "eemrsu": [
    "resume"
  ],
  "eemrt": [
    "meter"
  ],
  "eemry": [
    "emery"
  ],
  "eems": [
    "seem"
  ],
  "eemss": [
    "seems"
  ],
  "eemsss": [
    "messes"
  ],
  "eemst": [
    "meets"
  ],
  "eemt": [
    "meet"
  ],
  "een": [
    "nee"
  ],
  "eennp": [
    "penne"
  ],
  "eennrt": [
    "tenner"
  ],
  "eennsu": [
    "unseen"
  ],
  "eenopr": [
    "opener",
    "reopen"
  ],
  "eenprt": [
    "repent"
  ],
  "eenqsu": [
    "queens"
  ],
  "eenqu": [
    "queen"
  ],
  "eenrs": [
    "sneer"
  ],
  "eenrst": [
    "enters",
    "resent"
  ],
  "eenrsu": [
    "ensure"
  ],
  "eenrsv": [
    "nerves"
  ],
  "eenrt": [
    "enter"
  ],
  "eenrtu": [
    "tenure"
  ],
  "eenrv": [
    "nerve",
    "never"
  ],
  "eenrw": [
    "renew"
  ],
  "eens": [
    "seen"
  ],
  "eenss": [
    "sense"
  ],
  "eensss": [
    "senses"
  ],
  "eenssv": [
    "sevens"
  ],
  "eenst": [
    "teens",
    "tense"
  ],
  "eenstt": [
    "tenets"
  ],
  "eenstv": [
    "events"
  ],
  "eenstw": [
    "newest"
  ],
  "eensty": [
    "teensy"
  ],
  "eensu": [
    "ensue"
  ],
  "eensuv": [
    "venues"
  ],
  "eensv": [
    "evens",
    "seven"
  ],
  "eenswy": [
    "weensy"
  ],
  "eent": [
    "teen"
  ],
  "eenttx": [
    "extent"
  ],
  "eentv": [
    "event"
  ],
  "eentw": [
    "tween"
  ],
  "eenty": [
    "teeny"
  ],
  "eenuv": [
    "venue"
  ],
  "eenv": [
    "even",
    "neve"
  ],
  "eenwy": [
    "weeny"
  ],
  "eeopsx": [
    "expose"
  ],
  "eeoptu": [
    "toupee"
  ],
  "eeorst": [
    "stereo"
  ],
  "eeorsz": [
    "zeroes"
  ],
  "eep": [
    "pee"
  ],
  "eepp": [
    "peep"
  ],
  "eepppr": [
    "pepper"
  ],
  "eepps": [
    "peeps"
  ],
  "eeppst": [
    "steppe"
  ],
  "eepr": [
    "peer"
  ],
  "eeprs": [
    "peers",
    "spree"
  ],
  "eeprst": [
    "pester",
    "peters"
  ],
  "eeprsu": [
    "peruse"
  ],
  "eeprt": [
    "peter"
  ],
  "eeprtu": [
    "repute"
  ],
  "eeprtw": [
    "pewter"
  ],
  "eeprtx": [
    "expert"
  ],
  "eepru": [
    "puree"
  ],
  "eeps": [
    "pees",
    "seep"
  ],
  "eepssw": [
    "sweeps"
  ],
  "eepst": [
    "steep"
  ],
  "eepsw": [
    "sweep"
  ],
  "eepw": [
    "weep"
  ],
  "eepwy": [
    "weepy"
  ],
  "eeqrsu": [
    "queers"
  ],
  "eeqru": [
    "queer"
  ],
  "eequu": [
    "queue"
  ],
  "eer": [
    "ere",
    "ree"
  ],
  "eerrsv": [
    "server"
  ],
  "eerrtv": [
    "revert"
  ],
  "eers": [
    "seer"
  ],
  "eerssv": [
    "serves"
  ],
  "eerssw": [
    "sewers"
  ],
  "eerst": [
    "ester",
    "reset",
    "steer",
    "trees"
  ],
  "eerstt": [
    "retest",
    "street"
  ],
  "eersv": [
    "serve",
    "sever",
    "verse"
  ],
  "eersvw": [
    "swerve"
  ],
  "eersw": [
    "sewer"
  ],
  "eert": [
    "tree"
  ],
  "eerttw": [
    "wetter"
  ],
  "eerv": [
    "ever",
    "veer"
  ],
  "eervy": [
    "every"
  ],
  "eerw": [
    "were"
  ],
  "eery": [
    "eyre"
  ],
  "ees": [
    "see"
  ],
  "eess": [
    "sees"
  ],
  "eesstw": [
    "sweets"
  ],
  "eessx": [
    "sexes"
  ],
  "eestw": [
    "sweet"
  ],
  "eesv": [
    "eves"
  ],
  "eesx": [
    "exes"
  ],
  "eesy": [
    "eyes"
  ],
  "eet": [
    "tee"
  ],
  "eettw": [
    "tweet"
  ],
  "eev": [
    "eve",
    "vee"
  ],
  "eew": [
    "ewe",
    "wee"
  ],
  "eey": [
    "eye"
  ],
  "eez": [
    "zee"
  ],
  "effi": [
    "fife"
  ],
  "effilp": [
    "piffle"
  ],
  "efflru": [
    "ruffle"
  ],
  "effor": [
    "offer"
  ],
  "effors": [
    "offers"
  ],
  "effort": [
    "effort"
  ],
  "effost": [
    "offset"
  ],
  "effrsu": [
    "suffer"
  ],
  "efgin": [
    "feign"
  ],
  "efginr": [
    "finger",
    "fringe"
  ],
  "efgir": [
    "grief"
  ],
  "efgiru": [
    "figure"
  ],
  "efgoor": [
    "forego"
  ],
  "efgor": [
    "forge",
    "gofer"
  ],
  "efgorr": [
    "forger"
  ],
  "efgort": [
    "forget"
  ],
  "efguu": [
    "fugue"
  ],
  "efhirs": [
    "fisher"
  ],
  "efhiss": [
    "fishes"
  ],
  "efhist": [
    "fetish"
  ],
  "efhit": [
    "thief"
  ],
  "efhls": [
    "flesh",
    "shelf"
  ],
  "efhlsy": [
    "fleshy"
  ],
  "efhrru": [
    "fuhrer"
  ],
  "efhrs": [
    "fresh"
  ],
  "efhstt": [
    "thefts"
  ],
  "efht": [
    "heft"
  ],
  "efhtt": [
    "theft"
  ],
  "efhty": [
    "hefty"
  ],
  "efikn": [
    "knife"
  ],
  "efil": [
    "file",
    "life"
  ],
  "efillr": [
    "filler",
    "refill"
  ],
  "efilr": [
    "flier",
    "lifer",
    "rifle"
  ],
  "efilrs": [
    "fliers",
    "lifers",
    "rifles"
  ],
  "efilrt": [
    "filter",
    "trifle"
  ],
  "efils": [
    "files",
    "flies"
  ],
  "efilst": [
    "itself",
    "stifle"
  ],
  "efilt": [
    "filet"
  ],
  "efiltu": [
    "futile"
  ],
  "efilzz": [
    "fizzle"
  ],
  "efimrr": [
    "firmer"
  ],
  "efin": [
    "fine"
  ],
  "efinr": [
    "finer"
  ],
  "efins": [
    "fines"
  ],
  "efinst": [
    "finest"
  ],
  "efiprx": [
    "prefix"
  ],
  "efir": [
    "fire",
    "rife"
  ],
  "efirs": [
    "fires",
    "fries"
  ],
  "efirst": [
    "strife"
  ],
  "efirtt": [
    "fitter"
  ],
  "efirv": [
    "fiver"
  ],
  "efirvy": [
    "verify"
  ],
  "efirx": [
    "fixer"
  ],
  "efiry": [
    "fiery"
  ],
  "efisty": [
    "feisty"
  ],
  "efisv": [
    "fives"
  ],
  "efisx": [
    "fixes"
  ],
  "efiv": [
    "five"
  ],
  "efiw": [
    "wife"
  ],
  "efklu": [
    "fluke"
  ],
  "efl": [
    "elf"
  ],
  "efll": [
    "fell"
  ],
  "efllow": [
    "fellow"
  ],
  "efllru": [
    "fuller"
  ],
  "eflmsy": [
    "myself"
  ],
  "eflno": [
    "felon"
  ],
  "eflnos": [
    "felons"
  ],
  "eflnoy": [
    "felony"
  ],
  "eflo": [
    "floe"
  ],
  "eflorw": [
    "flower",
    "fowler"
  ],
  "eflrsy": [
    "flyers"
  ],
  "eflry": [
    "flyer"
  ],
  "efls": [
    "self"
  ],
  "eflst": [
    "lefts"
  ],
  "eflstu": [
    "flutes"
  ],
  "eflsu": [
    "fuels"
  ],
  "eflsuu": [
    "useful"
  ],
  "eflt": [
    "felt",
    "left"
  ],
  "efltu": [
    "flute"
  ],
  "eflty": [
    "lefty"
  ],
  "eflu": [
    "flue",
    "fuel"
  ],
  "eflw": [
    "flew"
  ],
  "eflx": [
    "flex"
  ],
  "efmorr": [
    "former",
    "reform"
  ],
  "efmru": [
    "femur"
  ],
  "efmsu": [
    "fumes"
  ],
  "efn": [
    "fen"
  ],
  "efnorz": [
    "frozen"
  ],
  "efnost": [
    "soften"
  ],
  "efnot": [
    "often"
  ],
  "efnr": [
    "fern"
  ],
  "efnryz": [
    "frenzy"
  ],
  "efo": [
    "foe"
  ],
  "efoorr": [
    "roofer"
  ],
  "efoort": [
    "footer"
  ],
  "efor": [
    "fore"
  ],
  "eforrv": [
    "fervor"
  ],
  "eforst": [
    "forest",
    "foster",
    "softer"
  ],
  "efort": [
    "forte"
  ],
  "efory": [
    "foyer"
  ],
  "eforz": [
    "froze"
  ],
  "efoss": [
    "fosse"
  ],
  "efosx": [
    "foxes"
  ],
  "efr": [
    "fer"
  ],
  "efrrsu": [
    "surfer"
  ],
  "efrry": [
    "ferry",
    "fryer"
  ],
  "efrt": [
    "fret"
  ],
  "efrtuu": [
    "future"
  ],
  "efss": [
    "fess"
  ],
  "efssu": [
    "fuses"
  ],
  "efstu": [
    "fetus"
  ],
  "efsu": [
    "fuse"
  ],
  "efw": [
    "few"
  ],
  "efy": [
    "fey"
  ],
  "efz": [
    "fez"
  ],
  "egg": [
    "egg"
  ],
  "egggil": [
    "giggle"
  ],
  "egggin": [
    "egging"
  ],
  "eggglo": [
    "goggle"
  ],
  "egggno": [
    "eggnog"
  ],
  "egghru": [
    "hugger"
  ],
  "eggijl": [
    "jiggle"
  ],
  "eggilw": [
    "wiggle"
  ],
  "egginr": [
    "ginger",
    "nigger"
  ],
  "eggjlu": [
    "juggle"
  ],
  "eggjor": [
    "jogger"
  ],
  "egglor": [
    "logger"
  ],
  "eggly": [
    "leggy"
  ],
  "eggmru": [
    "mugger"
  ],
  "eggnru": [
    "grunge"
  ],
  "eggor": [
    "gorge"
  ],
  "eggou": [
    "gouge"
  ],
  "eggs": [
    "eggs"
  ],
  "eghhi": [
    "heigh"
  ],
  "eghhir": [
    "higher"
  ],
  "eghhit": [
    "eighth",
    "height"
  ],
  "eghils": [
    "sleigh"
  ],
  "eghins": [
    "hinges"
  ],
  "eghist": [
    "eights"
  ],
  "eghisw": [
    "weighs"
  ],
  "eghit": [
    "eight"
  ],
  "eghitw": [
    "weight"
  ],
  "eghity": [
    "eighty"
  ],
  "eghiw": [
    "weigh"
  ],
  "eghlnt": [
    "length"
  ],
  "eghnou": [
    "enough"
  ],
  "eghnru": [
    "hunger"
  ],
  "eghopr": [
    "gopher"
  ],
  "eghott": [
    "ghetto"
  ],
  "eghrsu": [
    "gusher"
  ],
  "eghstu": [
    "hugest"
  ],
  "eghu": [
    "huge"
  ],
  "egiilr": [
    "girlie"
  ],
  "egiimm": [
    "gimmie"
  ],
  "egiint": [
    "ignite"
  ],
  "egijln": [
    "jingle"
  ],
  "egikn": [
    "eking"
  ],
  "egilmn": [
    "mingle"
  ],
  "egilmt": [
    "gimlet"
  ],
  "egilno": [
    "legion"
  ],
  "egilnr": [
    "linger"
  ],
  "egilns": [
    "ingles",
    "single"
  ],
  "egilnt": [
    "tingle"
  ],
  "egilpt": [
    "piglet"
  ],
  "egilsu": [
    "uglies"
  ],
  "egilt": [
    "legit"
  ],
  "egimm": [
    "gimme"
  ],
  "egimr": [
    "grime"
  ],
  "egimrs": [
    "grimes"
  ],
  "eginns": [
    "ensign"
  ],
  "eginoo": [
    "goonie"
  ],
  "eginop": [
    "pigeon"
  ],
  "eginor": [
    "ignore",
    "region"
  ],
  "eginr": [
    "reign"
  ],
  "eginrr": [
    "ringer"
  ],
  "eginrs": [
    "reigns",
    "resign",
    "singer"
  ],
  "eginrw": [
    "winger"
  ],
  "egins": [
    "singe"
  ],
  "eginst": [
    "ingest"
  ],
  "eginsu": [
    "genius"
  ],
  "eginsv": [
    "givens"
  ],
  "eginsw": [
    "sewing"
  ],
  "egintw": [
    "twinge"
  ],
  "eginv": [
    "given"
  ],
  "egiors": [
    "orgies"
  ],
  "egiort": [
    "goiter"
  ],
  "egipr": [
    "gripe"
  ],
  "egiprs": [
    "gripes"
  ],
  "egirst": [
    "tigers"
  ],
  "egirsv": [
    "givers"
  ],
  "egirt": [
    "tiger"
  ],
  "egirv": [
    "giver"
  ],
  "egisv": [
    "gives"
  ],
  "egiv": [
    "give"
  ],
  "egjlnu": [
    "jungle"
  ],
  "egk": [
    "keg"
  ],
  "egks": [
    "kegs"
  ],
  "egl": [
    "gel",
    "leg"
  ],
  "egln": [
    "glen"
  ],
  "eglnor": [
    "longer"
  ],
  "eglnou": [
    "lounge"
  ],
  "eglnpu": [
    "plunge"
  ],
  "eglnty": [
    "gently"
  ],
  "eglnu": [
    "lunge"
  ],
  "eglo": [
    "ogle"
  ],
  "eglops": [
    "gospel"
  ],
  "eglorv": [
    "glover",
    "grovel"
  ],
  "eglosv": [
    "gloves"
  ],
  "eglouy": [
    "eulogy"
  ],
  "eglov": [
    "glove"
  ],
  "eglru": [
    "gruel"
  ],
  "egls": [
    "gels",
    "legs"
  ],
  "eglsu": [
    "glues"
  ],
  "eglu": [
    "glue",
    "luge"
  ],
  "egm": [
    "gem"
  ],
  "egmnnu": [
    "gunmen"
  ],
  "egmno": [
    "gnome"
  ],
  "egmnor": [
    "monger"
  ],
  "egmnos": [
    "gnomes"
  ],
  "egmntu": [
    "nutmeg"
  ],
  "egmoru": [
    "morgue"
  ],
  "egmr": [
    "germ"
  ],
  "egmrs": [
    "germs"
  ],
  "egms": [
    "gems"
  ],
  "egn": [
    "eng",
    "gen"
  ],
  "egnnru": [
    "gunner"
  ],
  "egno": [
    "gone"
  ],
  "egnooy": [
    "gooney"
  ],
  "egnops": [
    "sponge"
  ],
  "egnor": [
    "goner"
  ],
  "egnors": [
    "goners"
  ],
  "egnott": [
    "gotten"
  ],
  "egnotu": [
    "tongue"
  ],
  "egnoxy": [
    "oxygen"
  ],
  "egnrtu": [
    "urgent"
  ],
  "egnruy": [
    "gurney"
  ],
  "egnst": [
    "gents"
  ],
  "egnsu": [
    "genus"
  ],
  "ego": [
    "ego"
  ],
  "egoorv": [
    "groove"
  ],
  "egoos": [
    "goose"
  ],
  "egoost": [
    "stooge"
  ],
  "egooy": [
    "gooey"
  ],
  "egopr": [
    "grope"
  ],
  "egor": [
    "ergo",
    "gore",
    "ogre"
  ],
  "egorr": [
    "roger"
  ],
  "egorrs": [
    "rogers"
  ],
  "egors": [
    "goers",
    "ogres"
  ],
  "egorsu": [
    "grouse",
    "rogues"
  ],
  "egorsv": [
    "groves"
  ],
  "egoru": [
    "rogue",
    "rouge"
  ],
  "egorv": [
    "grove"
  ],
  "egos": [
    "egos",
    "goes"
  ],
  "egouv": [
    "vogue"
  ],
  "egp": [
    "peg"
  ],
  "egpru": [
    "purge"
  ],
  "egptu": [
    "getup"
  ],
  "egrsu": [
    "surge",
    "urges"
  ],
  "egrttu": [
    "gutter"
  ],
  "egru": [
    "urge"
  ],
  "egrw": [
    "grew"
  ],
  "egry": [
    "grey"
  ],
  "egs": [
    "seg"
  ],
  "egsstu": [
    "guests"
  ],
  "egssu": [
    "guess"
  ],
  "egst": [
    "gets"
  ],
  "egstu": [
    "guest"
  ],
  "egt": [
    "get"
  ],
  "egv": [
    "veg"
  ],
  "ehh": [
    "heh"
  ],
  "ehhnpy": [
    "hyphen"
  ],
  "ehiipp": [
    "hippie"
  ],
  "ehik": [
    "hike"
  ],
  "ehikr": [
    "hiker"
  ],
  "ehikrs": [
    "hikers",
    "shriek"
  ],
  "ehil": [
    "heil"
  ],
  "ehilor": [
    "holier"
  ],
  "ehilos": [
    "holies"
  ],
  "ehilrs": [
    "relish"
  ],
  "ehilw": [
    "while"
  ],
  "ehilx": [
    "helix"
  ],
  "ehinor": [
    "heroin"
  ],
  "ehinrs": [
    "shrine"
  ],
  "ehinrw": [
    "whiner"
  ],
  "ehins": [
    "shine"
  ],
  "ehinss": [
    "shines"
  ],
  "ehint": [
    "thine"
  ],
  "ehinw": [
    "whine"
  ],
  "ehiprs": [
    "perish"
  ],
  "ehir": [
    "heir",
    "hire"
  ],
  "ehirs": [
    "heirs",
    "hires"
  ],
  "ehirst": [
    "theirs"
  ],
  "ehirsv": [
    "shiver"
  ],
  "ehirt": [
    "their"
  ],
  "ehirtt": [
    "hitter"
  ],
  "ehirtv": [
    "thrive"
  ],
  "ehirtw": [
    "whiter",
    "wither"
  ],
  "ehisss": [
    "hisses"
  ],
  "ehisst": [
    "thesis"
  ],
  "ehissw": [
    "wishes"
  ],
  "ehist": [
    "heist"
  ],
  "ehistu": [
    "tushie"
  ],
  "ehistw": [
    "whites"
  ],
  "ehisv": [
    "hives"
  ],
  "ehitw": [
    "white"
  ],
  "ehitwy": [
    "whitey"
  ],
  "ehiv": [
    "hive"
  ],
  "ehjops": [
    "joseph"
  ],
  "ehknru": [
    "hunker"
  ],
  "ehkoor": [
    "hooker"
  ],
  "ehkors": [
    "kosher"
  ],
  "ehkoy": [
    "hokey"
  ],
  "ehll": [
    "hell"
  ],
  "ehllo": [
    "hello"
  ],
  "ehllor": [
    "holler"
  ],
  "ehlls": [
    "hells",
    "shell"
  ],
  "ehllss": [
    "shells"
  ],
  "ehllsy": [
    "shelly"
  ],
  "ehlm": [
    "helm"
  ],
  "ehlmo": [
    "mohel"
  ],
  "ehlmoy": [
    "homely"
  ],
  "ehlms": [
    "helms"
  ],
  "ehlo": [
    "helo",
    "hole"
  ],
  "ehlos": [
    "holes"
  ],
  "ehlost": [
    "hostel",
    "hotels"
  ],
  "ehlosv": [
    "shovel"
  ],
  "ehlot": [
    "hotel"
  ],
  "ehlov": [
    "hovel"
  ],
  "ehlow": [
    "whole"
  ],
  "ehlp": [
    "help"
  ],
  "ehlps": [
    "helps"
  ],
  "ehlruy": [
    "hurley"
  ],
  "ehlstu": [
    "hustle",
    "sleuth"
  ],
  "ehlsw": [
    "welsh"
  ],
  "ehlty": [
    "ethyl"
  ],
  "ehm": [
    "hem"
  ],
  "ehmmru": [
    "hummer"
  ],
  "ehmo": [
    "home"
  ],
  "ehmor": [
    "homer"
  ],
  "ehmors": [
    "homers"
  ],
  "ehmort": [
    "mother"
  ],
  "ehmos": [
    "homes"
  ],
  "ehmoy": [
    "homey"
  ],
  "ehmp": [
    "hemp"
  ],
  "ehmrsy": [
    "rhymes"
  ],
  "ehmry": [
    "rhyme"
  ],
  "ehms": [
    "mesh"
  ],
  "ehmt": [
    "meth",
    "them"
  ],
  "ehmty": [
    "thyme"
  ],
  "ehn": [
    "hen"
  ],
  "ehnop": [
    "phone"
  ],
  "ehnops": [
    "phones"
  ],
  "ehnopy": [
    "phoney"
  ],
  "ehnort": [
    "hornet",
    "throne"
  ],
  "ehnos": [
    "shone"
  ],
  "ehnost": [
    "honest"
  ],
  "ehnoy": [
    "honey"
  ],
  "ehnrtu": [
    "hunter"
  ],
  "ehnry": [
    "henry"
  ],
  "ehns": [
    "hens"
  ],
  "ehnstt": [
    "tenths"
  ],
  "ehnt": [
    "then"
  ],
  "ehntt": [
    "tenth"
  ],
  "ehnw": [
    "when"
  ],
  "eho": [
    "hoe"
  ],
  "ehoopy": [
    "phooey"
  ],
  "ehoort": [
    "hooter"
  ],
  "ehoost": [
    "soothe"
  ],
  "ehoosv": [
    "hooves"
  ],
  "ehop": [
    "hope"
  ],
  "ehoppr": [
    "hopper"
  ],
  "ehoprt": [
    "thorpe"
  ],
  "ehops": [
    "hopes"
  ],
  "ehor": [
    "hero"
  ],
  "ehors": [
    "horse",
    "shore"
  ],
  "ehorss": [
    "horses",
    "shores"
  ],
  "ehorst": [
    "others"
  ],
  "ehorsw": [
    "shower",
    "whores"
  ],
  "ehorsy": [
    "horsey"
  ],
  "ehort": [
    "other"
  ],
  "ehortt": [
    "hotter"
  ],
  "ehorty": [
    "theory"
  ],
  "ehorv": [
    "hover"
  ],
  "ehorw": [
    "whore"
  ],
  "ehos": [
    "hoes",
    "hose",
    "shoe"
  ],
  "ehoss": [
    "hoses",
    "shoes"
  ],
  "ehossu": [
    "houses"
  ],
  "ehossv": [
    "shoves"
  ],
  "ehost": [
    "those"
  ],
  "ehosu": [
    "house"
  ],
  "ehosv": [
    "shove"
  ],
  "ehosw": [
    "whose"
  ],
  "ehow": [
    "howe"
  ],
  "ehp": [
    "hep"
  ],
  "ehprsu": [
    "pusher"
  ],
  "ehpry": [
    "hyper"
  ],
  "ehpryz": [
    "zephyr"
  ],
  "ehpssu": [
    "pushes"
  ],
  "ehpw": [
    "phew"
  ],
  "ehpy": [
    "hype"
  ],
  "ehr": [
    "her"
  ],
  "ehrrsy": [
    "sherry"
  ],
  "ehrs": [
    "hers"
  ],
  "ehrssu": [
    "rushes",
    "ushers"
  ],
  "ehrsu": [
    "usher"
  ],
  "ehrsw": [
    "shrew"
  ],
  "ehrtw": [
    "threw"
  ],
  "ehrtz": [
    "hertz"
  ],
  "ehs": [
    "she"
  ],
  "ehss": [
    "shes"
  ],
  "eht": [
    "eth",
    "the"
  ],
  "ehtw": [
    "whet"
  ],
  "ehty": [
    "they"
  ],
  "ehu": [
    "hue"
  ],
  "ehww": [
    "whew"
  ],
  "ehwy": [
    "whey"
  ],
  "ehx": [
    "hex"
  ],
  "ehy": [
    "hey",
    "yeh"
  ],
  "eiiknp": [
    "pinkie"
  ],
  "eiills": [
    "lilies"
  ],
  "eiilrv": [
    "virile"
  ],
  "eiilrx": [
    "elixir"
  ],
  "eiintv": [
    "invite"
  ],
  "eiippy": [
    "yippie"
  ],
  "eiipsx": [
    "pixies"
  ],
  "eiipx": [
    "pixie"
  ],
  "eijknr": [
    "jerkin"
  ],
  "eijknu": [
    "junkie"
  ],
  "eijnor": [
    "joiner"
  ],
  "eijnru": [
    "injure"
  ],
  "eijrsu": [
    "juries"
  ],
  "eijstu": [
    "jesuit"
  ],
  "eikl": [
    "like"
  ],
  "eikllr": [
    "killer"
  ],
  "eiklly": [
    "likely"
  ],
  "eiklns": [
    "silken"
  ],
  "eiklnt": [
    "tinkle"
  ],
  "eiklnu": [
    "unlike"
  ],
  "eiklnw": [
    "winkle"
  ],
  "eiklrt": [
    "kilter"
  ],
  "eikls": [
    "likes"
  ],
  "eikm": [
    "mike"
  ],
  "eikms": [
    "mikes"
  ],
  "eikmst": [
    "kismet"
  ],
  "eikmsu": [
    "muskie"
  ],
  "eiknov": [
    "invoke"
  ],
  "eiknrs": [
    "sinker"
  ],
  "eiknsv": [
    "knives"
  ],
  "eikntt": [
    "kitten"
  ],
  "eikoor": [
    "rookie"
  ],
  "eikp": [
    "pike"
  ],
  "eikps": [
    "spike"
  ],
  "eikpss": [
    "spikes"
  ],
  "eikpsy": [
    "spikey"
  ],
  "eikrs": [
    "skier"
  ],
  "eikrss": [
    "kisser"
  ],
  "eikrst": [
    "strike"
  ],
  "eikss": [
    "sikes",
    "skies"
  ],
  "eiksss": [
    "kisses"
  ],
  "eikst": [
    "kites"
  ],
  "eiksy": [
    "yikes"
  ],
  "eikt": [
    "kite"
  ],
  "eil": [
    "lei",
    "lie"
  ],
  "eillmo": [
    "mollie"
  ],
  "eillmr": [
    "miller"
  ],
  "eillmt": [
    "millet"
  ],
  "eillrt": [
    "tiller"
  ],
  "eilltt": [
    "little"
  ],
  "eillvy": [
    "lively"
  ],
  "eilm": [
    "lime",
    "mile"
  ],
  "eilmpp": [
    "pimple"
  ],
  "eilmpr": [
    "prelim"
  ],
  "eilmps": [
    "simple"
  ],
  "eilms": [
    "limes",
    "miles",
    "slime",
    "smile"
  ],
  "eilmss": [
    "smiles"
  ],
  "eilmsy": [
    "smiley"
  ],
  "eilmty": [
    "timely"
  ],
  "eilmy": [
    "limey"
  ],
  "eiln": [
    "lien",
    "line"
  ],
  "eilnn": [
    "linen"
  ],
  "eilnns": [
    "linens"
  ],
  "eilnpp": [
    "nipple"
  ],
  "eilnpu": [
    "lineup"
  ],
  "eilnr": [
    "liner"
  ],
  "eilnrs": [
    "liners"
  ],
  "eilns": [
    "lines"
  ],
  "eilnst": [
    "enlist",
    "listen",
    "silent",
    "tinsel"
  ],
  "eilnuv": [
    "unveil"
  ],
  "eilnv": [
    "liven"
  ],
  "eilopt": [
    "polite"
  ],
  "eilosv": [
    "olives"
  ],
  "eilott": [
    "toilet"
  ],
  "eilotv": [
    "violet"
  ],
  "eilou": [
    "louie"
  ],
  "eilov": [
    "olive"
  ],
  "eilp": [
    "pile"
  ],
  "eilppr": [
    "ripple"
  ],
  "eilpr": [
    "peril"
  ],
  "eilprs": [
    "perils",
    "pliers"
  ],
  "eilprt": [
    "triple"
  ],
  "eilps": [
    "piles",
    "spiel"
  ],
  "eilpsx": [
    "pixels"
  ],
  "eilr": [
    "lier",
    "rile"
  ],
  "eilrst": [
    "liters"
  ],
  "eilrsv": [
    "livers",
    "silver",
    "sliver"
  ],
  "eilrt": [
    "liter"
  ],
  "eilrtt": [
    "litter"
  ],
  "eilrv": [
    "liver"
  ],
  "eilrvy": [
    "verily"
  ],
  "eilry": [
    "riley"
  ],
  "eils": [
    "lies"
  ],
  "eilsst": [
    "stiles"
  ],
  "eilst": [
    "tiles"
  ],
  "eilstt": [
    "titles"
  ],
  "eilsv": [
    "evils",
    "lives",
    "veils"
  ],
  "eilsvw": [
    "swivel"
  ],
  "eilsw": [
    "lewis"
  ],
  "eilswy": [
    "wisely"
  ],
  "eilszz": [
    "sizzle"
  ],
  "eilt": [
    "lite",
    "tile"
  ],
  "eiltt": [
    "title"
  ],
  "eiltvy": [
    "levity"
  ],
  "eilu": [
    "lieu"
  ],
  "eilv": [
    "evil",
    "live",
    "veil",
    "vile"
  ],
  "eimm": [
    "mime"
  ],
  "eimmnu": [
    "immune"
  ],
  "eimmrs": [
    "simmer"
  ],
  "eimms": [
    "mimes"
  ],
  "eimn": [
    "mine"
  ],
  "eimnr": [
    "miner"
  ],
  "eimnrs": [
    "miners"
  ],
  "eimnrv": [
    "vermin"
  ],
  "eimns": [
    "mines"
  ],
  "eimntt": [
    "mitten"
  ],
  "eimntu": [
    "minute"
  ],
  "eimoor": [
    "roomie"
  ],
  "eimops": [
    "impose"
  ],
  "eimosv": [
    "movies"
  ],
  "eimotv": [
    "motive"
  ],
  "eimov": [
    "movie"
  ],
  "eimox": [
    "moxie"
  ],
  "eimpr": [
    "prime"
  ],
  "eimprr": [
    "primer"
  ],
  "eimprt": [
    "permit"
  ],
  "eimpru": [
    "umpire"
  ],
  "eimrst": [
    "merits",
    "mister",
    "timers"
  ],
  "eimrsx": [
    "mixers"
  ],
  "eimrsy": [
    "misery"
  ],
  "eimrt": [
    "merit",
    "timer"
  ],
  "eimrx": [
    "mixer"
  ],
  "eims": [
    "semi"
  ],
  "eimsss": [
    "misses"
  ],
  "eimssu": [
    "misuse"
  ],
  "eimssx": [
    "sexism"
  ],
  "eimst": [
    "items",
    "mites",
    "smite",
    "times"
  ],
  "eimsx": [
    "mixes"
  ],
  "eimt": [
    "emit",
    "item",
    "mite",
    "time"
  ],
  "einn": [
    "nine"
  ],
  "einnr": [
    "inner"
  ],
  "einnrs": [
    "sinner"
  ],
  "einnrt": [
    "intern"
  ],
  "einnrw": [
    "winner"
  ],
  "einns": [
    "nines"
  ],
  "einnst": [
    "tennis"
  ],
  "einntt": [
    "intent"
  ],
  "einntv": [
    "invent"
  ],
  "einnty": [
    "ninety"
  ],
  "einnu": [
    "ennui"
  ],
  "einops": [
    "ponies"
  ],
  "einopt": [
    "pointe"
  ],
  "einors": [
    "senior"
  ],
  "einos": [
    "noise"
  ],
  "einoss": [
    "noises"
  ],
  "einotw": [
    "townie"
  ],
  "einp": [
    "pine"
  ],
  "einppr": [
    "nipper"
  ],
  "einprs": [
    "sniper"
  ],
  "einps": [
    "penis",
    "pines",
    "snipe",
    "spine"
  ],
  "einpst": [
    "instep"
  ],
  "einpt": [
    "inept"
  ],
  "einquu": [
    "unique"
  ],
  "einr": [
    "rein"
  ],
  "einrs": [
    "resin",
    "rinse",
    "risen",
    "siren"
  ],
  "einrss": [
    "sirens"
  ],
  "einrst": [
    "insert"
  ],
  "einrsu": [
    "insure"
  ],
  "einrt": [
    "inter"
  ],
  "einrtw": [
    "winter"
  ],
  "einru": [
    "urine"
  ],
  "einrwy": [
    "winery"
  ],
  "eins": [
    "sine"
  ],
  "einst": [
    "stein"
  ],
  "einstv": [
    "invest"
  ],
  "einsuw": [
    "unwise"
  ],
  "einsux": [
    "unisex"
  ],
  "einsv": [
    "veins"
  ],
  "einsvx": [
    "vixens"
  ],
  "einsw": [
    "swine"
  ],
  "eint": [
    "nite"
  ],
  "eintty": [
    "entity"
  ],
  "eintu": [
    "unite",
    "untie"
  ],
  "eintw": [
    "twine"
  ],
  "einv": [
    "vein",
    "vine"
  ],
  "einvx": [
    "vixen"
  ],
  "einw": [
    "wine"
  ],
  "eiops": [
    "poise"
  ],
  "eiopss": [
    "posies"
  ],
  "eiopst": [
    "potsie"
  ],
  "eioptt": [
    "tiptoe"
  ],
  "eiostv": [
    "soviet"
  ],
  "eip": [
    "pie"
  ],
  "eipp": [
    "pipe"
  ],
  "eippr": [
    "piper"
  ],
  "eipprr": [
    "ripper"
  ],
  "eipprt": [
    "tipper"
  ],
  "eipprz": [
    "zipper"
  ],
  "eipps": [
    "pipes"
  ],
  "eippuy": [
    "yuppie"
  ],
  "eipr": [
    "pier",
    "ripe"
  ],
  "eiprst": [
    "priest",
    "sprite",
    "stripe"
  ],
  "eiprsv": [
    "vipers"
  ],
  "eiprsw": [
    "wipers"
  ],
  "eiprsz": [
    "prizes"
  ],
  "eiprt": [
    "tripe"
  ],
  "eiprv": [
    "viper"
  ],
  "eiprw": [
    "wiper"
  ],
  "eiprz": [
    "prize"
  ],
  "eips": [
    "pies"
  ],
  "eipss": [
    "spies"
  ],
  "eipsss": [
    "pisses"
  ],
  "eipst": [
    "spite"
  ],
  "eipsw": [
    "swipe",
    "wipes"
  ],
  "eiptt": [
    "petit"
  ],
  "eipw": [
    "wipe"
  ],
  "eipy": [
    "yipe"
  ],
  "eiqrsu": [
    "squire"
  ],
  "eiqruv": [
    "quiver"
  ],
  "eiqtu": [
    "quiet",
    "quite"
  ],
  "eiqtuy": [
    "equity"
  ],
  "eirrsv": [
    "rivers"
  ],
  "eirrtt": [
    "ritter"
  ],
  "eirrtw": [
    "writer"
  ],
  "eirrv": [
    "river"
  ],
  "eirs": [
    "rise",
    "sire"
  ],
  "eirss": [
    "rises"
  ],
  "eirsst": [
    "resist",
    "sister"
  ],
  "eirst": [
    "rites",
    "tires",
    "tries"
  ],
  "eirstt": [
    "sitter"
  ],
  "eirstv": [
    "strive"
  ],
  "eirstw": [
    "writes"
  ],
  "eirsw": [
    "wires",
    "wiser"
  ],
  "eirt": [
    "rite",
    "tier",
    "tire"
  ],
  "eirtt": [
    "trite"
  ],
  "eirtuv": [
    "virtue"
  ],
  "eirtw": [
    "write"
  ],
  "eirw": [
    "weir",
    "wire"
  ],
  "eisssu": [
    "issues"
  ],
  "eisst": [
    "sites"
  ],
  "eisstu": [
    "suites",
    "tissue"
  ],
  "eisstw": [
    "wisest"
  ],
  "eisstx": [
    "exists",
    "sexist"
  ],
  "eissu": [
    "issue"
  ],
  "eissx": [
    "sixes"
  ],
  "eissz": [
    "sizes"
  ],
  "eist": [
    "site",
    "ties"
  ],
  "eistu": [
    "suite"
  ],
  "eistx": [
    "exist",
    "exits"
  ],
  "eisvw": [
    "views",
    "wives"
  ],
  "eisw": [
    "wise"
  ],
  "eisz": [
    "size"
  ],
  "eit": [
    "tie"
  ],
  "eitx": [
    "exit"
  ],
  "eity": [
    "yeti"
  ],
  "eiv": [
    "vie"
  ],
  "eivv": [
    "vive"
  ],
  "eivw": [
    "view"
  ],
  "ejko": [
    "joke"
  ],
  "ejkor": [
    "joker"
  ],
  "ejkors": [
    "jokers"
  ],
  "ejkos": [
    "jokes"
  ],
  "ejkr": [
    "jerk"
  ],
  "ejkrs": [
    "jerks"
  ],
  "ejkry": [
    "jerky"
  ],
  "ejku": [
    "juke"
  ],
  "ejll": [
    "jell"
  ],
  "ejlly": [
    "jelly"
  ],
  "ejlpu": [
    "julep"
  ],
  "ejmpru": [
    "jumper"
  ],
  "ejnny": [
    "jenny"
  ],
  "ejnos": [
    "jones"
  ],
  "ejnosy": [
    "enjoys"
  ],
  "ejnoy": [
    "enjoy"
  ],
  "ejo": [
    "joe"
  ],
  "ejos": [
    "joes"
  ],
  "ejoy": [
    "joey"
  ],
  "ejrry": [
    "jerry"
  ],
  "ejss": [
    "jess"
  ],
  "ejst": [
    "jest",
    "jets"
  ],
  "ejsw": [
    "jews"
  ],
  "ejt": [
    "jet"
  ],
  "ejw": [
    "jew"
  ],
  "ekl": [
    "elk"
  ],
  "eklly": [
    "kelly"
  ],
  "eklnos": [
    "kelson"
  ],
  "eklnt": [
    "knelt"
  ],
  "ekloor": [
    "looker"
  ],
  "eklosy": [
    "yokels"
  ],
  "ekloy": [
    "yokel"
  ],
  "eklp": [
    "kelp"
  ],
  "ekls": [
    "elks"
  ],
  "ekmnoy": [
    "monkey"
  ],
  "ekmors": [
    "smoker"
  ],
  "ekmos": [
    "smoke"
  ],
  "ekmoss": [
    "smokes"
  ],
  "ekmosy": [
    "smokey"
  ],
  "ekmstu": [
    "musket"
  ],
  "ekn": [
    "ken"
  ],
  "eknnsu": [
    "sunken"
  ],
  "ekno": [
    "keno"
  ],
  "eknops": [
    "spoken"
  ],
  "eknorr": [
    "kroner"
  ],
  "eknost": [
    "tokens"
  ],
  "eknot": [
    "token"
  ],
  "eknow": [
    "woken"
  ],
  "eknr": [
    "kern"
  ],
  "eknsu": [
    "nukes"
  ],
  "eknt": [
    "kent"
  ],
  "eknu": [
    "nuke"
  ],
  "eknw": [
    "knew"
  ],
  "ekop": [
    "poke"
  ],
  "ekopr": [
    "poker"
  ],
  "ekops": [
    "pokes",
    "spoke"
  ],
  "ekopss": [
    "spokes"
  ],
  "ekopy": [
    "pokey"
  ],
  "ekorrw": [
    "worker"
  ],
  "ekorst": [
    "stroke"
  ],
  "ekosst": [
    "stokes"
  ],
  "ekost": [
    "stoke"
  ],
  "ekot": [
    "toke"
  ],
  "ekow": [
    "woke"
  ],
  "ekoy": [
    "yoke"
  ],
  "ekpr": [
    "perk"
  ],
  "ekprs": [
    "perks"
  ],
  "ekpry": [
    "perky"
  ],
  "ekpsy": [
    "pesky"
  ],
  "ekpt": [
    "kept"
  ],
  "ekpu": [
    "puke"
  ],
  "ekrry": [
    "kerry"
  ],
  "ekrt": [
    "trek"
  ],
  "ekrtuy": [
    "turkey"
  ],
  "ekssy": [
    "sykes"
  ],
  "eksty": [
    "tykes"
  ],
  "eksy": [
    "keys"
  ],
  "eky": [
    "key"
  ],
  "ell": [
    "ell"
  ],
  "ellmow": [
    "mellow"
  ],
  "ellmru": [
    "muller"
  ],
  "ellms": [
    "smell"
  ],
  "ellmss": [
    "smells"
  ],
  "ellmsy": [
    "smelly"
  ],
  "ellmtu": [
    "mullet"
  ],
  "ellnop": [
    "pollen"
  ],
  "ellnoy": [
    "lonely"
  ],
  "ellnsu": [
    "sullen"
  ],
  "ellny": [
    "nelly"
  ],
  "ellorr": [
    "roller"
  ],
  "ellort": [
    "toller"
  ],
  "ellosy": [
    "solely"
  ],
  "ellovy": [
    "lovely"
  ],
  "ellowy": [
    "yellow"
  ],
  "ellpru": [
    "puller"
  ],
  "ellps": [
    "spell"
  ],
  "ellpss": [
    "spells"
  ],
  "ellqu": [
    "quell"
  ],
  "ells": [
    "sell"
  ],
  "ellss": [
    "sells"
  ],
  "ellssw": [
    "swells"
  ],
  "ellst": [
    "tells"
  ],
  "ellsw": [
    "swell",
    "wells"
  ],
  "ellsy": [
    "yells"
  ],
  "ellt": [
    "tell"
  ],
  "elltu": [
    "tulle"
  ],
  "ellty": [
    "telly"
  ],
  "ellw": [
    "well"
  ],
  "elly": [
    "yell"
  ],
  "elm": [
    "elm",
    "mel"
  ],
  "elmmpu": [
    "pummel"
  ],
  "elmno": [
    "lemon",
    "melon"
  ],
  "elmnos": [
    "melons",
    "solemn"
  ],
  "elmnot": [
    "molten"
  ],
  "elmnoy": [
    "lemony"
  ],
  "elmo": [
    "mole"
  ],
  "elmopy": [
    "employ"
  ],
  "elmors": [
    "morsel"
  ],
  "elmort": [
    "merlot"
  ],
  "elmos": [
    "moles"
  ],
  "elmost": [
    "molest",
    "motels"
  ],
  "elmot": [
    "motel"
  ],
  "elmoty": [
    "motley"
  ],
  "elmouv": [
    "volume"
  ],
  "elmr": [
    "merl"
  ],
  "elmrty": [
    "myrtle"
  ],
  "elmru": [
    "lemur"
  ],
  "elms": [
    "elms"
  ],
  "elmst": [
    "melts",
    "smelt"
  ],
  "elmsu": [
    "mules"
  ],
  "elmt": [
    "melt"
  ],
  "elmu": [
    "mule"
  ],
  "elmuy": [
    "muley"
  ],
  "elmuzz": [
    "muzzle"
  ],
  "elnnos": [
    "nelson"
  ],
  "elnntu": [
    "tunnel"
  ],
  "elno": [
    "leno",
    "lone",
    "noel"
  ],
  "elnoos": [
    "loosen"
  ],
  "elnooy": [
    "looney"
  ],
  "elnopy": [
    "openly"
  ],
  "elnor": [
    "loner"
  ],
  "elnors": [
    "loners"
  ],
  "elnoss": [
    "lesson"
  ],
  "elnost": [
    "stolen"
  ],
  "elnosv": [
    "novels"
  ],
  "elnov": [
    "novel"
  ],
  "elnozz": [
    "nozzle"
  ],
  "elnpty": [
    "plenty"
  ],
  "elns": [
    "lens"
  ],
  "elnssu": [
    "unless"
  ],
  "elnt": [
    "lent"
  ],
  "elnwy": [
    "newly"
  ],
  "elo": [
    "ole"
  ],
  "eloors": [
    "looser"
  ],
  "eloos": [
    "loose"
  ],
  "elop": [
    "pole"
  ],
  "eloppr": [
    "lopper"
  ],
  "eloppt": [
    "topple"
  ],
  "eloprt": [
    "petrol"
  ],
  "elops": [
    "poles",
    "slope"
  ],
  "elopss": [
    "slopes"
  ],
  "elor": [
    "lore",
    "role"
  ],
  "elorrs": [
    "sorrel"
  ],
  "elors": [
    "loser",
    "roles",
    "sorel"
  ],
  "elorss": [
    "losers"
  ],
  "elorsv": [
    "lovers"
  ],
  "elorsw": [
    "lowers",
    "slower"
  ],
  "eloruv": [
    "louvre"
  ],
  "elorv": [
    "lover"
  ],
  "elorvy": [
    "overly"
  ],
  "elorw": [
    "lower"
  ],
  "elorwy": [
    "lowery"
  ],
  "elos": [
    "lose",
    "sole"
  ],
  "eloss": [
    "loses",
    "soles"
  ],
  "elosss": [
    "losses"
  ],
  "elossv": [
    "solves"
  ],
  "elost": [
    "stole"
  ],
  "elostw": [
    "lowest",
    "towels"
  ],
  "elosu": [
    "louse"
  ],
  "elosv": [
    "loves",
    "solve"
  ],
  "elosvw": [
    "vowels",
    "wolves"
  ],
  "elott": [
    "lotte"
  ],
  "elottu": [
    "outlet"
  ],
  "elotw": [
    "towel"
  ],
  "elov": [
    "love"
  ],
  "elovw": [
    "vowel"
  ],
  "elow": [
    "lowe"
  ],
  "elppru": [
    "purple"
  ],
  "elppsu": [
    "supple"
  ],
  "elpruy": [
    "purely"
  ],
  "elpry": [
    "reply"
  ],
  "elpssu": [
    "pluses",
    "pulses"
  ],
  "elpst": [
    "pelts",
    "slept",
    "spelt"
  ],
  "elpsu": [
    "pulse"
  ],
  "elpt": [
    "pelt"
  ],
  "elpuzz": [
    "puzzle"
  ],
  "elrrsu": [
    "rulers"
  ],
  "elrru": [
    "ruler"
  ],
  "elrstu": [
    "luster",
    "result",
    "rustle"
  ],
  "elrsu": [
    "lures",
    "rules"
  ],
  "elrsuy": [
    "surely"
  ],
  "elrttu": [
    "turtle"
  ],
  "elru": [
    "lure",
    "rule"
  ],
  "elry": [
    "rely"
  ],
  "elss": [
    "less"
  ],
  "elsstu": [
    "tussle"
  ],
  "elssty": [
    "styles"
  ],
  "elst": [
    "lest",
    "lets"
  ],
  "elstw": [
    "welts"
  ],
  "elsty": [
    "style"
  ],
  "elsw": [
    "slew"
  ],
  "elt": [
    "let"
  ],
  "eltw": [
    "welt"
  ],
  "elvy": [
    "levy"
  ],
  "elx": [
    "lex"
  ],
  "ely": [
    "lye"
  ],
  "elz": [
    "lez"
  ],
  "emmnot": [
    "moment"
  ],
  "emmo": [
    "memo"
  ],
  "emmory": [
    "memory"
  ],
  "emmos": [
    "memos"
  ],
  "emmrsu": [
    "summer"
  ],
  "emmsuu": [
    "museum"
  ],
  "emn": [
    "men"
  ],
  "emno": [
    "nome",
    "omen"
  ],
  "emnors": [
    "sermon"
  ],
  "emnort": [
    "mentor"
  ],
  "emnos": [
    "omens"
  ],
  "emnosy": [
    "moneys"
  ],
  "emnot": [
    "monte"
  ],
  "emnov": [
    "venom"
  ],
  "emnow": [
    "women"
  ],
  "emnoy": [
    "money"
  ],
  "emnsu": [
    "menus"
  ],
  "emnu": [
    "menu"
  ],
  "emoor": [
    "romeo"
  ],
  "emoos": [
    "moose"
  ],
  "emop": [
    "mope",
    "poem"
  ],
  "emoprr": [
    "romper"
  ],
  "emops": [
    "mopes",
    "poems"
  ],
  "emopt": [
    "tempo"
  ],
  "emopy": [
    "mopey"
  ],
  "emoqsu": [
    "mosque"
  ],
  "emor": [
    "more"
  ],
  "emorrt": [
    "tremor"
  ],
  "emors": [
    "morse"
  ],
  "emorsv": [
    "movers"
  ],
  "emorsw": [
    "mowers"
  ],
  "emort": [
    "metro"
  ],
  "emorw": [
    "mower"
  ],
  "emos": [
    "some"
  ],
  "emossu": [
    "mousse"
  ],
  "emosu": [
    "mouse"
  ],
  "emosuy": [
    "mousey"
  ],
  "emosv": [
    "moves"
  ],
  "emosy": [
    "mosey"
  ],
  "emot": [
    "mote",
    "tome"
  ],
  "emott": [
    "totem"
  ],
  "emov": [
    "move"
  ],
  "emow": [
    "meow"
  ],
  "empr": [
    "perm"
  ],
  "emprs": [
    "sperm"
  ],
  "empst": [
    "temps"
  ],
  "empstu": [
    "septum"
  ],
  "empt": [
    "temp"
  ],
  "emptt": [
    "tempt"
  ],
  "empty": [
    "empty"
  ],
  "emr": [
    "rem"
  ],
  "emrry": [
    "merry"
  ],
  "emrst": [
    "terms"
  ],
  "emrstu": [
    "muster"
  ],
  "emrsu": [
    "serum"
  ],
  "emrt": [
    "term"
  ],
  "ems": [
    "ems"
  ],
  "emss": [
    "mess"
  ],
  "emsst": [
    "stems"
  ],
  "emssty": [
    "system"
  ],
  "emssu": [
    "muses"
  ],
  "emssy": [
    "messy"
  ],
  "emst": [
    "stem"
  ],
  "emsu": [
    "muse"
  ],
  "emt": [
    "met"
  ],
  "emtu": [
    "mute"
  ],
  "emw": [
    "mew"
  ],
  "enno": [
    "neon",
    "none"
  ],
  "ennorw": [
    "renown"
  ],
  "ennost": [
    "sonnet"
  ],
  "ennotw": [
    "newton"
  ],
  "ennpy": [
    "penny"
  ],
  "ennrru": [
    "runner"
  ],
  "eno": [
    "one"
  ],
  "enoors": [
    "sooner"
  ],
  "enoos": [
    "noose"
  ],
  "enoosz": [
    "snooze"
  ],
  "enooz": [
    "ozone"
  ],
  "enop": [
    "nope",
    "open",
    "peon"
  ],
  "enopr": [
    "prone"
  ],
  "enoprs": [
    "person"
  ],
  "enoprv": [
    "proven"
  ],
  "enops": [
    "opens"
  ],
  "enoptt": [
    "potent"
  ],
  "enorry": [
    "ornery"
  ],
  "enors": [
    "senor",
    "snore"
  ],
  "enorss": [
    "sensor",
    "snores"
  ],
  "enorst": [
    "stoner",
    "tenors"
  ],
  "enorsw": [
    "owners"
  ],
  "enort": [
    "tenor",
    "toner"
  ],
  "enortt": [
    "rotten"
  ],
  "enorw": [
    "owner"
  ],
  "enos": [
    "nose",
    "ones"
  ],
  "enoss": [
    "noses"
  ],
  "enosst": [
    "stones"
  ],
  "enost": [
    "notes",
    "steno",
    "stone",
    "tones"
  ],
  "enosty": [
    "stoney"
  ],
  "enosy": [
    "nosey"
  ],
  "enosz": [
    "zones"
  ],
  "enot": [
    "note",
    "tone"
  ],
  "enov": [
    "oven"
  ],
  "enovw": [
    "woven"
  ],
  "enovy": [
    "envoy"
  ],
  "enox": [
    "oxen"
  ],
  "enoz": [
    "zone"
  ],
  "enp": [
    "pen"
  ],
  "enprsu": [
    "prunes"
  ],
  "enpru": [
    "prune"
  ],
  "enps": [
    "pens"
  ],
  "enpst": [
    "spent"
  ],
  "enrrsu": [
    "reruns"
  ],
  "enrrtu": [
    "return",
    "turner"
  ],
  "enrssu": [
    "nurses"
  ],
  "enrst": [
    "rents",
    "stern"
  ],
  "enrstu": [
    "unrest"
  ],
  "enrstw": [
    "strewn"
  ],
  "enrsty": [
    "sentry"
  ],
  "enrsu": [
    "nurse",
    "runes"
  ],
  "enrsuu": [
    "unsure"
  ],
  "enrt": [
    "rent"
  ],
  "enrtuu": [
    "untrue"
  ],
  "enrty": [
    "entry"
  ],
  "enru": [
    "rune"
  ],
  "ens": [
    "sen"
  ],
  "enss": [
    "ness"
  ],
  "ensstu": [
    "sunset"
  ],
  "enst": [
    "nest",
    "sent",
    "tens"
  ],
  "enstt": [
    "tents"
  ],
  "enstu": [
    "tunes"
  ],
  "enstv": [
    "vents"
  ],
  "ensux": [
    "nexus"
  ],
  "ensw": [
    "news",
    "sewn"
  ],
  "ensy": [
    "syne"
  ],
  "ent": [
    "net",
    "ten"
  ],
  "entt": [
    "tent"
  ],
  "enttwy": [
    "twenty"
  ],
  "entu": [
    "tune"
  ],
  "entv": [
    "vent"
  ],
  "entw": [
    "newt",
    "went"
  ],
  "entx": [
    "next"
  ],
  "envy": [
    "envy"
  ],
  "enw": [
    "new"
  ],
  "eny": [
    "yen"
  ],
  "eoopps": [
    "oppose"
  ],
  "eooprr": [
    "poorer"
  ],
  "eooz": [
    "ooze"
  ],
  "eopp": [
    "pope"
  ],
  "eopppr": [
    "popper"
  ],
  "eopppt": [
    "poppet"
  ],
  "eopprr": [
    "proper"
  ],
  "eopr": [
    "pore",
    "rope"
  ],
  "eoprrt": [
    "porter",
    "report"
  ],
  "eoprs": [
    "pores",
    "poser",
    "prose",
    "ropes"
  ],
  "eoprss": [
    "spores"
  ],
  "eoprst": [
    "poster",
    "presto"
  ],
  "eoprsv": [
    "proves"
  ],
  "eoprsw": [
    "powers"
  ],
  "eoprtt": [
    "potter"
  ],
  "eoprtu": [
    "troupe"
  ],
  "eoprtx": [
    "export"
  ],
  "eoprty": [
    "poetry"
  ],
  "eoprv": [
    "prove"
  ],
  "eoprw": [
    "power"
  ],
  "eops": [
    "pose"
  ],
  "eopss": [
    "pesos",
    "poses",
    "posse"
  ],
  "eopsss": [
    "posses"
  ],
  "eopssu": [
    "spouse"
  ],
  "eopst": [
    "pesto",
    "poets",
    "topes"
  ],
  "eopt": [
    "poet"
  ],
  "eopxy": [
    "epoxy"
  ],
  "eoqrtu": [
    "torque"
  ],
  "eoqstu": [
    "quotes"
  ],
  "eoqtu": [
    "quote"
  ],
  "eor": [
    "ore"
  ],
  "eorrr": [
    "error"
  ],
  "eorrrs": [
    "errors"
  ],
  "eorrrt": [
    "terror"
  ],
  "eorrst": [
    "resort",
    "roster"
  ],
  "eorrt": [
    "retro"
  ],
  "eorrtt": [
    "retort"
  ],
  "eorrtu": [
    "router"
  ],
  "eorrv": [
    "rover"
  ],
  "eors": [
    "eros",
    "rose",
    "sore"
  ],
  "eorss": [
    "roses",
    "sores"
  ],
  "eorsst": [
    "stores"
  ],
  "eorst": [
    "store"
  ],
  "eorstu": [
    "routes"
  ],
  "eorstv": [
    "voters"
  ],
  "eorstw": [
    "towers"
  ],
  "eorsty": [
    "oyster"
  ],
  "eorsu": [
    "euros",
    "rouse"
  ],
  "eorsw": [
    "swore",
    "worse"
  ],
  "eorsz": [
    "zeros"
  ],
  "eort": [
    "tore"
  ],
  "eortt": [
    "otter"
  ],
  "eorttx": [
    "extort"
  ],
  "eortu": [
    "outer",
    "route"
  ],
  "eortv": [
    "trove",
    "voter"
  ],
  "eortvx": [
    "vortex"
  ],
  "eortw": [
    "tower",
    "wrote"
  ],
  "eoru": [
    "euro"
  ],
  "eoruvy": [
    "voyeur"
  ],
  "eorv": [
    "over"
  ],
  "eorw": [
    "wore"
  ],
  "eorxx": [
    "xerox"
  ],
  "eory": [
    "yore"
  ],
  "eorz": [
    "zero"
  ],
  "eos": [
    "ose"
  ],
  "eossst": [
    "tosses"
  ],
  "eossu": [
    "souse"
  ],
  "eost": [
    "toes"
  ],
  "eosttu": [
    "outset"
  ],
  "eostv": [
    "stove",
    "votes"
  ],
  "eosuy": [
    "youse"
  ],
  "eosw": [
    "owes",
    "woes"
  ],
  "eot": [
    "toe"
  ],
  "eott": [
    "tote"
  ],
  "eotv": [
    "veto",
    "vote"
  ],
  "eow": [
    "owe",
    "woe"
  ],
  "eoyz": [
    "oyez"
  ],
  "epp": [
    "pep"
  ],
  "epppry": [
    "preppy"
  ],
  "eppptu": [
    "puppet"
  ],
  "eppr": [
    "prep"
  ],
  "epprsu": [
    "supper",
    "uppers"
  ],
  "eppru": [
    "upper"
  ],
  "epr": [
    "per",
    "rep"
  ],
  "eprru": [
    "purer"
  ],
  "eprry": [
    "perry"
  ],
  "eprs": [
    "reps"
  ],
  "eprss": [
    "press"
  ],
  "eprst": [
    "strep"
  ],
  "eprstu": [
    "purest"
  ],
  "eprsu": [
    "purse",
    "super"
  ],
  "eprsuu": [
    "pursue"
  ],
  "eprsy": [
    "preys"
  ],
  "eprttu": [
    "putter"
  ],
  "eprtty": [
    "pretty"
  ],
  "eprtu": [
    "erupt"
  ],
  "eprtw": [
    "twerp"
  ],
  "epru": [
    "pure"
  ],
  "epry": [
    "prey",
    "pyre"
  ],
  "epsst": [
    "pests",
    "steps"
  ],
  "epsstu": [
    "upsets"
  ],
  "epst": [
    "pest",
    "pets",
    "step"
  ],
  "epstu": [
    "setup",
    "upset"
  ],
  "epstw": [
    "swept"
  ],
  "epsty": [
    "types"
  ],
  "epsw": [
    "spew"
  ],
  "ept": [
    "pet"
  ],
  "eptty": [
    "petty"
  ],
  "eptw": [
    "wept"
  ],
  "epty": [
    "type"
  ],
  "epw": [
    "pew"
  ],
  "epy": [
    "yep"
  ],
  "eqruy": [
    "query"
  ],
  "eqstu": [
    "quest"
  ],
  "err": [
    "err"
  ],
  "errttu": [
    "turret"
  ],
  "errtu": [
    "truer"
  ],
  "errty": [
    "terry"
  ],
  "ers": [
    "ers",
    "res",
    "ser"
  ],
  "erssst": [
    "stress"
  ],
  "ersst": [
    "rests"
  ],
  "erssu": [
    "users"
  ],
  "erssuv": [
    "versus"
  ],
  "erst": [
    "rest"
  ],
  "ersttu": [
    "truest"
  ],
  "erstuu": [
    "uterus"
  ],
  "ersty": [
    "tyres"
  ],
  "ersu": [
    "ruse",
    "sure",
    "user"
  ],
  "ersuvy": [
    "survey"
  ],
  "erttu": [
    "utter"
  ],
  "ertu": [
    "true"
  ],
  "erty": [
    "trey",
    "tyre"
  ],
  "eru": [
    "rue"
  ],
  "erv": [
    "rev"
  ],
  "ervy": [
    "very"
  ],
  "erx": [
    "rex"
  ],
  "ery": [
    "rye"
  ],
  "ess": [
    "ess"
  ],
  "esst": [
    "sets"
  ],
  "esstt": [
    "tests"
  ],
  "esstv": [
    "vests"
  ],
  "essu": [
    "sues",
    "uses"
  ],
  "est": [
    "set"
  ],
  "estt": [
    "test"
  ],
  "esttx": [
    "texts"
  ],
  "estty": [
    "testy"
  ],
  "estux": [
    "tuxes"
  ],
  "estv": [
    "vest",
    "vets"
  ],
  "estw": [
    "stew",
    "west",
    "wets"
  ],
  "estyz": [
    "zesty"
  ],
  "estz": [
    "zest"
  ],
  "esu": [
    "sue",
    "use"
  ],
  "esw": [
    "sew"
  ],
  "esx": [
    "sex"
  ],
  "esxy": [
    "sexy"
  ],
  "esy": [
    "yes"
  ],
  "ett": [
    "tet"
  ],
  "ettx": [
    "text"
  ],
  "etv": [
    "vet"
  ],
  "etw": [
    "wet"
  ],
  "ety": [
    "yet"
  ],
  "ffflu": [
    "fluff"
  ],
  "fffluy": [
    "fluffy"
  ],
  "ffgino": [
    "offing"
  ],
  "ffgir": [
    "griff"
  ],
  "ffgu": [
    "guff"
  ],
  "ffhist": [
    "fifths"
  ],
  "ffhit": [
    "fifth"
  ],
  "ffhiw": [
    "whiff"
  ],
  "ffhuy": [
    "huffy"
  ],
  "ffi": [
    "iff"
  ],
  "ffij": [
    "jiff"
  ],
  "ffijy": [
    "jiffy"
  ],
  "ffiks": [
    "skiff"
  ],
  "ffillu": [
    "fulfil"
  ],
  "ffimnu": [
    "muffin"
  ],
  "ffins": [
    "sniff"
  ],
  "ffir": [
    "riff"
  ],
  "ffist": [
    "stiff"
  ],
  "ffit": [
    "tiff"
  ],
  "ffity": [
    "fifty"
  ],
  "ffiy": [
    "iffy"
  ],
  "ffmu": [
    "muff"
  ],
  "ffnoru": [
    "runoff"
  ],
  "ffnsu": [
    "snuff"
  ],
  "ffo": [
    "off"
  ],
  "ffos": [
    "offs"
  ],
  "ffpsu": [
    "puffs"
  ],
  "ffpt": [
    "pfft"
  ],
  "ffpu": [
    "puff"
  ],
  "ffpuy": [
    "puffy"
  ],
  "ffru": [
    "ruff"
  ],
  "ffsstu": [
    "stuffs"
  ],
  "ffstu": [
    "stuff"
  ],
  "ffstuy": [
    "stuffy"
  ],
  "fghilt": [
    "flight"
  ],
  "fghirt": [
    "fright"
  ],
  "fghist": [
    "fights"
  ],
  "fghit": [
    "fight"
  ],
  "fghotu": [
    "fought"
  ],
  "fgi": [
    "fig"
  ],
  "fgiiln": [
    "filing"
  ],
  "fgiinr": [
    "firing"
  ],
  "fgiinx": [
    "fixing"
  ],
  "fgiln": [
    "fling"
  ],
  "fgilny": [
    "flying"
  ],
  "fginry": [
    "frying"
  ],
  "fginu": [
    "fungi"
  ],
  "fgirt": [
    "grift"
  ],
  "fgist": [
    "gifts"
  ],
  "fgit": [
    "gift"
  ],
  "fglnu": [
    "flung"
  ],
  "fglo": [
    "flog",
    "golf"
  ],
  "fglu": [
    "gulf"
  ],
  "fgnsuu": [
    "fungus"
  ],
  "fgo": [
    "fog"
  ],
  "fgoo": [
    "goof"
  ],
  "fgoor": [
    "forgo"
  ],
  "fgoort": [
    "forgot"
  ],
  "fgooy": [
    "goofy"
  ],
  "fgor": [
    "frog"
  ],
  "fgors": [
    "frogs"
  ],
  "fguu": [
    "fugu"
  ],
  "fhiins": [
    "finish"
  ],
  "fhilt": [
    "filth"
  ],
  "fhilty": [
    "filthy"
  ],
  "fhirtt": [
    "thrift"
  ],
  "fhis": [
    "fish"
  ],
  "fhisst": [
    "shifts"
  ],
  "fhist": [
    "shift"
  ],
  "fhisty": [
    "shifty"
  ],
  "fhisy": [
    "fishy"
  ],
  "fhlsu": [
    "flush"
  ],
  "fhoo": [
    "hoof"
  ],
  "fhort": [
    "forth"
  ],
  "fhortu": [
    "fourth"
  ],
  "fiimst": [
    "misfit"
  ],
  "fikn": [
    "fink"
  ],
  "fikrs": [
    "frisk"
  ],
  "fikrsy": [
    "frisky"
  ],
  "fill": [
    "fill"
  ],
  "fillrs": [
    "frills"
  ],
  "fills": [
    "fills"
  ],
  "filly": [
    "filly"
  ],
  "film": [
    "film"
  ],
  "filmry": [
    "firmly"
  ],
  "films": [
    "films"
  ],
  "filmsy": [
    "flimsy"
  ],
  "filnor": [
    "florin"
  ],
  "filnsu": [
    "sinful"
  ],
  "filnt": [
    "flint"
  ],
  "filnux": [
    "influx"
  ],
  "filo": [
    "foil"
  ],
  "filoss": [
    "fossil"
  ],
  "filp": [
    "flip"
  ],
  "filps": [
    "flips"
  ],
  "filrt": [
    "flirt"
  ],
  "filst": [
    "lifts"
  ],
  "filt": [
    "lift"
  ],
  "fimnor": [
    "inform"
  ],
  "fimot": [
    "motif"
  ],
  "fimr": [
    "firm"
  ],
  "fimrs": [
    "firms"
  ],
  "fin": [
    "fin"
  ],
  "fino": [
    "info"
  ],
  "finosu": [
    "fusion"
  ],
  "finoty": [
    "notify"
  ],
  "fins": [
    "fins"
  ],
  "fintu": [
    "unfit"
  ],
  "finty": [
    "nifty"
  ],
  "fioprt": [
    "profit"
  ],
  "fiottu": [
    "outfit"
  ],
  "fir": [
    "fir"
  ],
  "firs": [
    "firs"
  ],
  "first": [
    "first"
  ],
  "firstu": [
    "fruits"
  ],
  "firt": [
    "rift"
  ],
  "firtu": [
    "fruit"
  ],
  "firtuy": [
    "fruity"
  ],
  "firtz": [
    "fritz"
  ],
  "firyzz": [
    "frizzy"
  ],
  "fis": [
    "ifs"
  ],
  "fisst": [
    "fists"
  ],
  "fist": [
    "fist",
    "fits",
    "sift"
  ],
  "fistw": [
    "swift"
  ],
  "fit": [
    "fit"
  ],
  "fix": [
    "fix"
  ],
  "fizz": [
    "fizz"
  ],
  "fjlouy": [
    "joyful"
  ],
  "fklnu": [
    "flunk"
  ],
  "fklnuy": [
    "flunky"
  ],
  "fklo": [
    "folk"
  ],
  "fklos": [
    "folks"
  ],
  "fklosy": [
    "folksy"
  ],
  "fknu": [
    "funk"
  ],
  "fknuy": [
    "funky"
  ],
  "fkor": [
    "fork"
  ],
  "fkors": [
    "forks"
  ],
  "flloow": [
    "follow"
  ],
  "flloy": [
    "folly"
  ],
  "fllu": [
    "full"
  ],
  "flluy": [
    "fully"
  ],
  "flnow": [
    "flown"
  ],
  "floo": [
    "fool"
  ],
  "floor": [
    "floor"
  ],
  "floors": [
    "floors"
  ],
  "floos": [
    "fools"
  ],
  "flooyz": [
    "floozy"
  ],
  "flop": [
    "flop"
  ],
  "floppy": [
    "floppy"
  ],
  "flops": [
    "flops"
  ],
  "flor": [
    "rolf"
  ],
  "floru": [
    "flour"
  ],
  "floss": [
    "floss"
  ],
  "flosty": [
    "softly"
  ],
  "flosw": [
    "flows"
  ],
  "flot": [
    "loft"
  ],
  "floty": [
    "lofty"
  ],
  "flou": [
    "foul"
  ],
  "flow": [
    "flow",
    "fowl",
    "wolf"
  ],
  "flrruy": [
    "flurry"
  ],
  "flrsuu": [
    "sulfur"
  ],
  "flu": [
    "flu"
  ],
  "flux": [
    "flux"
  ],
  "fly": [
    "fly"
  ],
  "fmor": [
    "form",
    "from"
  ],
  "fmors": [
    "forms"
  ],
  "fmoru": [
    "forum"
  ],
  "fnnuy": [
    "funny"
  ],
  "fnorst": [
    "fronts"
  ],
  "fnort": [
    "front"
  ],
  "fnorw": [
    "frown"
  ],
  "fnot": [
    "font"
  ],
  "fnotu": [
    "futon"
  ],
  "fnu": [
    "fun"
  ],
  "foop": [
    "poof"
  ],
  "foopr": [
    "proof"
  ],
  "fooprs": [
    "proofs"
  ],
  "foops": [
    "poofs"
  ],
  "foopy": [
    "poofy"
  ],
  "foor": [
    "roof"
  ],
  "foors": [
    "roofs"
  ],
  "foot": [
    "foot"
  ],
  "foow": [
    "woof"
  ],
  "fop": [
    "fop"
  ],
  "fopr": [
    "prof"
  ],
  "for": [
    "for",
    "fro"
  ],
  "forst": [
    "frost"
  ],
  "forsty": [
    "frosty"
  ],
  "forsu": [
    "fours"
  ],
  "fort": [
    "fort"
  ],
  "forty": [
    "forty"
  ],
  "foru": [
    "four"
  ],
  "fost": [
    "soft"
  ],
  "fosty": [
    "softy"
  ],
  "fotu": [
    "tofu"
  ],
  "fox": [
    "fox"
  ],
  "foxy": [
    "foxy"
  ],
  "frruy": [
    "furry"
  ],
  "frsu": [
    "furs",
    "surf"
  ],
  "frtu": [
    "turf"
  ],
  "fru": [
    "fur"
  ],
  "fruy": [
    "fury"
  ],
  "fry": [
    "fry"
  ],
  "fssu": [
    "fuss"
  ],
  "fssuy": [
    "fussy"
  ],
  "fuyzz": [
    "fuzzy"
  ],
  "fuzz": [
    "fuzz"
  ],
  "gggily": [
    "giggly"
  ],
  "gggory": [
    "groggy"
  ],
  "ggi": [
    "gig"
  ],
  "ggiinv": [
    "giving"
  ],
  "ggijly": [
    "jiggly"
  ],
  "ggilno": [
    "ogling"
  ],
  "ggilnu": [
    "gluing"
  ],
  "ggiloo": [
    "gigolo"
  ],
  "ggilwy": [
    "wiggly"
  ],
  "ggims": [
    "miggs"
  ],
  "gginno": [
    "noggin"
  ],
  "ggino": [
    "going"
  ],
  "gginor": [
    "gringo"
  ],
  "gginru": [
    "urging"
  ],
  "ggipy": [
    "piggy"
  ],
  "ggis": [
    "gigs"
  ],
  "ggiwy": [
    "wiggy"
  ],
  "gglooy": [
    "googly"
  ],
  "ggmuy": [
    "muggy"
  ],
  "ggno": [
    "gong"
  ],
  "ggnruy": [
    "grungy"
  ],
  "ggor": [
    "grog"
  ],
  "ggosy": [
    "soggy"
  ],
  "ghhi": [
    "high"
  ],
  "ghhily": [
    "highly"
  ],
  "ghhis": [
    "highs"
  ],
  "ghhist": [
    "thighs"
  ],
  "ghhit": [
    "thigh"
  ],
  "ghhotu": [
    "though"
  ],
  "ghiikn": [
    "hiking"
  ],
  "ghiinr": [
    "hiring"
  ],
  "ghiknt": [
    "knight"
  ],
  "ghilno": [
    "holing"
  ],
  "ghilpt": [
    "plight"
  ],
  "ghilst": [
    "lights",
    "slight"
  ],
  "ghilt": [
    "light"
  ],
  "ghimno": [
    "homing"
  ],
  "ghimt": [
    "might"
  ],
  "ghimty": [
    "mighty"
  ],
  "ghin": [
    "nigh"
  ],
  "ghinop": [
    "hoping"
  ],
  "ghinos": [
    "hosing"
  ],
  "ghinst": [
    "nights",
    "things"
  ],
  "ghint": [
    "night",
    "thing"
  ],
  "ghinty": [
    "nighty"
  ],
  "ghiort": [
    "righto"
  ],
  "ghirst": [
    "rights"
  ],
  "ghirt": [
    "girth",
    "right"
  ],
  "ghirtw": [
    "wright"
  ],
  "ghirty": [
    "righty"
  ],
  "ghis": [
    "sigh"
  ],
  "ghiss": [
    "sighs"
  ],
  "ghisst": [
    "sights"
  ],
  "ghist": [
    "sight"
  ],
  "ghistt": [
    "tights"
  ],
  "ghitt": [
    "tight"
  ],
  "ghlosu": [
    "slough"
  ],
  "ghlou": [
    "ghoul"
  ],
  "ghno": [
    "hong"
  ],
  "ghnost": [
    "thongs"
  ],
  "ghnot": [
    "thong"
  ],
  "ghnruy": [
    "hungry"
  ],
  "ghnu": [
    "hung"
  ],
  "gho": [
    "hog"
  ],
  "ghortw": [
    "growth"
  ],
  "ghoru": [
    "rough"
  ],
  "ghos": [
    "gosh",
    "hogs"
  ],
  "ghosst": [
    "ghosts"
  ],
  "ghost": [
    "ghost"
  ],
  "ghostu": [
    "sought"
  ],
  "ghotu": [
    "ought",
    "tough"
  ],
  "ghrssu": [
    "shrugs"
  ],
  "ghrsu": [
    "shrug"
  ],
  "ghstu": [
    "thugs"
  ],
  "ghsu": [
    "gush",
    "hugs"
  ],
  "ghsuy": [
    "gushy"
  ],
  "ghtu": [
    "thug"
  ],
  "ghu": [
    "hug",
    "ugh"
  ],
  "giikln": [
    "liking"
  ],
  "giikns": [
    "skiing"
  ],
  "giiknv": [
    "viking"
  ],
  "giilnn": [
    "lining"
  ],
  "giilnp": [
    "piling"
  ],
  "giilnv": [
    "living"
  ],
  "giilv": [
    "vigil"
  ],
  "giimnn": [
    "mining"
  ],
  "giimnt": [
    "timing"
  ],
  "giimnx": [
    "mixing"
  ],
  "giinnn": [
    "inning"
  ],
  "giinnp": [
    "pining"
  ],
  "giinnw": [
    "wining"
  ],
  "giinor": [
    "origin"
  ],
  "giinpp": [
    "piping"
  ],
  "giinpw": [
    "wiping"
  ],
  "giinrs": [
    "rising"
  ],
  "giinrt": [
    "tiring"
  ],
  "giinrv": [
    "virgin"
  ],
  "giinrw": [
    "wiring"
  ],
  "giinsz": [
    "sizing"
  ],
  "gij": [
    "jig"
  ],
  "gijkno": [
    "joking"
  ],
  "gikn": [
    "king"
  ],
  "giknop": [
    "poking"
  ],
  "giknpu": [
    "puking"
  ],
  "gikns": [
    "kings"
  ],
  "gill": [
    "gill"
  ],
  "gillr": [
    "grill"
  ],
  "gillrs": [
    "grills"
  ],
  "gills": [
    "gills"
  ],
  "giln": [
    "ling"
  ],
  "gilno": [
    "lingo"
  ],
  "gilnos": [
    "losing"
  ],
  "gilnov": [
    "loving"
  ],
  "gilnru": [
    "luring",
    "ruling"
  ],
  "gilns": [
    "sling"
  ],
  "gilnss": [
    "slings"
  ],
  "gilnt": [
    "glint"
  ],
  "gilnty": [
    "tingly"
  ],
  "gilny": [
    "lying"
  ],
  "giloo": [
    "igloo"
  ],
  "gilr": [
    "girl"
  ],
  "gilrs": [
    "girls"
  ],
  "gilrsy": [
    "grisly"
  ],
  "gilry": [
    "girly"
  ],
  "giltu": [
    "guilt"
  ],
  "giltuy": [
    "guilty"
  ],
  "gimnno": [
    "mignon"
  ],
  "gimnop": [
    "moping"
  ],
  "gimnov": [
    "moving"
  ],
  "gimnow": [
    "mowing"
  ],
  "gimnsu": [
    "musing"
  ],
  "gimosz": [
    "gizmos"
  ],
  "gimoz": [
    "gizmo"
  ],
  "gimp": [
    "gimp"
  ],
  "gimr": [
    "grim"
  ],
  "gin": [
    "gin"
  ],
  "ginnos": [
    "nosing"
  ],
  "ginnot": [
    "noting"
  ],
  "ginnow": [
    "owning"
  ],
  "ginnoz": [
    "zoning"
  ],
  "ginntu": [
    "tuning"
  ],
  "ginny": [
    "ginny"
  ],
  "ginoow": [
    "wooing"
  ],
  "ginooz": [
    "oozing"
  ],
  "ginopr": [
    "poring"
  ],
  "ginops": [
    "posing"
  ],
  "ginor": [
    "groin"
  ],
  "ginors": [
    "signor"
  ],
  "ginorv": [
    "roving"
  ],
  "ginorw": [
    "rowing"
  ],
  "ginosw": [
    "sowing"
  ],
  "ginott": [
    "toting"
  ],
  "ginotu": [
    "outing"
  ],
  "ginotv": [
    "voting"
  ],
  "ginoty": [
    "toying"
  ],
  "ginow": [
    "owing"
  ],
  "ginp": [
    "ping"
  ],
  "ginppu": [
    "upping"
  ],
  "ginprs": [
    "spring"
  ],
  "ginpry": [
    "prying"
  ],
  "ginpsy": [
    "spying"
  ],
  "ginpty": [
    "typing"
  ],
  "ginr": [
    "grin",
    "ring"
  ],
  "ginrs": [
    "grins",
    "rings"
  ],
  "ginrst": [
    "string"
  ],
  "ginrty": [
    "trying"
  ],
  "ginrw": [
    "wring"
  ],
  "gins": [
    "sign",
    "sing"
  ],
  "ginss": [
    "signs",
    "sings"
  ],
  "ginsst": [
    "stings"
  ],
  "ginssw": [
    "swings"
  ],
  "ginst": [
    "sting"
  ],
  "ginsty": [
    "stingy"
  ],
  "ginsu": [
    "suing",
    "using"
  ],
  "ginsw": [
    "swing",
    "wings"
  ],
  "gint": [
    "ting"
  ],
  "ginty": [
    "tying"
  ],
  "ginvy": [
    "vying"
  ],
  "ginw": [
    "wing"
  ],
  "ginz": [
    "zing"
  ],
  "giopss": [
    "gossip"
  ],
  "giorr": [
    "rigor"
  ],
  "giorv": [
    "vigor"
  ],
  "gioy": [
    "yogi"
  ],
  "gip": [
    "pig"
  ],
  "gipr": [
    "grip"
  ],
  "giprs": [
    "grips"
  ],
  "gips": [
    "pigs"
  ],
  "gipsty": [
    "pigsty"
  ],
  "gir": [
    "rig"
  ],
  "girs": [
    "rigs"
  ],
  "girst": [
    "grits"
  ],
  "girt": [
    "trig"
  ],
  "gist": [
    "gist"
  ],
  "gistw": [
    "twigs"
  ],
  "gisw": [
    "swig",
    "wigs"
  ],
  "git": [
    "git"
  ],
  "gitw": [
    "twig"
  ],
  "giv": [
    "vig"
  ],
  "giw": [
    "wig"
  ],
  "giz": [
    "zig"
  ],
  "gjo": [
    "jog"
  ],
  "gjsu": [
    "jugs"
  ],
  "gju": [
    "jug"
  ],
  "gknu": [
    "gunk"
  ],
  "glloy": [
    "golly"
  ],
  "gllu": [
    "gull"
  ],
  "glmoo": [
    "gloom"
  ],
  "glmooy": [
    "gloomy"
  ],
  "glmu": [
    "glum"
  ],
  "glno": [
    "long"
  ],
  "glnos": [
    "longs"
  ],
  "glnpuu": [
    "unplug"
  ],
  "glnsu": [
    "lungs",
    "slung"
  ],
  "glnu": [
    "lung"
  ],
  "glo": [
    "log"
  ],
  "gloo": [
    "logo"
  ],
  "glop": [
    "glop"
  ],
  "glorw": [
    "growl"
  ],
  "glory": [
    "glory"
  ],
  "glos": [
    "logs"
  ],
  "gloss": [
    "gloss"
  ],
  "glossy": [
    "glossy"
  ],
  "glow": [
    "glow"
  ],
  "glpsu": [
    "plugs"
  ],
  "glpu": [
    "plug"
  ],
  "glssu": [
    "slugs"
  ],
  "glsu": [
    "slug"
  ],
  "glu": [
    "lug"
  ],
  "gluy": [
    "ugly"
  ],
  "gmoor": [
    "groom"
  ],
  "gmoors": [
    "grooms"
  ],
  "gmos": [
    "smog"
  ],
  "gmpruy": [
    "grumpy"
  ],
  "gmpyy": [
    "pygmy"
  ],
  "gmsu": [
    "gums",
    "mugs",
    "smug"
  ],
  "gmsy": [
    "gyms"
  ],
  "gmu": [
    "gum",
    "mug"
  ],
  "gmy": [
    "gym"
  ],
  "gnnsuu": [
    "unsung"
  ],
  "gnoo": [
    "goon"
  ],
  "gnoos": [
    "goons"
  ],
  "gnooz": [
    "gonzo"
  ],
  "gnop": [
    "pong"
  ],
  "gnorst": [
    "strong"
  ],
  "gnorsw": [
    "wrongs"
  ],
  "gnorw": [
    "grown",
    "wrong"
  ],
  "gnos": [
    "song"
  ],
  "gnoss": [
    "songs"
  ],
  "gnost": [
    "tongs"
  ],
  "gnosw": [
    "gowns"
  ],
  "gnot": [
    "tong"
  ],
  "gnouy": [
    "young"
  ],
  "gnow": [
    "gown"
  ],
  "gnprsu": [
    "sprung"
  ],
  "gnrstu": [
    "strung"
  ],
  "gnrtu": [
    "grunt"
  ],
  "gnru": [
    "rung"
  ],
  "gnruw": [
    "wrung"
  ],
  "gnstu": [
    "stung"
  ],
  "gnsu": [
    "guns",
    "snug",
    "sung"
  ],
  "gnsuw": [
    "swung"
  ],
  "gntu": [
    "tung"
  ],
  "gnu": [
    "gun"
  ],
  "goo": [
    "goo"
  ],
  "goopy": [
    "goopy"
  ],
  "goorvy": [
    "groovy"
  ],
  "goprsu": [
    "groups"
  ],
  "gopru": [
    "group"
  ],
  "gor": [
    "gor"
  ],
  "gorss": [
    "gross"
  ],
  "gorsw": [
    "grows"
  ],
  "gortuy": [
    "yogurt"
  ],
  "gorw": [
    "grow"
  ],
  "gory": [
    "gory",
    "orgy"
  ],
  "gostu": [
    "gusto"
  ],
  "got": [
    "got"
  ],
  "gotu": [
    "gout"
  ],
  "gpsyy": [
    "gypsy"
  ],
  "gpu": [
    "pug"
  ],
  "gru": [
    "rug"
  ],
  "gruu": [
    "guru"
  ],
  "gstu": [
    "guts"
  ],
  "gsuy": [
    "guys"
  ],
  "gtu": [
    "gut",
    "tug"
  ],
  "guy": [
    "guy"
  ],
  "hhmpu": [
    "humph"
  ],
  "hhmrty": [
    "rhythm"
  ],
  "hhnu": [
    "hunh"
  ],
  "hhoosw": [
    "whoosh"
  ],
  "hhs": [
    "shh"
  ],
  "hhssu": [
    "shush"
  ],
  "hhsu": [
    "hush"
  ],
  "hhu": [
    "huh"
  ],
  "hiintw": [
    "within"
  ],
  "hiknrs": [
    "shrink"
  ],
  "hiknst": [
    "thinks"
  ],
  "hiknt": [
    "think"
  ],
  "hiksw": [
    "whisk"
  ],
  "hikswy": [
    "whisky"
  ],
  "hill": [
    "hill"
  ],
  "hillpu": [
    "uphill"
  ],
  "hillrt": [
    "thrill"
  ],
  "hills": [
    "hills",
    "shill"
  ],
  "hilmoy": [
    "homily"
  ],
  "hilnty": [
    "thinly"
  ],
  "hilops": [
    "polish"
  ],
  "hilrw": [
    "whirl"
  ],
  "hilstw": [
    "whilst"
  ],
  "hilt": [
    "hilt"
  ],
  "him": [
    "him"
  ],
  "himmsy": [
    "shimmy"
  ],
  "himprs": [
    "shrimp"
  ],
  "himrt": [
    "mirth"
  ],
  "himst": [
    "smith"
  ],
  "himsw": [
    "whims"
  ],
  "himswy": [
    "whimsy"
  ],
  "himw": [
    "whim"
  ],
  "hinnt": [
    "ninth"
  ],
  "hinor": [
    "rhino"
  ],
  "hinpsu": [
    "punish"
  ],
  "hinpsx": [
    "sphinx"
  ],
  "hins": [
    "shin"
  ],
  "hinss": [
    "shins"
  ],
  "hinst": [
    "hints",
    "thins"
  ],
  "hinsy": [
    "shiny"
  ],
  "hint": [
    "hint",
    "thin"
  ],
  "hinwy": [
    "whiny"
  ],
  "hiopp": [
    "hippo"
  ],
  "hiopps": [
    "hippos"
  ],
  "hiost": [
    "hoist"
  ],
  "hip": [
    "hip",
    "phi"
  ],
  "hips": [
    "hips",
    "ship"
  ],
  "hipss": [
    "ships"
  ],
  "hipty": [
    "pithy"
  ],
  "hipw": [
    "whip"
  ],
  "hiqssu": [
    "squish"
  ],
  "hirsst": [
    "shirts"
  ],
  "hirst": [
    "shirt"
  ],
  "hirstt": [
    "thirst"
  ],
  "hirtty": [
    "thirty"
  ],
  "his": [
    "his"
  ],
  "hiss": [
    "hiss"
  ],
  "hissu": [
    "sushi"
  ],
  "hist": [
    "hits",
    "this"
  ],
  "histx": [
    "sixth"
  ],
  "hisv": [
    "shiv"
  ],
  "hisw": [
    "wish"
  ],
  "hit": [
    "hit"
  ],
  "hitw": [
    "whit",
    "with"
  ],
  "hiwz": [
    "whiz"
  ],
  "hjnnoy": [
    "johnny"
  ],
  "hjno": [
    "john"
  ],
  "hjnos": [
    "johns"
  ],
  "hjos": [
    "josh"
  ],
  "hklu": [
    "hulk"
  ],
  "hkno": [
    "honk"
  ],
  "hknoou": [
    "unhook"
  ],
  "hknos": [
    "honks"
  ],
  "hknoy": [
    "honky"
  ],
  "hknrsu": [
    "shrunk"
  ],
  "hknsu": [
    "hunks"
  ],
  "hknu": [
    "hunk"
  ],
  "hknuy": [
    "hunky"
  ],
  "hkoo": [
    "hook"
  ],
  "hkoopu": [
    "hookup"
  ],
  "hkoos": [
    "hooks",
    "shook"
  ],
  "hkooy": [
    "hooky"
  ],
  "hkssu": [
    "husks"
  ],
  "hksu": [
    "husk"
  ],
  "hksuy": [
    "husky"
  ],
  "hlloow": [
    "hollow"
  ],
  "hllou": [
    "hullo"
  ],
  "hllowy": [
    "wholly"
  ],
  "hlloy": [
    "holly"
  ],
  "hllu": [
    "hull"
  ],
  "hlmpy": [
    "lymph"
  ],
  "hlnouy": [
    "unholy"
  ],
  "hloruy": [
    "hourly"
  ],
  "hlot": [
    "holt"
  ],
  "hlow": [
    "howl"
  ],
  "hloy": [
    "holy"
  ],
  "hlpsu": [
    "plush"
  ],
  "hlru": [
    "hurl"
  ],
  "hlssu": [
    "slush"
  ],
  "hlstuy": [
    "thusly"
  ],
  "hlsu": [
    "lush"
  ],
  "hmm": [
    "hmm"
  ],
  "hmmsuu": [
    "hummus"
  ],
  "hmnopy": [
    "nympho"
  ],
  "hmnost": [
    "months"
  ],
  "hmnot": [
    "month"
  ],
  "hmnpy": [
    "nymph"
  ],
  "hmnsy": [
    "hymns"
  ],
  "hmny": [
    "hymn"
  ],
  "hmo": [
    "ohm"
  ],
  "hmoo": [
    "homo"
  ],
  "hmoop": [
    "oomph"
  ],
  "hmoos": [
    "homos"
  ],
  "hmoost": [
    "smooth"
  ],
  "hmopr": [
    "morph"
  ],
  "hmoru": [
    "humor"
  ],
  "hmoruu": [
    "humour"
  ],
  "hmost": [
    "moths"
  ],
  "hmostu": [
    "mouths"
  ],
  "hmot": [
    "moth"
  ],
  "hmotu": [
    "mouth"
  ],
  "hmotuy": [
    "mouthy"
  ],
  "hmow": [
    "whom"
  ],
  "hmpruy": [
    "murphy"
  ],
  "hmpsu": [
    "humps"
  ],
  "hmptu": [
    "thump"
  ],
  "hmpu": [
    "hump"
  ],
  "hmsty": [
    "myths"
  ],
  "hmsu": [
    "hums",
    "mush"
  ],
  "hmsuu": [
    "humus"
  ],
  "hmsuy": [
    "mushy"
  ],
  "hmty": [
    "myth"
  ],
  "hmu": [
    "hum"
  ],
  "hno": [
    "hon",
    "noh"
  ],
  "hnoor": [
    "honor"
  ],
  "hnoors": [
    "honors"
  ],
  "hnooru": [
    "honour"
  ],
  "hnopsy": [
    "syphon"
  ],
  "hnopy": [
    "phony"
  ],
  "hnor": [
    "horn"
  ],
  "hnors": [
    "horns"
  ],
  "hnorst": [
    "thorns"
  ],
  "hnort": [
    "north",
    "thorn"
  ],
  "hnortw": [
    "thrown"
  ],
  "hnos": [
    "nosh"
  ],
  "hnosw": [
    "shown"
  ],
  "hnstu": [
    "shunt"
  ],
  "hnsu": [
    "huns",
    "shun"
  ],
  "hntu": [
    "hunt"
  ],
  "hnu": [
    "hun"
  ],
  "hoo": [
    "oho",
    "ooh"
  ],
  "hoop": [
    "hoop",
    "pooh"
  ],
  "hoops": [
    "hoops"
  ],
  "hoopst": [
    "photos"
  ],
  "hoopsw": [
    "whoops"
  ],
  "hoopt": [
    "photo"
  ],
  "hoopw": [
    "whoop"
  ],
  "hoorrr": [
    "horror"
  ],
  "hoos": [
    "shoo"
  ],
  "hoosst": [
    "shoots"
  ],
  "hoost": [
    "shoot"
  ],
  "hoot": [
    "hoot"
  ],
  "hoott": [
    "tooth"
  ],
  "hop": [
    "hop"
  ],
  "hoppy": [
    "hoppy"
  ],
  "hoprty": [
    "trophy"
  ],
  "hops": [
    "hops",
    "shop"
  ],
  "hopss": [
    "shops"
  ],
  "hopstu": [
    "upshot"
  ],
  "hopy": [
    "hypo"
  ],
  "hoqtu": [
    "quoth"
  ],
  "horsst": [
    "shorts"
  ],
  "horst": [
    "short"
  ],
  "horstw": [
    "throws"
  ],
  "horsty": [
    "shorty"
  ],
  "horsu": [
    "hours"
  ],
  "hortw": [
    "throw",
    "worth"
  ],
  "hortwy": [
    "worthy"
  ],
  "horu": [
    "hour"
  ],
  "hosst": [
    "hosts",
    "shots"
  ],
  "hosstu": [
    "shouts"
  ],
  "hossw": [
    "shows"
  ],
  "host": [
    "host",
    "hots",
    "shot",
    "tosh"
  ],
  "hostu": [
    "shout",
    "south"
  ],
  "hostuy": [
    "youths"
  ],
  "hosw": [
    "hows",
    "show"
  ],
  "hoswy": [
    "showy"
  ],
  "hot": [
    "hot",
    "tho"
  ],
  "hotu": [
    "thou"
  ],
  "hotuy": [
    "youth"
  ],
  "how": [
    "how",
    "who"
  ],
  "hoy": [
    "hoy"
  ],
  "hpsu": [
    "push"
  ],
  "hpsuy": [
    "pushy"
  ],
  "hpu": [
    "hup"
  ],
  "hrruy": [
    "hurry"
  ],
  "hrsttu": [
    "thrust",
    "truths"
  ],
  "hrstu": [
    "hurst",
    "hurts"
  ],
  "hrsu": [
    "rush"
  ],
  "hrttu": [
    "truth"
  ],
  "hrtu": [
    "hurt",
    "ruth",
    "thru"
  ],
  "hsstu": [
    "shuts"
  ],
  "hssuy": [
    "hussy"
  ],
  "hstu": [
    "huts",
    "shut",
    "thus",
    "tush"
  ],
  "hstuy": [
    "tushy"
  ],
  "hswy": [
    "whys"
  ],
  "hsy": [
    "shy"
  ],
  "htu": [
    "hut"
  ],
  "hty": [
    "thy"
  ],
  "hwy": [
    "why"
  ],
  "iijmny": [
    "jiminy"
  ],
  "iikt": [
    "tiki"
  ],
  "iikw": [
    "kiwi"
  ],
  "iilmst": [
    "limits"
  ],
  "iilmt": [
    "limit"
  ],
  "iilnov": [
    "violin"
  ],
  "iimn": [
    "mini"
  ],
  "iimnno": [
    "minion"
  ],
  "iimsss": [
    "missis"
  ],
  "iinosv": [
    "vision"
  ],
  "iinsst": [
    "insist"
  ],
  "iinttw": [
    "nitwit"
  ],
  "iiprst": [
    "spirit"
  ],
  "iirs": [
    "iris"
  ],
  "iisstv": [
    "visits"
  ],
  "iistv": [
    "visit"
  ],
  "ijll": [
    "jill"
  ],
  "ijmmy": [
    "jimmy"
  ],
  "ijn": [
    "jin"
  ],
  "ijno": [
    "join"
  ],
  "ijnoru": [
    "junior"
  ],
  "ijnos": [
    "joins"
  ],
  "ijnost": [
    "joints"
  ],
  "ijnot": [
    "joint"
  ],
  "ijnruy": [
    "injury"
  ],
  "ijnx": [
    "jinx"
  ],
  "ikkn": [
    "kink"
  ],
  "ikkny": [
    "kinky"
  ],
  "ikkos": [
    "kiosk"
  ],
  "ikkr": [
    "kirk"
  ],
  "ikl": [
    "ilk"
  ],
  "ikll": [
    "kill"
  ],
  "iklls": [
    "kills",
    "skill"
  ],
  "ikllss": [
    "skills"
  ],
  "iklm": [
    "milk"
  ],
  "iklmy": [
    "milky"
  ],
  "ikln": [
    "kiln",
    "link"
  ],
  "iklnpu": [
    "uplink"
  ],
  "iklns": [
    "links",
    "slink"
  ],
  "iklnsy": [
    "slinky"
  ],
  "iklo": [
    "kilo"
  ],
  "iklos": [
    "kilos"
  ],
  "ikls": [
    "silk"
  ],
  "iklss": [
    "silks"
  ],
  "iklsy": [
    "silky"
  ],
  "iklt": [
    "kilt"
  ],
  "ikmn": [
    "mink"
  ],
  "ikmnoo": [
    "kimono"
  ],
  "ikmns": [
    "minks"
  ],
  "ikmps": [
    "skimp"
  ],
  "ikmpsy": [
    "skimpy"
  ],
  "ikmrs": [
    "smirk"
  ],
  "ikms": [
    "skim"
  ],
  "ikn": [
    "ink",
    "kin"
  ],
  "iknnsy": [
    "skinny"
  ],
  "ikno": [
    "oink"
  ],
  "iknp": [
    "pink"
  ],
  "iknps": [
    "pinks"
  ],
  "iknpy": [
    "pinky"
  ],
  "iknr": [
    "rink"
  ],
  "ikns": [
    "kins",
    "sink",
    "skin"
  ],
  "iknss": [
    "sinks",
    "skins"
  ],
  "iknsst": [
    "stinks"
  ],
  "iknst": [
    "stink"
  ],
  "iknsty": [
    "stinky"
  ],
  "iknsw": [
    "winks"
  ],
  "iknt": [
    "knit"
  ],
  "iknw": [
    "wink"
  ],
  "iko": [
    "koi"
  ],
  "ikp": [
    "kip"
  ],
  "ikps": [
    "skip"
  ],
  "ikpsy": [
    "spiky"
  ],
  "ikqrsu": [
    "quirks"
  ],
  "ikqru": [
    "quirk"
  ],
  "ikqruy": [
    "quirky"
  ],
  "ikrs": [
    "kris",
    "risk"
  ],
  "ikrss": [
    "risks"
  ],
  "ikrsst": [
    "skirts"
  ],
  "ikrst": [
    "skirt"
  ],
  "ikrsy": [
    "risky"
  ],
  "iks": [
    "ski"
  ],
  "ikss": [
    "kiss",
    "skis"
  ],
  "ikssy": [
    "kissy"
  ],
  "ikst": [
    "skit"
  ],
  "ikt": [
    "kit"
  ],
  "iktty": [
    "kitty"
  ],
  "ill": [
    "ill"
  ],
  "illm": [
    "mill"
  ],
  "illms": [
    "mills"
  ],
  "illopw": [
    "pillow"
  ],
  "illoww": [
    "willow"
  ],
  "illp": [
    "pill"
  ],
  "illps": [
    "pills",
    "spill"
  ],
  "illpss": [
    "spills"
  ],
  "ills": [
    "ills",
    "sill"
  ],
  "illsst": [
    "stills"
  ],
  "illst": [
    "still"
  ],
  "illsw": [
    "swill",
    "wills"
  ],
  "illsy": [
    "silly"
  ],
  "illt": [
    "till"
  ],
  "illv": [
    "vill"
  ],
  "illw": [
    "will"
  ],
  "illwy": [
    "willy"
  ],
  "illy": [
    "lily"
  ],
  "ilm": [
    "mil"
  ],
  "ilmnou": [
    "moulin"
  ],
  "ilmo": [
    "limo",
    "milo",
    "moil"
  ],
  "ilmos": [
    "limos"
  ],
  "ilmp": [
    "limp"
  ],
  "ilmppy": [
    "pimply"
  ],
  "ilmps": [
    "limps"
  ],
  "ilmpsy": [
    "simply"
  ],
  "ilmpy": [
    "imply"
  ],
  "ilms": [
    "slim"
  ],
  "ilmsy": [
    "slimy"
  ],
  "ilmt": [
    "milt"
  ],
  "iln": [
    "lin",
    "nil"
  ],
  "ilno": [
    "lion",
    "loin"
  ],
  "ilnoot": [
    "lotion"
  ],
  "ilnos": [
    "lions",
    "loins"
  ],
  "ilnost": [
    "tonsil"
  ],
  "ilnpst": [
    "splint"
  ],
  "ilnstu": [
    "insult"
  ],
  "ilnt": [
    "lint"
  ],
  "ilntu": [
    "until"
  ],
  "ilnvy": [
    "vinyl"
  ],
  "ilo": [
    "oil"
  ],
  "iloop": [
    "polio"
  ],
  "ilops": [
    "spoil"
  ],
  "ilopss": [
    "spoils"
  ],
  "ilopst": [
    "pilots",
    "pistol"
  ],
  "ilopt": [
    "pilot"
  ],
  "iloqru": [
    "liquor"
  ],
  "ilos": [
    "oils",
    "soil"
  ],
  "ilosu": [
    "louis"
  ],
  "iloy": [
    "oily"
  ],
  "ilp": [
    "lip"
  ],
  "ilppsu": [
    "pupils"
  ],
  "ilpptu": [
    "pulpit"
  ],
  "ilppu": [
    "pupil"
  ],
  "ilppy": [
    "lippy"
  ],
  "ilps": [
    "lips",
    "slip"
  ],
  "ilpss": [
    "slips"
  ],
  "ilpsst": [
    "splits"
  ],
  "ilpst": [
    "split"
  ],
  "ilptu": [
    "tulip"
  ],
  "ilqstu": [
    "quilts"
  ],
  "ilqtu": [
    "quilt"
  ],
  "ilrsw": [
    "swirl"
  ],
  "ilrswy": [
    "swirly"
  ],
  "ilrtw": [
    "twirl"
  ],
  "ils": [
    "lis"
  ],
  "ilsst": [
    "lists"
  ],
  "ilsstt": [
    "stilts"
  ],
  "ilst": [
    "list",
    "slit"
  ],
  "ilt": [
    "lit",
    "til"
  ],
  "iltt": [
    "tilt"
  ],
  "iltw": [
    "wilt"
  ],
  "ilwy": [
    "wily"
  ],
  "immstu": [
    "summit"
  ],
  "imnnow": [
    "minnow"
  ],
  "imnoot": [
    "motion"
  ],
  "imnor": [
    "minor"
  ],
  "imnors": [
    "minors"
  ],
  "imnosy": [
    "simony"
  ],
  "imnst": [
    "mints"
  ],
  "imnsu": [
    "minus"
  ],
  "imnt": [
    "mint"
  ],
  "imntuy": [
    "mutiny"
  ],
  "imnty": [
    "minty"
  ],
  "imopr": [
    "primo"
  ],
  "imoprt": [
    "import"
  ],
  "imopu": [
    "opium"
  ],
  "imorrr": [
    "mirror"
  ],
  "imorrs": [
    "morris"
  ],
  "imost": [
    "moist"
  ],
  "imotv": [
    "vomit"
  ],
  "imp": [
    "imp"
  ],
  "impp": [
    "pimp"
  ],
  "impr": [
    "prim"
  ],
  "imps": [
    "simp"
  ],
  "impsw": [
    "wimps"
  ],
  "impux": [
    "mixup"
  ],
  "impw": [
    "wimp"
  ],
  "impwy": [
    "wimpy"
  ],
  "imqrsu": [
    "squirm"
  ],
  "imr": [
    "rim"
  ],
  "imrt": [
    "trim"
  ],
  "ims": [
    "mis",
    "sim"
  ],
  "imss": [
    "miss",
    "sims"
  ],
  "imsssu": [
    "missus"
  ],
  "imssw": [
    "swims"
  ],
  "imssy": [
    "missy"
  ],
  "imst": [
    "mist"
  ],
  "imstt": [
    "mitts"
  ],
  "imsty": [
    "misty"
  ],
  "imsw": [
    "swim"
  ],
  "imtt": [
    "mitt"
  ],
  "imx": [
    "mix"
  ],
  "inn": [
    "inn"
  ],
  "innny": [
    "ninny"
  ],
  "innoo": [
    "onion"
  ],
  "innoos": [
    "onions"
  ],
  "innoot": [
    "notion"
  ],
  "innosu": [
    "unions"
  ],
  "innou": [
    "union"
  ],
  "ino": [
    "ion"
  ],
  "inoops": [
    "poison"
  ],
  "inoopt": [
    "option",
    "potion"
  ],
  "inoprs": [
    "prison"
  ],
  "inopst": [
    "piston",
    "points"
  ],
  "inopt": [
    "pinot",
    "pinto",
    "point"
  ],
  "inopty": [
    "pointy"
  ],
  "inor": [
    "iron",
    "noir"
  ],
  "inors": [
    "irons",
    "rosin"
  ],
  "inorst": [
    "intros"
  ],
  "inort": [
    "intro"
  ],
  "inory": [
    "irony"
  ],
  "inos": [
    "ions"
  ],
  "inostx": [
    "toxins"
  ],
  "inosw": [
    "winos"
  ],
  "inosy": [
    "noisy"
  ],
  "inot": [
    "into"
  ],
  "inotx": [
    "toxin"
  ],
  "inov": [
    "vino"
  ],
  "inp": [
    "nip",
    "pin"
  ],
  "inppy": [
    "nippy"
  ],
  "inprst": [
    "prints",
    "sprint"
  ],
  "inprt": [
    "print"
  ],
  "inps": [
    "pins",
    "snip",
    "spin"
  ],
  "inpss": [
    "spins"
  ],
  "inpst": [
    "pints"
  ],
  "inpsy": [
    "spiny"
  ],
  "inpt": [
    "pint"
  ],
  "inptu": [
    "input"
  ],
  "inpuz": [
    "unzip"
  ],
  "inqstu": [
    "quints"
  ],
  "inr": [
    "rin"
  ],
  "inrsu": [
    "ruins"
  ],
  "inru": [
    "ruin"
  ],
  "ins": [
    "ins",
    "sin"
  ],
  "inss": [
    "sins"
  ],
  "inssu": [
    "sinus"
  ],
  "inst": [
    "snit",
    "tins"
  ],
  "instt": [
    "stint"
  ],
  "instu": [
    "units"
  ],
  "instw": [
    "twins"
  ],
  "insw": [
    "wins"
  ],
  "int": [
    "tin"
  ],
  "intty": [
    "nitty"
  ],
  "intu": [
    "unit"
  ],
  "intuy": [
    "unity"
  ],
  "intw": [
    "twin"
  ],
  "inty": [
    "tiny"
  ],
  "inw": [
    "win"
  ],
  "inx": [
    "nix"
  ],
  "iny": [
    "yin"
  ],
  "iop": [
    "poi"
  ],
  "ioprr": [
    "prior"
  ],
  "ioprrs": [
    "priors"
  ],
  "iopsu": [
    "pious"
  ],
  "ioptv": [
    "pivot"
  ],
  "iorst": [
    "riots"
  ],
  "iorstu": [
    "suitor"
  ],
  "iorsv": [
    "visor"
  ],
  "iort": [
    "riot",
    "trio"
  ],
  "iorvy": [
    "ivory"
  ],
  "iottuw": [
    "outwit"
  ],
  "ipp": [
    "pip"
  ],
  "ipprty": [
    "trippy"
  ],
  "ipptuy": [
    "uppity"
  ],
  "ippty": [
    "tippy"
  ],
  "ippyz": [
    "zippy"
  ],
  "ipr": [
    "rip"
  ],
  "iprs": [
    "rips"
  ],
  "iprss": [
    "priss"
  ],
  "iprsst": [
    "strips"
  ],
  "iprssy": [
    "prissy"
  ],
  "iprst": [
    "strip",
    "trips"
  ],
  "iprt": [
    "trip"
  ],
  "iprtuy": [
    "purity"
  ],
  "iprvy": [
    "privy"
  ],
  "ips": [
    "pis",
    "psi",
    "sip"
  ],
  "ipss": [
    "piss"
  ],
  "ipsst": [
    "spits"
  ],
  "ipst": [
    "pits",
    "spit",
    "tips"
  ],
  "ipstty": [
    "typist"
  ],
  "ipsty": [
    "tipsy"
  ],
  "ipstz": [
    "spitz"
  ],
  "ipt": [
    "pit",
    "tip"
  ],
  "ipty": [
    "pity"
  ],
  "ipz": [
    "zip"
  ],
  "iqrstu": [
    "squirt"
  ],
  "iqstu": [
    "quits"
  ],
  "iqtu": [
    "quit"
  ],
  "iquz": [
    "quiz"
  ],
  "irs": [
    "sir",
    "sri"
  ],
  "irss": [
    "sirs"
  ],
  "irsst": [
    "stirs"
  ],
  "irsstw": [
    "wrists"
  ],
  "irst": [
    "stir"
  ],
  "irstw": [
    "wrist"
  ],
  "irsuv": [
    "virus"
  ],
  "irtw": [
    "writ"
  ],
  "irtyz": [
    "ritzy"
  ],
  "irtz": [
    "ritz"
  ],
  "iss": [
    "sis"
  ],
  "isssw": [
    "swiss"
  ],
  "isssy": [
    "sissy"
  ],
  "isst": [
    "sits"
  ],
  "issttw": [
    "twists"
  ],
  "isstu": [
    "suits"
  ],
  "ist": [
    "its",
    "sit",
    "tis"
  ],
  "istt": [
    "tits"
  ],
  "isttw": [
    "twist",
    "twits"
  ],
  "isttwy": [
    "twisty"
  ],
  "istu": [
    "suit"
  ],
  "istw": [
    "wits"
  ],
  "istxy": [
    "sixty"
  ],
  "istz": [
    "zits"
  ],
  "isv": [
    "vis"
  ],
  "isx": [
    "six"
  ],
  "itt": [
    "tit"
  ],
  "itttu": [
    "tutti"
  ],
  "ittty": [
    "titty"
  ],
  "ittw": [
    "twit"
  ],
  "ittwy": [
    "witty"
  ],
  "itw": [
    "wit"
  ],
  "ityzz": [
    "tizzy"
  ],
  "itz": [
    "zit"
  ],
  "ivy": [
    "ivy"
  ],
  "jknu": [
    "junk"
  ],
  "jknuy": [
    "junky"
  ],
  "jlloy": [
    "jolly"
  ],
  "jlot": [
    "jolt"
  ],
  "jmoo": [
    "mojo"
  ],
  "jmpsu": [
    "jumps"
  ],
  "jmpu": [
    "jump"
  ],
  "jmpuy": [
    "jumpy"
  ],
  "joosuy": [
    "joyous"
  ],
  "jorrsu": [
    "jurors"
  ],
  "jorru": [
    "juror"
  ],
  "jostu": [
    "joust"
  ],
  "josy": [
    "joys"
  ],
  "jot": [
    "jot"
  ],
  "joy": [
    "joy"
  ],
  "jruy": [
    "jury"
  ],
  "jstu": [
    "just"
  ],
  "jsu": [
    "jus"
  ],
  "jtu": [
    "jut"
  ],
  "kklsu": [
    "skulk"
  ],
  "kknsu": [
    "skunk"
  ],
  "kkoos": [
    "kooks"
  ],
  "kkooy": [
    "kooky"
  ],
  "kllno": [
    "knoll"
  ],
  "kllssu": [
    "skulls"
  ],
  "kllsu": [
    "skull"
  ],
  "kloo": [
    "look"
  ],
  "kloos": [
    "looks"
  ],
  "kloy": [
    "yolk"
  ],
  "klrsu": [
    "lurks"
  ],
  "klru": [
    "lurk"
  ],
  "klsu": [
    "sulk"
  ],
  "kltuyz": [
    "klutzy"
  ],
  "kltuz": [
    "klutz"
  ],
  "kmno": [
    "monk"
  ],
  "kmnos": [
    "monks"
  ],
  "kmosy": [
    "smoky"
  ],
  "kmruy": [
    "murky"
  ],
  "knnow": [
    "known"
  ],
  "knoos": [
    "snook"
  ],
  "knost": [
    "knots"
  ],
  "knosw": [
    "knows"
  ],
  "knot": [
    "knot"
  ],
  "know": [
    "know"
  ],
  "knowy": [
    "wonky"
  ],
  "knpsu": [
    "punks",
    "spunk"
  ],
  "knpsuy": [
    "spunky"
  ],
  "knpu": [
    "punk"
  ],
  "knpuy": [
    "punky"
  ],
  "knrstu": [
    "trunks"
  ],
  "knrtu": [
    "trunk"
  ],
  "knstu": [
    "stunk"
  ],
  "knsu": [
    "sunk"
  ],
  "koops": [
    "spook"
  ],
  "koopss": [
    "spooks"
  ],
  "koopsy": [
    "spooky"
  ],
  "koor": [
    "rook"
  ],
  "koos": [
    "sook"
  ],
  "koot": [
    "took"
  ],
  "kopr": [
    "pork"
  ],
  "kopruw": [
    "workup"
  ],
  "kopry": [
    "porky"
  ],
  "korst": [
    "stork"
  ],
  "korsw": [
    "works"
  ],
  "korw": [
    "work"
  ],
  "koss": [
    "koss"
  ],
  "kow": [
    "wok"
  ],
  "krstu": [
    "turks"
  ],
  "krtu": [
    "turk"
  ],
  "kst": [
    "tsk"
  ],
  "kstu": [
    "tusk"
  ],
  "ksy": [
    "sky"
  ],
  "kuy": [
    "yuk"
  ],
  "llloy": [
    "lolly"
  ],
  "lllu": [
    "lull"
  ],
  "llmo": [
    "moll"
  ],
  "llmoy": [
    "molly"
  ],
  "llnu": [
    "null"
  ],
  "lloowy": [
    "woolly"
  ],
  "llop": [
    "poll"
  ],
  "llops": [
    "polls"
  ],
  "llor": [
    "roll"
  ],
  "llors": [
    "rolls"
  ],
  "llorst": [
    "stroll",
    "trolls"
  ],
  "llort": [
    "troll"
  ],
  "llost": [
    "tolls"
  ],
  "lloswy": [
    "slowly"
  ],
  "llot": [
    "toll"
  ],
  "llowy": [
    "lowly"
  ],
  "llpsu": [
    "pulls"
  ],
  "llpu": [
    "pull"
  ],
  "lluu": [
    "lulu"
  ],
  "lmo": [
    "mol"
  ],
  "lmoo": [
    "loom"
  ],
  "lmoot": [
    "molto"
  ],
  "lmosty": [
    "mostly"
  ],
  "lmoy": [
    "moly"
  ],
  "lmppu": [
    "plump"
  ],
  "lmpsu": [
    "lumps",
    "plums"
  ],
  "lmpu": [
    "lump",
    "plum"
  ],
  "lmpuy": [
    "lumpy"
  ],
  "lmsu": [
    "slum"
  ],
  "lmu": [
    "lum"
  ],
  "lnnosy": [
    "nylons"
  ],
  "lnnoy": [
    "nylon"
  ],
  "lnoo": [
    "loon"
  ],
  "lnoos": [
    "loons"
  ],
  "lnooy": [
    "loony"
  ],
  "lnoy": [
    "only"
  ],
  "lnruuy": [
    "unruly"
  ],
  "lnxy": [
    "lynx"
  ],
  "loo": [
    "loo"
  ],
  "loop": [
    "loop",
    "polo",
    "pool"
  ],
  "loopry": [
    "poorly"
  ],
  "loops": [
    "loops",
    "pools",
    "spool"
  ],
  "loos": [
    "loos",
    "solo"
  ],
  "loosst": [
    "stools"
  ],
  "loost": [
    "stool",
    "tools"
  ],
  "loot": [
    "loot",
    "tool"
  ],
  "loott": [
    "lotto"
  ],
  "loow": [
    "wool"
  ],
  "lop": [
    "lop"
  ],
  "lopp": [
    "plop"
  ],
  "loppsy": [
    "sloppy"
  ],
  "loprw": [
    "prowl"
  ],
  "lops": [
    "slop"
  ],
  "lopst": [
    "plots"
  ],
  "lopt": [
    "plot"
  ],
  "lopw": [
    "plow"
  ],
  "lopy": [
    "ploy",
    "poly"
  ],
  "lorry": [
    "lorry"
  ],
  "los": [
    "sol"
  ],
  "loss": [
    "loss"
  ],
  "losst": [
    "slots"
  ],
  "lossu": [
    "souls"
  ],
  "lost": [
    "lost",
    "lots",
    "slot"
  ],
  "lostv": [
    "volts"
  ],
  "lostyz": [
    "zlotys"
  ],
  "losu": [
    "soul"
  ],
  "losuy": [
    "lousy"
  ],
  "losw": [
    "lows",
    "owls",
    "slow"
  ],
  "lot": [
    "lot"
  ],
  "lotu": [
    "lout"
  ],
  "lotv": [
    "volt"
  ],
  "low": [
    "low",
    "owl"
  ],
  "lox": [
    "lox"
  ],
  "lppsuy": [
    "supply"
  ],
  "lppu": [
    "pulp"
  ],
  "lprsu": [
    "slurp"
  ],
  "lpsu": [
    "plus"
  ],
  "lpsuu": [
    "lupus"
  ],
  "lrstuy": [
    "sultry"
  ],
  "lrsu": [
    "slur"
  ],
  "lrsuy": [
    "surly"
  ],
  "lrtuy": [
    "truly"
  ],
  "lruuxy": [
    "luxury"
  ],
  "lsstu": [
    "lusts",
    "sluts"
  ],
  "lsttuy": [
    "slutty"
  ],
  "lstu": [
    "lust",
    "slut"
  ],
  "lsy": [
    "sly"
  ],
  "ltuz": [
    "lutz"
  ],
  "luv": [
    "luv"
  ],
  "lux": [
    "lux"
  ],
  "mmmoy": [
    "mommy"
  ],
  "mmmuy": [
    "mummy"
  ],
  "mmnosu": [
    "summon"
  ],
  "mmo": [
    "mom"
  ],
  "mmos": [
    "moms"
  ],
  "mmoty": [
    "tommy"
  ],
  "mmpsu": [
    "mumps"
  ],
  "mmrruu": [
    "murmur"
  ],
  "mmruy": [
    "rummy"
  ],
  "mmsu": [
    "mums"
  ],
  "mmtuy": [
    "tummy"
  ],
  "mmu": [
    "mum",
    "umm"
  ],
  "mmuuuu": [
    "muumuu"
  ],
  "mmuyy": [
    "yummy"
  ],
  "mno": [
    "mon"
  ],
  "mnoo": [
    "mono",
    "moon"
  ],
  "mnoor": [
    "moron"
  ],
  "mnoors": [
    "morons"
  ],
  "mnoos": [
    "moons"
  ],
  "mnor": [
    "morn",
    "norm"
  ],
  "mnoru": [
    "mourn"
  ],
  "mnottu": [
    "mutton"
  ],
  "mnotu": [
    "mount"
  ],
  "moo": [
    "moo"
  ],
  "moopr": [
    "promo"
  ],
  "moor": [
    "room"
  ],
  "moorrw": [
    "morrow"
  ],
  "moors": [
    "moors",
    "rooms"
  ],
  "moorst": [
    "motors"
  ],
  "moort": [
    "motor"
  ],
  "moorv": [
    "vroom"
  ],
  "moory": [
    "roomy"
  ],
  "moot": [
    "moot"
  ],
  "moott": [
    "motto"
  ],
  "mooz": [
    "zoom"
  ],
  "mop": [
    "mop",
    "pom"
  ],
  "mopprt": [
    "prompt"
  ],
  "mopr": [
    "prom",
    "romp"
  ],
  "moprs": [
    "proms"
  ],
  "mops": [
    "mops"
  ],
  "mopssu": [
    "possum"
  ],
  "mopst": [
    "stomp"
  ],
  "mor": [
    "mor",
    "rom"
  ],
  "morrsu": [
    "rumors"
  ],
  "morru": [
    "rumor"
  ],
  "morruu": [
    "rumour"
  ],
  "morsst": [
    "storms"
  ],
  "morst": [
    "storm"
  ],
  "morstu": [
    "tumors"
  ],
  "morsty": [
    "stormy"
  ],
  "morsw": [
    "worms"
  ],
  "mort": [
    "mort"
  ],
  "mortu": [
    "tumor"
  ],
  "morw": [
    "worm"
  ],
  "moss": [
    "moss"
  ],
  "most": [
    "most",
    "toms"
  ],
  "mosttu": [
    "utmost"
  ],
  "mosu": [
    "sumo"
  ],
  "mosuy": [
    "mousy"
  ],
  "mot": [
    "tom"
  ],
  "mow": [
    "mow"
  ],
  "moy": [
    "yom"
  ],
  "mppsu": [
    "pumps"
  ],
  "mppu": [
    "pump"
  ],
  "mprstu": [
    "trumps"
  ],
  "mprsuu": [
    "rumpus"
  ],
  "mprtu": [
    "trump"
  ],
  "mpru": [
    "rump"
  ],
  "mpsstu": [
    "stumps"
  ],
  "mpstu": [
    "stump"
  ],
  "mpsu": [
    "sump"
  ],
  "mpu": [
    "ump"
  ],
  "mru": [
    "rum"
  ],
  "mssu": [
    "muss",
    "sums"
  ],
  "mstu": [
    "must"
  ],
  "msu": [
    "sum"
  ],
  "mttu": [
    "mutt"
  ],
  "muy": [
    "yum"
  ],
  "nnoo": [
    "noon"
  ],
  "nnosy": [
    "sonny"
  ],
  "nnou": [
    "noun"
  ],
  "nnruy": [
    "runny"
  ],
  "nnsu": [
    "nuns"
  ],
  "nnsuy": [
    "sunny"
  ],
  "nnu": [
    "nun"
  ],
  "nnwy": [
    "wynn"
  ],
  "noo": [
    "noo"
  ],
  "noopr": [
    "porno"
  ],
  "nooprs": [
    "pornos"
  ],
  "nooprt": [
    "pronto"
  ],
  "noops": [
    "snoop",
    "spoon"
  ],
  "noopss": [
    "spoons"
  ],
  "noopsy": [
    "snoopy"
  ],
  "noos": [
    "soon"
  ],
  "noost": [
    "toons"
  ],
  "noosty": [
    "snooty"
  ],
  "noot": [
    "onto",
    "toon"
  ],
  "nopr": [
    "porn"
  ],
  "noptuw": [
    "uptown"
  ],
  "nopu": [
    "upon"
  ],
  "nopy": [
    "pony"
  ],
  "nor": [
    "nor"
  ],
  "norst": [
    "snort"
  ],
  "norsw": [
    "sworn"
  ],
  "nort": [
    "torn"
  ],
  "nortuu": [
    "outrun"
  ],
  "norw": [
    "worn"
  ],
  "nos": [
    "nos",
    "son"
  ],
  "noss": [
    "sons"
  ],
  "nost": [
    "snot",
    "tons"
  ],
  "nostty": [
    "snotty"
  ],
  "nostu": [
    "snout"
  ],
  "nostw": [
    "towns"
  ],
  "nosty": [
    "stony"
  ],
  "nosu": [
    "nous"
  ],
  "nosw": [
    "owns",
    "snow",
    "sown"
  ],
  "noswy": [
    "snowy"
  ],
  "nosy": [
    "nosy"
  ],
  "not": [
    "not",
    "ton"
  ],
  "notu": [
    "unto"
  ],
  "notw": [
    "town",
    "wont"
  ],
  "noty": [
    "tony"
  ],
  "now": [
    "now",
    "own",
    "won"
  ],
  "noy": [
    "yon"
  ],
  "npsu": [
    "puns",
    "spun"
  ],
  "nptu": [
    "punt"
  ],
  "npu": [
    "pun"
  ],
  "npuy": [
    "puny"
  ],
  "nrstu": [
    "turns"
  ],
  "nrsu": [
    "runs",
    "urns"
  ],
  "nrtu": [
    "runt",
    "turn"
  ],
  "nru": [
    "run",
    "urn"
  ],
  "nssttu": [
    "stunts"
  ],
  "nsttu": [
    "stunt"
  ],
  "nstu": [
    "nuts",
    "stun"
  ],
  "nsu": [
    "sun",
    "uns"
  ],
  "nttuy": [
    "nutty"
  ],
  "ntu": [
    "nut"
  ],
  "oopp": [
    "poop"
  ],
  "oopr": [
    "poor"
  ],
  "ooprst": [
    "troops"
  ],
  "ooprt": [
    "troop"
  ],
  "ooprtu": [
    "uproot"
  ],
  "oops": [
    "oops"
  ],
  "oopssw": [
    "swoops"
  ],
  "oopst": [
    "stoop"
  ],
  "oopsw": [
    "swoop",
    "woops"
  ],
  "oorrsw": [
    "sorrow"
  ],
  "oorst": [
    "roost",
    "roots",
    "torso"
  ],
  "oort": [
    "root",
    "roto",
    "toro"
  ],
  "oost": [
    "soot"
  ],
  "oostt": [
    "toots"
  ],
  "oot": [
    "too"
  ],
  "oott": [
    "otto",
    "toot"
  ],
  "oow": [
    "woo"
  ],
  "oowyz": [
    "woozy"
  ],
  "ooz": [
    "zoo"
  ],
  "opp": [
    "pop"
  ],
  "opppy": [
    "poppy"
  ],
  "oppr": [
    "prop"
  ],
  "opprs": [
    "props"
  ],
  "opps": [
    "pops"
  ],
  "opr": [
    "pro"
  ],
  "oprs": [
    "pros"
  ],
  "oprsst": [
    "sports"
  ],
  "oprst": [
    "ports",
    "sport"
  ],
  "oprstu": [
    "stupor"
  ],
  "oprsty": [
    "sporty"
  ],
  "oprsu": [
    "pours"
  ],
  "oprt": [
    "port"
  ],
  "opru": [
    "pour"
  ],
  "oprxy": [
    "proxy"
  ],
  "ops": [
    "ops",
    "sop"
  ],
  "opsst": [
    "posts",
    "spots",
    "stops"
  ],
  "opssu": [
    "soups"
  ],
  "opst": [
    "post",
    "pots",
    "spot",
    "stop",
    "tops"
  ],
  "opstty": [
    "spotty"
  ],
  "opsu": [
    "opus",
    "soup"
  ],
  "opsuy": [
    "soupy"
  ],
  "opsw": [
    "wops"
  ],
  "opt": [
    "pot",
    "top"
  ],
  "optty": [
    "potty"
  ],
  "optu": [
    "pout"
  ],
  "opty": [
    "typo"
  ],
  "opw": [
    "pow",
    "wop"
  ],
  "opx": [
    "pox"
  ],
  "orrsy": [
    "sorry"
  ],
  "orrwy": [
    "worry"
  ],
  "orsst": [
    "sorts"
  ],
  "orst": [
    "rots",
    "sort"
  ],
  "orsttu": [
    "tutors"
  ],
  "orstu": [
    "roust",
    "tours"
  ],
  "orstw": [
    "worst"
  ],
  "orsty": [
    "story"
  ],
  "orsu": [
    "ours",
    "sour"
  ],
  "orsuy": [
    "yours"
  ],
  "orsw": [
    "rows"
  ],
  "orsy": [
    "rosy"
  ],
  "ort": [
    "ort",
    "rot",
    "tor"
  ],
  "ortt": [
    "trot"
  ],
  "orttu": [
    "trout",
    "tutor"
  ],
  "orttuy": [
    "tryout"
  ],
  "ortu": [
    "tour"
  ],
  "orty": [
    "tory",
    "troy"
  ],
  "oru": [
    "our"
  ],
  "oruy": [
    "your"
  ],
  "orw": [
    "row"
  ],
  "oss": [
    "sos"
  ],
  "osst": [
    "toss"
  ],
  "ossu": [
    "sous"
  ],
  "ost": [
    "sot"
  ],
  "ostt": [
    "tots"
  ],
  "ostu": [
    "outs"
  ],
  "ostw": [
    "stow",
    "twos"
  ],
  "osty": [
    "toys"
  ],
  "osvw": [
    "vows"
  ],
  "osw": [
    "sow"
  ],
  "osx": [
    "sox"
  ],
  "osy": [
    "soy"
  ],
  "otu": [
    "out"
  ],
  "otw": [
    "tow",
    "two"
  ],
  "oty": [
    "toy"
  ],
  "ouy": [
    "you"
  ],
  "ovw": [
    "vow"
  ],
  "oww": [
    "wow"
  ],
  "pppuy": [
    "puppy"
  ],
  "ppu": [
    "pup"
  ],
  "prru": [
    "purr"
  ],
  "prssu": [
    "spurs"
  ],
  "prstu": [
    "spurt"
  ],
  "prsu": [
    "spur"
  ],
  "prsuy": [
    "syrup"
  ],
  "prsy": [
    "spry"
  ],
  "pry": [
    "pry"
  ],
  "psst": [
    "psst"
  ],
  "pssu": [
    "puss"
  ],
  "pssuy": [
    "pussy"
  ],
  "pstu": [
    "puts"
  ],
  "psu": [
    "pus",
    "sup",
    "ups"
  ],
  "psy": [
    "spy"
  ],
  "pttuy": [
    "putty"
  ],
  "ptu": [
    "put"
  ],
  "puy": [
    "yup"
  ],
  "rssttu": [
    "struts",
    "trusts"
  ],
  "rsstu": [
    "truss"
  ],
  "rsttu": [
    "strut",
    "trust"
  ],
  "rsttuy": [
    "trusty"
  ],
  "rstu": [
    "rust"
  ],
  "rstuy": [
    "rusty"
  ],
  "rtu": [
    "rut"
  ],
  "rty": [
    "try"
  ],
  "rwy": [
    "wry"
  ],
  "sssu": [
    "suss"
  ],
  "ssuw": [
    "wuss"
  ],
  "ssuwy": [
    "wussy"
  ],
  "sty": [
    "sty"
  ],
  "ttu": [
    "tut"
  ],
  "ttuu": [
    "tutu"
  ],
  "tux": [
    "tux"
  ]
};