export const Neutral0 = '#2B2D31';
export const Neutral1 = '#4F545F';
export const Neutral2 = '#626874';
export const Neutral3 = '#767B88';
export const Neutral4 = '#8A909C';
export const Neutral5 = '#9FA4B1';
export const Neutral6 = '#B4B9C5';
export const Neutral7 = '#CACFDA';
export const Neutral8 = '#E1E5EE';
export const Neutral9 = '#F5F8FF';
export const Neutral10 = '#FFFFFF';

export const Yellow0 = '#4C3E1A';
export const Yellow1 = '#695626';
export const Yellow2 = '#866F35';
export const Yellow3 = '#A48945';
export const Yellow4 = '#C1A357';
export const Yellow5 = '#DEBD6A';
export const Yellow6 = '#FBD87F';
export const Yellow7 = '#FFE6A7';
export const Yellow8 = '#FFF1CC';
export const Yellow9 = '#FFFBF2';

export const Blue0 = '#004C55';
export const Blue1 = '#006A77';
export const Blue2 = '#008999';
export const Blue3 = '#00A7BB';
export const Blue4 = '#0AC7DD';
export const Blue5 = '#1BE7FF';
export const Blue6 = '#4EECFF';
export const Blue7 = '#81F2FF';
export const Blue8 = '#B5F7FF';
export const Blue9 = '#E8FCFF';

export const Green0 = '#005C48';
export const Green1 = '#008568';
export const Green2 = '#00AD88';
export const Green3 = '#00D6A8';
export const Green4 = '#10FFCB';
export const Green5 = '#3BFFD4';
export const Green6 = '#66FFDD';
export const Green7 = '#91FFE7';
export const Green8 = '#BCFFF0';
export const Green9 = '#E7FFFA';

export const Pink0 = '#4D0922';
export const Pink1 = '#6F1234';
export const Pink2 = '#911F48';
export const Pink3 = '#B32E5E';
export const Pink4 = '#D54076';
export const Pink5 = '#F75590';
export const Pink6 = '#FF7DAC';
export const Pink7 = '#FFA3C4';
export const Pink8 = '#FFC8DC';
export const Pink9 = '#FFEEF4';
